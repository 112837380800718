import { LocalizedIssue } from './lfIntegrationApi';
import { ErrorOption } from 'react-hook-form';
import { i18n as I18n } from 'i18next';
import { getIssueMessage } from './util/getIssueMessage';
import { lfPathToNamePath } from './util/pathNameConvertions';

/**
 * Sets a list of LF issues probably obtained from the server into RHF via its
 * `setError` function.
 */
export function setLfIssues(
  issues: LocalizedIssue[],
  setError: (name: any, error: ErrorOption) => void,
  shouldFocus: boolean = false,
  i18n: I18n,
  i18nErrorMessagesPrefixes: string[] = []
) {
  for (const { path, code, data } of issues) {
    const namePath =
      data?.path != null ? lfPathToNamePath(data.path) : lfPathToNamePath(path);
    const message = getIssueMessage(
      namePath,
      code,
      data,
      i18n,
      i18nErrorMessagesPrefixes
    );
    setError(namePath, { type: code, message, shouldFocus });
  }
}
