import React, { ReactNode } from 'react';
import {
  Container,
  ContainerProps,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3, 2),
      },
      '@media print': {
        padding: 0,
        width: '100%',
        maxWidth: '100%',
      },
    },
    errorMessage: {
      color: theme.palette.error.light,
      fontWeight: 700,
      textAlign: 'center',
    },
  })
);

/**
 * Main content properties.
 */
export interface MainContentProps extends Omit<ContainerProps, 'children'> {
  children?: ReactNode;
  errorMessage?: ReactNode;
  className?: string;
}

/**
 * Main content wrapper (allows adding more classes via `className`).
 */
export function MainContent({
  children,
  errorMessage,
  className,
  ...otherProps
}: MainContentProps) {
  const classes = useStyles();

  return (
    <Container
      className={`app-main-content ${classes.root} ${className}`}
      maxWidth="lg"
      {...otherProps}
      data-cy="app-main-content"
    >
      {errorMessage && (
        <Typography
          className={classes.errorMessage}
          component="h1"
          variant="h2"
          data-cy="error-message"
        >
          {errorMessage}
        </Typography>
      )}
      {!errorMessage && children}
    </Container>
  );
}
