import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainContent } from '../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../util/formatters';
import { useCreditSettlement } from './useCreditSettlement';
import { ActionableHeader } from '../../components/ActionableHeader';
import { useCreditSettlementActions } from './useCreditSettlementActions';
import { NotSpecified } from '../../components/NotSpecified';
import {
  replacePathParams,
  TAXPAYER_PATH,
  USER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../navigation/paths';
import { Link } from '../../components/Link';
import { ExtraInfo } from '../../components/ExtraInfo';
import { CreditSettlementStatus } from './creditSettlementsApi';
import { UserRole } from '../../providers/ProfileProvider';
import { VoidCreditSettlementForm } from './VoidCreditSettlementForm';

/**
 * Page with tax information.
 */
export function CreditSettlementPage() {
  const [t, i18n] = useTranslation(['common', 'creditSettlements']);
  const { id } = useParams<{ id: string }>();
  useSetDocumentTitle(
    t('creditSettlements:creditSettlementPage.documentTitle', { id })
  );
  const {
    creditSettlement,
    isFetching,
    notFound,
    setCreditSettlement,
    refetch,
  } = useCreditSettlement(+id);
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const actions = useCreditSettlementActions(creditSettlement, () =>
    setIsVoidFormOpen(true)
  );

  // Assessment information fields
  const assessmentInformation: DescriptionListItem[] = [
    {
      key: 'assessment-number',
      label: t('creditSettlements:creditSettlementFields.assessmentNumber'),
      content: creditSettlement?.assessmentNumber ? (
        <>
          <Link
            to={replacePathParams(VAT_ASSESSMENT_PATH, {
              id: creditSettlement.assessmentNumber,
            })}
          >
            {creditSettlement.assessmentNumber}
          </Link>
          {creditSettlement?.assessmentNumberInserted &&
            creditSettlement.assessmentNumber !==
              creditSettlement.assessmentNumberInserted && (
              <ExtraInfo>
                {`${t(
                  'creditSettlements:creditSettlementFields.assessmentNumberInserted'
                )}: `}
                <Link
                  to={replacePathParams(VAT_ASSESSMENT_PATH, {
                    id: creditSettlement.assessmentNumberInserted,
                  })}
                >
                  {creditSettlement.assessmentNumberInserted}
                </Link>
              </ExtraInfo>
            )}
        </>
      ) : (
        <NotSpecified>{t('creditSettlements:noAssessmentNumber')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxpayer-id',
      label: t('creditSettlements:creditSettlementFields.taxpayerId'),
      content: creditSettlement && (
        <>
          <Link
            to={replacePathParams(TAXPAYER_PATH, {
              id: creditSettlement.taxpayerId,
            })}
          >
            {creditSettlement.taxpayerId}
          </Link>
          {creditSettlement.taxpayerName && (
            <ExtraInfo>{creditSettlement.taxpayerName}</ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxable-year-period',
      label: `${t('creditSettlements:creditSettlementFields.taxableYear')}/${t(
        'creditSettlements:creditSettlementFields.taxablePeriod'
      ).toLocaleLowerCase(i18n.language)}`,
      content: `${creditSettlement?.taxableYear}/${creditSettlement?.taxablePeriod}`,
      fetching: isFetching,
    },
  ];

  // Credit settlement information fields
  const creditSettlementInformation: DescriptionListItem[] = [
    {
      key: 'credit-settlement-date',
      label: t('creditSettlements:creditSettlementFields.creditSettlementDate'),
      content: formatDate(creditSettlement?.creditSettlementDate),
      fetching: isFetching,
    },
    {
      key: 'amount',
      label: t('creditSettlements:creditSettlementFields.amount'),
      content: formatCurrency(creditSettlement?.amount),
      fetching: isFetching,
    },
    {
      key: 'description',
      label: t('creditSettlements:creditSettlementFields.description'),
      content: creditSettlement?.description || (
        <NotSpecified>
          {t('creditSettlements:descriptionNotSpecified')}
        </NotSpecified>
      ),
      preserveWhiteSpaces: true,
      fetching: isFetching,
    },
    {
      key: 'created-date',
      label: t('creditSettlements:creditSettlementFields.createdDate'),
      content: creditSettlement && (
        <>
          {formatDateTime(creditSettlement.createdDate)}
          <ExtraInfo>
            {t(
              'creditSettlements:creditSettlementFields.createdBy'
            ).toLocaleLowerCase(i18n.language)}{' '}
            {
              <Link
                to={replacePathParams(USER_PATH, {
                  username: creditSettlement.createdBy,
                })}
                role={UserRole.Manager}
              >
                {creditSettlement.createdBy}
              </Link>
            }
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'changed-date',
      label: t('creditSettlements:creditSettlementFields.changedDate'),
      content: creditSettlement && (
        <>
          {formatDateTime(creditSettlement.changedDate)}
          {creditSettlement.status === CreditSettlementStatus.Voided &&
            creditSettlement.changedBy && (
              <ExtraInfo>
                {t(
                  'creditSettlements:creditSettlementFields.changedBy'
                ).toLocaleLowerCase(i18n.language)}{' '}
                {
                  <Link
                    to={replacePathParams(USER_PATH, {
                      username: creditSettlement.changedBy,
                    })}
                    role={UserRole.Manager}
                  >
                    {creditSettlement.changedBy}
                  </Link>
                }
              </ExtraInfo>
            )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'void-description',
      label: t('creditSettlements:creditSettlementFields.voidDescription'),
      content: creditSettlement?.voidDescription || (
        <NotSpecified>
          {t('creditSettlements:voidDescriptionNotSpecified')}
        </NotSpecified>
      ),
      preserveWhiteSpaces: true,
      hidden:
        isFetching ||
        creditSettlement!.status !== CreditSettlementStatus.Voided,
    },
  ];

  return (
    <MainContent
      errorMessage={notFound && t('creditSettlements:creditSettlementNotFound')}
    >
      <ActionableHeader
        title={t('creditSettlements:creditSettlementPage.title')}
        titleId={id}
        status={
          creditSettlement &&
          t(
            `creditSettlements:creditSettlementStatus.${creditSettlement.status}`
          )
        }
        statusVariant={
          creditSettlement?.status === CreditSettlementStatus.Voided
            ? 'error'
            : 'success'
        }
        actions={actions}
      />

      <VoidCreditSettlementForm
        creditSettlement={creditSettlement}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newCreditSettlement) =>
          setCreditSettlement(newCreditSettlement)
        }
        onFailureDueToOutdatedUI={refetch}
      />

      <PaperDescriptionList
        title={t('creditSettlements:assessmentInformation')}
        items={assessmentInformation}
      />
      <PaperDescriptionList
        title={t('creditSettlements:creditSettlementInformation')}
        items={creditSettlementInformation}
      />
    </MainContent>
  );
}
