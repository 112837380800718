import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Payment, usePaymentsApi } from './paymentsApi';
import { useLfForm } from '../../util/lfIntegration';
import { Form, FormTextField } from '../../components/Form';
import { ivageCommon } from '../../ivageCommon';
import { TitleId } from '../../components/TitleId';
import {Action, DialogActionButtons} from "../../components/Actions";

/**
 * Package in IVAGE common containing the void payment form schema.
 */
const voidPaymentFormSchemaPkg = ivageCommon.feature.payment.schema;

/**
 * Properties of the void payment form.
 */
export interface VoidPaymentFormProps {
  payment: Payment | null;
  open: boolean;

  setOpen(open: boolean): void;

  onSuccess(payment: Payment): void;

  onFailureDueToOutdatedUI(): void;
}

/**
 * Modal form used to void a payment.
 */
export function VoidPaymentForm({
  payment,
  open,
  setOpen,
  onSuccess,
  onFailureDueToOutdatedUI,
}: VoidPaymentFormProps) {
  const [t] = useTranslation(['common', 'payments']);
  const { voidPayment } = usePaymentsApi();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: { voidDescription: '' },
    formValidatorName: 'voidPaymentFormValidator',
    i18nErrorMessagesPrefixes: 'payments:voidPaymentForm.fieldErrors',
  });
  const { reset, getValues, formState } = formMethods;

  // Reset form whenever the payment changes or modal changes open state
  useEffect(() => {
    reset();
  }, [reset, payment, open]);

  // Close form modal
  function close() {
    setOpen(false);
  }

  async function onSubmit() {
    const voidPaymentForm = getValues();
    try {
      const newPayment = await voidPayment(payment!.paymentId, voidPaymentForm);
      close();
      onSuccess(newPayment);
      enqueueSnackbar(
        t('payments:paymentVoidedSuccessfully', { id: payment!.paymentId }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        close();
        onFailureDueToOutdatedUI();
        enqueueSnackbar(
          t('payments:errorVoidingPaymentDueToOutdatedUI', {
            id: payment!.paymentId,
          }),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          t('payments:errorVoidingPayment', { id: payment!.paymentId }),
          { variant: 'error' }
        );
      }
    }
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: `void-payment-form-${payment?.paymentId}-submit`,
      type: 'submit',
      label: t('payments:voidPaymentForm.void'),
      style: 'error',
      loading: formState.isSubmitting,
      disabled: !payment,
    },
    {
      id: `void-payment-form-${payment?.paymentId}-cancel`,
      label: t('formActions.cancel'),
      run: () => close(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={close}
      disableBackdropClick={formState.isDirty || formState.isSubmitting}
      disableEscapeKeyDown={formState.isDirty || formState.isSubmitting}
      aria-labelledby="void-payment-form"
    >
      <Form onSubmit={onSubmit} {...formMethods}>
        <DialogTitle id="void-payment-form">
          {t('payments:voidPaymentForm.title') + ' '}
          <TitleId titleId={payment?.paymentId} />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Void description */}
            <Grid item xs={12}>
              <FormTextField
                name="voidDescription"
                label={t('payments:paymentFields.voidDescription')}
                multiline
                rows={5}
                inputProps={{
                  maxLength:
                    voidPaymentFormSchemaPkg.VOID_DESCRIPTION_MAX_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
