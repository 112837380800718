import { getMonth, getYear, startOfToday, subMonths } from 'date-fns';
import { PaymentForm } from '../paymentsApi';
import { localDateToSameDateInUTC } from '../../../util/dateUtils';
import { TaxablePeriod, TaxId } from '../../../common';

/**
 * Default "payment form" value.
 */
export function defaultPaymentForm(): PaymentForm {
  return {
    taxpayerId: '',
    assessmentNumber: '',
    taxId: TaxId.VAT,
    taxableYear: getYear(subMonths(new Date(), 1)),
    taxablePeriod: (getMonth(subMonths(new Date(), 1)) + 1)
      .toString()
      .padStart(2, '0') as TaxablePeriod,
    paymentDate: localDateToSameDateInUTC(startOfToday()),
    amount: null,
    bankId: null,
    transactionMethod: null,
  };
}
