import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  CHECKING_ACCOUNT_PATH,
  replacePathParams,
} from '../../navigation/paths';
import { Action } from '../../components/Actions';
import { CheckingAccount } from './checkingAccountsApi';
import { OpenInNew } from '@material-ui/icons';

/**
 * Hook that returns actions that can be performed on a checking account.
 */
export function useCheckingAccountActions(
  checkingAccount: CheckingAccount | null
): Action[] {
  const [t] = useTranslation('checkingAccounts');
  const { pathname } = useLocation();

  const accountPath = replacePathParams(CHECKING_ACCOUNT_PATH, {
    id: checkingAccount?.taxpayerId,
  });

  return useMemo(
    () => [
      {
        id: `checking-account-${checkingAccount?.taxpayerId}-open`,
        label: t('checkingAccounts:checkingAccountActions.open'),
        component: Link,
        to: accountPath,
        icon: <OpenInNew />,
        hidden: !checkingAccount || pathname === accountPath,
      },
    ],
    [t, accountPath, checkingAccount, pathname]
  );
}
