import { useCallback } from 'react';
import { TaxablePeriod } from '../../common';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Representation of a VAT omission.
 */
export interface VatOmission {
  taxpayerId: string;
  taxableYear: number;
  taxablePeriod: TaxablePeriod;
  name: string;
  representative: string;
  phone: string;
  email: string;
}

/**
 * Options used to get VAT omissions.
 */
export interface GetVatOmissionsFilterOptions {
  taxpayerId?: string;
  taxableYear?: number;
  taxablePeriod?: TaxablePeriod;
}

/**
 * URL to access VAT omissions.
 */
export const VAT_OMISSIONS_URL = '/api/vat-omissions';

/**
 * Hook exposing the VAT omissions API.
 */
export function useVatOmissionsApi() {
  const { getJson, postJson } = useFetchFromApi();

  /**
   * Returns a list of all VAT omissions filtered by the provided options.
   */
  const getVatOmissions = useCallback(
    async (
      filterOptions: GetVatOmissionsFilterOptions = {}
    ): Promise<VatOmission[]> =>
      await getJson(VAT_OMISSIONS_URL, { params: filterOptions }),
    [getJson]
  );

  /**
   * Notifying a taxpayer of a VAT omission by email.
   */
  const notifyVatOmissionByEmail = useCallback(
    (
      taxpayerId: string,
      taxableYear: number,
      taxablePeriod: TaxablePeriod
    ): Promise<void> => {
      const vatOmissionId = [taxpayerId, taxableYear, taxablePeriod].join('-');
      return postJson(
        `${VAT_OMISSIONS_URL}/${vatOmissionId}/email-notification`
      );
    },
    [postJson]
  );

  return { getVatOmissions, notifyVatOmissionByEmail };
}
