import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
    },
    margin: {
      fontSize: '2rem',
      lineHeight: 0,
    },
  })
);

interface CustomExtraInfoProps {
  children: ReactNode;
  nonBreaking?: boolean;
  marginSide?: 'left' | 'right';
}

/**
 * Properties of the extra info.
 */
export type ExtraInfoProps = CustomExtraInfoProps &
  DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

/**
 * Component to display "extra" information.
 */
export function ExtraInfo({
  children,
  nonBreaking = false,
  marginSide = 'left',
  className,
  ...props
}: ExtraInfoProps) {
  const classes = useStyles();

  // Use a white space character as "margin" to allow text to break (unless
  // `nonBreaking` is set)
  const marginChar = nonBreaking ? ' ' : ' ';
  const marginEl = <span className={classes.margin}>{marginChar}</span>;

  return (
    <span
      data-cy="extra"
      {...props}
      className={`${classes.root} ${className || ''}`}
    >
      {marginSide === 'left' && marginEl}({children})
      {marginSide === 'right' && marginEl}
    </span>
  );
}
