/**
 * Regex used to get the namespace used by a key.
 */
const NS_REGEX = /^(\w+):.*$/;

/**
 * Returns the i18n ns used by the key
 */
export function getI18nNsFromKey(key: string): string | null {
  return key.match(NS_REGEX)?.[1] ?? null;
}
