import React from 'react';
import { VatAssessment } from '../vatAssessmentsApi';
import { ActionButtons } from '../../../components/Actions';
import { useVatAssessmentActions } from '../useVatAssessmentActions';
import { NotSpecified } from '../../../components/NotSpecified';
import { useTranslation } from 'react-i18next';

/**
 * Properties for the VAT assessment history row actions.
 */
interface VatAssessmentHistoryRowActionsProps {
  // Whether this assessment is the one currently in view
  currentlyOpen: boolean;
  vatAssessment: VatAssessment;
  onRecalculate: (
    oldVatAssessment: VatAssessment,
    newVatAssessment: VatAssessment
  ) => void;
  onFailureDueToOutdatedUI: () => void;
}

/**
 * Actions in a VAT assessment history row.
 */
export function VatAssessmentHistoryRowActions({
  currentlyOpen,
  vatAssessment,
  onRecalculate,
  onFailureDueToOutdatedUI,
}: VatAssessmentHistoryRowActionsProps) {
  const [t] = useTranslation('vatAssessments');
  const actions = useVatAssessmentActions(
    vatAssessment,
    (newVatAssessment) => onRecalculate(vatAssessment, newVatAssessment),
    onFailureDueToOutdatedUI
  );

  return currentlyOpen ? (
    <NotSpecified>
      {t(
        'vatAssessments:vatAssessmentPage.vatAssessmentHistoryDataTable.currentlyOpen'
      )}
    </NotSpecified>
  ) : (
    <ActionButtons actions={actions} variant="outlined" size="small" />
  );
}
