import { eachMonthOfInterval, getYear, isAfter, isBefore } from 'date-fns';
import { VatActivity } from '../../Taxpayers';

/**
 * Months when a VAT return can be filed according to the taxpayer's tax
 * activities.
 */
export function activeMonthDates(
  vatActivities: VatActivity[],
  vatReturnInitialTaxableYearPeriod: Date
): Date[] {
  const lastAllowedDate = new Date();
  return [
    ...new Set(
      vatActivities.flatMap(({ startDate, endDate }) => {
        const clampStartDate = isBefore(
          startDate,
          vatReturnInitialTaxableYearPeriod
        )
          ? vatReturnInitialTaxableYearPeriod
          : startDate;
        const clampEndDate =
          endDate == null || isAfter(endDate, lastAllowedDate)
            ? lastAllowedDate
            : endDate;
        return isAfter(clampStartDate, clampEndDate)
          ? []
          : eachMonthOfInterval({
              start: clampStartDate,
              end: clampEndDate,
            }).map((month) => +month);
      })
    ),
  ].map((timestamp) => new Date(timestamp));
}

/**
 * Set of years when a VAT return can be filed, given the active months.
 */
export function activeYears(activeMonthDates: Date[]): Set<number> {
  return new Set(activeMonthDates.map((date) => getYear(date)));
}
