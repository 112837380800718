import { ReactNode } from 'react';

/**
 * An action used throughout the application.
 */
export interface Action {
  /**
   * When providing a `run` function, the `id` of an action should be unique for
   * the **whole** application, since we use the global store partially indexed
   * by this id to manage state associated with the action (whether the action
   * is currently running: information that should be kept across views).
   */
  id: string;
  label: ReactNode;
  run?: (event?: any) => void;
  disabled?: boolean;
  loading?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  style?: ActionStyle;
  [otherProp: string]: any;
}

/**
 * A group of actions.
 */
export interface ActionGroup extends Action {
  children: Action[];
  // Unsupported properties
  run?: never;
  loading?: never;
}

/**
 * Style of an action.
 */
export type ActionStyle = 'default' | 'success' | 'error';

/**
 * Whether an action is an action group.
 */
export function isActionGroup(action: Action): action is ActionGroup {
  return Array.isArray(action.children);
}
