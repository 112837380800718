import React, { useMemo } from 'react';
import { OpenInNew, RemoveCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  CREDIT_SETTLEMENT_PATH,
  replacePathParams,
} from '../../navigation/paths';
import { Action } from '../../components/Actions';
import {
  CreditSettlement,
  CreditSettlementStatus,
} from './creditSettlementsApi';

/**
 * Hook that returns actions that can be performed on a credit settlement.
 */
export function useCreditSettlementActions(
  creditSettlement: CreditSettlement | null,
  onVoid: () => void
): Action[] {
  const [t] = useTranslation('creditSettlements');
  const { pathname } = useLocation();

  const creditSettlementPath = replacePathParams(CREDIT_SETTLEMENT_PATH, {
    id: creditSettlement?.creditSettlementId,
  });

  return useMemo(
    () => [
      {
        id: `credit-settlement-${creditSettlement?.creditSettlementId}-open`,
        label: t('creditSettlements:creditSettlementActions.open'),
        component: Link,
        to: creditSettlementPath,
        icon: <OpenInNew />,
        hidden: !creditSettlement || pathname === creditSettlementPath,
      },
      {
        id: `credit-settlement-${creditSettlement?.creditSettlementId}-void`,
        label: t('creditSettlements:creditSettlementActions.void'),
        run: onVoid,
        icon: <RemoveCircleOutline />,
        style: 'error',
        hidden:
          !creditSettlement ||
          creditSettlement.status === CreditSettlementStatus.Voided,
      },
    ],
    [t, creditSettlementPath, creditSettlement, pathname, onVoid]
  );
}
