import React, { useState, Suspense } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Lock } from '@material-ui/icons';
import {
  NavigationTreeItem,
  useNavigationTree,
} from '../navigation/useNavigationTree';
import { Skeleton } from '@material-ui/lab';
import { useProfile } from '../providers/ProfileProvider';
import { AppVersion } from '../components/AppVersion';
import { ThemeVariant } from '../providers/ThemeProvider';

/**
 * Style of drawer list item.
 */
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    list: {
      flex: 1,
    },
    lockedDrawer: {
      textAlign: 'center',
      fontSize: '7rem',
      margin: theme.spacing(2),
      color:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
    },
    version: {
      width: '100%',
      padding: theme.spacing(1),
      textAlign: 'center',
    },
  })
);

/**
 * Style of drawer list item.
 */
const useListItemStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: ({ depth }: Pick<DrawerListItemProps, 'depth'>) =>
        theme.spacing(2 * (depth + 1)),
    },
    fetching: {
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
    },
    text: {
      fontWeight: ({ depth }: Pick<DrawerListItemProps, 'depth'>) =>
        depth === 0 ? 500 : 'inherit',
    },
  })
);

/**
 * Properties of the drawer content.
 */
export interface DrawerContentProps {
  onItemClick?: () => void;
}

/**
 * Content of the drawer.
 */
export function DrawerContent(props: DrawerContentProps) {
  const classes = useStyle();
  const { isFetching, mustChangePassword } = useProfile();
  const navigationTree = useNavigationTree();

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {/* Profile loading, show skeleton */}
        {isFetching && <FetchingListItems skeletons={3} />}

        {/* Must change password */}
        {mustChangePassword && (
          <div className={classes.lockedDrawer}>
            <Lock fontSize="inherit" />
          </div>
        )}

        {/* Profile loaded */}
        {!isFetching &&
          !mustChangePassword &&
          navigationTree.map((item) => (
            <DrawerListItem
              item={item}
              depth={0}
              key={item.key}
              drawerProps={props}
            />
          ))}
      </List>

      {/* Application version */}
      <AppVersion className={classes.version} />
    </div>
  );
}

/**
 * Properties of a fetching list item.
 */
interface FetchingListItemsProps {
  skeletons?: number;
  depth?: number;
}

/**
 * Component shown when fetching list items.
 */
function FetchingListItems({
  skeletons = 2,
  depth = 0,
}: FetchingListItemsProps) {
  const classes = useListItemStyle({ depth });

  return (
    <>
      {Array(skeletons)
        .fill(0)
        .map((_, i) => (
          <ListItem
            key={`skeleton-${i}`}
            className={`${classes.root} ${classes.fetching}`}
          >
            <ListItemText
              primary={
                <Skeleton
                  width={
                    (i + 1) % 3 === 0
                      ? '87.5%'
                      : (i + 1) % 2 === 0
                      ? '100%'
                      : '75%'
                  }
                />
              }
            />
          </ListItem>
        ))}
    </>
  );
}

/**
 * Properties of a drawer list item.
 */
interface DrawerListItemProps {
  item: NavigationTreeItem;
  depth: number;
  drawerProps: DrawerContentProps;
}

/**
 * Drawer list item.
 */
function DrawerListItem({ item, depth, drawerProps }: DrawerListItemProps) {
  const { hasRole } = useProfile();

  return item.role && !hasRole(item.role) ? null : item.children != null ? (
    <CollapsibleDrawerListItem
      item={item}
      depth={depth}
      drawerProps={drawerProps}
    />
  ) : (
    <NonCollapsibleDrawerListItem
      item={item}
      depth={depth}
      drawerProps={drawerProps}
    />
  );
}

/**
 * Non-collapsible drawer list item.
 */
function NonCollapsibleDrawerListItem({
  item,
  depth,
  drawerProps,
}: DrawerListItemProps) {
  const classes = useListItemStyle({ depth });
  const location = useLocation();
  const isSelected = item.path != null && item.path === location.pathname;
  const linkProps = item.path != null ? { component: Link, to: item.path } : {};
  return (
    <ListItem
      className={classes.root}
      button
      dense
      selected={isSelected}
      onClick={drawerProps.onItemClick}
      {...linkProps}
      data-cy={`drawer-item-${item.key}`}
    >
      <ListItemText classes={{ primary: classes.text }} primary={item.label} />
    </ListItem>
  );
}

/**
 * Collapsible drawer list item.
 */
function CollapsibleDrawerListItem({
  item,
  depth,
  drawerProps,
}: DrawerListItemProps) {
  const classes = useListItemStyle({ depth });
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(shouldStartOpen(item));

  // Whether the item should start open (if it matches the `childrenPathRegExp`
  // or one of its children items is active)
  function shouldStartOpen(item: NavigationTreeItem): boolean {
    return (
      (item.childrenPathRegExp &&
        item.childrenPathRegExp.test(location.pathname)) ||
      item.path === location.pathname ||
      (Array.isArray(item.children) &&
        item.children.some((child) => shouldStartOpen(child)))
    );
  }

  // Children as a component
  const ItemChildren =
    item.children != null && !Array.isArray(item.children)
      ? item.children
      : null;

  function renderChildrenItems(items: NavigationTreeItem[]) {
    return (
      <>
        {items.map((it) => (
          <DrawerListItem
            item={it}
            depth={depth + 1}
            key={it.key}
            drawerProps={drawerProps}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <ListItem
        className={classes.root}
        button
        dense
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        data-cy={`drawer-item-${item.key}`}
      >
        <ListItemText
          classes={{ primary: classes.text }}
          primary={item.label}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" mountOnEnter>
        <List disablePadding>
          {ItemChildren ? (
            <Suspense fallback={<FetchingListItems depth={depth + 1} />}>
              <ItemChildren
                renderChildren={renderChildrenItems}
                renderFetching={() => <FetchingListItems depth={depth + 1} />}
              />
            </Suspense>
          ) : (
            renderChildrenItems(item.children as NavigationTreeItem[])
          )}
        </List>
      </Collapse>
    </>
  );
}
