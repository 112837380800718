import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { TaxRefund, useTaxRefundsApi } from './taxRefundsApi';
import { useLfForm } from '../../util/lfIntegration';
import { Form, FormTextField } from '../../components/Form';
import { ivageCommon } from '../../ivageCommon';
import { TitleId } from '../../components/TitleId';
import {Action, DialogActionButtons} from "../../components/Actions";

/**
 * Package in IVAGE common containing the void tax refund form schema.
 */
const voidTaxRefundFormSchemaPkg = ivageCommon.feature.taxrefund.schema;

/**
 * Properties of the void tax refund form.
 */
export interface VoidTaxRefundFormProps {
  taxRefund: TaxRefund | null;
  open: boolean;

  setOpen(open: boolean): void;

  onSuccess(taxRefund: TaxRefund): void;

  onFailureDueToOutdatedUI(): void;
}

/**
 * Modal form used to void a tax refund.
 */
export function VoidTaxRefundForm({
  taxRefund,
  open,
  setOpen,
  onSuccess,
  onFailureDueToOutdatedUI,
}: VoidTaxRefundFormProps) {
  const [t] = useTranslation(['common', 'taxRefunds']);
  const { voidTaxRefund } = useTaxRefundsApi();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: { voidDescription: '' },
    formValidatorName: 'voidTaxRefundFormValidator',
    i18nErrorMessagesPrefixes: 'taxRefunds:voidTaxRefundForm.fieldErrors',
  });
  const { reset, getValues, formState } = formMethods;

  // Reset form whenever the tax refund changes or modal changes open state
  useEffect(() => {
    reset();
  }, [reset, taxRefund, open]);

  // Close form modal
  function close() {
    setOpen(false);
  }

  async function onSubmit() {
    const voidTaxRefundForm = getValues();
    try {
      const newTaxRefund = await voidTaxRefund(
        taxRefund!.taxRefundId,
        voidTaxRefundForm
      );
      close();
      onSuccess(newTaxRefund);
      enqueueSnackbar(
        t('taxRefunds:taxRefundVoidedSuccessfully', {
          id: taxRefund!.taxRefundId,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        close();
        onFailureDueToOutdatedUI();
        enqueueSnackbar(
          t('taxRefunds:errorVoidingTaxRefundDueToOutdatedUI', {
            id: taxRefund!.taxRefundId,
          }),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          t('taxRefunds:errorVoidingTaxRefund', { id: taxRefund!.taxRefundId }),
          { variant: 'error' }
        );
      }
    }
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: `void-tax-refund-form-${taxRefund?.taxRefundId}-submit`,
      type: 'submit',
      label: t('taxRefunds:voidTaxRefundForm.void'),
      style: 'error',
      loading: formState.isSubmitting,
      disabled: !taxRefund,
    },
    {
      id: `void-tax-refund-form-${taxRefund?.taxRefundId}-cancel`,
      label: t('formActions.cancel'),
      run: () => close(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={close}
      disableBackdropClick={formState.isDirty || formState.isSubmitting}
      disableEscapeKeyDown={formState.isDirty || formState.isSubmitting}
      aria-labelledby="void-tax-refund-form"
    >
      <Form onSubmit={onSubmit} {...formMethods}>
        <DialogTitle id="void-tax-refund-form">
          {t('taxRefunds:voidTaxRefundForm.title') + ' '}
          <TitleId titleId={taxRefund?.taxRefundId} />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Void description */}
            <Grid item xs={12}>
              <FormTextField
                name="voidDescription"
                label={t('taxRefunds:taxRefundFields.voidDescription')}
                multiline
                rows={5}
                inputProps={{
                  maxLength:
                    voidTaxRefundFormSchemaPkg.VOID_DESCRIPTION_MAX_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
