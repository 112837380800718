import React, { ReactNode, useCallback } from 'react';
import {
  FetchFromApiContext,
  RequestInitWithParams,
} from './FetchFromApiContext';
import { useReauthentication } from '../ReauthenticationProvider';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { objectToQueryParamsString } from '../../util/objectToQueryParamsString';
import { useResetPassword } from '../ResetPasswordProvider';

/**
 * Properties of the fetch from API provider.
 */
export interface FetchFromApiProviderProps {
  children: ReactNode;
}

/**
 * Whether a response has JSON content.
 */
function responseHasJsonContent(response: Response): boolean {
  const contentType = response.headers.get('content-type');
  return !!(contentType && contentType.indexOf('application/json') !== -1);
}

/**
 * Fetch from API provider. Throughout the application, we should use the
 * provided fetch from API object whenever we want to fetch from the API since
 * this provider handles all `401` responses by requesting that the user logs in
 * again.
 */
export function FetchFromApiProvider({ children }: FetchFromApiProviderProps) {
  const [t] = useTranslation('common');
  const { reauthenticate } = useReauthentication();
  const { resetPassword } = useResetPassword();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Fetch implementation that detects two situations:
   * - A `401` status (lack of authentication): the implementation requests that
   * the user logs in again before retrying to fetch.
   * - A `403` status with a "mustChangePassword" message: the implementation
   * asks the user to reset the password before retrying to fetch.
   */
  const fetchImpl = useCallback(
    async (input: RequestInfo, init?: RequestInit) => {
      while (true) {
        const response = await fetch(input, init);
        if (response.status === 401) {
          enqueueSnackbar(t('authentication.sessionExpired'), {
            id: 'session-expired',
            variant: 'error',
            preventDuplicate: true,
          });
          if (await reauthenticate()) {
            continue;
          }
        } else if (
          response.status === 403 &&
          responseHasJsonContent(response) &&
          (await response.json()).message === 'mustChangePassword'
        ) {
          enqueueSnackbar(t('resetPassword.passwordResetRequired'), {
            id: 'reset-password-requested',
            variant: 'info',
            preventDuplicate: true,
          });
          if (await resetPassword()) {
            continue;
          }
        }
        return response;
      }
    },
    [enqueueSnackbar, reauthenticate, resetPassword, t]
  );

  /**
   * Fetch JSON implementation that throws non-`ok` responses and supports query
   * params.
   */
  const fetchJson = useCallback(
    async (url: string, initWithParams?: RequestInitWithParams) => {
      const { params, ...init } = initWithParams ?? {};
      const response = await fetchImpl(
        url +
          (params && Object.keys(params).length > 0
            ? `?${objectToQueryParamsString(params)}`
            : ''),
        {
          ...init,
          headers: {
            ...(init.body != null
              ? { 'Content-Type': 'application/json' }
              : {}),
            ...init?.headers,
          },
        }
      );
      if (!response) {
        return null;
      } else if (response.ok) {
        if (responseHasJsonContent(response)) {
          return await response.json();
        }
      } else {
        throw response;
      }
    },
    [fetchImpl]
  );

  /**
   * Get JSON implementation.
   */
  const getJson = useCallback(
    async (url: string, init?: RequestInitWithParams) => fetchJson(url, init),
    [fetchJson]
  );

  /**
   * Delete JSON implementation.
   */
  const deleteJson = useCallback(
    async (url: string, init?: RequestInitWithParams) =>
      fetchJson(url, { ...init, method: 'DELETE' }),
    [fetchJson]
  );

  /**
   * Post JSON implementation that sends the provided `data` as a JSON string.
   */
  const postJson = useCallback(
    async (url: string, data?: any, init?: RequestInitWithParams) =>
      fetchJson(url, {
        ...init,
        method: 'POST',
        ...(data === undefined ? {} : { body: JSON.stringify(data) }),
      }),
    [fetchJson]
  );

  /**
   * Patch JSON implementation that sends the provided `data` as a JSON string.
   */
  const patchJson = useCallback(
    async (url: string, data?: any, init?: RequestInitWithParams) =>
      fetchJson(url, {
        ...init,
        method: 'PATCH',
        ...(data === undefined ? {} : { body: JSON.stringify(data) }),
      }),
    [fetchJson]
  );

  /**
   * Put JSON implementation that sends the provided `data` as a JSON string.
   */
  const putJson = useCallback(
    async (url: string, data?: any, init?: RequestInitWithParams) =>
      fetchJson(url, {
        ...init,
        method: 'PUT',
        ...(data === undefined ? {} : { body: JSON.stringify(data) }),
      }),
    [fetchJson]
  );

  return (
    <FetchFromApiContext.Provider
      value={{
        fetch: fetchImpl,
        getJson,
        deleteJson,
        postJson,
        patchJson,
        putJson,
      }}
    >
      {children}
    </FetchFromApiContext.Provider>
  );
}
