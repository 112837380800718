import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { useTranslation } from 'react-i18next';
import { useVatAssessmentsApi, VatAssessment } from './vatAssessmentsApi';

/**
 * Result of the `useVatAssessment` hook.
 */
export interface VatAssessmentResource {
  isFetching: boolean;
  vatAssessment: VatAssessment | null;
  notFound: boolean;
  setVatAssessment: Dispatch<SetStateAction<VatAssessment | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a VAT assessment from the API, if no `assessmentNumber` is
 * passed this hook does nothing.
 */
export function useVatAssessment(
  assessmentNumber?: string
): VatAssessmentResource {
  const [t] = useTranslation('vatAssessments');
  const { getVatAssessment } = useVatAssessmentsApi();
  const getAssessmentCb = useCallback(
    () =>
      assessmentNumber != null ? getVatAssessment(assessmentNumber) : null,
    [assessmentNumber, getVatAssessment]
  );
  const {
    isFetching,
    resource: vatAssessment,
    notFound,
    setResource: setVatAssessment,
    refetch,
  } = useResource({
    fetchResource: getAssessmentCb,
    errorFetchingResourceMessage: t(
      'vatAssessments:errorFetchingVatAssessment',
      { id: assessmentNumber ?? '' }
    ),
  });

  return { isFetching, vatAssessment, notFound, setVatAssessment, refetch };
}
