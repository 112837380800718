import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Methods to interact with history.
 */
export type HistoryMethod = 'push' | 'replace';

/**
 * Result of the `useQueryParams` hook.
 */
export type UseQueryParamsResult = [
  queryParam: Record<string, string>,
  setQueryParams: (
    queryParams: Record<string, any>,
    method?: HistoryMethod
  ) => void
];

/**
 * Hook to get/set query params from/into the current location, if
 * `allowedParams` is passed, then only those params will be considered.
 */
export function useQueryParams(allowedParams?: string[]): UseQueryParamsResult {
  const location = useLocation();
  const history = useHistory();

  /**
   * `location.search` string but with only the relevant query params and in
   * alphabetical order (so that changes in the order don't affect the query
   * params object).
   */
  const relevantSearch = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const relevantUrlParamsArray = [];
    for (const urlParam of urlParams) {
      if (!allowedParams || allowedParams.indexOf(urlParam[0]) !== -1) {
        relevantUrlParamsArray.push(urlParam);
      }
    }
    relevantUrlParamsArray.sort(([param1], [param2]) =>
      param1.localeCompare(param2)
    );
    return new URLSearchParams(relevantUrlParamsArray).toString();
  }, [allowedParams, location.search]);

  /**
   * Object representing the relevant query params.
   */
  const queryParams = useMemo(() => {
    const params: Record<string, any> = {};
    const urlParams = new URLSearchParams(relevantSearch);
    for (const [param, paramVal] of urlParams) {
      params[param] = paramVal;
    }
    return params;
  }, [relevantSearch]);

  /**
   * Function to set query params. Only the params in the provided object will
   * be changed, others will be left as is. Params with a value that `== null`
   * will be removed, other values are set as strings.
   */
  const setQueryParams = useCallback(
    (params: Record<string, any>, method: HistoryMethod = 'push') => {
      const urlParams = new URLSearchParams(location.search);
      for (const param of Object.keys(params)) {
        if (!allowedParams || allowedParams.indexOf(param) !== -1) {
          const paramVal = params[param];
          if (paramVal != null) {
            urlParams.set(param, String(paramVal));
          } else {
            urlParams.delete(param);
          }
        }
      }
      history[method](`${location.pathname}?${urlParams.toString()}`);
    },
    [allowedParams, history, location.pathname, location.search]
  );

  return [queryParams, setQueryParams];
}
