import { useCallback } from 'react';
import { TaxablePeriod } from '../../common';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Type of a VAT assessment.
 */
export interface VatAssessment {
  assessmentId: number;
  assessmentNumber: string;
  taxReturnId: number;
  taxpayerId: string;
  taxpayerName: string;
  taxpayerEmail: string;
  taxableYear: number;
  taxablePeriod: TaxablePeriod;
  vatFinal: number;
  interestFinal: number;
  vatRefundableFinal: number;
  paymentRefundableFinal: number;
  deadlineDate: Date | null;
  excessReported: number;
  vatTotal: number;
  vatCredit: number;
  previousPeriodsCompensation: number;
  vatTotalAdjusted: number;
  vatCreditAdjusted: number;
  previousPeriodsCompensationAdjusted: number;
  status: VatAssessmentStatus;
  statusDate: Date;
  createdDate: Date;
}

/**
 * Status of a VAT assessment.
 */
export enum VatAssessmentStatus {
  Effective = 'EFFECTIVE',
  Replaced = 'REPLACED',
  Voided = 'VOIDED',
}

/**
 * Options used to get VAT assessments.
 */
export interface GetVatAssessmentsFilterOptions {
  taxpayerId?: string;
  taxableYear?: number;
  taxablePeriod?: TaxablePeriod;
  latestPerTaxableYearPeriod?: boolean;
}

/**
 * Representation of a VAT event.
 */
export interface VatEvent {
  eventId: number;
  eventType: VatEventType;
  eventResourceId: number;
  eventDate: Date;
  valueDate: Date;
  // When the `eventType` is "VAT return", this value can be negative or
  // positive depending on whether the value is to be entered or to compensate
  // respectively
  amount: number;
  vat: number;
  interest: number;
  vatRefundable: number;
  paymentRefundable: number;
  vatFinal: number;
  interestFinal: number;
  vatRefundableFinal: number;
  paymentRefundableFinal: number;
  interestTotal: number;
  vatInterests: VatInterest[];
}

/**
 * Representation of a VAT interest.
 */
export interface VatInterest {
  vatInterestId: number;
  startDate: Date;
  endDate: Date;
  debtAmount: number;
  interestRate: number;
  interestAmount: number;
}

/**
 * Type of a VAT event.
 */
export enum VatEventType {
  VatReturn = 'VAT_RETURN',
  TaxRefund = 'TAX_REFUND',
  Payment = 'PAYMENT',
  PaymentRefund = 'PAYMENT_REFUND',
  CreditSettlement = 'CREDIT_SETTLEMENT',
  Interest = 'INTEREST',
}

/**
 * URL to access VAT assessments.
 */
export const VAT_ASSESSMENTS_URL = '/api/vat-assessments';

/**
 * Transforms a VAT assessment JSON as given by the API into our internal VAT
 * assessment representation.
 */
function jsonToVatAssessment(vatAssessmentJson: any): VatAssessment {
  return {
    ...vatAssessmentJson,
    deadlineDate:
      vatAssessmentJson.deadlineDate &&
      new Date(vatAssessmentJson.deadlineDate),
    statusDate: new Date(vatAssessmentJson.statusDate),
    createdDate: new Date(vatAssessmentJson.createdDate),
  };
}

/**
 * Transforms a VAT assessment JSON as given by the API into our internal VAT
 * assessment representation.
 */
function jsonToVatEvent(vatEventJson: any): VatEvent {
  return {
    ...vatEventJson,
    eventDate: new Date(vatEventJson.eventDate),
    valueDate: new Date(vatEventJson.valueDate),
    vatInterests: vatEventJson.vatInterests.map((vatInterestJson: any) => ({
      ...vatInterestJson,
      startDate: new Date(vatInterestJson.startDate),
      endDate: new Date(vatInterestJson.endDate),
    })),
  };
}

/**
 * Hook exposing the VAT assessments API.
 */
export function useVatAssessmentsApi() {
  const { getJson, postJson } = useFetchFromApi();

  /**
   * Gets a list of assessments matching the provided filter options.
   */
  const getVatAssessments = useCallback(
    async function (
      filterOptions: GetVatAssessmentsFilterOptions = {}
    ): Promise<VatAssessment[]> {
      return (
        await getJson(VAT_ASSESSMENTS_URL, { params: filterOptions })
      ).map(jsonToVatAssessment);
    },
    [getJson]
  );

  /**
   * Gets the assessment with the provided id.
   */
  const getVatAssessment = useCallback(
    async function (assessmentNumber: string): Promise<VatAssessment> {
      return jsonToVatAssessment(
        await getJson(
          `${VAT_ASSESSMENTS_URL}/${encodeURIComponent(assessmentNumber)}`
        )
      );
    },
    [getJson]
  );

  /**
   * Recalculates the VAT assessment and returns the new VAT assessment.
   */
  const recalculateVatAssessment = useCallback(
    async function (assessmentNumber: string): Promise<VatAssessment> {
      return jsonToVatAssessment(
        await postJson(
          `${VAT_ASSESSMENTS_URL}/${encodeURIComponent(
            assessmentNumber
          )}/recalculation`
        )
      );
    },
    [postJson]
  );

  /**
   * Gets the list of events associated with a VAT assessment.
   */
  const getVatEvents = useCallback(
    async function (assessmentNumber: string): Promise<VatEvent[]> {
      return (
        await getJson(
          `${VAT_ASSESSMENTS_URL}/${encodeURIComponent(
            assessmentNumber
          )}/events`
        )
      ).map(jsonToVatEvent);
    },
    [getJson]
  );

  /**
   * Emails the payment document to the taxpayer.
   */
  const emailPaymentDocument = useCallback(
    function (assessmentNumber: string): Promise<void> {
      return postJson(
        `${VAT_ASSESSMENTS_URL}/${encodeURIComponent(
          assessmentNumber
        )}/payment-document/email`
      );
    },
    [postJson]
  );

  return {
    getVatAssessments,
    getVatAssessment,
    recalculateVatAssessment,
    getVatEvents,
    emailPaymentDocument,
  };
}
