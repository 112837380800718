import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableColumns,
  InnerDataTable,
} from '../../../components/DataTable';
import { useCurrencyFormatter } from '../../../util/formatters';
import { StateCheckingAccountPeriod } from '../stateCheckingAccountApi';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

/**
 * Properties of the state checking account per period table.
 */
export interface StateCheckingAccountsPerPeriodTableProps {
  stateCheckingAccountPeriods: StateCheckingAccountPeriod[];
}

/**
 * Table listing the state checking accounts per period.
 */
export function StateCheckingAccountsPerPeriodTable({
  stateCheckingAccountPeriods,
}: StateCheckingAccountsPerPeriodTableProps) {
  const [t] = useTranslation(['common', 'stateCheckingAccount']);
  const formatCurrency = useCurrencyFormatter();

  const columns: DataTableColumns<StateCheckingAccountPeriod> = {
    taxablePeriod: {
      label: t(
        'stateCheckingAccount:stateCheckingAccountPeriodFields.taxablePeriod'
      ),
      value: ({ taxablePeriod }) => taxablePeriod,
      format: (value) => t(`taxablePeriod.${value}`),
      defaultSortDirection: 'desc',
      sortable: false,
    },
    debits: {
      align: 'right',
      label: t('stateCheckingAccount:stateCheckingAccountPeriodFields.debits'),
      value: ({ vatCreditAvailable, paymentRefundableFinal }) => vatCreditAvailable + paymentRefundableFinal,
      format: (value, { vatFinal, interestFinal }) =>
        value === 0 &&
        (vatFinal !== 0 || interestFinal !== 0)
          ? ''
          : formatCurrency(value as number),
      sortable: false,
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatCreditAvailable !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatCreditAvailable)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.vatCreditAvailable'
                )}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.paymentRefundableFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('stateCheckingAccount:stateCheckingAccountPeriodFields.credits'),
      value: ({ vatFinal, interestFinal }) =>
        vatFinal + interestFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
      sortable: false,
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.vatFinal'
                )}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.interestFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
  };

  return (
    <InnerDataTable
      title={t(
        'stateCheckingAccount:stateCheckingAccountPage.stateCheckingAccountsPerPeriodDataTable.title'
      )}
      rows={stateCheckingAccountPeriods || []}
      rowId={({ taxablePeriod }) => taxablePeriod}
      columns={columns}
      emptyMessage={''}
      defaultSortBy="taxablePeriod"
      minWidth={400}
    />
  );
}
