import React from 'react';
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      marginLeft: theme.spacing(-1),
      '@media print': {
        display: 'none',
      },
    },
  })
);

/**
 * Properties of the download table button.
 */
export interface DownloadTableButtonProps {
  fileName: string;
  downloadUrl: string;
  disabled?: boolean;
}

/**
 * Button used to download a table as CSV.
 */
export function DownloadTableButton({
  fileName,
  downloadUrl,
  disabled,
}: DownloadTableButtonProps) {
  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <Tooltip title={t('dataTable.downloadTableAsCsv')!} arrow>
      <IconButton
        component="a"
        className={classes.iconButton}
        size="small"
        data-cy="download-table-button"
        href={downloadUrl}
        download={`${fileName}.csv`}
        disabled={disabled}
      >
        <SaveAlt />
      </IconButton>
    </Tooltip>
  );
}
