import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, useTheme,
} from '@material-ui/core';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialogContext } from './ConfirmationDialogContext';
import { Action, DialogActionButtons } from '../../components/Actions';

/**
 * Properties of the confirmation dialog provider.
 */
export interface ConfirmationDialogProviderProps {
  children: ReactNode;
}

/**
 * Confirmation dialog provider.
 */
export function ConfirmationDialogProvider({
  children,
}: ConfirmationDialogProviderProps) {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<ReactNode>();
  const resolve = useRef<(ok: boolean) => void>();

  const getConfirmation = useCallback(
    (message: ReactNode) =>
      new Promise<boolean>((res) => {
        resolve.current = res;
        setMessage(message);
        setIsOpen(true);
      }),
    []
  );

  const cancel = useCallback(() => {
    resolve.current!(false);
    setIsOpen(false);
  }, []);

  const confirm = useCallback(() => {
    resolve.current!(true);
    setIsOpen(false);
  }, []);

  // Actions
  const actions: Action[] = [
    {
      id: 'confirmation-dialog-confirm',
      label: t('confirmationDialog.confirm'),
      color: 'primary',
      run: () => confirm(),
      autoFocus: true,
    },
    {
      id: 'confirmation-dialog-cancel',
      label: t('confirmationDialog.cancel'),
      run: () => cancel(),
    },
  ];

  return (
    <ConfirmationDialogContext.Provider value={{ confirm: getConfirmation }}>
      <Dialog
        open={isOpen}
        onClose={cancel}
        aria-describedby="confirmation-dialog-description"
        // We may show a confirmation dialog from a button in a menu; in such
        // cases the confirmation modal should be on top of said menu (so we set
        // the `z-index` to that of a menu)
        style={{ zIndex: theme.zIndex.tooltip }}
      >
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Dialog>

      {children}
    </ConfirmationDialogContext.Provider>
  );
}
