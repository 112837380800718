import React from 'react';
import { CreditSettlement } from '../creditSettlementsApi';
import { ActionButtons } from '../../../components/Actions';
import { useCreditSettlementActions } from '../useCreditSettlementActions';

/**
 * Properties for the credit settlement row actions.
 */
interface CreditSettlementActionsProps {
  creditSettlement: CreditSettlement;
  onVoid: () => void;
}

/**
 * Actions in a credit settlement row.
 */
export function CreditSettlementRowActions({
  creditSettlement,
  onVoid,
}: CreditSettlementActionsProps) {
  return (
    <ActionButtons
      actions={useCreditSettlementActions(creditSettlement, onVoid)!}
      variant="outlined"
      size="small"
    />
  );
}
