import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../../util/useResource';
import { useVatReturnsApi, VatReturnConstants } from '../vatReturnsApi';
import { useTranslation } from 'react-i18next';
import { TaxablePeriod } from '../../../common';

/**
 * VAT return constants resource.
 */
export interface VatReturnConstantsResource {
  isFetching: boolean;
  vatReturnConstants: VatReturnConstants | null;
  notFound: boolean;
  setVatReturnConstants: Dispatch<SetStateAction<VatReturnConstants | null>>;
}

/**
 * Hook used to fetch the VAT return constants associated with the given taxable
 * year/period from the API. If either the year or period are `null` then this
 * hook does nothing.
 */
export function useVatReturnConstants(
  taxableYear?: number | null,
  taxablePeriod?: TaxablePeriod | null
): VatReturnConstantsResource {
  const [t] = useTranslation('vatReturns');
  const { getVatReturnConstants } = useVatReturnsApi();
  const getVatReturnConstantsCb = useCallback(
    () =>
      taxableYear != null && taxablePeriod != null
        ? getVatReturnConstants(taxableYear, taxablePeriod)
        : null,
    [taxableYear, taxablePeriod, getVatReturnConstants]
  );
  const {
    isFetching,
    resource: vatReturnConstants,
    notFound,
    setResource: setVatReturnConstants,
  } = useResource({
    fetchResource: getVatReturnConstantsCb,
    errorFetchingResourceMessage: t(
      'vatReturns:vatReturnFormPage:errorFetchingVatReturnConstants',
      { taxableYear, taxablePeriod }
    ),
  });

  return {
    isFetching,
    vatReturnConstants,
    notFound,
    setVatReturnConstants,
  };
}
