import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  createStyles,
  Fade,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Action } from './Actions/Action';
import { ActionButtons } from './Actions/ActionButtons';
import { Skeleton } from '@material-ui/lab';
import { StatusChip, StatusChipStatus } from './StatusChip';
import { ThemeVariant } from '../providers/ThemeProvider';
import { TitleId } from './TitleId';

// Sizes of the top bar, required for the sticky header
const TOP_BAR_HEIGHT = 64;
const TOP_BAR_HEIGHT_SM = 56;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '@media screen': {
        // Sticky styles
        position: 'sticky',
        zIndex: theme.zIndex.appBar,
        padding: theme.spacing(2),
        margin: theme.spacing(-2),
        top: TOP_BAR_HEIGHT,
        [theme.breakpoints.down('xs')]: {
          top: TOP_BAR_HEIGHT_SM,
        },
      }
    },
    headerStuck: {
      '@media screen': {
        backgroundColor: theme.palette.background.default,
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: 5,
          bottom: -5,
          left: 0,
          backgroundImage: `radial-gradient(
          farthest-side at 50% 0,
          ${
            theme.palette.grey[
              theme.palette.type === ThemeVariant.Light ? 400 : 900
              ]
          },
          transparent
        )`,
        },
      },
    },
    titleContainer: {
      position: 'relative',
      wordBreak: 'break-word',
      '& > *': {
        marginRight: theme.spacing(1),
        '&:last-child': {
          [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2),
          },
          [theme.breakpoints.down('sm')]: {
            marginRight: 0,
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        flex: 1,
      },
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.7rem',
        lineHeight: '1.11',
      },
    },
    titleId: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '60%',
      },
    },
    actionsContainer: {
      '@media print': {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
  })
);

/**
 * Properties of the actionable header.
 */
export interface ActionableHeaderProps {
  title: ReactNode;
  actions?: Action[];
  titleId?: string | number | null;
  status?: ReactNode;
  statusVariant?: StatusChipStatus;
  fetching?: boolean;
  className?: string;
}

/**
 * Header which may contain actions.
 */
export function ActionableHeader({
  title,
  actions = [],
  titleId,
  status,
  statusVariant,
  fetching,
  className,
}: ActionableHeaderProps) {
  const classes = useStyles();
  const theme = useTheme();
  const headerRef = useRef<HTMLElement>(null);
  const [headerIsStuck, setHeaderIsStuck] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  // Set `headerIsStuck` when the "sticky" behaviour of the header applies
  const currentHeader = headerRef.current;
  const navbarSize = isXs ? TOP_BAR_HEIGHT_SM : TOP_BAR_HEIGHT;
  useEffect(() => {
    if (currentHeader != null) {
      const observer = new IntersectionObserver(
        ([e]) => setHeaderIsStuck(e.intersectionRatio < 1),
        // Using a margin of `-1px` relative to the `top` will force an
        // intersection and detect the "stuck" state
        { rootMargin: `-${navbarSize + 1}px 0px 0px 0px`, threshold: [1] }
      );
      observer.observe(currentHeader);
      return () => observer.unobserve(currentHeader);
    }
  }, [navbarSize, currentHeader]);

  const nonHiddenActions = actions.filter((action) => !action.hidden);

  const hasActions = nonHiddenActions.length > 0;
  return (
    <header
      ref={headerRef}
      className={`${classes.header} ${
        headerIsStuck ? classes.headerStuck : ''
      } ${className ?? ''}`}
    >
      <Typography
        className={classes.titleContainer}
        component="h1"
        variant="h4"
      >
        {fetching ? (
          <Skeleton />
        ) : (
          <>
            <span className={classes.title}>{title}</span>
            <TitleId className={classes.titleId} titleId={titleId} />
            <Fade in={status != null} mountOnEnter={true}>
              <StatusChip
                status={statusVariant}
                label={status}
                size={isMd ? 'medium' : 'small'}
              />
            </Fade>
          </>
        )}
      </Typography>

      <Fade in={hasActions && !fetching} mountOnEnter={true}>
        <div className={classes.actionsContainer}>
          {/* When the screen is at least `md`, show all actions as main
              actions, otherwise show the secondary actions in the menu */}
          <ActionButtons
            size={isMd ? 'medium' : 'small'}
            // Actions appearing on the right side are reversed (right to left
            // in order of importance)
            actions={isMd ? [...actions].reverse() : actions}
            numberOfMainActions={isMd ? Infinity : 1}
            variant="contained"
          />
        </div>
      </Fade>
    </header>
  );
}
