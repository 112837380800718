import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  GetVatReturnsFilterOptions,
  useVatReturnsApi,
  VAT_RETURNS_URL,
} from '../vatReturnsApi';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { VatReturnsTable } from './VatReturnsTable';
import { SearchForm } from '../../../components/SearchForm';
import { useQueryParams } from '../../../util/useQueryParams';
import { useResource } from '../../../util/useResource';
import { FormTaxpayerIdField } from '../../../components/Form';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { TaxablePeriod } from '../../../common';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useLfForm } from '../../../util/lfIntegration';

// Search parameters
const searchParams = ['taxpayerId', 'taxableYear', 'taxablePeriod'];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    taxableYear: isNaN(+queryParams.taxableYear)
      ? null
      : +queryParams.taxableYear,
    taxablePeriod: Object.values(TaxablePeriod).includes(
      queryParams.taxablePeriod as any
    )
      ? (queryParams.taxablePeriod as any)
      : null,
  };
}

/**
 * Page dedicated to consult VAT returns.
 */
export function VatReturnsPage() {
  const [t] = useTranslation(['common', 'vatReturns']);
  useSetDocumentTitle(t('vatReturns:vatReturnsPage.documentTitle'));
  const { getVatReturns } = useVatReturnsApi();
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      taxableYear: null as number | null,
      taxablePeriod: null as TaxablePeriod | null,
    },
    formValidatorName: 'getVatReturnsFormValidator',
    i18nErrorMessagesPrefixes:
      'vatReturns:vatReturnsPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const fetchVatReturns = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getVatReturns({ ...queryParams, latestPerTaxableYearPeriod: true })
        : null,
    [getVatReturns, isValid, queryParams]
  );
  const {
    isFetching,
    resource: vatReturns,
    setResource: setVatReturns,
    refetch: refetchVatReturns,
  } = useResource({
    fetchResource: fetchVatReturns,
    errorFetchingResourceMessage: t(
      'vatReturns:vatReturnsPage.errorFetchingVatReturns'
    ),
  });

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const { taxpayerId, taxableYear, taxablePeriod } = getValues();
    const getVatReturnsOptions: GetVatReturnsFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      taxableYear: taxableYear || undefined,
      taxablePeriod: taxablePeriod || undefined,
    };
    // Pressing "search" when nothing changed should refetch the VAT returns
    if (areSameQueryParams(getVatReturnsOptions, queryParams, searchParams)) {
      refetchVatReturns();
    } else {
      setQueryParams(getVatReturnsOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader title={t('vatReturns:vatReturnsPage.title')} />

      <SearchForm
        title={t('vatReturns:vatReturnsPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        {/* TIN */}
        <Grid item xs={12} md={4}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('vatReturns:vatReturnFields.taxpayerId')}
            size="small"
          />
        </Grid>

        {/* Taxable year/period */}
        <FormTaxableYearPeriod
          taxableYearProps={{
            name: 'taxableYear',
            label: t('vatReturns:vatReturnFields.taxableYear'),
            size: 'small',
          }}
          taxablePeriodProps={{
            name: 'taxablePeriod',
            label: t('vatReturns:vatReturnFields.taxablePeriod'),
            size: 'small',
          }}
        >
          {(taxableYear, taxablePeriod) => (
            <>
              <Grid item xs={6} md={4}>
                {taxableYear}
              </Grid>
              <Grid item xs={6} md={4}>
                {taxablePeriod}
              </Grid>
            </>
          )}
        </FormTaxableYearPeriod>
      </SearchForm>

      <VatReturnsTable
        fetching={isFetching}
        vatReturns={vatReturns || []}
        setVatReturns={setVatReturns}
        refetchVatReturns={refetchVatReturns}
        downloadButton={
          <DownloadTableButton
            fileName={t('vatReturns:vatReturnsPage.dataTable.downloadFileName')}
            downloadUrl={`${VAT_RETURNS_URL}?${objectToQueryParamsString({
              ...queryParams,
              latestPerTaxableYearPeriod: true,
              format: 'csv',
            })}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
