import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { CheckingAccount, useCheckingAccountsApi } from './checkingAccountsApi';
import { useTranslation } from 'react-i18next';

/**
 * Checking account as a resource.
 */
export interface CheckingAccountResource {
  isFetching: boolean;
  checkingAccount: CheckingAccount | null;
  notFound: boolean;
  setCheckingAccount: Dispatch<SetStateAction<CheckingAccount | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a checking account from the API, if no `taxpayerId` is
 * passed this hook does nothing.
 */
export function useCheckingAccount(
  taxpayerId?: string
): CheckingAccountResource {
  const [t] = useTranslation('checkingAccounts');
  const { getCheckingAccount } = useCheckingAccountsApi();
  const getCheckingAccountCb = useCallback(
    () => (taxpayerId ? getCheckingAccount(taxpayerId) : null),
    [getCheckingAccount, taxpayerId]
  );
  const {
    isFetching,
    resource: checkingAccount,
    notFound,
    setResource: setCheckingAccount,
    refetch,
  } = useResource({
    fetchResource: getCheckingAccountCb,
    errorFetchingResourceMessage: t(
      'checkingAccounts:errorFetchingCheckingAccount',
      { id: taxpayerId ?? '' }
    ),
  });

  return { isFetching, checkingAccount, notFound, setCheckingAccount, refetch };
}
