import { useCallback } from 'react';
import { TaxablePeriod, TaxId, TransactionMethod } from '../../common';
import { toISODateString } from '../../util/dateUtils';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Type of a payment.
 */
export interface Payment {
  paymentId: number;
  assessmentNumber: string;
  assessmentNumberInserted: string;
  taxpayerId: string;
  taxpayerName: string;
  taxId: TaxId;
  taxableYear: number;
  taxablePeriod: TaxablePeriod | null;
  paymentDate: Date;
  amount: number;
  bankId: number;
  transactionMethod: TransactionMethod;
  status: PaymentStatus;
  createdDate: Date;
  createdBy: string;
  changedDate: Date;
  voidDescription: string | null;
  changedBy: string | null;
}

/**
 * Status of the payment.
 */
export enum PaymentStatus {
  Submitted = 'SUBMITTED',
  Voided = 'VOIDED',
}

/**
 * Options used to get payments.
 */
export interface GetPaymentsFilterOptions {
  taxpayerId?: string;
  taxId?: TaxId;
  taxableYear?: number;
  taxablePeriod?: TaxablePeriod;
}

/**
 * Form fields used to void a payment.
 */
export interface VoidPaymentForm {
  voidDescription: string;
}

/**
 * Form fields when creating a payment.
 */
export interface PaymentForm {
  taxpayerId: string;
  assessmentNumber: string;
  taxId: TaxId | null;
  taxableYear: number | null;
  taxablePeriod: TaxablePeriod | null;
  paymentDate: Date | null;
  amount: number | null;
  bankId: number | null;
  transactionMethod: string | null;
}

/**
 * URL to access payments.
 */
export const PAYMENTS_URL = '/api/payments';

/**
 * Transforms a payment JSON as given by the API into our internal payment
 * representation.
 */
function jsonToPayment(paymentJson: any): Payment {
  return {
    ...paymentJson,
    paymentDate: new Date(paymentJson.paymentDate),
    createdDate: new Date(paymentJson.createdDate),
    changedDate: new Date(paymentJson.changedDate),
  };
}

/**
 * Hook exposing the payments API.
 */
export function usePaymentsApi() {
  const { getJson, postJson, putJson } = useFetchFromApi();

  /**
   * Gets a list of payments matching the provided filter options.
   */
  const getPayments = useCallback(
    async function (
      filterOptions: GetPaymentsFilterOptions = {}
    ): Promise<Payment[]> {
      return (await getJson(PAYMENTS_URL, { params: filterOptions })).map(
        jsonToPayment
      );
    },
    [getJson]
  );

  /**
   * Gets the payment with the provided id.
   */
  const getPayment = useCallback(
    async function (paymentId: number): Promise<Payment> {
      return jsonToPayment(
        await getJson(`${PAYMENTS_URL}/${encodeURIComponent(paymentId)}`)
      );
    },
    [getJson]
  );

  /**
   * Voids a payment.
   */
  const voidPayment = useCallback(
    async function (
      paymentId: number,
      form: VoidPaymentForm
    ): Promise<Payment> {
      return jsonToPayment(
        await putJson(
          `${PAYMENTS_URL}/${encodeURIComponent(paymentId)}/status`,
          {
            newStatus: PaymentStatus.Voided,
            ...form,
          }
        )
      );
    },
    [putJson]
  );

  /**
   * Creates a new payment from the provided payment fields and returns it.
   */
  const createPayment = useCallback(
    async function (form: PaymentForm): Promise<Payment> {
      const toCreate = {
        ...form,
        paymentDate: form.paymentDate && toISODateString(form.paymentDate),
      };
      return jsonToPayment(await postJson(PAYMENTS_URL, toCreate));
    },
    [postJson]
  );

  return { getPayments, getPayment, voidPayment, createPayment };
}
