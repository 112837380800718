import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MainContent } from '../../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../../util/formatters';
import { useVatAssessment } from '../useVatAssessment';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { useVatAssessmentActions } from '../useVatAssessmentActions';
import { Link } from '../../../components/Link';
import {
  replacePathParams,
  TAXPAYER_PATH,
  VAT_ASSESSMENT_PATH,
  VAT_RETURN_PATH,
} from '../../../navigation/paths';
import { VatAssessmentHistoryTable } from './VatAssessmentHistoryTable';
import { VatEventsTable } from './VatEventsTable';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { NotSpecified } from '../../../components/NotSpecified';
import { VatAssessmentStatus } from '../vatAssessmentsApi';
import { CurlyBrace } from '../../../components/CurlyBrace';

/**
 * Page with the information of a VAT return.
 */
export function VatAssessmentPage() {
  const [t, i18n] = useTranslation('vatAssessments');
  const { id } = useParams<{ id: string }>();
  useSetDocumentTitle(
    t('vatAssessments:vatAssessmentPage.documentTitle', { id })
  );
  const { vatAssessment, isFetching, notFound, refetch } = useVatAssessment(id);
  const history = useHistory();
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();

  const actions = useVatAssessmentActions(
    vatAssessment,
    (newVatAssessment) =>
      history.push(
        replacePathParams(VAT_ASSESSMENT_PATH, {
          id: newVatAssessment.assessmentNumber,
        })
      ),
    refetch
  );

  const debits =
    vatAssessment && vatAssessment.vatFinal + vatAssessment.interestFinal;
  const credits =
    vatAssessment &&
    vatAssessment.vatRefundableFinal + vatAssessment.paymentRefundableFinal;
  // Whether the debits should be shown (otherwise the credits are shown)
  const showDebits = debits !== 0 || credits === 0;

  // VAT return information fields
  const assessmentInformation: DescriptionListItem[] = [
    {
      key: 'taxpayer-id',
      label: t('vatAssessments:vatAssessmentFields.taxpayerId'),
      content: vatAssessment && (
        <>
          <Link
            to={replacePathParams(TAXPAYER_PATH, {
              id: vatAssessment.taxpayerId,
            })}
          >
            {vatAssessment.taxpayerId}
          </Link>
          {vatAssessment.taxpayerName && (
            <ExtraInfo>{vatAssessment.taxpayerName}</ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxable-year-period',
      label: `${t('vatAssessments:vatAssessmentFields.taxableYear')}/${t(
        'vatAssessments:vatAssessmentFields.taxablePeriod'
      ).toLocaleLowerCase(i18n.language)}`,
      content: `${vatAssessment?.taxableYear}/${vatAssessment?.taxablePeriod}`,
      fetching: isFetching,
    },
    {
      key: 'debits-credits',
      label: isFetching
        ? `${t('vatAssessments:vatAssessmentFields.debits')}/${t(
            'vatAssessments:vatAssessmentFields.credits'
          ).toLocaleLowerCase(i18n.language)}`
        : t(
            `vatAssessments:vatAssessmentFields.${
              showDebits ? 'debits' : 'credits'
            }`
          ),
      content:
        vatAssessment && (debits !== 0 || credits !== 0) ? (
          <CurlyBrace
            leftSide={formatCurrency(showDebits ? debits : credits)}
            rightSide={
              <>
                <div>
                  {showDebits
                    ? formatCurrency(vatAssessment!.vatFinal)
                    : formatCurrency(vatAssessment!.vatRefundableFinal)}
                  <ExtraInfo nonBreaking>
                    {t(
                      `vatAssessments:vatAssessmentFields.${
                        showDebits ? 'vatFinal' : 'vatRefundableFinal'
                      }`
                    )}
                  </ExtraInfo>
                </div>
                <div>
                  {showDebits
                    ? formatCurrency(vatAssessment!.interestFinal)
                    : formatCurrency(vatAssessment!.paymentRefundableFinal)}
                  <ExtraInfo nonBreaking>
                    {t(
                      `vatAssessments:vatAssessmentFields.${
                        showDebits ? 'interestFinal' : 'paymentRefundableFinal'
                      }`
                    )}
                  </ExtraInfo>
                </div>
              </>
            }
          />
        ) : (
          formatCurrency(showDebits ? debits : credits)
        ),
      fetching: isFetching,
    },
    {
      key: 'excess-reported',
      label: t('vatAssessments:vatAssessmentFields.excessReported'),
      content: formatCurrency(vatAssessment?.excessReported),
      fetching: isFetching,
    },
    {
      key: 'deadline-date',
      label: t('vatAssessments:vatAssessmentFields.deadlineDate'),
      content: formatDate(vatAssessment?.deadlineDate) || (
        <NotSpecified>
          {t('vatAssessments:deadlineDateNotApplicable')}
        </NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'created-date',
      label: t('vatAssessments:vatAssessmentFields.createdDate'),
      content: formatDateTime(vatAssessment?.createdDate),
      fetching: isFetching,
    },
  ];

  // Assessment information fields
  const correctedInformation: DescriptionListItem[] = [
    {
      key: 'tax-return-id',
      label: t('vatAssessments:vatAssessmentFields.taxReturnId'),
      content: vatAssessment && (
        <Link
          to={replacePathParams(VAT_RETURN_PATH, {
            id: vatAssessment.taxReturnId,
          })}
        >
          {vatAssessment.taxReturnId}
        </Link>
      ),
      fetching: isFetching,
    },
    {
      key: 'vat-total-adjusted',
      label: t('vatAssessments:vatAssessmentFields.vatTotalAdjusted'),
      content: vatAssessment && (
        <>
          {formatCurrency(vatAssessment.vatTotalAdjusted)}
          <ExtraInfo>
            {vatAssessment.vatTotal === vatAssessment.vatTotalAdjusted
              ? t('vatAssessments:amountAsDeclared')
              : t('vatAssessments:amountCorrected', {
                  declared: formatCurrency(vatAssessment.vatTotal),
                })}
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'vat-credit-adjusted',
      label: t('vatAssessments:vatAssessmentFields.vatCreditAdjusted'),
      content: vatAssessment && (
        <>
          {formatCurrency(vatAssessment.vatCreditAdjusted)}
          <ExtraInfo>
            {vatAssessment.vatCredit === vatAssessment.vatCreditAdjusted
              ? t('vatAssessments:amountAsDeclared')
              : t('vatAssessments:amountCorrected', {
                  declared: formatCurrency(vatAssessment.vatCredit),
                })}
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'previous-periods-compensation-adjusted',
      label: t(
        'vatAssessments:vatAssessmentFields.previousPeriodsCompensationAdjusted'
      ),
      content: vatAssessment && (
        <>
          {formatCurrency(vatAssessment.previousPeriodsCompensationAdjusted)}
          <ExtraInfo>
            {vatAssessment.previousPeriodsCompensation ===
            vatAssessment.previousPeriodsCompensationAdjusted
              ? t('vatAssessments:amountAsDeclared')
              : t('vatAssessments:amountCorrected', {
                  declared: formatCurrency(
                    vatAssessment.previousPeriodsCompensation
                  ),
                })}
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
  ];

  return (
    <MainContent
      errorMessage={notFound && t('vatAssessments:vatAssessmentNotFound')}
    >
      <ActionableHeader
        title={t('vatAssessments:vatAssessmentPage.title')}
        titleId={id}
        status={
          vatAssessment &&
          t(`vatAssessments:vatAssessmentStatus.${vatAssessment.status}`)
        }
        statusVariant={
          vatAssessment?.status === VatAssessmentStatus.Effective
            ? 'success'
            : vatAssessment?.status === VatAssessmentStatus.Voided
            ? 'error'
            : 'default'
        }
        actions={actions}
      />

      <PaperDescriptionList
        title={t('vatAssessments:assessmentInformation')}
        items={assessmentInformation}
      />
      <PaperDescriptionList
        title={t('vatAssessments:correctedInformation')}
        items={correctedInformation}
      />

      <VatEventsTable vatAssessment={vatAssessment} />

      <VatAssessmentHistoryTable
        vatAssessment={vatAssessment}
        refetchVatAssessment={refetch}
      />
    </MainContent>
  );
}
