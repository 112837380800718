import React, { ChangeEvent, ReactNode } from 'react';
import {
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5, 2),
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    firstRow: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      flex: 1,
      lineHeight: 1.1,
    },
    filter: {
      marginLeft: theme.spacing(2),
    },
    titleActionsLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    actionsLeftContainer: {
      marginLeft: theme.spacing(2),
      '@media print': {
        display: 'none',
      },
    },
    actionsRightContainer: {
      display: 'flex',
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: theme.spacing(1.5),
        flexBasis: '100%',
      },
      '@media print': {
        display: 'none',
      },
    },
  })
);

/**
 * Properties for the data table toolbar.
 */
interface DataTableToolbarProps {
  tableId: number;
  title?: ReactNode;
  dense: boolean;
  showFilter: boolean;
  filter?: string;
  onFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  actionsLeft?: ReactNode;
  actionsRight?: ReactNode;
}

/**
 * Toolbar for the data table.
 */
export function DataTableToolbar({
  tableId,
  title,
  dense,
  showFilter,
  filter,
  onFilterChange,
  actionsLeft,
  actionsRight,
}: DataTableToolbarProps) {
  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <Toolbar
      className={classes.root}
      variant={dense ? 'dense' : 'regular'}
      data-cy="data-table-toolbar"
    >
      <div className={classes.firstRow}>
        <div className={classes.titleActionsLeft}>
          {/* Title */}
          <Typography
            className={classes.title}
            component="h2"
            variant="h6"
            id={`data-table-title-${tableId}`}
          >
            {title}
          </Typography>

          {/* Left actions container */}
          {actionsLeft && (
            <div className={classes.actionsLeftContainer}>{actionsLeft}</div>
          )}
        </div>

        {/* Filter */}
        {showFilter && (
          <TextField
            className={classes.filter}
            value={filter || ''}
            variant="outlined"
            size="small"
            label={t('dataTable.filter')}
            name="filter"
            onChange={onFilterChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>

      {/* Right actions container */}
      {actionsRight && (
        <div className={classes.actionsRightContainer}>{actionsRight}</div>
      )}
    </Toolbar>
  );
}
