import React, { ReactNode } from 'react';
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        borderRadius: 0,
      },
      '@media print': {
        breakInside: 'avoid',
        boxShadow: 'none',
        marginLeft: 0,
        marginRight: 0,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
      }
    },
    header: {
      marginBottom: theme.spacing(2),
    },
  })
);

/**
 * Properties of the titled paper.
 */
export interface TitledPaperProps {
  title?: ReactNode;
  children: ReactNode;
}

/**
 * Paper section with a title.
 */
export function TitledPaper({ title, children }: TitledPaperProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {title && (
        <Typography className={classes.header} component="h2" variant="h6">
          {title}
        </Typography>
      )}

      {children}
    </Paper>
  );
}
