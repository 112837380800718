import React from 'react';
import { Taxpayer } from '../taxpayersApi';
import { ActionButtons } from '../../../components/Actions';
import { useTaxpayerActions } from '../useTaxpayerActions';

/**
 * Properties for the taxpayer row actions.
 */
interface TaxpayerActionsProps {
  taxpayer: Taxpayer;
  onFailureDueToOutdatedUI: () => void;
}

/**
 * Actions in a taxpayer row.
 */
export function TaxpayerRowActions({
  taxpayer,
  onFailureDueToOutdatedUI,
}: TaxpayerActionsProps) {
  return (
    <ActionButtons
      actions={useTaxpayerActions(taxpayer, onFailureDueToOutdatedUI)!}
      variant="outlined"
      size="small"
    />
  );
}
