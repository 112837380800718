import { VatReturnForm, VatReturnOrigin } from '../vatReturnsApi';
import { getMonth, getYear, startOfToday, subMonths } from 'date-fns';
import { TaxablePeriod } from '../../../common';
import { localDateToSameDateInUTC } from '../../../util/dateUtils';

/**
 * Default VAT return form.
 */
export function defaultVatReturnForm(
  vatReturnForm: Partial<VatReturnForm> | null
): VatReturnForm {
  return {
    taxpayerId: vatReturnForm?.taxpayerId ?? '',
    origin: vatReturnForm?.origin ?? VatReturnOrigin.Normal,
    taxableYear:
      vatReturnForm?.taxableYear ?? getYear(subMonths(new Date(), 1)),
    taxablePeriod:
      vatReturnForm?.taxablePeriod ??
      ((getMonth(subMonths(new Date(), 1)) + 1)
        .toString()
        .padStart(2, '0') as TaxablePeriod),
    deliveryDate:
      vatReturnForm?.deliveryDate ?? localDateToSameDateInUTC(startOfToday()),
    // Representative ----------------------------------------------------------
    interestedName: vatReturnForm?.interestedName ?? '',
    // Debt determination/accrued ----------------------------------------------
    generalFactor: vatReturnForm?.generalFactor ?? null,
    generalBase1: vatReturnForm?.generalBase1 ?? null,
    generalRate1: vatReturnForm?.generalRate1 ?? null,
    generalQuota1: vatReturnForm?.generalQuota1 ?? null,
    generalBase2: vatReturnForm?.generalBase2 ?? null,
    generalRate2: vatReturnForm?.generalRate2 ?? null,
    generalQuota2: vatReturnForm?.generalQuota2 ?? null,
    generalBase3: vatReturnForm?.generalBase3 ?? null,
    generalRate3: vatReturnForm?.generalRate3 ?? null,
    generalQuota3: vatReturnForm?.generalQuota3 ?? null,
    interestOnLatePaymentBase: vatReturnForm?.interestOnLatePaymentBase ?? null,
    interestOnLatePaymentType: vatReturnForm?.interestOnLatePaymentType ?? null,
    interestOnLatePaymentQuota:
      vatReturnForm?.interestOnLatePaymentQuota ?? null,
    surchargeABase: vatReturnForm?.surchargeABase ?? null,
    surchargeAType: vatReturnForm?.surchargeAType ?? null,
    surchargeAQuota: vatReturnForm?.surchargeAQuota ?? null,
    surchargeBBase: vatReturnForm?.surchargeBBase ?? null,
    surchargeBType: vatReturnForm?.surchargeBType ?? null,
    surchargeBQuota: vatReturnForm?.surchargeBQuota ?? null,
    intracommunityAcquisitionsBase:
      vatReturnForm?.intracommunityAcquisitionsBase ?? null,
    intracommunityAcquisitionsQuota:
      vatReturnForm?.intracommunityAcquisitionsQuota ?? null,
    totalAccrued: vatReturnForm?.totalAccrued ?? 0,
    // Debt determination/deductible -------------------------------------------
    internalOperationsQuotas: vatReturnForm?.internalOperationsQuotas ?? null,
    importQuotas: vatReturnForm?.importQuotas ?? null,
    intercommunityAcquisitions:
      vatReturnForm?.intercommunityAcquisitions ?? null,
    investmentGoodsAcquisitions:
      vatReturnForm?.investmentGoodsAcquisitions ?? null,
    investmentsCompensation: vatReturnForm?.investmentsCompensation ?? null,
    previousPeriodsCompensation:
      vatReturnForm?.previousPeriodsCompensation ?? null,
    totalDeductible: vatReturnForm?.totalDeductible ?? 0,
    // Debt determination/totals -----------------------------------------------
    vatTotal: vatReturnForm?.vatTotal ?? 0,
    vatCredit: vatReturnForm?.vatCredit ?? 0,
  };
}
