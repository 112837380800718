import React, {
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ActionsMenuPopper, MenuAction } from './ActionsMenuPopper';

interface ButtonProps {
  ref: RefObject<any>;
  'aria-controls'?: string;
  'aria-expanded'?: 'true';
  'aria-haspopup': 'menu';
  onClick: () => void;
}

/**
 * Actions button properties.
 */
export interface ActionsButtonProps {
  actions: MenuAction[];
  children: (buttonProps: ButtonProps) => ReactNode;
}

/**
 * Button that, when clicked, shows a list of actions.
 */
export function ActionsButton({ actions, children }: ActionsButtonProps) {
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);

  const handleActionsMenuToggle = useCallback(() => {
    setIsActionsMenuOpen((isOpen) => !isOpen);
  }, []);

  const menuListId = useMemo(() => `actions-menu-list-${Math.random()}`, []);
  return (
    <>
      {children({
        ref: anchorRef,
        'aria-controls': isActionsMenuOpen ? menuListId : undefined,
        'aria-expanded': isActionsMenuOpen ? 'true' : undefined,
        'aria-haspopup': 'menu',
        onClick: handleActionsMenuToggle,
      })}

      <ActionsMenuPopper
        anchorRef={anchorRef}
        open={isActionsMenuOpen}
        setOpen={setIsActionsMenuOpen}
        actions={actions}
        menuListId={menuListId}
      />
    </>
  );
}
