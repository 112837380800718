import React from 'react';
import { VatActivity, Taxpayer } from '../taxpayersApi';
import { ActionButtons } from '../../../components/Actions';
import { useVatActivityActions } from './useVatActivityActions';

/**
 * Properties for the VAT activity row actions.
 */
interface VatActivityActionsProps {
  taxpayer: Taxpayer | null;
  vatActivity: VatActivity;
  onEditActionClick: (vatActivity: VatActivity) => void;
  onRemove: (removedVatActivity: VatActivity) => void;
  onRemoveFailureDueToOutdatedUI: () => void;
}

/**
 * Actions in a VAT activity row.
 */
export function VatActivityRowActions({
  taxpayer,
  vatActivity,
  onEditActionClick,
  onRemove,
  onRemoveFailureDueToOutdatedUI,
}: VatActivityActionsProps) {
  return (
    <ActionButtons
      actions={useVatActivityActions(
        taxpayer,
        vatActivity,
        () => onEditActionClick(vatActivity),
        onRemove,
        onRemoveFailureDueToOutdatedUI
      )}
      variant="outlined"
      size="small"
    />
  );
}
