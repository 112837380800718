import { useCallback } from 'react';
import { TaxablePeriod } from '../../common';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Representation of a checking account.
 */
export interface CheckingAccount {
  taxpayerId: string;
  taxpayerName: string;
  accessDate: Date;
  vatFinal: number;
  interestFinal: number;
  vatCreditAvailable: number;
  paymentRefundableFinal: number;
}

/**
 * Representation of a period of the checking account.
 */
export interface CheckingAccountPeriod {
  taxableYear: number;
  taxablePeriod: TaxablePeriod;
  assessmentNumber: string;
  vatFinal: number;
  interestFinal: number;
  vatCreditAvailable: number;
  paymentRefundableFinal: number;
}

/**
 * Options used to get checking accounts.
 */
export interface GetCheckingAccountsFilterOptions {
  taxpayerId?: string;
  taxpayerName?: string;
  exactNameMatch?: boolean;
}

/**
 * URL to access checking accounts.
 */
export const CHECKING_ACCOUNTS_URL = '/api/checking-accounts';

/**
 * Transforms a checking account JSON as given by the API into our internal
 * checking account representation.
 */
function jsonToCheckingAccount(checkingAccountJson: any): CheckingAccount {
  return {
    ...checkingAccountJson,
    accessDate: new Date(checkingAccountJson.accessDate),
  };
}

/**
 * Hook exposing the checking accounts API.
 */
export function useCheckingAccountsApi() {
  const { getJson } = useFetchFromApi();

  /**
   * Gets a list of checking accounts matching the provided filter options.
   */
  const getCheckingAccounts = useCallback(
    async (
      filterOptions: GetCheckingAccountsFilterOptions = {}
    ): Promise<CheckingAccount[]> =>
      (await getJson(CHECKING_ACCOUNTS_URL, { params: filterOptions })).map(
        jsonToCheckingAccount
      ),
    [getJson]
  );

  /**
   * Gets the checking account of the taxpayer with the provided id.
   */
  const getCheckingAccount = useCallback(
    async (taxpayerId: string): Promise<CheckingAccount> =>
      jsonToCheckingAccount(
        await getJson(
          `${CHECKING_ACCOUNTS_URL}/${encodeURIComponent(taxpayerId)}`
        )
      ),
    [getJson]
  );

  /**
   * Gets the list of periods of a checking account.
   */
  const getCheckingAccountPeriods = useCallback(
    async (taxpayerId: string): Promise<CheckingAccountPeriod[]> =>
      await getJson(
        `${CHECKING_ACCOUNTS_URL}/${encodeURIComponent(taxpayerId)}/periods`
      ),
    [getJson]
  );

  return { getCheckingAccounts, getCheckingAccount, getCheckingAccountPeriods };
}
