import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { AuthenticatedUser, login, logout, refresh } from './authenticationApi';
import { AuthenticationContext } from './AuthenticatedContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

/**
 * Properties of the authentication provider.
 */
export interface AuthenticationProviderProps {
  children: ReactNode;
}

/**
 * Authentication provider. Children may use the `useAuthentication` hook to
 * handle authentication.
 */
export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const [t] = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isFetchingInitialUser, setIsFetchingInitialUser] = useState(true);
  const [userAuth, setUserAuth] = useState<AuthenticatedUser | null>(null);

  /**
   * Login implementation.
   */
  const loginImpl = useCallback(async (username: string, password: string) => {
    const userAuth = await login(username, password);
    setUserAuth(userAuth);
    return userAuth;
  }, []);

  /**
   * Refresh implementation.
   */
  const refreshImpl = useCallback(async () => {
    const newUserAuth = await refresh();
    setUserAuth(newUserAuth);
    return newUserAuth;
  }, []);

  /**
   * Logout implementation.
   */
  const logoutImpl = useCallback(async () => {
    try {
      await logout();
    } catch (err) {
    } finally {
      setUserAuth(null);
    }
  }, []);

  // Perform a `refresh` on page load. We may or may not be authenticated, but
  // we'll know depending on the result of the `refresh`.
  useEffect(() => {
    async function fetchInitialUser() {
      try {
        await refreshImpl();
        setIsFetchingInitialUser(false);
      } catch (err) {
        // Expected error of not being authenticated
        if (err instanceof Response && err.status === 401) {
          setIsFetchingInitialUser(false);
        } else {
          // Unexpected error, likely a network issue
          const snackbarKey = `errorFetchingInitialUser-${Math.random()}`;
          // Any other error, provide action to retry fetching the resource
          enqueueSnackbar(t('authentication.errorFetchingInitialUser'), {
            variant: 'error',
            persist: true,
            key: snackbarKey,
            action: () => (
              <Button
                color="inherit"
                onClick={() => {
                  closeSnackbar(snackbarKey);
                  fetchInitialUser();
                }}
              >
                {t('snackbarActions.retry')}
              </Button>
            ),
          });
        }
      }
    }

    fetchInitialUser();
  }, [closeSnackbar, enqueueSnackbar, refreshImpl, t]);

  return (
    <AuthenticationContext.Provider
      value={{
        isFetching: isFetchingInitialUser,
        user: userAuth,
        api: { login: loginImpl, refresh: refreshImpl, logout: logoutImpl },
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
