import React, { Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { VatAssessment, VatAssessmentStatus } from '../vatAssessmentsApi';
import { VatAssessmentRowActions } from './VatAssessmentRowActions';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import { StatusChip } from '../../../components/StatusChip';
import {
  replacePathParams,
  TAXPAYER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { Link } from '../../../components/Link';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

/**
 * Properties of the VAT assessments table.
 */
export interface VatAssessmentsTableProps {
  fetching: boolean;
  vatAssessments: VatAssessment[];
  setVatAssessments: Dispatch<SetStateAction<VatAssessment[] | null>>;
  refetchVatAssessments: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing VAT assessments.
 */
export function VatAssessmentsTable({
  fetching,
  vatAssessments,
  setVatAssessments,
  refetchVatAssessments,
  downloadButton,
}: VatAssessmentsTableProps) {
  const [t] = useTranslation(['common', 'vatAssessments']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();

  // When recalculating the interest of a VAT assessment, simply replace the old
  // one by the new one since we're only showing the latest
  const replaceVatAssessment = useCallback(
    (oldVatAssessment: VatAssessment, newVatAssessment: VatAssessment) => {
      setVatAssessments(
        (vatAssessments) =>
          vatAssessments &&
          vatAssessments.map((assess) =>
            assess.assessmentId === oldVatAssessment.assessmentId
              ? newVatAssessment
              : assess
          )
      );
    },
    [setVatAssessments]
  );

  const columns: DataTableColumns<VatAssessment> = {
    assessmentNumber: {
      label: t('vatAssessments:vatAssessmentFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber,
      render: (content, value) => (
        <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxpayerId: {
      label: t('vatAssessments:vatAssessmentFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxableYearPeriod: {
      label: t('vatAssessments:vatAssessmentFields.taxablePeriod'),
      value: ({ taxableYear, taxablePeriod }) =>
        `${taxableYear}/${taxablePeriod}`,
      defaultSortDirection: 'desc',
    },
    createdDate: {
      label: t('vatAssessments:vatAssessmentFields.created'),
      value: ({ createdDate }) => createdDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    debits: {
      align: 'right',
      label: t('vatAssessments:vatAssessmentFields.debits'),
      value: ({ vatFinal, interestFinal }) => vatFinal + interestFinal,
      format: (value, { vatRefundableFinal, paymentRefundableFinal }) =>
        value === 0 &&
        (vatRefundableFinal !== 0 || paymentRefundableFinal !== 0)
          ? ''
          : formatCurrency(value as number),
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.vatFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.interestFinal')}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('vatAssessments:vatAssessmentFields.credits'),
      value: ({ vatRefundableFinal, paymentRefundableFinal }) =>
        vatRefundableFinal + paymentRefundableFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatRefundableFinal !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.vatRefundableFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.paymentRefundableFinal')}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    status: {
      label: t('vatAssessments:vatAssessmentFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`vatAssessments:vatAssessmentStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={
            status === VatAssessmentStatus.Effective
              ? 'success'
              : status === VatAssessmentStatus.Voided
              ? 'error'
              : 'default'
          }
          label={content}
        />
      ),
      paddinglessY: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <VatAssessmentRowActions
          vatAssessment={row}
          onRecalculate={replaceVatAssessment}
          onFailureDueToOutdatedUI={refetchVatAssessments}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <DataTable
      title={t('vatAssessments:vatAssessmentsPage.dataTable.title')}
      rows={vatAssessments}
      rowId={({ assessmentId }) => assessmentId}
      columns={columns}
      emptyMessage={t(
        'vatAssessments:vatAssessmentsPage.dataTable.emptyMessage'
      )}
      toolbarActionsLeft={downloadButton}
      fetching={fetching}
      defaultSortBy="taxableYearPeriod"
      minWidth={1000}
    />
  );
}
