import React from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import {
  Brightness2,
  Brightness4,
  Brightness7,
  SettingsBrightness,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ThemeVariant, useThemeVariant } from '../providers/ThemeProvider';
import { Action, ActionsButton } from './Actions';

/**
 * Properties of the theme toggler.
 */
export interface ThemeTogglerProps extends IconButtonProps {}

/**
 * Button to toggle the app's theme title.
 */
export function ThemeToggler({ ...iconButtonProps }: ThemeTogglerProps) {
  const [t] = useTranslation('common');
  const { themeVariant, setThemeVariant } = useThemeVariant();

  const icons = {
    [ThemeVariant.Auto]: <SettingsBrightness />,
    [ThemeVariant.Light]: <Brightness7 />,
    [ThemeVariant.Dark]: <Brightness2 />,
  };

  const themeActions: Action[] = Object.values(ThemeVariant).map((variant) => ({
    id: `theme-variant-${variant}`,
    label: t(`theme.${variant}`),
    run: () => setThemeVariant(variant),
    icon: icons[variant],
    selected: themeVariant === variant,
  }));

  return (
    <ActionsButton actions={themeActions}>
      {(buttonProps) => (
        <Tooltip title={t('theme.choose')!} arrow placement="left">
          <IconButton
            {...buttonProps}
            {...iconButtonProps}
            aria-label={t('theme.choose')}
            data-cy="theme-toggle-button"
          >
            {<Brightness4 />}
          </IconButton>
        </Tooltip>
      )}
    </ActionsButton>
  );
}
