import { ivageCommon } from '../../ivageCommon';
import { appendErrors, Resolver, transformToNestObject } from 'react-hook-form';
import { LocalizedIssue } from './lfIntegrationApi';
import { i18n as I18n } from 'i18next';
import { getIssueMessage } from './util/getIssueMessage';
import { isDate, isValid } from 'date-fns';
import { toISODateString } from '../dateUtils';
import { lfPathToNamePath } from './util/pathNameConvertions';

/**
 * Options used by the resolver.
 */
export interface LfResolverOptions {
  formValidatorName: string;
  formValidatorArgs?: any[];
  i18n: I18n;
  i18nErrorMessagesPrefixes?: string[];
}

/**
 * Context used by the LF resolver.
 */
export interface LfResolverContext {
  lfIssues?: LocalizedIssue[];
  [prop: string]: any;
}

/**
 * Integration of LF with RHF as a RHF resolver that runs the LF validator named
 * `formValidatorName`. Receives an `i18next` instance and a prefix or list of
 * prefixes where to find validation error messages.
 */
export function lfResolver({
  formValidatorName,
  formValidatorArgs = [],
  i18n,
  i18nErrorMessagesPrefixes = [],
}: LfResolverOptions): Resolver<any, LfResolverContext> {
  const validator = ivageCommon[formValidatorName](...formValidatorArgs);

  return async function (values, context, validateAllFieldCriteria = false) {
    const jsonForm = JSON.stringify(
      values,
      // Transform `Date` instances into ISO date strings
      (key, value) => {
        if (value !== null && typeof value === 'object') {
          for (const prop of Object.keys(value)) {
            const propValue = value[prop];
            if (isDate(propValue)) {
              value[prop] = isValid(propValue)
                ? toISODateString(propValue)
                : null;
            }
          }
        }
        return value;
      }
    );

    try {
      const issues: LocalizedIssue[] = [
        // Allow issues to come from the context as to support manually
        // adding/removing them
        ...(context?.lfIssues ?? []),
        ...JSON.parse(await validator.validate(jsonForm)),
      ];

      if (issues.length === 0) {
        return { values, errors: {} };
      } else {
        return {
          values: {},
          errors: transformToNestObject(
            parseLfIssues(
              issues,
              validateAllFieldCriteria,
              i18n,
              i18nErrorMessagesPrefixes
            )
          ),
        };
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
      }
      return { values: {}, errors: { '': { type: 'validating', message: e } } };
    }
  };
}

function parseLfIssues(
  issues: LocalizedIssue[],
  validateAllFieldCriteria: boolean,
  i18n: I18n,
  i18nErrorMessagesPrefixes: string[]
) {
  return issues.reduce((prev: Record<string, any>, { path, code, data }) => {
    const namePath =
      data?.path != null ? lfPathToNamePath(data.path) : lfPathToNamePath(path);
    const message = getIssueMessage(
      namePath,
      code,
      data,
      i18n,
      i18nErrorMessagesPrefixes
    );

    return {
      ...prev,
      ...(prev[namePath] && validateAllFieldCriteria
        ? {
            [namePath]: appendErrors(
              namePath,
              validateAllFieldCriteria,
              prev,
              code,
              message
            ),
          }
        : {
            [namePath]: prev[namePath] ?? {
              type: code,
              message,
              ...(validateAllFieldCriteria
                ? { types: { [code]: message } }
                : {}),
            },
          }),
    };
  }, {});
}
