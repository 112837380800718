import React, {
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from '@material-ui/core';
import { theme } from '../../styles/themes';
import { ThemeContext } from './ThemeContext';

/**
 * Theme variant.
 */
export enum ThemeVariant {
  Auto = 'auto',
  Light = 'light',
  Dark = 'dark',
}

/**
 * Active theme variant.
 */
export type ActiveThemeVariant = ThemeVariant.Light | ThemeVariant.Dark;

/**
 * Name of the local storage item where the preferred theme variant is stored.
 */
export const THEME_VARIANT_ITEM = 'theme';

/**
 * Properties of the theme provider.
 */
export interface ThemeProviderProps {
  children: ReactNode;
}

/**
 * Theme provider.
 */
export function ThemeProvider({ children }: ThemeProviderProps) {
  const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');

  const variantToUse = useCallback(
    (variant: ThemeVariant): ActiveThemeVariant =>
      variant === ThemeVariant.Light || variant === ThemeVariant.Dark
        ? variant
        : prefersDarkTheme
        ? ThemeVariant.Dark
        : ThemeVariant.Light,
    [prefersDarkTheme]
  );

  const [themeVariant, setThemeVariant] = useState<ThemeVariant>(() => {
    const storageVariant = localStorage.getItem(THEME_VARIANT_ITEM);
    return storageVariant === ThemeVariant.Light ||
      storageVariant === ThemeVariant.Dark
      ? storageVariant
      : ThemeVariant.Auto;
  });

  const exposedSetVariant = useCallback(
    (themeVariant: SetStateAction<ThemeVariant>) => {
      setThemeVariant((variant) => {
        const newVariant =
          typeof themeVariant === 'function'
            ? themeVariant(variant)
            : themeVariant;
        if (newVariant === ThemeVariant.Auto) {
          localStorage.removeItem(THEME_VARIANT_ITEM);
        } else {
          localStorage.setItem(THEME_VARIANT_ITEM, newVariant);
        }
        return newVariant;
      });
    },
    []
  );

  const activeThemeVariant = useMemo(
    () => variantToUse(themeVariant),
    [variantToUse, themeVariant]
  );

  const themeObj = useMemo(
    () => theme(activeThemeVariant),
    [activeThemeVariant]
  );

  // Set the document body's background colour based on the theme since this
  // styles the top-most `Suspense`
  useEffect(() => {
    document.body.style.backgroundColor = themeObj.palette.background.default;
  }, [themeObj]);

  return (
    <ThemeContext.Provider
      value={{
        themeVariant,
        setThemeVariant: exposedSetVariant,
        activeThemeVariant,
      }}
    >
      <MuiThemeProvider theme={themeObj}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
