import React, { ReactNode, RefObject } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  Switch,
  SwitchProps,
} from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { triggerNames } from '../../util/triggerNames';
import { resolveObject } from '../../util/resolveObject';
import { scrollAndFocus } from './util/scrollAndFocus';

interface CustomFormSwitchProps extends SwitchProps {
  name: string;
  label?: ReactNode;
  fetching?: boolean;
  rules?: RegisterOptions;
  onChangeTriggers?: string[];
  helperText?: ReactNode;
  FormControlProps?: Omit<FormControlProps, 'error'>;
  FormControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
}

/**
 * Properties of the form switch component.
 */
export type FormSwitchProps = CustomFormSwitchProps &
  Omit<SwitchProps, 'checked'>;

/**
 * Switch to be used within an RHF form context.
 */
export function FormSwitch({
  name,
  label,
  fetching,
  rules,
  disabled,
  color = 'primary',
  onChange,
  onChangeTriggers,
  onBlur,
  helperText,
  FormControlProps,
  FormControlLabelProps,
  ...otherProps
}: FormSwitchProps) {
  const { control, errors, formState, trigger } = useFormContext();
  let switchRef: RefObject<HTMLInputElement>;

  const shouldDisable = disabled || fetching || formState.isSubmitting;
  const error = resolveObject(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => scrollAndFocus(switchRef)}
      render={({
        onChange: controllerOnChange,
        onBlur: controllerOnBlur,
        value,
        name,
        ref,
      }) => (
        <FormControl error={!!error} {...FormControlProps}>
          <FormControlLabel
            control={
              <Switch
                {...otherProps}
                name={name}
                checked={value}
                color={color}
                inputRef={(switchRef = ref)}
                onChange={(evt, checked) => {
                  controllerOnChange(checked);
                  onChangeTriggers &&
                    triggerNames(formState, trigger, ...onChangeTriggers);
                  onChange?.(evt, checked);
                }}
                onBlur={(evt) => {
                  controllerOnBlur();
                  onBlur?.(evt);
                }}
              />
            }
            label={label}
            disabled={shouldDisable}
            {...FormControlLabelProps}
          />
          {(error || helperText) && (
            <FormHelperText>{error?.message ?? helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
