import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { Payment, usePaymentsApi } from './paymentsApi';
import { useTranslation } from 'react-i18next';

/**
 * Result of the `usePayment` hook.
 */
export interface PaymentResource {
  isFetching: boolean;
  payment: Payment | null;
  notFound: boolean;
  setPayment: Dispatch<SetStateAction<Payment | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a payment from the API, if no `paymentId` is passed this
 * hook does nothing.
 */
export function usePayment(paymentId?: number): PaymentResource {
  const [t] = useTranslation('payments');
  const { getPayment } = usePaymentsApi();
  const getPaymentCb = useCallback(
    () => (paymentId != null ? getPayment(paymentId) : null),
    [getPayment, paymentId]
  );
  const {
    isFetching,
    resource: payment,
    notFound,
    setResource: setPayment,
    refetch,
  } = useResource({
    fetchResource: getPaymentCb,
    errorFetchingResourceMessage: t('payments:errorFetchingPayment', {
      id: paymentId ?? '',
    }),
  });

  return { isFetching, payment, notFound, setPayment, refetch };
}
