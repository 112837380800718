import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { CreditSettlement, CreditSettlementStatus } from '../creditSettlementsApi';
import { CreditSettlementRowActions } from './CreditSettlementRowActions';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import { StatusChip } from '../../../components/StatusChip';
import {
  replacePathParams,
  CREDIT_SETTLEMENT_PATH,
  TAXPAYER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { Link } from '../../../components/Link';
import { NotSpecified } from '../../../components/NotSpecified';
import { VoidCreditSettlementForm } from '../VoidCreditSettlementForm';

/**
 * Properties of the credit settlements table.
 */
export interface CreditSettlementsTableProps {
  fetching: boolean;
  creditSettlements: CreditSettlement[];
  setCreditSettlements: Dispatch<SetStateAction<CreditSettlement[] | null>>;
  refetchCreditSettlements: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing credit settlements.
 */
export function CreditSettlementsTable({
  fetching,
  creditSettlements,
  setCreditSettlements,
  refetchCreditSettlements,
  downloadButton,
}: CreditSettlementsTableProps) {
  const [t] = useTranslation(['common', 'creditSettlements']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const [creditSettlementVoiding, setCreditSettlementVoiding] =
    useState<CreditSettlement | null>(null);

  const setCreditSettlement = useCallback(
    (creditSettlement: CreditSettlement) => {
      setCreditSettlements(
        (creditSettlements) =>
          creditSettlements &&
          creditSettlements.map((ref) =>
            ref.creditSettlementId === creditSettlement.creditSettlementId
              ? creditSettlement
              : ref
          )
      );
    },
    [setCreditSettlements]
  );

  const columns: DataTableColumns<CreditSettlement> = {
    creditSettlementId: {
      label: '#',
      value: ({ creditSettlementId }) => creditSettlementId,
      render: (content, value) => (
        <Link to={replacePathParams(CREDIT_SETTLEMENT_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    assessmentNumber: {
      label: t('creditSettlements:creditSettlementFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber || null,
      render: (content, value) =>
        value ? (
          <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
            {content}
          </Link>
        ) : (
          <NotSpecified>
            {t('creditSettlements:noAssessmentNumber')}
          </NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    taxpayerId: {
      label: t('creditSettlements:creditSettlementFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxableYearPeriod: {
      label: t('creditSettlements:creditSettlementFields.taxablePeriod'),
      value: ({ taxableYear, taxablePeriod }) =>
        `${taxableYear}/${taxablePeriod}`,
      defaultSortDirection: 'desc',
    },
    creditSettlementDate: {
      label: t('creditSettlements:creditSettlementFields.date'),
      value: ({ creditSettlementDate }) => creditSettlementDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    amount: {
      label: t('creditSettlements:creditSettlementFields.amount'),
      value: ({ amount }) => amount,
      format: (value) => formatCurrency(value as number),
      align: 'right',
    },
    status: {
      label: t('creditSettlements:creditSettlementFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`creditSettlements:creditSettlementStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={
            status === CreditSettlementStatus.Voided ? 'error' : 'success'
          }
          label={content}
        />
      ),
      paddinglessY: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <CreditSettlementRowActions
          creditSettlement={row}
          onVoid={() => {
            setCreditSettlementVoiding(row);
            setIsVoidFormOpen(true);
          }}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <>
      <VoidCreditSettlementForm
        creditSettlement={creditSettlementVoiding}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newCreditSettlement) =>
          setCreditSettlement(newCreditSettlement)
        }
        onFailureDueToOutdatedUI={refetchCreditSettlements}
      />

      <DataTable
        title={t('creditSettlements:creditSettlementsPage.dataTable.title')}
        rows={creditSettlements}
        rowId={({ creditSettlementId }) => creditSettlementId}
        columns={columns}
        emptyMessage={t(
          'creditSettlements:creditSettlementsPage.dataTable.emptyMessage'
        )}
        toolbarActionsLeft={downloadButton}
        fetching={fetching}
        defaultSortBy="taxableYearPeriod"
        minWidth={1100}
      />
    </>
  );
}
