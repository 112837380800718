import { createContext } from 'react';

/**
 * Custom "request init" with support for query params.
 */
export interface RequestInitWithParams extends RequestInit {
  params?: Record<string, any>;
}

/**
 * Fetch from API interface.
 */
export interface FetchFromApi {
  fetch(input: RequestInfo, init?: RequestInit): Promise<Response>;

  getJson<T = any>(url: string, init?: RequestInitWithParams): Promise<T>;

  deleteJson<T = any>(url: string, init?: RequestInitWithParams): Promise<T>;

  postJson<T = any>(
    url: string,
    data?: any,
    init?: RequestInitWithParams
  ): Promise<T>;

  patchJson<T = any>(
    url: string,
    data?: any,
    init?: RequestInitWithParams
  ): Promise<T>;

  putJson<T = any>(
    url: string,
    data?: any,
    init?: RequestInitWithParams
  ): Promise<T>;
}

/**
 * Fetch from API context.
 */
export const FetchFromApiContext = createContext<FetchFromApi>(
  undefined as any
);
