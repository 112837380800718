import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ReauthenticationContext } from './ReauthenticationContext';
import { Form, FormPasswordField, FormTextField } from '../../components/Form';
import { useLfForm } from '../../util/lfIntegration';
import { useAuthentication } from '../AuthenticationProvider';
import { useSnackbar } from 'notistack';
import { nextRefreshTime, nextRefreshTimeAfterError } from './nextRefreshTime';
import { Action, DialogActionButtons } from '../../components/Actions';

// Maximum delay we can pass to `setTimeout`
const MAX_SET_TIMEOUT_DELAY = 2 ** 31 - 1;

/**
 * Properties of the reauthentication provider.
 */
export interface ReauthenticationProviderProps {
  children: ReactNode;
}

/**
 * Reauthentication provider.
 */
export function ReauthenticationProvider({
  children,
}: ReauthenticationProviderProps) {
  const [t] = useTranslation('common');
  const {
    isFetching: isFetchingInitialUser,
    user,
    api: { login, refresh, logout },
  } = useAuthentication();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: { username: user?.username ?? '', password: '' },
    formValidatorName: 'loginFormValidator',
    i18nErrorMessagesPrefixes: 'authentication.fieldErrors',
  });
  const { getValues, reset, formState, setLfIssues } = formMethods;
  const [isOpen, setIsOpen] = useState(false);

  // Reauthentication promise and respective "resolver", multiple API calls may
  // fail at once, so we use a single promise so they all wait for the same
  // "reauthentication" to finish.
  const promise = useRef<Promise<boolean> | null>(null);
  const resolve = useRef<((ok: boolean) => void) | null>(null);

  // Reset form whenever the `isOpen` state changes
  useEffect(() => {
    reset({ username: user?.username ?? '', password: '' });
  }, [reset, user, isOpen]);

  /**
   * Automatically refresh the JWT when a user is logged in.
   */
  const [refreshAt, setRefreshAt] = useState<number | null>(null);
  useEffect(() => {
    if (user != null && !isOpen) {
      const timeoutDelay = Math.min(
        Math.max(0, (refreshAt ?? nextRefreshTime(user)) - Date.now()),
        MAX_SET_TIMEOUT_DELAY
      );
      const timeoutId = setTimeout(async () => {
        try {
          const newUser = await refresh();
          setRefreshAt(nextRefreshTime(newUser));
        } catch (err) {
          // Session expired
          if (err instanceof Response && err.status === 401) {
            if (!isOpen) {
              setIsOpen(true);
              enqueueSnackbar(t('authentication.sessionExpired'), {
                id: 'session-expired',
                variant: 'error',
                preventDuplicate: true,
              });
            }
          } else {
            setRefreshAt(nextRefreshTimeAfterError());
          }
        }
      }, timeoutDelay);
      return () => clearTimeout(timeoutId);
    }
  }, [
    user,
    refreshAt,
    t,
    enqueueSnackbar,
    refresh,
    isOpen,
    isFetchingInitialUser,
  ]);

  async function onSubmit() {
    const { username, password } = getValues();
    try {
      await login(username, password);
      resolve.current?.(true);
      promise.current = null;
      resolve.current = null;
      setIsOpen(false);
      closeSnackbar('session-expired');
    } catch (err) {
      if (err instanceof Response && err.status === 401) {
        setLfIssues([{ path: '/', code: 'invalidCredentials' }]);
      } else {
        enqueueSnackbar(t('reauthentication.errorReauthenticating'), {
          variant: 'error',
        });
      }
    }
  }

  const reauthenticateImpl = useCallback(() => {
    if (promise.current == null) {
      promise.current = new Promise<boolean>((res) => {
        resolve.current = res;
        setIsOpen(true);
      });
    }
    return promise.current;
  }, []);

  const logoutImpl = useCallback(async () => {
    await logout();
    resolve.current?.(false);
    promise.current = null;
    resolve.current = null;
    setIsOpen(false);
  }, [logout]);

  // Actions of the form
  const actions: Action[] = [
    {
      id: 'reauthentication-submit',
      type: 'submit',
      label: t('reauthentication.loginButton'),
      color: 'primary',
      loading: formState.isSubmitting,
    },
    {
      id: 'reauthentication-logout',
      label: t('reauthentication.logoutButton'),
      run: () => logoutImpl(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <ReauthenticationContext.Provider
      value={{ reauthenticate: reauthenticateImpl }}
    >
      <Dialog
        open={isOpen}
        disableEscapeKeyDown
        disableBackdropClick
        aria-labelledby="reauthentication-title"
      >
        <Form onSubmit={onSubmit} {...formMethods}>
          <DialogTitle id="reauthentication-title">
            {t('reauthentication.title')}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              {/* Username */}
              <Grid item xs={12}>
                <FormTextField
                  name="username"
                  label={t('authentication.fields.username')}
                  disabled
                />
              </Grid>

              {/* Password */}
              <Grid item xs={12}>
                <FormPasswordField
                  name="password"
                  label={t('authentication.fields.password')}
                  autoComplete="current-password"
                  autoFocus
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <DialogActionButtons actions={actions} />
          </DialogActions>
        </Form>
      </Dialog>

      {children}
    </ReauthenticationContext.Provider>
  );
}
