import React from 'react';
import {
  Container,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { Form, FormPasswordField, FormTextField } from '../../components/Form';
import { ThemeToggler } from '../../components/ThemeToggler';
import { Redirect } from 'react-router-dom';
import { REDIRECT_PARAM, ROOT_PATH } from '../../navigation/paths';
import { AppVersion } from '../../components/AppVersion';
import { useLfForm } from '../../util/lfIntegration';
import { useQueryParams } from '../../util/useQueryParams';
import { useSnackbar } from 'notistack';
import { ActionButton } from '../../components/Actions/ActionButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    toggleThemeButtonContainer: {
      padding: theme.spacing(1),
      alignSelf: 'flex-end',
    },
    logo: {
      height: 140,
      marginBottom: theme.spacing(3),
    },
    paperContainer: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        padding: 0,
      },
    },
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    submit: {
      marginTop: theme.spacing(3),
    },
    versionContainer: {
      flex: 1,
      display: 'flex',
      padding: theme.spacing(2),
      alignSelf: 'center',
    },
    version: {
      alignSelf: 'flex-end',
    },
  })
);

/**
 * Query params used by the login page.
 */
const allowedQueryParams = [REDIRECT_PARAM];

/**
 * Login page.
 */
export function LoginPage() {
  const classes = useStyles();
  const [t] = useTranslation('common');
  useSetDocumentTitle(t('loginPage.documentTitle'));
  const {
    user,
    api: { login },
  } = useAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const [queryParams] = useQueryParams(allowedQueryParams);
  const formMethods = useLfForm({
    defaultValues: { username: '', password: '' },
    formValidatorName: 'loginFormValidator',
    i18nErrorMessagesPrefixes: 'authentication.fieldErrors',
  });
  const { getValues, formState, setLfIssues } = formMethods;

  async function onSubmit() {
    const { username, password } = getValues();
    try {
      await login(username, password);
    } catch (err) {
      if (err instanceof Response && err.status === 401) {
        setLfIssues([{ path: '/', code: 'invalidCredentials' }]);
      } else {
        enqueueSnackbar(t('loginPage.errorLoggingIn'), {
          variant: 'error',
        });
      }
    }
  }

  // Submit action
  const submitAction = {
    id: 'login-form-submit',
    type: 'submit',
    label: t('loginPage.loginButton'),
    color: 'primary',
    loading: formState.isSubmitting,
  };

  return user ? (
    <Redirect to={queryParams[REDIRECT_PARAM] || ROOT_PATH} />
  ) : (
    <div className={classes.root}>
      {/* Toggle theme */}
      <div className={classes.toggleThemeButtonContainer}>
        <ThemeToggler />
      </div>

      <Container
        className={classes.paperContainer}
        component="main"
        maxWidth="xs"
      >
        <Paper className={classes.paper} elevation={3} data-cy="app-login">
          <img
            className={classes.logo}
            src="/logo.svg"
            alt={t('logoAltText')}
          />
          <Typography variant="h5" component="h1">
            {t('title')}
          </Typography>

          <Form onSubmit={onSubmit} {...formMethods}>
            {/* Username */}
            <FormTextField
              name="username"
              label={t('authentication.fields.username')}
              margin="normal"
            />

            {/* Password */}
            <FormPasswordField
              name="password"
              label={t('authentication.fields.password')}
              margin="normal"
              autoComplete="current-password"
            />

            {/* Submit */}
            <ActionButton
              className={classes.submit}
              variant="contained"
              fullWidth
              action={submitAction}
            />
          </Form>
        </Paper>
      </Container>

      {/* Application version */}
      <div className={classes.versionContainer}>
        <AppVersion className={classes.version} />
      </div>
    </div>
  );
}
