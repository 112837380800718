import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useVatReturnsApi, VatReturn } from './vatReturnsApi';
import { useLfForm } from '../../util/lfIntegration';
import { Form, FormTextField } from '../../components/Form';
import { ivageCommon } from '../../ivageCommon';
import { TitleId } from '../../components/TitleId';
import { Action } from '../../components/Actions';
import { DialogActionButtons } from '../../components/Actions';

/**
 * Package in IVAGE common containing the void VAT return form schema.
 */
const voidVatReturnFormSchemaPkg = ivageCommon.feature.vatreturn.schema;

/**
 * Properties of the void VAT return form.
 */
export interface VoidVatReturnFormProps {
  vatReturn: VatReturn | null;
  open: boolean;
  setOpen(open: boolean): void;
  onSuccess?(vatReturn: VatReturn): void;
  onFailureDueToOutdatedUI(): void;
}

/**
 * Modal form used to void a VAT return.
 */
export function VoidVatReturnForm({
  vatReturn,
  open,
  setOpen,
  onSuccess,
  onFailureDueToOutdatedUI,
}: VoidVatReturnFormProps) {
  const [t] = useTranslation(['common', 'vatReturns']);
  const { voidVatReturn } = useVatReturnsApi();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: { voidDescription: '' },
    formValidatorName: 'voidVatReturnFormValidator',
    i18nErrorMessagesPrefixes: 'vatReturns:voidVatReturnForm.fieldErrors',
  });
  const { reset, getValues, formState } = formMethods;

  // Reset form whenever the VAT return changes or modal changes open state
  useEffect(() => {
    reset();
  }, [reset, vatReturn, open]);

  // Close form modal
  function close() {
    setOpen(false);
  }

  async function onSubmit() {
    const voidVatReturnForm = getValues();
    try {
      const newVatReturn = await voidVatReturn(
        vatReturn!.taxReturnId,
        voidVatReturnForm
      );
      close();
      onSuccess?.(newVatReturn);
      enqueueSnackbar(
        t('vatReturns:vatReturnVoidedSuccessfully', {
          id: vatReturn!.taxReturnId,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        close();
        onFailureDueToOutdatedUI();
        enqueueSnackbar(
          t('vatReturns:errorVoidingVatReturnDueToOutdatedUI', {
            id: vatReturn!.taxReturnId,
          }),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          t('vatReturns:errorVoidingVatReturn', { id: vatReturn!.taxReturnId }),
          { variant: 'error' }
        );
      }
    }
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: `void-vat-return-form-${vatReturn?.taxReturnId}-submit`,
      type: 'submit',
      label: t('vatReturns:voidVatReturnForm.void'),
      style: 'error',
      loading: formState.isSubmitting,
      disabled: !vatReturn,
    },
    {
      id: `void-vat-return-form-${vatReturn?.taxReturnId}-cancel`,
      label: t('formActions.cancel'),
      run: () => close(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={close}
      disableBackdropClick={formState.isDirty || formState.isSubmitting}
      disableEscapeKeyDown={formState.isDirty || formState.isSubmitting}
      aria-labelledby="void-tax-refund-form"
    >
      <Form onSubmit={onSubmit} {...formMethods}>
        <DialogTitle id="void-tax-refund-form">
          {t('vatReturns:voidVatReturnForm.title') + ' '}
          <TitleId titleId={vatReturn?.taxReturnId} />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Void description */}
            <Grid item xs={12}>
              <FormTextField
                name="voidDescription"
                label={t('vatReturns:vatReturnFields.voidDescription')}
                multiline
                rows={5}
                inputProps={{
                  maxLength:
                    voidVatReturnFormSchemaPkg.VOID_DESCRIPTION_MAX_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
