import {
  Button,
  ButtonProps,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Action, isActionGroup } from './Action';
import { useActionIsRunning, useActionsAreRunning } from './useActionIsRunning';
import React, { useRef, useState } from 'react';
import { ActionsMenuPopper } from './ActionsMenuPopper';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    action: {
      whiteSpace: 'nowrap',
    },
    actionContainedError: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    actionOutlinedError: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    actionTextError: {
      color: theme.palette.error.main,
    },
    actionContainedSuccess: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    },
    actionOutlinedSuccess: {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
    actionTextSuccess: {
      color: theme.palette.success.main,
    },
    loadingIcon: {
      marginRight: theme.spacing(0.5),
    },
  })
);

/**
 * Action button props.
 */
export interface ActionButtonProps extends Omit<ButtonProps, 'action'> {
  action: Action;
}

/**
 * A single action button.
 */
export function ActionButton({
  variant,
  action,
  className: buttonClassName,
  ...buttonProps
}: ActionButtonProps) {
  const classes = useStyles();
  const {
    id,
    label,
    run,
    icon,
    loading,
    disabled,
    className: actionClassName,
    style,
    ...actionProps
  } = action;
  const [running, setRunning, deleteRunning] = useActionIsRunning(id);

  // `run` implementation that marks the action as running
  const runImpl = async (evt: any) => {
    setRunning(true);
    try {
      await run?.(evt);
    } finally {
      deleteRunning();
    }
  };

  // The action may represent a group of actions
  const isGroup = isActionGroup(action);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const groupLoading = useActionsAreRunning(isGroup ? action.children : []);
  const handleGroupToggle = () => setIsGroupOpen((isOpen) => !isOpen);
  const menuListId = `actions-menu-list-${action.id}`;

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={isGroup && isGroupOpen ? menuListId : undefined}
        aria-expanded={isGroup && isGroupOpen ? 'true' : undefined}
        aria-haspopup={isGroup && 'menu'}
        className={`${buttonClassName} ${actionClassName ?? ''} ${
          classes.action
        } ${
          !loading && !running && !disabled
            ? style === 'error'
              ? variant === 'outlined'
                ? classes.actionOutlinedError
                : variant === 'contained'
                ? classes.actionContainedError
                : classes.actionTextError
              : style === 'success'
              ? variant === 'outlined'
                ? classes.actionOutlinedSuccess
                : variant === 'contained'
                ? classes.actionContainedSuccess
                : classes.actionTextSuccess
              : ''
            : ''
        }`}
        variant={variant}
        onClick={(evt) => (isGroup ? handleGroupToggle() : runImpl(evt))}
        startIcon={
          loading || groupLoading || running ? (
            <CircularProgress className={classes.loadingIcon} size={16} />
          ) : (
            icon
          )
        }
        endIcon={isGroup && (isGroupOpen ? <ArrowDropUp /> : <ArrowDropDown />)}
        {...buttonProps}
        {...actionProps}
        disabled={loading || running || disabled}
        data-cy={`main-action-${id}`}
      >
        {label}
      </Button>

      {/* Actions of group */}
      {isGroup && (
        <ActionsMenuPopper
          anchorRef={anchorRef}
          open={isGroupOpen}
          setOpen={setIsGroupOpen}
          actions={action.children}
          menuListId={menuListId}
        />
      )}
    </>
  );
}
