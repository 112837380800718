import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainContent } from '../../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../../util/formatters';
import { useVatReturn } from '../useVatReturn';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { useVatReturnActions } from '../useVatReturnActions';
import { Link } from '../../../components/Link';
import {
  replacePathParams,
  TAXPAYER_PATH,
  USER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { VatReturnHistoryTable } from './VatReturnHistoryTable';
import { NotSpecified } from '../../../components/NotSpecified';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { VatReturn, VatReturnStatus } from '../vatReturnsApi';
import { UserRole } from '../../../providers/ProfileProvider';
import { VoidVatReturnForm } from '../VoidVatReturnForm';

/**
 * Page with the information of a VAT return.
 */
export function VatReturnPage() {
  const [t, i18n] = useTranslation('vatReturns');
  const { id } = useParams<{ id: string }>();
  useSetDocumentTitle(t('vatReturns:vatReturnPage.documentTitle', { id }));
  const {
    vatReturn,
    isFetching,
    notFound,
    setVatReturn,
    refetch,
  } = useVatReturn(+id);
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const [vatReturnVoiding, setVatReturnVoiding] = useState<VatReturn | null>(
    null
  );
  const [onVoidSuccess, setOnVoidSuccess] = useState<
    (newVatReturn: VatReturn) => void
  >();

  const actions = useVatReturnActions(vatReturn, () => {
    setVatReturnVoiding(vatReturn);
    setIsVoidFormOpen(true);
    setOnVoidSuccess(() => (newVatReturn: VatReturn) =>
      setVatReturn(newVatReturn)
    );
  });

  const showVatTotal = vatReturn?.vatTotal !== 0 || vatReturn?.vatCredit === 0;

  // VAT return information fields
  const vatReturnInformation: DescriptionListItem[] = [
    {
      key: 'taxpayer-id',
      label: t('vatReturns:vatReturnFields.taxpayerId'),
      content: vatReturn && (
        <>
          <Link
            to={replacePathParams(TAXPAYER_PATH, {
              id: vatReturn.taxpayerId,
            })}
          >
            {vatReturn.taxpayerId}
          </Link>
          {vatReturn.taxpayerName && (
            <ExtraInfo>{vatReturn.taxpayerName}</ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'origin',
      label: t('vatReturns:vatReturnFields.origin'),
      content: vatReturn && t(`vatReturns:vatReturnOrigin.${vatReturn.origin}`),
      fetching: isFetching,
    },
    {
      key: 'taxable-year-period',
      label: `${t('vatReturns:vatReturnFields.taxableYear')}/${t(
        'vatReturns:vatReturnFields.taxablePeriod'
      ).toLocaleLowerCase(i18n.language)}`,
      content: `${vatReturn?.taxableYear}/${vatReturn?.taxablePeriod}`,
      fetching: isFetching,
    },
    {
      key: 'deliveryDate',
      label: t('vatReturns:vatReturnFields.deliveryDate'),
      content: formatDate(vatReturn?.deliveryDate),
      fetching: isFetching,
    },
    {
      key: 'vat-total-vat-credit',
      label: isFetching
        ? t('vatReturns:vatReturnPage.vatTotalVatCreditDeclared')
        : t(
            `vatReturns:vatReturnFields.${
              showVatTotal ? 'vatTotalDeclared' : 'vatCreditDeclared'
            }`
          ),
      content: formatCurrency(
        showVatTotal ? vatReturn?.vatTotal : vatReturn?.vatCredit
      ),
      fetching: isFetching,
    },
    {
      key: 'created-date',
      label: t('vatReturns:vatReturnFields.createdDate'),
      content: vatReturn && (
        <>
          {formatDateTime(vatReturn.createdDate)}
          <ExtraInfo>
            {t('vatReturns:vatReturnFields.createdBy').toLocaleLowerCase(
              i18n.language
            )}{' '}
            {
              <Link
                to={replacePathParams(USER_PATH, {
                  username: vatReturn.createdBy,
                })}
                role={UserRole.Manager}
              >
                {vatReturn.createdBy}
              </Link>
            }
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'void-description',
      label: t('vatReturns:vatReturnFields.voidDescription'),
      content: vatReturn?.voidDescription || (
        <NotSpecified>
          {t('vatReturns:voidDescriptionNotSpecified')}
        </NotSpecified>
      ),
      preserveWhiteSpaces: true,
      hidden: isFetching || vatReturn?.status !== VatReturnStatus.Voided,
    },
  ];

  // Assessment information fields
  const assessmentInformation: DescriptionListItem[] = [
    {
      key: 'assessment-number',
      label: t('vatReturns:vatReturnFields.assessmentNumber'),
      content:
        vatReturn &&
        (vatReturn.assessmentNumber ? (
          <Link
            to={replacePathParams(VAT_ASSESSMENT_PATH, {
              id: vatReturn.assessmentNumber,
            })}
          >
            {vatReturn.assessmentNumber}
          </Link>
        ) : (
          <NotSpecified>{t('vatReturns:noAssessmentNumber')}</NotSpecified>
        )),
      fetching: isFetching,
    },
  ];

  return (
    <MainContent errorMessage={notFound && t('vatReturns:vatReturnNotFound')}>
      <ActionableHeader
        title={t('vatReturns:vatReturnPage.title')}
        titleId={id}
        actions={actions}
        status={
          vatReturn && t(`vatReturns:vatReturnStatus.${vatReturn.status}`)
        }
        statusVariant={
          vatReturn?.status === VatReturnStatus.Effective
            ? 'success'
            : vatReturn?.status === VatReturnStatus.Voided
            ? 'error'
            : 'default'
        }
      />

      <VoidVatReturnForm
        vatReturn={vatReturnVoiding}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={onVoidSuccess}
        onFailureDueToOutdatedUI={refetch}
      />

      <PaperDescriptionList
        title={t('vatReturns:vatReturnInformation')}
        items={vatReturnInformation}
      />
      <PaperDescriptionList
        title={t('vatReturns:assessmentInformation')}
        items={assessmentInformation}
      />

      <VatReturnHistoryTable
        vatReturn={vatReturn}
        setVatReturnVoiding={setVatReturnVoiding}
        setIsVoidFormOpen={setIsVoidFormOpen}
        setOnVoidSuccess={setOnVoidSuccess}
      />
    </MainContent>
  );
}
