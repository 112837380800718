import { Taxpayer } from '../taxpayersApi';
import { useDivisionSections } from '../../../providers/CatalogsProvider';
import { useMemo } from 'react';

/**
 * Return of the use division sections hook.
 */
export interface FormattedAddressResource {
  isFetching: boolean;
  formattedAddress: string | null;
}

/**
 * Hook that formats a taxpayer's address.
 */
export function useFormatTaxpayerAddress(
  taxpayer: Taxpayer | null
): FormattedAddressResource {
  const { sections, isFetching } = useDivisionSections(taxpayer?.divisionId);

  const formattedAddress = useMemo(
    () =>
      [
        taxpayer?.address,
        [
          sections?.municipality?.name,
          sections?.district?.name,
          sections?.province?.name,
        ]
          .filter((part) => part)
          .join(', '),
      ]
        .filter((line) => line)
        .join('\n'),
    [sections, taxpayer]
  );

  return useMemo(
    () => ({ formattedAddress, isFetching }),
    [formattedAddress, isFetching]
  );
}
