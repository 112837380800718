import { getMonth, getYear, startOfToday, subMonths } from 'date-fns';
import { TaxRefundForm } from '../taxRefundsApi';
import { localDateToSameDateInUTC } from '../../../util/dateUtils';
import { TaxablePeriod, TaxId } from '../../../common';

/**
 * Default "tax refund form" value.
 */
export function defaultTaxRefundForm(): TaxRefundForm {
  return {
    taxpayerId: '',
    assessmentNumber: '',
    taxId: TaxId.VAT,
    taxableYear: getYear(subMonths(new Date(), 1)),
    taxablePeriod: (getMonth(subMonths(new Date(), 1)) + 1)
      .toString()
      .padStart(2, '0') as TaxablePeriod,
    taxRefundDate: localDateToSameDateInUTC(startOfToday()),
    amount: null,
    bankId: null,
    transactionMethod: null,
    description: '',
  };
}
