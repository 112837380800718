import { Dispatch, SetStateAction } from 'react';
import { useResource } from '../../util/useResource';
import {
  StateCheckingAccount,
  useStateCheckingAccountApi,
} from './stateCheckingAccountApi';
import { useTranslation } from 'react-i18next';

/**
 * State checking account as a resource.
 */
export interface StateCheckingAccountResource {
  isFetching: boolean;
  stateCheckingAccount: StateCheckingAccount | null;
  notFound: boolean;
  setStateCheckingAccount: Dispatch<
    SetStateAction<StateCheckingAccount | null>
  >;
  refetch: () => void;
}

/**
 * Hook used to fetch the state checking account from the API.
 */
export function useStateCheckingAccount(): StateCheckingAccountResource {
  const [t] = useTranslation('stateCheckingAccount');
  const { getStateCheckingAccount } = useStateCheckingAccountApi();
  const {
    isFetching,
    resource: stateCheckingAccount,
    notFound,
    setResource: setStateCheckingAccount,
    refetch,
  } = useResource({
    fetchResource: getStateCheckingAccount,
    errorFetchingResourceMessage: t(
      'stateCheckingAccount:errorFetchingStateCheckingAccount'
    ),
  });

  return {
    isFetching,
    stateCheckingAccount,
    notFound,
    setStateCheckingAccount,
    refetch,
  };
}
