import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { useCurrencyFormatter } from '../../../util/formatters';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import {
  STATE_CHECKING_ACCOUNT_URL,
  StateCheckingAccountPeriod,
  useStateCheckingAccountApi,
} from '../stateCheckingAccountApi';
import { StateCheckingAccountsPerPeriodTable } from './StateCheckingAccountsPerPeriodTable';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { useResource } from '../../../util/useResource';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

type StateCheckingAccountYear = Omit<
  StateCheckingAccountPeriod,
  'taxablePeriod' | 'assessmentNumber'
>;

/**
 * Table listing the state checking accounts per year.
 */
export function StateCheckingAccountsPerYearTable() {
  const [t] = useTranslation(['common', 'stateCheckingAccount']);
  const formatCurrency = useCurrencyFormatter();

  const { getStateCheckingAccountPeriods } = useStateCheckingAccountApi();
  const { isFetching, resource: stateCheckingAccountPeriods } = useResource({
    fetchResource: getStateCheckingAccountPeriods,
    errorFetchingResourceMessage: t(
      'stateCheckingAccount:stateCheckingAccountPage.errorFetchingStateCheckingAccountPeriods'
    ),
  });

  const stateCheckingAccountsPerYear = useMemo(
    () =>
      stateCheckingAccountPeriods?.reduce((obj, periodAccount) => {
        (
          obj[periodAccount.taxableYear] ||
          (obj[periodAccount.taxableYear] = [])
        ).push(periodAccount);
        return obj;
      }, {} as Record<string, StateCheckingAccountPeriod[]>),
    [stateCheckingAccountPeriods]
  );

  const stateCheckingAccountYears = useMemo(
    () =>
      stateCheckingAccountsPerYear &&
      Object.keys(stateCheckingAccountsPerYear).map((taxableYear) =>
        stateCheckingAccountsPerYear[taxableYear].reduce(
          (yearAccount, periodAccount) => ({
            ...yearAccount,
            vatCreditAvailable:
              yearAccount.vatCreditAvailable + periodAccount.vatCreditAvailable,
            paymentRefundableFinal:
              yearAccount.paymentRefundableFinal +
              periodAccount.paymentRefundableFinal,
            vatFinal: yearAccount.vatFinal + periodAccount.vatFinal,
            interestFinal:
              yearAccount.interestFinal + periodAccount.interestFinal,
          }),
          {
            taxableYear: +taxableYear,
            vatCreditAvailable: 0,
            paymentRefundableFinal: 0,
            vatFinal: 0,
            interestFinal: 0,
          } as StateCheckingAccountYear
        )
      ),
    [stateCheckingAccountsPerYear]
  );

  const columns: DataTableColumns<StateCheckingAccountYear> = {
    taxableYear: {
      label: t(
        'stateCheckingAccount:stateCheckingAccountPeriodFields.taxableYear'
      ),
      value: ({ taxableYear }) => taxableYear,
      defaultSortDirection: 'desc',
      sortable: false,
    },
    debits: {
      align: 'right',
      label: t('stateCheckingAccount:stateCheckingAccountPeriodFields.debits'),
      value: ({ vatCreditAvailable, paymentRefundableFinal }) => vatCreditAvailable + paymentRefundableFinal,
      format: (value, { vatFinal, interestFinal }) =>
        value === 0 &&
        (vatFinal !== 0 || interestFinal !== 0)
          ? ''
          : formatCurrency(value as number),
      sortable: false,
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatCreditAvailable !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatCreditAvailable)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.vatCreditAvailable'
                )}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.paymentRefundableFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('stateCheckingAccount:stateCheckingAccountPeriodFields.credits'),
      value: ({ vatFinal, interestFinal }) =>
        vatFinal + interestFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
      sortable: false,
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.vatFinal'
                )}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'stateCheckingAccount:stateCheckingAccountPeriodFields.interestFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
  };

  return (
    <DataTable
      title={t(
        'stateCheckingAccount:stateCheckingAccountPage.stateCheckingAccountsPerYearDataTable.title'
      )}
      rows={stateCheckingAccountYears || []}
      rowId={({ taxableYear }) => taxableYear}
      columns={columns}
      emptyMessage={t(
        'stateCheckingAccount:stateCheckingAccountPage.stateCheckingAccountsPerYearDataTable.emptyMessage'
      )}
      toolbarActionsLeft={
        <DownloadTableButton
          fileName={t(
            'stateCheckingAccount:stateCheckingAccountPage.stateCheckingAccountsPerYearDataTable.downloadFileName'
          )}
          downloadUrl={`${STATE_CHECKING_ACCOUNT_URL}/periods?${objectToQueryParamsString(
            { format: 'csv' }
          )}`}
          disabled={isFetching}
        />
      }
      fetching={isFetching}
      allowFilter={false}
      allowPagination={false}
      defaultSortBy="taxableYear"
      rowsPerPage={Number.MAX_VALUE}
      minWidth={400}
      renderExpandedRow={(row) => (
        <StateCheckingAccountsPerPeriodTable
          stateCheckingAccountPeriods={
            stateCheckingAccountsPerYear![row.taxableYear]
          }
        />
      )}
    />
  );
}
