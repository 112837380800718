import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Refresh } from '@material-ui/icons';
import { MainContent } from '../../components/MainContent';
import { RELOAD_PATH } from '../../navigation/paths';
import { ActionButton } from '../../components/Actions/ActionButton';
import { Action } from '../../components/Actions';

/**
 * Fallback error page.
 */
export function FallbackErrorPage() {
  const [t] = useTranslation('common');
  const history = useHistory();

  /**
   * Action that reloads the page.
   */
  const reloadAction: Action = {
    id: 'fallback-error-reload',
    label: t('fallbackErrorPage.reload'),
    style: 'error',
    icon: <Refresh />,
    run: () => history.push(RELOAD_PATH),
  };

  return (
    <MainContent
      errorMessage={
        <>
          {t('fallbackErrorPage.message')}
          <div>
            <ActionButton action={reloadAction} variant="outlined" />
          </div>
        </>
      }
    />
  );
}
