import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dateFnsUtils from '@date-io/date-fns';
import './App.css';
import '../i18n';
import { AuthenticationProvider } from '../providers/AuthenticationProvider';
import { useSetDocumentTitle } from '../util/useSetDocumentTitle';
import { getDateFnsLocale } from '../i18n';
import { LoginRouterConfig } from '../navigation/LoginRouterConfig';
import { CatalogsProvider } from '../providers/CatalogsProvider';
import { ConfirmationDialogProvider } from '../providers/ConfirmationDialogProvider';
import { PageLoading } from '../components/PageLoading';
import { ReauthenticationProvider } from '../providers/ReauthenticationProvider';
import { FetchFromApiProvider } from '../providers/FetchFromApiProvider';
import { StoreProvider } from '../providers/StoreProvider';
import { ResetPasswordProvider } from '../providers/ResetPasswordProvider';

/**
 * Application (provides services to the `MainApp`).
 */
export function App() {
  useSetDocumentTitle();
  const { i18n } = useTranslation();

  return (
    <Suspense fallback={<PageLoading />}>
      <StoreProvider>
        <MuiPickersUtilsProvider
          utils={dateFnsUtils}
          locale={getDateFnsLocale(i18n.language)}
        >
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <BrowserRouter>
              <ConfirmationDialogProvider>
                <AuthenticationProvider>
                  <ReauthenticationProvider>
                    <ResetPasswordProvider>
                      <FetchFromApiProvider>
                        <CatalogsProvider>
                          <CssBaseline />
                          <LoginRouterConfig />
                        </CatalogsProvider>
                      </FetchFromApiProvider>
                    </ResetPasswordProvider>
                  </ReauthenticationProvider>
                </AuthenticationProvider>
              </ConfirmationDialogProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StoreProvider>
    </Suspense>
  );
}
