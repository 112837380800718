import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { FormatQuote } from '@material-ui/icons';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  GetTaxpayersFilterOptions,
  TAXPAYERS_URL,
  TaxpayerStatus,
  useTaxpayersApi,
} from '../taxpayersApi';
import { TaxpayersTable } from './TaxpayersTable';
import { SearchForm } from '../../../components/SearchForm';
import { useResource } from '../../../util/useResource';
import { useQueryParams } from '../../../util/useQueryParams';
import {
  FormSelect,
  FormSwitch,
  FormTaxpayerIdField,
  FormTextField,
} from '../../../components/Form';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { useLfForm } from '../../../util/lfIntegration';
import { ivageCommon } from '../../../ivageCommon';

/**
 * Package in IVAGE common containing the taxpayer form schema.
 */
const taxpayerFormSchemaPkg = ivageCommon.feature.taxpayermanagement.schema;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exactMatchSwitchLabelRoot: {
      margin: 0,
    },
    exactMatchSwitchLabelLabel: {
      display: 'flex',
      color: theme.palette.text.secondary,
    },
  })
);

// Search parameters
const searchParams = [
  'taxpayerId',
  'oldTaxpayerId',
  'name',
  'exactNameMatch',
  'representative',
  'divisionId',
  'sectorId',
  'status',
];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    oldTaxpayerId: queryParams.oldTaxpayerId || '',
    name: queryParams.name || '',
    exactNameMatch: queryParams.exactNameMatch === 'true',
    representative: queryParams.representative || '',
    divisionId: isNaN(+queryParams.divisionId) ? null : +queryParams.divisionId,
    sectorId: isNaN(+queryParams.sectorId) ? null : +queryParams.sectorId,
    status: Object.values(TaxpayerStatus).includes(queryParams.status as any)
      ? (queryParams.status as any)
      : null,
  };
}

/**
 * Page dedicated to consult the existing taxpayers.
 */
export function TaxpayersPage() {
  const classes = useStyles();
  const [t, i18n] = useTranslation('taxpayers');
  useSetDocumentTitle(t('taxpayers:taxpayersPage.documentTitle'));
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      oldTaxpayerId: '',
      name: '',
      exactNameMatch: false,
      representative: '',
      divisionId: null as number | null,
      sectorId: null as number | null,
      status: null as TaxpayerStatus | null,
    },
    formValidatorName: 'getTaxpayersFormValidator',
    i18nErrorMessagesPrefixes: 'taxpayers:taxpayersPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const { getTaxpayers } = useTaxpayersApi();
  const fetchTaxpayers = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getTaxpayers(queryParams)
        : null,
    [getTaxpayers, isValid, queryParams]
  );
  const {
    isFetching,
    resource: taxpayers,
    refetch: refetchTaxpayers,
  } = useResource({
    fetchResource: fetchTaxpayers,
    errorFetchingResourceMessage: t(
      'taxpayers:taxpayersPage.errorFetchingTaxpayers'
    ),
  });
  const {
    isFetching: isFetchingDivisionsCatalog,
    catalog: divisionsCatalog,
  } = useCatalog('divisions');
  const {
    isFetching: isFetchingSectorsCatalog,
    catalog: sectorsCatalog,
  } = useCatalog('sectors');

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const {
      taxpayerId,
      oldTaxpayerId,
      name,
      exactNameMatch,
      representative,
      divisionId,
      sectorId,
      status,
    } = getValues();
    const getTaxpayersOptions: GetTaxpayersFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      oldTaxpayerId: oldTaxpayerId || undefined,
      name: name || undefined,
      exactNameMatch: exactNameMatch || undefined,
      representative: representative || undefined,
      divisionId: divisionId ?? undefined,
      sectorId: sectorId ?? undefined,
      status: status || undefined,
    };
    // Pressing "search" when nothing changed should refetch the taxpayers
    if (areSameQueryParams(getTaxpayersOptions, queryParams, searchParams)) {
      refetchTaxpayers();
    } else {
      setQueryParams(getTaxpayersOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader title={t('taxpayers:taxpayersPage.title')} />

      <SearchForm
        title={t('taxpayers:taxpayersPage.searchForm.title')}
        onSubmit={onSubmit}
        submitDisabled={isFetchingDivisionsCatalog || isFetchingSectorsCatalog}
        {...formMethods}
      >
        <Grid item xs={6} md={3}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('taxpayers:taxpayerFields.taxpayerId')}
            size="small"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormTextField
            name="oldTaxpayerId"
            label={t('taxpayers:taxpayerFields.oldTaxpayerId')}
            size="small"
            inputProps={{
              maxLength: taxpayerFormSchemaPkg.OLD_TAXPAYER_ID_MAX_LENGTH,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="name"
            label={t('taxpayers:taxpayerFields.name')}
            size="small"
            extraEndAdornment={
              <Tooltip
                title={
                  t('taxpayers:taxpayersPage.searchForm.fields.exactNameMatch')!
                }
                arrow
              >
                {/* The containing `div` makes the tooltip work properly */}
                <div>
                  <FormSwitch
                    name="exactNameMatch"
                    label={<FormatQuote />}
                    FormControlLabelProps={{
                      classes: {
                        root: classes.exactMatchSwitchLabelRoot,
                        label: classes.exactMatchSwitchLabelLabel,
                      },
                    }}
                  />
                </div>
              </Tooltip>
            }
            inputProps={{
              maxLength: taxpayerFormSchemaPkg.NAME_MAX_LENGTH,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormTextField
            name="representative"
            label={t('taxpayers:taxpayerFields.representative')}
            size="small"
            inputProps={{
              maxLength: taxpayerFormSchemaPkg.REPRESENTATIVE_MAX_LENGTH,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormSelect
            name="divisionId"
            label={t('taxpayers:taxpayerFields.municipality')}
            size="small"
            items={[
              {
                value: null,
                label: (
                  <em>
                    {t('taxpayers:taxpayersPage.searchForm.anyMunicipality')}
                  </em>
                ),
              },
              ...(divisionsCatalog?.municipalities
                .sort((m1, m2) => m1.name.localeCompare(m2.name, i18n.language))
                .map((municipality) => ({
                  value: municipality.id,
                  label: municipality.name,
                })) ?? []),
            ]}
            fetching={isFetchingDivisionsCatalog}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormSelect
            name="sectorId"
            label={t('taxpayers:taxpayerFields.sector')}
            size="small"
            items={[
              {
                value: null,
                label: (
                  <em>{t('taxpayers:taxpayersPage.searchForm.anySector')}</em>
                ),
              },
              ...(sectorsCatalog
                ?.sort((s1, s2) =>
                  s1.name.localeCompare(s2.name, i18n.language)
                )
                .map((sector) => ({
                  value: sector.id,
                  label: sector.name,
                })) ?? []),
            ]}
            fetching={isFetchingSectorsCatalog}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormSelect
            name="status"
            label={t('taxpayers:taxpayerFields.status')}
            size="small"
            items={[
              {
                value: null,
                label: <em>{t('taxpayers:taxpayerStatus.any')}</em>,
              },
              ...Object.values(TaxpayerStatus).map((status) => ({
                value: status,
                label: t(`taxpayers:taxpayerStatus.${status}`),
              })),
            ]}
          />
        </Grid>
      </SearchForm>

      <TaxpayersTable
        fetching={isFetching}
        taxpayers={taxpayers || []}
        refetchTaxpayers={refetchTaxpayers}
        downloadButton={
          <DownloadTableButton
            fileName={t('taxpayers:taxpayersPage.dataTable.downloadFileName')}
            downloadUrl={`${TAXPAYERS_URL}?${objectToQueryParamsString({
              ...queryParams,
              format: 'csv',
            })}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
