import React from 'react';
import { Payment } from '../paymentsApi';
import { ActionButtons } from '../../../components/Actions';
import { usePaymentActions } from '../usePaymentActions';

/**
 * Properties for the payment row actions.
 */
interface PaymentActionsProps {
  payment: Payment;
  onVoid: () => void;
}

/**
 * Actions in a payment row.
 */
export function PaymentRowActions({ payment, onVoid }: PaymentActionsProps) {
  return (
    <ActionButtons
      actions={usePaymentActions(payment, onVoid)!}
      variant="outlined"
      size="small"
    />
  );
}
