import { format } from 'date-fns';
import { useCallback } from 'react';

/**
 * Date format to use.
 */
export const DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Hook used to format dates.
 */
export function useDateFormatter() {
  return useCallback(
    (date?: Date | null) =>
      date == null || isNaN(+date) ? '' : format(date, DATE_FORMAT),
    []
  );
}
