import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormNumberField, FormNumberFieldProps } from './FormNumberField';

/**
 * Maximum allowed absolute value.
 */
const MAX_CURRENCY_ABS_VALUE = 999999999999999;

/**
 * Properties of the currency field.
 */
export type FormCurrencyFieldProps = FormNumberFieldProps;

/**
 * Currency field to be used within an RHF form context.
 */
export function FormCurrencyField({
  fixedDecimalScale = true,
  ...otherProps
}: FormCurrencyFieldProps) {
  const [t] = useTranslation('common');
  const decimalScale = process.env.REACT_APP_CURRENCY_DECIMAL_SCALE
    ? +process.env.REACT_APP_CURRENCY_DECIMAL_SCALE
    : undefined;

  return (
    <FormNumberField
      prefix={process.env.REACT_APP_CURRENCY_PREFIX}
      suffix={process.env.REACT_APP_CURRENCY_SUFFIX}
      thousandSeparator={t('currencyField.thousandSeparator')}
      decimalSeparator={t('currencyField.decimalSeparator')}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      min={-MAX_CURRENCY_ABS_VALUE}
      max={MAX_CURRENCY_ABS_VALUE}
      {...otherProps}
    />
  );
}
