import { useCallback } from 'react';
import { TaxablePeriod } from '../../common';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Representation of the state checking account.
 */
export interface StateCheckingAccount {
  accessDate: Date;
  vatFinal: number;
  interestFinal: number;
  vatCreditAvailable: number;
  paymentRefundableFinal: number;
}

/**
 * Representation of a period of the checking account.
 */
export interface StateCheckingAccountPeriod {
  taxableYear: number;
  taxablePeriod: TaxablePeriod;
  vatFinal: number;
  interestFinal: number;
  vatCreditAvailable: number;
  paymentRefundableFinal: number;
}

/**
 * URL to access the state checking account.
 */
export const STATE_CHECKING_ACCOUNT_URL = '/api/state-checking-account';

/**
 * Transforms the state checking account JSON as given by the API into our
 * internal checking account representation.
 */
function jsonToStateCheckingAccount(
  stateCheckingAccountJson: any
): StateCheckingAccount {
  return {
    ...stateCheckingAccountJson,
    accessDate: new Date(stateCheckingAccountJson.accessDate),
  };
}

/**
 * Hook exposing the state checking account.
 */
export function useStateCheckingAccountApi() {
  const { getJson } = useFetchFromApi();

  /**
   * Gets the state checking account.
   */
  const getStateCheckingAccount = useCallback(
    async (): Promise<StateCheckingAccount> =>
      jsonToStateCheckingAccount(await getJson(STATE_CHECKING_ACCOUNT_URL)),
    [getJson]
  );

  /**
   * Gets the list of periods of a checking account.
   */
  const getStateCheckingAccountPeriods = useCallback(
    async (): Promise<StateCheckingAccountPeriod[]> =>
      await getJson(`${STATE_CHECKING_ACCOUNT_URL}/periods`),
    [getJson]
  );

  return { getStateCheckingAccount, getStateCheckingAccountPeriods };
}
