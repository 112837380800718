/**
 * Options for password generation.
 */
export interface PasswordGenerationOptions {
  length?: number;
  minNumberOfLowerAlphas?: number;
  minNumberOfUpperAlphas?: number;
  minNumberOfDigits?: number;
  minNumberOfSpecials?: number;
  disableAmbiguousChars?: boolean;
}

// Defaults
const DFT_LENGTH = 12;
const DFT_MIN_NUMBER_OF_LOWER_ALPHAS = 1;
const DFT_MIN_NUMBER_OF_UPPER_ALPHAS = 1;
const DFT_MIN_NUMBER_OF_DIGITS = 1;
const DFT_MIN_NUMBER_OF_SPECIALS = 1;
const DFT_DISABLE_AMBIGUOUS_CHARS = true;

// Regex for removing ambiguous characters
const AMBIGUOUS_CHARS = /[l1IO0]/g;

// Alphabets
const LOWER_ALPHAS = 'abcdefghijklmnopqrstuvwxyz';
const UPPER_ALPHAS = LOWER_ALPHAS.toUpperCase();
const DIGITS = '0123456789';
const SPECIALS = '@#$!%*?&/=+-';
const ALL_CHARS = LOWER_ALPHAS + UPPER_ALPHAS + DIGITS + SPECIALS;

// Unambiguous alphabets
const UNAMBIGUOUS_LOWER_ALPHAS = LOWER_ALPHAS.replace(AMBIGUOUS_CHARS, '');
const UNAMBIGUOUS_UPPER_ALPHAS = UPPER_ALPHAS.replace(AMBIGUOUS_CHARS, '');
const UNAMBIGUOUS_DIGITS = DIGITS.replace(AMBIGUOUS_CHARS, '');
const UNAMBIGUOUS_SPECIALS = SPECIALS.replace(AMBIGUOUS_CHARS, '');
const UNAMBIGUOUS_ALL_CHARS =
  UNAMBIGUOUS_LOWER_ALPHAS +
  UNAMBIGUOUS_UPPER_ALPHAS +
  UNAMBIGUOUS_DIGITS +
  UNAMBIGUOUS_SPECIALS;

// Crypto instance (for IE 11)
const crypto = window.crypto || (window as any).msCrypto;

/**
 * Generates a new password.
 */
export function generatePassword({
  length = DFT_LENGTH,
  minNumberOfLowerAlphas = DFT_MIN_NUMBER_OF_LOWER_ALPHAS,
  minNumberOfUpperAlphas = DFT_MIN_NUMBER_OF_UPPER_ALPHAS,
  minNumberOfDigits = DFT_MIN_NUMBER_OF_DIGITS,
  minNumberOfSpecials = DFT_MIN_NUMBER_OF_SPECIALS,
  disableAmbiguousChars = DFT_DISABLE_AMBIGUOUS_CHARS,
}: PasswordGenerationOptions = {}): string {
  const enforcedNumberOfChars =
    minNumberOfLowerAlphas +
    minNumberOfUpperAlphas +
    minNumberOfDigits +
    minNumberOfSpecials;

  if (length < enforcedNumberOfChars) {
    throw new RangeError(
      'Invalid length given the minimum number of each character'
    );
  }

  // Alphabets to use
  const lowerAlphas = disableAmbiguousChars
    ? UNAMBIGUOUS_LOWER_ALPHAS
    : LOWER_ALPHAS;
  const upperAlphas = disableAmbiguousChars
    ? UNAMBIGUOUS_UPPER_ALPHAS
    : UPPER_ALPHAS;
  const digits = disableAmbiguousChars ? UNAMBIGUOUS_DIGITS : DIGITS;
  const specials = disableAmbiguousChars ? UNAMBIGUOUS_SPECIALS : SPECIALS;
  const allChars = disableAmbiguousChars ? UNAMBIGUOUS_ALL_CHARS : ALL_CHARS;

  // The first `length` values are used for the password generation, the
  // remaining values are used to shuffle the generated password
  const buffer = new Uint32Array(length * 2);
  crypto.getRandomValues(buffer);

  let bufferI = 0;
  let password = '';

  // Obtain the minimum required number of each type of character
  for (let i = 0; i < minNumberOfLowerAlphas; ++i) {
    password += lowerAlphas[buffer[bufferI++] % lowerAlphas.length];
  }
  for (let i = 0; i < minNumberOfUpperAlphas; ++i) {
    password += upperAlphas[buffer[bufferI++] % upperAlphas.length];
  }
  for (let i = 0; i < minNumberOfDigits; ++i) {
    password += digits[buffer[bufferI++] % digits.length];
  }
  for (let i = 0; i < minNumberOfSpecials; ++i) {
    password += specials[buffer[bufferI++] % specials.length];
  }
  // Obtain the remaining characters
  for (let i = 0, l = length - enforcedNumberOfChars; i < l; ++i) {
    password += allChars[buffer[bufferI++] % allChars.length];
  }

  // Shuffle the password (sort based on the last `length` random values)
  return password
    .split('')
    .map((c, i) => ({ i, c }))
    .sort(({ i: i1 }, { i: i2 }) => buffer[length + i1] - buffer[length + i2])
    .map(({ c }) => c)
    .join('');
}
