import { useCallback } from 'react';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Representation of a VAT indicator.
 */
export interface VatIndicator {
  accessDate: Date;
  indicator: any[];
}

/**
 * URL to access VAT indicators.
 */
export const VAT_INDICATORS_URL = '/api/vat-indicators';

/**
 * Transforms a VAT return JSON as given by the API into our internal VAT return
 * representation.
 */
function jsonToVatIndicator(vatIndicatorJson: any): VatIndicator {
  return {
    ...vatIndicatorJson,
    accessDate: new Date(vatIndicatorJson.accessDate),
  };
}

/**
 * Hook exposing the VAT indicators API.
 */
export function useVatIndicatorsApi() {
  const { getJson } = useFetchFromApi();

  /**
   * Returns the VAT indicator with the provided id.
   */
  const getVatIndicator = useCallback(
    async (vatIndicatorId: string): Promise<VatIndicator> =>
      jsonToVatIndicator(
        await getJson(
          `${VAT_INDICATORS_URL}/${encodeURIComponent(vatIndicatorId)}`
        )
      ),
    [getJson]
  );

  return { getVatIndicator };
}
