import { format } from 'date-fns';
import { useCallback } from 'react';

/**
 * Date time format to use.
 */
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

/**
 * Hook used to format dates with associated time.
 */
export function useDateTimeFormatter() {
  return useCallback(
    (date?: Date | null) =>
      date == null || isNaN(+date) ? '' : format(date, DATE_TIME_FORMAT),
    []
  );
}
