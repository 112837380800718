/**
 * Identifier of the type of tax.
 */
export enum TaxId {
  VAT = 'VAT',
}

/**
 * Taxable period of a tax (in the future we may support quarters with `T01-T04`
 * or similar).
 */
export enum TaxablePeriod {
  Jan = '01',
  Feb = '02',
  Mar = '03',
  Apr = '04',
  May = '05',
  Jun = '06',
  Jul = '07',
  Aug = '08',
  Sep = '09',
  Oct = '10',
  Nov = '11',
  Dec = '12',
}

/**
 * Transaction method.
 */
export enum TransactionMethod {
  Transfer = 'TRANSFER',
  Check = 'CHECK',
}
