import { Delete, Edit } from '@material-ui/icons';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from '../../../components/Actions';
import { VatActivity, Taxpayer, useTaxpayersApi } from '../taxpayersApi';
import { useSnackbar } from 'notistack';
import { useConfirmationDialog } from '../../../providers/ConfirmationDialogProvider';

/**
 * Hook that returns actions that can be performed on a VAT activity.
 */
export function useVatActivityActions(
  taxpayer: Taxpayer | null,
  vatActivity: VatActivity,
  onEditActionClick: () => void,
  onRemove: (removedVatActivity: VatActivity) => void,
  onRemoveFailureDueToOutdatedUI: () => void
): Action[] {
  const [t] = useTranslation('taxpayers');
  const { removeVatActivity } = useTaxpayersApi();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirmationDialog();

  // Removes a VAT activity and provides an option to undo the operation
  const removeImpl = useCallback(async () => {
    try {
      const removedActivity = await removeVatActivity(
        taxpayer!.taxpayerId,
        vatActivity.vatActivityId
      );
      onRemove(removedActivity);

      enqueueSnackbar(
        t('taxpayers:taxpayerPage.successRemovingVatActivity', {
          id: vatActivity.vatActivityId,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 404) {
        onRemoveFailureDueToOutdatedUI();

        enqueueSnackbar(
          t('taxpayers:taxpayerPage.errorRemovingVatActivityDueToOutdatedUI', {
            id: vatActivity.vatActivityId,
          }),
          { variant: 'error' }
        );
      } else if (err instanceof Response && err.status === 400) {
        enqueueSnackbar(
          t('taxpayers:taxpayerPage.errorRemovingVatActivityDueToTaxReturns', {
            id: vatActivity.vatActivityId,
          }),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          t('taxpayers:taxpayerPage.errorRemovingVatActivity', {
            id: vatActivity.vatActivityId,
          }),
          {
            variant: 'error',
          }
        );
      }
    }
  }, [
    removeVatActivity,
    taxpayer,
    vatActivity.vatActivityId,
    onRemove,
    enqueueSnackbar,
    t,
    onRemoveFailureDueToOutdatedUI,
  ]);

  return useMemo(
    () => [
      {
        id: `vat-activity-${vatActivity.vatActivityId}-edit`,
        label: t('taxpayers:vatActivityActions.edit'),
        run: onEditActionClick,
        icon: <Edit />,
        hidden: !taxpayer,
      },
      {
        id: `vat-activity-${vatActivity.vatActivityId}-remove`,
        label: t('taxpayers:vatActivityActions.remove'),
        run: async () => {
          if (
            await confirm(
              t('taxpayers:taxpayerPage.confirmVatActivityRemoval', {
                id: vatActivity.vatActivityId,
              })
            )
          ) {
            await removeImpl();
          }
        },
        icon: <Delete />,
        style: 'error',
        hidden: !taxpayer,
      },
    ],
    [
      confirm,
      onEditActionClick,
      removeImpl,
      t,
      vatActivity.vatActivityId,
      taxpayer,
    ]
  );
}
