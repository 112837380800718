import { createContext } from 'react';

/**
 * API to reauthenticate.
 */
export interface ReauthenticationApi {
  reauthenticate(): Promise<boolean>;
}

/**
 * Reauthentication context.
 */
export const ReauthenticationContext = createContext<ReauthenticationApi>(
  undefined as any
);
