import { useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

/**
 * Default decimal scale for rates.
 */
export const DEFAULT_RATE_DECIMAL_SCALE = 3;

/**
 * Formats a given value as currency.
 */
export function useRateFormatter(
  options?: Omit<NumberFormatProps, 'value' | 'displayType' | 'renderText'>
) {
  const [t] = useTranslation('common');

  return useCallback(
    (value: number | null | undefined) =>
      // XXX: https://github.com/s-yadav/react-number-format/issues/458
      renderToStaticMarkup(
        <NumberFormat
          value={value ?? undefined}
          suffix="%"
          thousandSeparator={t('currencyField.thousandSeparator') as string}
          decimalSeparator={t('currencyField.decimalSeparator')!}
          {...options}
          decimalScale={options?.decimalScale ?? DEFAULT_RATE_DECIMAL_SCALE}
          displayType="text"
          renderText={(value) => value.toString()}
        />
      ),
    [options, t]
  );
}
