import React, { FormEvent, ReactNode, useMemo } from 'react';
import { FormSelect, FormSelectProps } from './FormSelect';
import { TaxablePeriod } from '../../common';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { eachYearOfInterval, getYear, isWithinInterval } from 'date-fns';
import { useCatalog } from '../../providers/CatalogsProvider';

/**
 * Properties of the taxable year/period component.
 */
export interface FormTaxableYearPeriodProps {
  taxableYearProps: Omit<FormSelectProps, 'items'>;
  taxablePeriodProps: Omit<FormSelectProps, 'items'>;
  fetching?: boolean;
  maxDate?: Date;
  removeTaxablePeriodWhenInvalid?: boolean;
  children: (taxableYear: ReactNode, taxablePeriod: ReactNode) => ReactNode;
}

/**
 * Component used to render a taxable year/period.
 */
export function FormTaxableYearPeriod({
  taxableYearProps,
  taxablePeriodProps,
  fetching,
  maxDate,
  removeTaxablePeriodWhenInvalid = true,
  children,
}: FormTaxableYearPeriodProps) {
  const [t] = useTranslation('common');
  const { getValues, setValue, watch, formState } = useFormContext();
  const {
    isFetching: isFetchingConfigsCatalog,
    catalog: configsCatalog,
  } = useCatalog('configs');

  const vatReturnInitialTaxableYearPeriod =
    configsCatalog?.vatReturnInitialTaxableYearPeriod;
  const taxableYear = watch(taxableYearProps.name);

  // Interval of allowed taxable dates
  const allowedTaxableInterval = useMemo(
    () =>
      vatReturnInitialTaxableYearPeriod && {
        start: vatReturnInitialTaxableYearPeriod,
        end: maxDate ?? new Date(),
      },
    [vatReturnInitialTaxableYearPeriod, maxDate]
  );

  // Taxable years
  const allowedTaxableYears = useMemo(
    () =>
      allowedTaxableInterval &&
      eachYearOfInterval(allowedTaxableInterval)
        .reverse()
        .map((date) => getYear(date)),
    [allowedTaxableInterval]
  );

  // Whether a given period can be selected given the selected year
  function canSelectTaxablePeriod(
    year: number | null,
    period: TaxablePeriod | null
  ): boolean {
    return (
      year == null ||
      period == null ||
      !allowedTaxableInterval ||
      isWithinInterval(new Date(year, +period - 1), allowedTaxableInterval)
    );
  }

  function onTaxableYearChange(evt: FormEvent<HTMLDivElement>) {
    // Remove taxable period if, when selecting the year, the period becomes
    // invalid
    const {
      [taxableYearProps.name]: taxableYear,
      [taxablePeriodProps.name]: taxablePeriod,
    } = getValues([taxableYearProps.name, taxablePeriodProps.name]);
    if (
      removeTaxablePeriodWhenInvalid &&
      !canSelectTaxablePeriod(taxableYear, taxablePeriod)
    ) {
      setValue(taxablePeriodProps.name, null, {
        shouldValidate:
          formState.isSubmitted || !!formState.touched[taxablePeriodProps.name],
        shouldDirty: true,
      });
    }

    // Call provided `onChange` if one was specified
    taxableYearProps.onChange?.(evt);
  }

  const taxableYearNode = (
    <FormSelect
      {...taxableYearProps}
      items={[
        {
          value: null,
          label: <em>{t('taxableYear.none')}</em>,
        },
        ...(allowedTaxableYears ?? []).map((year) => ({
          value: year,
          label: year,
        })),
      ]}
      fetching={
        fetching || isFetchingConfigsCatalog || taxableYearProps.fetching
      }
      onChange={onTaxableYearChange}
    />
  );

  const taxablePeriodNode = (
    <FormSelect
      {...taxablePeriodProps}
      items={[
        {
          value: null,
          label: <em>{t('taxablePeriod.none')}</em>,
        },
        ...Object.values(TaxablePeriod).map((period) => ({
          value: period,
          label: t(`taxablePeriod.${period}`),
          disabled: !canSelectTaxablePeriod(taxableYear, period),
        })),
      ]}
      fetching={
        fetching || isFetchingConfigsCatalog || taxablePeriodProps.fetching
      }
    />
  );

  return <>{children(taxableYearNode, taxablePeriodNode)}</>;
}
