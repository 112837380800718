import React from 'react';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Form, FormProps } from './Form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        borderRadius: 0,
      },
      '@media print': {
        breakInside: 'avoid',
        boxShadow: 'none',
        marginLeft: 0,
        marginRight: 0,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
      }
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      justifyContent: 'space-between',
    },
    title: {
      flex: 1,
      lineHeight: 1.1,
    },
    grid: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    searchButton: {
      marginLeft: theme.spacing(2),
      '@media print': {
        display: 'none',
      }
    },
  })
);

/**
 * Properties of the search form.
 */
export interface SearchFormProps extends FormProps {
  title: string;
  submitDisabled?: boolean;
}

/**
 * Form used for searching.
 */
export function SearchForm({
  title,
  submitDisabled,
  children,
  formState,
  ...otherProps
}: SearchFormProps) {
  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <Paper className={classes.root} data-cy="search-form">
      <Form formState={formState} {...otherProps}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} component="h2" variant="h6">
            {title}
          </Typography>

          <Button
            className={classes.searchButton}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<Search />}
            disabled={formState.isSubmitting || submitDisabled}
          >
            {t('searchForm.searchButton')}
          </Button>
        </Toolbar>

        <Grid className={classes.grid} container spacing={2}>
          {children}
        </Grid>
      </Form>
    </Paper>
  );
}
