import React, { RefObject, useEffect } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import {
  CircularProgress,
  createStyles,
  Fade,
  makeStyles,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { resolveObject } from '../../util/resolveObject';
import { useDisabledFieldStyles } from './ReadOnlyField';
import { triggerNames } from '../../util/triggerNames';
import { DATE_FORMAT } from '../../util/formatters';
import { useInputRef } from './util/useInputRef';
import { scrollAndFocus } from './util/scrollAndFocus';
import { localDateToSameDateInUTC } from '../../util/dateUtils';
import { useLfFormContext } from '../../util/lfIntegration';
import { namePathToLfPath } from '../../util/lfIntegration/util/pathNameConvertions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendarIcon: {
      marginRight: theme.spacing(-1.5),
      '@media print': {
        display: 'none',
      },
    },
  })
);

interface CustomFormDateFieldProps {
  name: string;
  fetching?: boolean;
  rules?: RegisterOptions;
  onChange?: (date: any, value?: string | null) => void;
  onChangeTriggers?: string[];
  inputRef?: RefObject<HTMLInputElement>;
}

/**
 * Properties of the currency field.
 */
export type FormDateFieldProps = CustomFormDateFieldProps &
  Omit<KeyboardDatePickerProps, 'onChange' | 'value'>;

/**
 * Date field to be used within an RHF form context.
 */
export function FormDateField({
  name,
  label,
  fetching,
  autoOk = true,
  clearable = true,
  disabled,
  rules,
  variant,
  format = DATE_FORMAT,
  placeholder,
  inputVariant = disabled ? 'filled' : 'outlined',
  fullWidth = true,
  helperText,
  inputRef,
  onChange,
  onChangeTriggers,
  onBlur,
  onAccept,
  inputProps,
  InputProps = {},
  ...otherProps
}: FormDateFieldProps) {
  const classes = { ...useStyles(), ...useDisabledFieldStyles() };
  const [t] = useTranslation('common');
  const {
    control,
    errors,
    formState,
    trigger,
    watch,
    setManualLfIssue,
    removeManualLfIssue,
  } = useLfFormContext();
  const hasCoarsePointer = useMediaQuery('(pointer: coarse)');
  const dateFieldRef = useInputRef(inputRef);

  const variantToUse = variant ?? (hasCoarsePointer ? 'dialog' : 'inline');
  const shouldDisable = disabled || fetching || formState.isSubmitting;
  const error = resolveObject(errors, name);

  // Add a manual LF validation that checks whether the date is invalid
  const date = watch(name);
  useEffect(() => {
    removeManualLfIssue({ path: namePathToLfPath(name), code: 'invalid' });
    if (date != null && isNaN(+date)) {
      setManualLfIssue({ path: namePathToLfPath(name), code: 'invalid' });
    }
  }, [date, name, removeManualLfIssue, setManualLfIssue]);

  // Properties to use when the variant is dialog
  const dialogProps =
    variantToUse === 'dialog'
      ? {
          clearable,
          okLabel: t('dateField.actions.ok'),
          cancelLabel: t('dateField.actions.cancel'),
          clearLabel: t('dateField.actions.clear'),
          todayLabel: t('dateField.actions.today'),
        }
      : {};

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => scrollAndFocus(dateFieldRef)}
      render={({
        onChange: controllerOnChange,
        onBlur: controllerOnBlur,
        value,
        name,
      }) => (
        <KeyboardDatePicker
          autoOk={autoOk}
          inputVariant={inputVariant}
          fullWidth={fullWidth}
          label={label}
          disabled={shouldDisable}
          variant={variantToUse}
          format={format}
          placeholder={placeholder ?? t('dateField.placeholder')}
          {...dialogProps}
          {...otherProps}
          value={value ?? null}
          name={name}
          inputProps={{ ...inputProps, ref: dateFieldRef }}
          onChange={(date, value) => {
            date = date ? localDateToSameDateInUTC(date) : null;
            controllerOnChange(date);
            onChange?.(date, value);
            onChangeTriggers &&
              triggerNames(formState, trigger, ...onChangeTriggers);
          }}
          onBlur={(evt) => {
            controllerOnBlur();
            onBlur?.(evt);
          }}
          onAccept={(date) => {
            onAccept?.(date);
            // Focus the input field after accepting a value, doing so makes it
            // so that a value can't be selected without the input becoming
            // "touched" in RHF
            setTimeout(() => dateFieldRef.current?.focus());
          }}
          KeyboardButtonProps={{
            classes: { root: classes.calendarIcon },
          }}
          keyboardIcon={
            fetching ? (
              <Fade in={fetching}>
                <CircularProgress size={20} />
              </Fade>
            ) : undefined
          }
          InputProps={{
            ...InputProps,
            classes: {
              ...InputProps.classes,
              disabled: `${classes.disabledField} ${
                InputProps.classes?.disabled ?? ''
              }`,
            },
          }}
          error={!!error}
          helperText={error?.message ?? helperText}
        />
      )}
    />
  );
}
