import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableColumns,
  InnerDataTable
} from '../../../components/DataTable';
import { useCurrencyFormatter } from '../../../util/formatters';
import { CheckingAccountPeriod } from '../checkingAccountsApi';
import { Link } from '../../../components/Link';
import {
  replacePathParams,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

/**
 * Properties of the checking account per period table.
 */
export interface CheckingAccountsPerPeriodTableProps {
  checkingAccountPeriods: CheckingAccountPeriod[];
}

/**
 * Table listing the checking accounts per period.
 */
export function CheckingAccountsPerPeriodTable({
  checkingAccountPeriods,
}: CheckingAccountsPerPeriodTableProps) {
  const [t] = useTranslation(['common', 'checkingAccounts']);
  const formatCurrency = useCurrencyFormatter();

  const columns: DataTableColumns<CheckingAccountPeriod> = {
    taxablePeriod: {
      label: t('checkingAccounts:checkingAccountPeriodFields.taxablePeriod'),
      value: ({ taxablePeriod }) => taxablePeriod,
      format: (value) => t(`taxablePeriod.${value}`),
      defaultSortDirection: 'desc',
      sortable: false,
    },
    assessmentNumber: {
      label: t('checkingAccounts:checkingAccountPeriodFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber,
      render: (content, value) => (
        <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
      sortable: false,
    },
    debits: {
      align: 'right',
      label: t('checkingAccounts:checkingAccountPeriodFields.debits'),
      value: ({ vatFinal, interestFinal }) => vatFinal + interestFinal,
      format: (value, { vatCreditAvailable, paymentRefundableFinal }) =>
        value === 0 &&
        (vatCreditAvailable !== 0 || paymentRefundableFinal !== 0)
          ? ''
          : formatCurrency(value as number),
      sortable: false,
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t('checkingAccounts:checkingAccountPeriodFields.vatFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.interestFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('checkingAccounts:checkingAccountPeriodFields.credits'),
      value: ({ vatCreditAvailable, paymentRefundableFinal }) =>
        vatCreditAvailable + paymentRefundableFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
      sortable: false,
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatCreditAvailable !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatCreditAvailable)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.vatCreditAvailable'
                )}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.paymentRefundableFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
  };

  return (
    <InnerDataTable
      title={t(
        'checkingAccounts:checkingAccountPage.checkingAccountsPerPeriodDataTable.title'
      )}
      rows={checkingAccountPeriods || []}
      rowId={({ taxablePeriod }) => taxablePeriod}
      columns={columns}
      emptyMessage={''}
      defaultSortBy="taxablePeriod"
      minWidth={400}
    />
  );
}
