import { useMemo } from 'react';
import { Division } from './catalogsApi';
import { useCatalog } from './useCatalog';

/**
 * Return of the use division sections hook.
 */
export interface DivisionSectionsResource {
  isFetching: boolean;
  sections: DivisionSections | null;
}

/**
 * Sections of a division.
 */
export interface DivisionSections {
  province: Division;
  district: Division;
  municipality: Division;
}

/**
 * Hook used to fetch the sections of the a division with the given id.
 */
export function useDivisionSections(
  divisionId?: number | null
): DivisionSectionsResource {
  const { isFetching, catalog } = useCatalog('divisions');

  const municipality = useMemo(
    () =>
      divisionId != null && catalog
        ? catalog.municipalities.find(
            (municipality) => municipality.id === divisionId
          )!
        : null,
    [divisionId, catalog]
  );
  const district = useMemo(
    () =>
      municipality
        ? catalog!.districts.find(
            (district) => district.id === municipality.parentID
          )!
        : null,
    [catalog, municipality]
  );
  const province = useMemo(
    () =>
      district
        ? catalog!.provinces.find(
            (province) => province.id === district.parentID
          )!
        : null,
    [catalog, district]
  );

  return useMemo(
    () =>
      municipality == null || district == null || province == null
        ? { isFetching, sections: null }
        : { isFetching, sections: { province, district, municipality } },
    [isFetching, municipality, district, province]
  );
}
