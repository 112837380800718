import { Interval, isEqual, maxTime, subDays } from 'date-fns';
import { VatActivity, Taxpayer } from '../taxpayersApi';

/**
 * Returns a list of intervals representing the VAT activities, except the one
 * being edited (when editing).
 */
export function vatActivitiesToIntervals(
  taxpayer: Taxpayer,
  editingVatActivity: VatActivity | null
): Interval[] {
  const excludingEditing = editingVatActivity
    ? taxpayer.vatActivities.filter(
        (activity) => activity !== editingVatActivity
      )
    : taxpayer.vatActivities;
  return excludingEditing.map(({ startDate, endDate }) => ({
    start: startDate,
    end: endDate || new Date(maxTime),
  }));
}

/**
 * Merges the list of VAT activity intervals so that if an interval starts where
 * the previous interval ends, then the intervals are merged into one interval.
 */
export function mergeVatActivityIntervals(
  vatActivityIntervals: Interval[]
): Interval[] {
  return vatActivityIntervals
    .sort((i1, i2) => i1.start.valueOf() - i2.start.valueOf())
    .reduce((intervals: Interval[], interval) => {
      if (intervals.length === 0) {
        return [interval];
      }
      const prevInterval = intervals[intervals.length - 1];
      if (isEqual(prevInterval.end, subDays(interval.start, 1))) {
        prevInterval.end = interval.end;
      } else {
        intervals.push(interval);
      }
      return intervals;
    }, []);
}
