import { AuthenticatedUser } from '../AuthenticationProvider';

/**
 * Time at which the token should next be refreshed. We set the refresh time to
 * be halfway until expiration (if the token will expire in 2h then we refresh
 * in 1h) +/- some random amount.
 */
export function nextRefreshTime(userAuth: AuthenticatedUser): number {
  const expires = +userAuth.expires;
  const now = Date.now();
  const timeUntilExpiration = Math.max(expires - now, 0);
  // Add some sort of randomness to the next refresh time to prevent multiple
  // clients from being synchronised and possibly all refreshing at the same
  // time consistently. This random amount is here +/- up to a tenth of the time
  // until expiration.
  const random = (Math.random() * 2 - 1) * timeUntilExpiration / 10;
  return now + timeUntilExpiration / 2 + random;
}

/**
 * Time at which the token should next be refreshed after an error occurred when
 * attempting to refresh it. We set the refresh time to about 1 minute.
 */
export function nextRefreshTimeAfterError(): number {
  // Add some sort of randomness to the next refresh time to prevent multiple
  // clients from being synchronised and possibly all refreshing at the same
  // time consistently. This random amount is here +/- 30 seconds.
  return Date.now() + 60_000 + (Math.random() * 2 - 1) * 30_000;
}
