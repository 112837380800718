import { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
    },
  })
);

/**
 * Properties of the not specified common.
 */
export interface NotSpecifiedProps {
  children: ReactNode;
}

/**
 * Component to display "not specified" information.
 */
export function NotSpecified({ children }: NotSpecifiedProps) {
  const classes = useStyles();

  return (
    <em className={classes.root} data-cy="not-specified">
      {children}
    </em>
  );
}
