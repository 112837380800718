import React, { ReactNode } from 'react';
import {
  CircularProgress,
  createStyles,
  Fade,
  InputAdornment,
  makeStyles,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';

/**
 * Style for disabled inputs (also used by other fields).
 */
export const useDisabledFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabledField: {
      // Make it a bit easier to read disabled inputs
      color: theme.palette.text.secondary,
      '@media print': {
        color: theme.palette.text.primary,
      }
    },
  })
);

interface CustomReadOnlyFieldProps {
  fetching?: boolean;
  extraEndAdornment?: ReactNode;
}

/**
 * Properties of the read-only field.
 */
export type ReadOnlyFieldProps = CustomReadOnlyFieldProps &
  Omit<TextFieldProps, 'disabled'>;

/**
 * Read-only field in a form (not bound to RHF).
 */
export function ReadOnlyField({
  fetching,
  variant = 'filled',
  fullWidth = true,
  InputProps = {},
  extraEndAdornment,
  ...otherProps
}: ReadOnlyFieldProps = {}) {
  const classes = useDisabledFieldStyles();

  return (
    <TextField
      variant={variant}
      fullWidth={fullWidth}
      {...otherProps}
      disabled
      InputProps={{
        ...InputProps,
        classes: {
          ...InputProps.classes,
          disabled: `${classes.disabledField} ${
            InputProps.classes?.disabled ?? ''
          }`,
        },
        endAdornment: (fetching || extraEndAdornment) && (
          <InputAdornment position="end">
            <Fade in={fetching} mountOnEnter={true}>
              <CircularProgress size={20} />
            </Fade>
            {extraEndAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
}
