import { Email, ErrorOutline } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  AUTOMATIC_VAT_RETURN_PATH,
  replacePathParams,
} from '../../navigation/paths';
import { Action } from '../../components/Actions';
import { useVatOmissionsApi, VatOmission } from './vatOmissionsApi';
import {vatOmissionId} from "./util/vatOmissionId";

/**
 * Hook that returns actions that can be performed on a VAT omission.
 */
export function useVatOmissionActions(
  vatOmission: VatOmission | null
): Action[] {
  const [t] = useTranslation('vatOmissions');
  const { notifyVatOmissionByEmail } = useVatOmissionsApi();
  const { enqueueSnackbar } = useSnackbar();

  const id = vatOmission && vatOmissionId(vatOmission);

  const notifyByEmail = useCallback(async () => {
    try {
      await notifyVatOmissionByEmail(
        vatOmission!.taxpayerId,
        vatOmission!.taxableYear,
        vatOmission!.taxablePeriod
      );
      enqueueSnackbar(
        t('vatOmissions:vatOmissionEmailSentSuccessfully', {
          taxpayerId: vatOmission!.taxpayerId,
          name: vatOmission!.name,
          taxableYear: vatOmission!.taxableYear,
          taxablePeriod: vatOmission!.taxablePeriod,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      enqueueSnackbar(
        t('vatOmissions:errorSendingVatOmissionEmail', {
          taxpayerId: vatOmission!.taxpayerId,
          name: vatOmission!.name,
          taxableYear: vatOmission!.taxableYear,
          taxablePeriod: vatOmission!.taxablePeriod,
        }),
        { variant: 'error' }
      );
    }
  }, [enqueueSnackbar, notifyVatOmissionByEmail, t, vatOmission]);

  return useMemo(
    () => [
      {
        id: `vat-omission-${id}-rectify`,
        label: t('vatOmissions:vatOmissionActions.rectify'),
        component: RouterLink,
        to: replacePathParams(AUTOMATIC_VAT_RETURN_PATH, {
          taxpayerId: vatOmission?.taxpayerId,
          taxableYear: vatOmission?.taxableYear,
          taxablePeriod: vatOmission?.taxablePeriod,
        }),
        icon: <ErrorOutline />,
        hidden: !vatOmission,
      },
      {
        id: `vat-omission-${id}-notify-by-email`,
        label: t('vatOmissions:vatOmissionActions.notifyByEmail'),
        run: () => notifyByEmail(),
        icon: <Email />,
        disabled: !vatOmission?.email,
        hidden: !vatOmission,
      },
    ],
    [id, t, vatOmission, notifyByEmail]
  );
}
