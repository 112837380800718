import { DataTableColumns } from './DataTable';

/**
 * Filters the rows.
 */
export function filterRows(
  rows: any[],
  columns: DataTableColumns<any>,
  filter: string,
  language: string
): any[] {
  if (filter === '') {
    return rows;
  }

  return rows.filter((row) => {
    for (const columnId of Object.keys(columns)) {
      const column = columns[columnId];
      if (
        column.filterable === false ||
        column.hidden ||
        column.value == null
      ) {
        return false;
      }
      const value = column.value(row);
      const formatted = column.format
        ? column.format(value, row)
        : value == null
        ? ''
        : String(value);
      if (matchAgainstFilter(formatted, filter, language) != null) {
        return true;
      }
    }
    return false;
  });
}

/**
 * Result of matching a cell against a filter. When a match was found, contains
 * the prefix, match, and suffix of the cell as parts of a tuple which can be
 * joined with, for example, the match in bold.
 */
export type CellMatch = [string, string, string] | null;

/**
 * Matches a formatted cell against the filter and returns the match or `null`
 * when the field doesn't match.
 */
export function matchAgainstFilter(
  formatted: string | null,
  filter: string,
  language: string
): CellMatch {
  if (formatted == null || filter === '') {
    return null;
  }
  const normalizedField = formatted.toLocaleLowerCase(language);
  const normalizedFilter = filter.toLocaleLowerCase(language);
  const index = normalizedField.indexOf(normalizedFilter);
  return index === -1
    ? null
    : [
        formatted.slice(0, index),
        formatted.slice(index, index + filter.length),
        formatted.slice(index + filter.length),
      ];
}
