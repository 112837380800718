import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Action } from './Action';
import { ActionsMenuPopper } from './ActionsMenuPopper';
import { ActionButton } from './ActionButton';
import {useActionsAreRunning} from './useActionIsRunning';

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    loadingIcon: {
      position: 'absolute',
      color: 'inherit',
      opacity: 0.5
    },
  })
);

/**
 * Action buttons properties.
 */
export interface ActionButtonsProps extends ButtonGroupProps {
  actions?: Array<Action>;
  numberOfMainActions?: number;
}

/**
 * List of buttons representing actions.
 */
export function ActionButtons({
  actions = [],
  numberOfMainActions = 1,
  variant = 'contained',
  ...buttonGroupProps
}: ActionButtonsProps) {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleActionsMenuToggle = () => {
    setIsActionsMenuOpen((isOpen) => !isOpen);
  };

  // Ignore hidden actions
  const nonHiddenActions = actions.filter((action) => !action.hidden);
  const mainActions = nonHiddenActions?.slice(0, numberOfMainActions);
  const menuActions = nonHiddenActions?.slice(numberOfMainActions);

  const hasMenuActions = menuActions.length > 0;
  const showMenuActionsLoading = useActionsAreRunning(menuActions);

  const menuListId = useMemo(() => `actions-menu-list-${Math.random()}`, []);

  return (
    <>
      <ButtonGroup
        aria-label={t('actions')}
        variant={variant}
        {...buttonGroupProps}
      >
        {/* Main actions */}
        {mainActions.map((action) => (
          <ActionButton key={action.id} variant={variant} action={action} />
        ))}

        {/* More actions button */}
        {hasMenuActions && (
          <Button
            ref={anchorRef}
            aria-controls={isActionsMenuOpen ? menuListId : undefined}
            aria-expanded={isActionsMenuOpen ? 'true' : undefined}
            aria-label={t('moreActions')}
            aria-haspopup="menu"
            onClick={handleActionsMenuToggle}
            data-cy="actions-more"
          >
            {isActionsMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            {showMenuActionsLoading && (
              <CircularProgress className={classes.loadingIcon} size={20} />
            )}
          </Button>
        )}
      </ButtonGroup>

      {/* Menu actions popup */}
      {hasMenuActions && (
        <ActionsMenuPopper
          anchorRef={anchorRef}
          open={isActionsMenuOpen}
          setOpen={setIsActionsMenuOpen}
          actions={menuActions}
          menuListId={menuListId}
        />
      )}
    </>
  );
}
