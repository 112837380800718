import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { PageLoading } from '../components/PageLoading';

/**
 * Component used to reload the previously rendered component by going back to
 * it.
 */
export function Reloader() {
  const history = useHistory();
  useEffect(() => history.goBack(), [history]);

  return <PageLoading />;
}
