import { getYear } from 'date-fns';
import { ivageCommonUtil } from '../../../ivageCommon';
import { VatReturnForm, VatReturnOrigin } from '../vatReturnsApi';
import { TaxablePeriod } from '../../../common';

/**
 * Parses the string used as a query parameter to represent an omission and
 * returns a partial VAT return form with the relevant fields.
 */
export function parseOmissionString(
  omissionString?: string,
  vatReturnInitialTaxableYearPeriod?: Date
): Partial<VatReturnForm> | null {
  let [taxpayerId = '', taxableYearStr, taxablePeriod, ...rectifiedRest] =
    omissionString?.split('-') || [];
  const taxableYear = +taxableYearStr;

  // Minimally validate the values
  const isValidOmission =
    rectifiedRest.length === 0 &&
    ivageCommonUtil.taxpayerIdIsValid(taxpayerId) &&
    !isNaN(taxableYear) &&
    (!vatReturnInitialTaxableYearPeriod ||
      taxableYear >= getYear(vatReturnInitialTaxableYearPeriod)) &&
    taxableYear <= getYear(new Date()) &&
    Object.values(TaxablePeriod).includes(taxablePeriod as any);

  return isValidOmission
    ? {
        taxpayerId,
        taxableYear: vatReturnInitialTaxableYearPeriod ? taxableYear : null,
        taxablePeriod: taxablePeriod as TaxablePeriod,
        origin: VatReturnOrigin.Automatic,
      }
    : null;
}
