import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import {
  CreditSettlement,
  useCreditSettlementsApi,
} from './creditSettlementsApi';
import { useTranslation } from 'react-i18next';

/**
 * Result of the `useCreditSettlement` hook.
 */
export interface CreditSettlementResource {
  isFetching: boolean;
  creditSettlement: CreditSettlement | null;
  notFound: boolean;
  setCreditSettlement: Dispatch<SetStateAction<CreditSettlement | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a credit settlement from the API, if no
 * `creditSettlementId` is passed this hook does nothing.
 */
export function useCreditSettlement(
  creditSettlementId?: number
): CreditSettlementResource {
  const [t] = useTranslation('creditSettlements');
  const { getCreditSettlement } = useCreditSettlementsApi();
  const getCreditSettlementCb = useCallback(
    () =>
      creditSettlementId != null
        ? getCreditSettlement(creditSettlementId)
        : null,
    [creditSettlementId, getCreditSettlement]
  );
  const {
    isFetching,
    resource: creditSettlement,
    notFound,
    setResource: setCreditSettlement,
    refetch,
  } = useResource({
    fetchResource: getCreditSettlementCb,
    errorFetchingResourceMessage: t(
      'creditSettlements:errorFetchingCreditSettlement',
      { id: creditSettlementId ?? '' }
    ),
  });

  return {
    isFetching,
    creditSettlement,
    notFound,
    setCreditSettlement,
    refetch,
  };
}
