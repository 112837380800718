import React from 'react';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
    },
    progress: {
      margin: theme.spacing(12, 0),
    },
  })
);

/**
 * Page loading component.
 */
export function PageLoading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.progress}
        size={100}
        thickness={2.5}
      />
    </div>
  );
}
