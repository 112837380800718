import { FormState } from 'react-hook-form';
import { resolveObject } from './resolveObject';

/**
 * Helper function used to trigger other RHF fields.
 */
export function triggerNames(
  formState: FormState<Record<string, any>>,
  trigger: (name?: string | string[] | undefined) => Promise<boolean>,
  ...names: string[]
) {
  for (const name of names) {
    if (formState.isSubmitted || resolveObject(formState.touched, name)) {
      trigger(name);
    }
  }
}
