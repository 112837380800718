import React from 'react';
import { VatAssessment } from '../vatAssessmentsApi';
import { ActionButtons } from '../../../components/Actions';
import { useVatAssessmentActions } from '../useVatAssessmentActions';

/**
 * Properties for the VAT assessment row actions.
 */
interface VatAssessmentRowActionsProps {
  vatAssessment: VatAssessment;
  onRecalculate: (
    oldVatAssessment: VatAssessment,
    newVatAssessment: VatAssessment
  ) => void;
  onFailureDueToOutdatedUI: () => void;
}

/**
 * Actions in a VAT assessment row.
 */
export function VatAssessmentRowActions({
  vatAssessment,
  onRecalculate,
  onFailureDueToOutdatedUI,
}: VatAssessmentRowActionsProps) {
  return (
    <ActionButtons
      actions={useVatAssessmentActions(
        vatAssessment,
        (newVatAssessment) => onRecalculate(vatAssessment, newVatAssessment),
        onFailureDueToOutdatedUI
      )}
      variant="outlined"
      size="small"
    />
  );
}
