import React, { ReactNode, RefObject } from 'react';
import {
  CircularProgress,
  Fade,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { resolveObject } from '../../util/resolveObject';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import { useDisabledFieldStyles } from './ReadOnlyField';
import { triggerNames } from '../../util/triggerNames';
import { useInputRef } from './util/useInputRef';
import { scrollAndFocus } from './util/scrollAndFocus';

interface CustomFormNumberFieldProps {
  name: string;
  type?: 'text' | 'tel' | 'password';
  fetching?: boolean;
  rules?: RegisterOptions;
  extraEndAdornment?: ReactNode;
  onChangeTriggers?: string[];
  inputRef?: RefObject<HTMLInputElement>;
}

/**
 * Properties of the number field.
 */
export type FormNumberFieldProps = CustomFormNumberFieldProps &
  Omit<TextFieldProps, 'defaultValue' | 'type'> &
  Omit<NumberFormatProps, 'value' | 'type' | 'customInput' | 'allowNegative'>;

/**
 * Number field to be used within an RHF form context.
 */
export function FormNumberField({
  name,
  type = 'text',
  label,
  fetching,
  disabled,
  rules,
  variant = disabled ? 'filled' : 'outlined',
  fullWidth = true,
  size,
  inputRef,
  helperText,
  onValueChange,
  onBlur,
  onChangeTriggers,
  min,
  max,
  isNumericString,
  isAllowed,
  extraEndAdornment,
  inputProps = {},
  InputProps = {},
  ...otherProps
}: FormNumberFieldProps) {
  const classes = useDisabledFieldStyles();
  const { control, errors, formState, trigger } = useFormContext();
  const numberFieldRef = useInputRef(inputRef);

  const shouldDisable = disabled || fetching || formState.isSubmitting;
  const error = resolveObject(errors, name);

  /**
   * Disallow values out of bounds.
   */
  function isWithinBounds({ floatValue }: NumberFormatValues) {
    return (
      floatValue == null ||
      ((min == null || min > 0 || floatValue >= min) &&
        (max == null || max < 0 || floatValue <= max))
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => scrollAndFocus(numberFieldRef)}
      render={({
        onChange: controllerOnChange,
        onBlur: controllerOnBlur,
        value,
        name,
        ref,
      }) => (
        <NumberFormat
          type={type}
          value={value ?? ''}
          customInput={TextField}
          variant={variant}
          fullWidth={fullWidth}
          size={size as any}
          label={label}
          disabled={shouldDisable}
          {...otherProps}
          name={name}
          inputRef={numberFieldRef}
          ref={ref}
          isNumericString={isNumericString}
          min={min}
          max={max}
          allowNegative={min == null || min < 0}
          isAllowed={(values) =>
            isWithinBounds(values) && (!isAllowed || isAllowed(values))
          }
          onValueChange={(values) => {
            controllerOnChange(
              isNumericString ? values.value : values.floatValue ?? null
            );
            onValueChange?.(values);
            onChangeTriggers &&
              triggerNames(formState, trigger, ...onChangeTriggers);
          }}
          onBlur={(evt) => {
            controllerOnBlur();
            onBlur?.(evt);
          }}
          inputProps={{ inputMode: 'numeric', ...inputProps }}
          InputProps={{
            ...InputProps,
            classes: {
              ...InputProps.classes,
              disabled: `${classes.disabledField} ${
                InputProps.classes?.disabled ?? ''
              }`,
            },
            endAdornment: (fetching || extraEndAdornment) && (
              <InputAdornment position="end">
                <Fade in={fetching} mountOnEnter={true}>
                  <CircularProgress size={20} />
                </Fade>
                {extraEndAdornment}
              </InputAdornment>
            ),
          }}
          error={!!error}
          helperText={error?.message ?? helperText}
        />
      )}
    />
  );
}
