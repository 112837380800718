import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Result of the `useResource` hook.
 */
export interface Resource<T> {
  isFetching: boolean;
  resource: T | null;
  notFound: boolean;
  setResource: Dispatch<SetStateAction<T | null>>;

  refetch(): void;
}

/**
 * Options for using a resource.
 */
export interface UseResourceOptions<T> {
  fetchResource(): Promise<T> | T;

  errorFetchingResourceMessage: string;
}

/**
 * Hook used to fetch a resource from the API.
 */
export function useResource<T = any>({
  fetchResource,
  errorFetchingResourceMessage,
}: UseResourceOptions<T>): Resource<T> {
  const [t] = useTranslation('common');
  const [resourceObj, setResourceObj] = useState<{
    resource: T | null;
    isFetching: boolean;
    notFound: boolean;
  }>({ resource: null, isFetching: true, notFound: false });
  const [refetchCount, setRefetchCount] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // To refetch we increment the counter depended by the effect
  const refetch = useCallback(() => setRefetchCount((count) => count + 1), []);

  // Function that allows manually setting the resource
  const setResource = useCallback(
    (resource) =>
      setResourceObj((resourceObj) => {
        const res =
          typeof resource === 'function'
            ? resource(resourceObj.resource)
            : resource;
        return {
          resource: res,
          isFetching: false,
          notFound: res === null,
        };
      }),
    []
  );

  // Fetch resource on start-up
  useEffect(() => {
    const snackbarKey = `errorFetchingResource-${Math.random()}`;
    let isInEffect = true;

    async function getAndSetResource() {
      setResourceObj({ resource: null, isFetching: true, notFound: false });
      try {
        const resource = await fetchResource();
        if (isInEffect) {
          setResourceObj({ resource, isFetching: false, notFound: false });
        }
      } catch (err) {
        if (isInEffect) {
          if (err instanceof Response && err.status === 404) {
            // No resource found
            setResourceObj({
              resource: null,
              isFetching: false,
              notFound: true,
            });
          } else {
            // Any other error, provide action to retry fetching the resource
            enqueueSnackbar(errorFetchingResourceMessage, {
              variant: 'error',
              persist: true,
              key: snackbarKey,
              action: () => (
                <Button
                  color="inherit"
                  onClick={() => {
                    closeSnackbar(snackbarKey);
                    if (isInEffect) {
                      refetch();
                    }
                  }}
                >
                  {t('snackbarActions.retry')}
                </Button>
              ),
            });
          }
        }
      }
    }
    getAndSetResource();
    return () => {
      closeSnackbar(snackbarKey);
      isInEffect = false;
    };
  }, [
    fetchResource,
    errorFetchingResourceMessage,
    refetch,
    refetchCount,
    t,
    enqueueSnackbar,
    closeSnackbar,
  ]);

  return { ...resourceObj, setResource, refetch };
}
