import React, { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CurlyBrace } from './CurlyBrace';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 2, 1, 0),
    },
    expandIcon: {
      marginLeft: theme.spacing(-1.5),
    }
  })
);

/**
 * Properties of the curly brace cell.
 */
export interface CurlyBraceCellProps {
  startExpanded?: boolean;
  children: ReactNode;
}

/**
 * Curly brace to be used within a cell of a data table which "expands" the
 * content of the cell to the left of it.
 */
export function CurlyBraceCell({
  startExpanded,
  children,
}: CurlyBraceCellProps) {
  const classes = useStyles();

  return (
    <CurlyBrace
      className={classes.root}
      expandButtonClassName={classes.expandIcon}
      startExpanded={startExpanded}
      rightSide={children}
    />
  );
}
