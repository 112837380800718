import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ResetPasswordContext } from './ResetPasswordContext';
import { Form, FormPasswordField } from '../../components/Form';
import { useLfForm } from '../../util/lfIntegration';
import { useAuthentication } from '../AuthenticationProvider';
import { useSnackbar } from 'notistack';
import { Action, DialogActionButtons } from '../../components/Actions';
import { resetPassword } from './resetPasswordApi';

/**
 * Properties of the reset password provider.
 */
export interface ResetPasswordProviderProps {
  children: ReactNode;
}

/**
 * Reset password provider.
 */
export function ResetPasswordProvider({
  children,
}: ResetPasswordProviderProps) {
  const [t] = useTranslation('common');
  const {
    api: { logout },
  } = useAuthentication();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: {
      newPassword: '',
      repeatNewPassword: '',
    },
    formValidatorName: 'resetPasswordFormValidator',
    i18nErrorMessagesPrefixes: 'resetPassword.fieldErrors',
  });
  const { getValues, formState, reset } = formMethods;
  const [isOpen, setIsOpen] = useState(false);

  // Reset form whenever the `isOpen` state changes
  useEffect(() => reset(), [isOpen, reset]);

  // Reset password promise and respective "resolver", multiple API calls may
  // fail at once, so we use a single promise so they all wait for the same
  // "reset password" to finish.
  const promise = useRef<Promise<boolean> | null>(null);
  const resolve = useRef<((ok: boolean) => void) | null>(null);

  async function onSubmit() {
    const { newPassword } = getValues();
    try {
      await resetPassword(newPassword);
      closeSnackbar('reset-password-requested');
      enqueueSnackbar(t('resetPassword.passwordResetSuccessfully'), {
        variant: 'success',
      });
      resolve.current?.(true);
      promise.current = null;
      resolve.current = null;
      setIsOpen(false);
    } catch (err) {
      enqueueSnackbar(t('resetPassword.errorResettingPassword'), {
        variant: 'error',
      });
    }
  }

  const resetPasswordImpl = useCallback(() => {
    if (promise.current == null) {
      promise.current = new Promise<boolean>((res) => {
        resolve.current = res;
        setIsOpen(true);
      });
    }
    return promise.current;
  }, []);

  const logoutImpl = useCallback(async () => {
    await logout();
    resolve.current?.(false);
    promise.current = null;
    resolve.current = null;
    setIsOpen(false);
  }, [logout]);

  // Actions of the form
  const actions: Action[] = [
    {
      id: 'reset-password-submit',
      type: 'submit',
      label: t('resetPassword.confirmButton'),
      color: 'primary',
      loading: formState.isSubmitting,
    },
    {
      id: 'reset-password-logout',
      label: t('resetPassword.logoutButton'),
      run: () => logoutImpl(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <ResetPasswordContext.Provider value={{ resetPassword: resetPasswordImpl }}>
      <Dialog
        open={isOpen}
        disableEscapeKeyDown
        disableBackdropClick
        aria-labelledby="reset-password-title"
      >
        <Form onSubmit={onSubmit} {...formMethods}>
          <DialogTitle id="reset-password-title">
            {t('resetPassword.title')}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              {/* New password */}
              <Grid item xs={12}>
                <FormPasswordField
                  name="newPassword"
                  label={t('resetPassword.fields.newPassword')}
                  autoComplete="new-password"
                  onChangeTriggers={['repeatNewPassword']}
                />
              </Grid>

              {/* Repeat new password */}
              <Grid item xs={12}>
                <FormPasswordField
                  name="repeatNewPassword"
                  label={t('resetPassword.fields.repeatNewPassword')}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <DialogActionButtons actions={actions} />
          </DialogActions>
        </Form>
      </Dialog>

      {children}
    </ResetPasswordContext.Provider>
  );
}
