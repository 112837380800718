import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { FormatQuote } from '@material-ui/icons';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  GetCheckingAccountsFilterOptions,
  CHECKING_ACCOUNTS_URL,
  useCheckingAccountsApi,
} from '../checkingAccountsApi';
import { CheckingAccountsTable } from './CheckingAccountsTable';
import { SearchForm } from '../../../components/SearchForm';
import { useResource } from '../../../util/useResource';
import { useQueryParams } from '../../../util/useQueryParams';
import {
  FormSwitch,
  FormTaxpayerIdField,
  FormTextField,
} from '../../../components/Form';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { useLfForm } from '../../../util/lfIntegration';
import { ivageCommon } from '../../../ivageCommon';

/**
 * Package in IVAGE common containing the taxpayer form schema.
 */
const taxpayerFormSchemaPkg = ivageCommon.feature.taxpayermanagement.schema;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exactMatchSwitchLabelRoot: {
      margin: 0,
    },
    exactMatchSwitchLabelLabel: {
      display: 'flex',
      color: theme.palette.text.secondary,
    },
  })
);

// Search parameters
const searchParams = ['taxpayerId', 'taxpayerName', 'exactNameMatch'];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    taxpayerName: queryParams.taxpayerName || '',
    exactNameMatch: queryParams.exactNameMatch === 'true',
  };
}

/**
 * Page dedicated to consult the taxpayers' checking accounts.
 */
export function CheckingAccountsPage() {
  const classes = useStyles();
  const [t] = useTranslation('checkingAccounts');
  useSetDocumentTitle(t('checkingAccounts:checkingAccountsPage.documentTitle'));
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      taxpayerName: '',
      exactNameMatch: false,
    },
    formValidatorName: 'getCheckingAccountsFormValidator',
    i18nErrorMessagesPrefixes:
      'checkingAccounts:checkingAccountsPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const { getCheckingAccounts } = useCheckingAccountsApi();
  const fetchCheckingAccounts = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getCheckingAccounts(queryParams)
        : null,
    [getCheckingAccounts, isValid, queryParams]
  );
  const {
    isFetching,
    resource: checkingAccounts,
    refetch: refetchCheckingAccounts,
  } = useResource({
    fetchResource: fetchCheckingAccounts,
    errorFetchingResourceMessage: t(
      'checkingAccounts:checkingAccountsPage.errorFetchingCheckingAccounts'
    ),
  });

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const { taxpayerId, taxpayerName, exactNameMatch } = getValues();
    const getCheckingAccountsOptions: GetCheckingAccountsFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      taxpayerName: taxpayerName || undefined,
      exactNameMatch: exactNameMatch || undefined,
    };
    // Pressing "search" when nothing changed should refetch the taxpayers
    if (
      areSameQueryParams(getCheckingAccountsOptions, queryParams, searchParams)
    ) {
      refetchCheckingAccounts();
    } else {
      setQueryParams(getCheckingAccountsOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader
        title={t('checkingAccounts:checkingAccountsPage.title')}
      />

      <SearchForm
        title={t('checkingAccounts:checkingAccountsPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        <Grid item xs={6} md={3}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('checkingAccounts:checkingAccountFields.taxpayerId')}
            size="small"
          />
        </Grid>
        <Grid item xs={6} md={9}>
          <FormTextField
            name="taxpayerName"
            label={t('checkingAccounts:checkingAccountFields.taxpayerName')}
            size="small"
            extraEndAdornment={
              <Tooltip
                title={
                  t(
                    'checkingAccounts:checkingAccountsPage.searchForm.fields.exactNameMatch'
                  )!
                }
                arrow
              >
                {/* The containing `div` makes the tooltip work properly */}
                <div>
                  <FormSwitch
                    name="exactNameMatch"
                    label={<FormatQuote />}
                    FormControlLabelProps={{
                      classes: {
                        root: classes.exactMatchSwitchLabelRoot,
                        label: classes.exactMatchSwitchLabelLabel,
                      },
                    }}
                  />
                </div>
              </Tooltip>
            }
            inputProps={{
              maxLength: taxpayerFormSchemaPkg.NAME_MAX_LENGTH,
            }}
          />
        </Grid>
      </SearchForm>

      <CheckingAccountsTable
        fetching={isFetching}
        checkingAccounts={checkingAccounts || []}
        downloadButton={
          <DownloadTableButton
            fileName={t(
              'checkingAccounts:checkingAccountsPage.dataTable.downloadFileName'
            )}
            downloadUrl={`${CHECKING_ACCOUNTS_URL}?${objectToQueryParamsString({
              ...queryParams,
              format: 'csv',
            })}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
