import React from 'react';
import {
  createStyles,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from '@material-ui/core';
import { DataTableColumns } from './DataTable';
import { SortDirection } from '../../util/stableSort';

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    paddinglessY: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    paddinglessX: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    expandRowCell: {
      width: 1,
    },
    hideInPrintCell: {
      '@media print': {
        padding: 0,
        width: 0,
      },
    },
    hideInPrint: {
      '@media print': {
        display: 'none',
      },
    },
  })
);

/**
 * Properties of the data table head.
 */
interface DataTableHeadProps<C extends DataTableColumns<any>> {
  columns: C;
  visibleColumnKeys: string[];
  onRequestSort: (event: React.MouseEvent<unknown>, columnId: keyof C) => void;
  sortBy: keyof C;
  sortDirection: SortDirection;
  hasExpandRowColumn: boolean;
}

/**
 * Head of the data table.
 */
export function DataTableHead<C extends DataTableColumns<any>>({
  columns,
  visibleColumnKeys,
  sortBy,
  sortDirection,
  onRequestSort,
  hasExpandRowColumn,
}: DataTableHeadProps<C>) {
  const classes = useStyles();

  function sortHandler(columnId: keyof C) {
    return (event: React.MouseEvent<unknown>) => onRequestSort(event, columnId);
  }

  return (
    <TableHead data-cy="data-table-head">
      <TableRow>
        {visibleColumnKeys.map((columnId) => {
          const column = columns[columnId];
          const defaultColumnOrderDirection =
            column.defaultSortDirection ?? 'asc';

          return (
            <TableCell
              key={columnId}
              align={column.align}
              sortDirection={sortBy === columnId ? sortDirection : false}
              data-cy={`data-table-column-${columnId}`}
              classes={{
                ...column.cellClasses,
                root: `${column.cellClasses?.root ?? ''} ${
                  column.paddinglessY ? classes.paddinglessY : ''
                } ${column.paddinglessX ? classes.paddinglessX : ''} ${
                  column.paddinglessX ? classes.paddinglessX : ''
                } ${column.hideInPrint ? classes.hideInPrintCell : ''}`,
              }}
              style={{ width: column.width ?? 'auto' }}
            >
              {column.sortable === false || column.value == null ? (
                <div className={column.hideInPrint ? classes.hideInPrint : ''}>
                  {column.label}
                </div>
              ) : (
                <TableSortLabel
                  className={column.hideInPrint ? classes.hideInPrint : ''}
                  active={sortBy === columnId}
                  direction={
                    sortBy === columnId
                      ? sortDirection
                      : defaultColumnOrderDirection
                  }
                  onClick={sortHandler(columnId)}
                >
                  {column.label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}

        {hasExpandRowColumn && (
          <TableCell
            className={`${classes.expandRowCell} ${classes.hideInPrintCell}`}
          />
        )}
      </TableRow>
    </TableHead>
  );
}
