import {
  CheckCircleOutline,
  Edit,
  OpenInNew,
  RemoveCircleOutline,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  EDIT_USER_PATH,
  replacePathParams,
  USER_PATH,
} from '../../navigation/paths';
import { User, UserStatus, useUsersApi } from './usersApi';
import { useCanChangeUserStatus, useCanEditUser } from './permissions';
import { Action, useActionIsRunning } from '../../components/Actions';
import { useIsMounted } from '../../util/useIsMounted';

/**
 * Hook that returns actions that can be performed on a user.
 */
export function useUserActions(
  user: User | null,
  onUpdateStatus: (user: User) => void,
  onUpdateStatusFailureDueToOutdatedUI: () => void
): Action[] {
  const [t] = useTranslation('users');
  const { updateUserStatus } = useUsersApi();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const isMounted = useIsMounted();

  const canEditUser = useCanEditUser(user);
  const canChangeUserStatus = useCanChangeUserStatus(user);

  const [isSuspending] = useActionIsRunning(`user-suspend-${user?.username}`);

  const updateStatusImpl = useCallback(
    async (newStatus: UserStatus) => {
      try {
        const newUser = await updateUserStatus(user!.username, newStatus);

        if (newStatus === UserStatus.Active) {
          enqueueSnackbar(
            t('users:userActivatedSuccessfully', { username: user!.username }),
            { variant: 'success' }
          );
        } else {
          enqueueSnackbar(
            t('users:userSuspendedSuccessfully', { username: user!.username }),
            { variant: 'success' }
          );
        }

        if (isMounted.current) {
          onUpdateStatus(newUser);
        }
      } catch (err) {
        if (err instanceof Response && err.status === 400) {
          if (newStatus === UserStatus.Active) {
            enqueueSnackbar(
              t('users:errorActivatingUserDueToOutdatedUI', {
                username: user!.username,
              }),
              { variant: 'error' }
            );
          } else {
            enqueueSnackbar(
              t('users:errorSuspendingUserDueToOutdatedUI', {
                username: user!.username,
              }),
              { variant: 'error' }
            );
          }

          if (isMounted.current) {
            onUpdateStatusFailureDueToOutdatedUI();
          }
        } else {
          if (newStatus === UserStatus.Active) {
            enqueueSnackbar(
              t('users:errorActivatingUser', { username: user!.username }),
              { variant: 'error' }
            );
          } else {
            enqueueSnackbar(
              t('users:errorSuspendingUser', { username: user!.username }),
              { variant: 'error' }
            );
          }
        }
      }
    },
    [
      updateUserStatus,
      user,
      isMounted,
      onUpdateStatus,
      enqueueSnackbar,
      t,
      onUpdateStatusFailureDueToOutdatedUI,
    ]
  );

  const userPath = replacePathParams(USER_PATH, { username: user?.username });

  return useMemo(
    () => [
      {
        id: `user-open-${user?.username}`,
        label: t('users:actions.open'),
        component: Link,
        to: userPath,
        icon: <OpenInNew />,
        hidden: !user || pathname === userPath,
      },
      {
        id: `user-edit-${user?.username}`,
        label: t('users:actions.edit'),
        component: Link,
        to: replacePathParams(EDIT_USER_PATH, { username: user?.username }),
        icon: <Edit />,
        color: 'primary',
        disabled: !canEditUser || isSuspending,
        hidden: !user || user.status === UserStatus.Suspended,
      },
      {
        id: `user-suspend-${user?.username}`,
        label: t('users:actions.suspend'),
        run: () => updateStatusImpl(UserStatus.Suspended),
        icon: <RemoveCircleOutline />,
        style: 'error',
        disabled: !canChangeUserStatus,
        hidden: !user || user.status === UserStatus.Suspended,
      },
      {
        id: `user-activate-${user?.username}`,
        label: t('users:actions.activate'),
        run: () => updateStatusImpl(UserStatus.Active),
        icon: <CheckCircleOutline />,
        style: 'success',
        disabled: !canChangeUserStatus,
        hidden: !user || user.status === UserStatus.Active,
      },
    ],
    [
      user,
      t,
      userPath,
      pathname,
      canEditUser,
      isSuspending,
      canChangeUserStatus,
      updateStatusImpl,
    ]
  );
}
