import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { App } from './app/App';
import { reportWebVitals } from './reportWebVitals';
import { PageLoading } from './components/PageLoading';
import { ThemeProvider } from './providers/ThemeProvider';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Suspense fallback={<PageLoading />}>
        <App />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(
  process.env.NODE_ENV !== 'production' ? console.log : undefined
);
export { REDIRECT_PARAM } from './navigation/paths';
