import React, { SetStateAction, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { MainContent } from '../../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../../util/formatters';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { useTaxpayer } from '../useTaxpayer';
import { VatActivitiesTable } from './VatActivitiesTable';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { NotSpecified } from '../../../components/NotSpecified';
import { useTaxpayerActions } from '../useTaxpayerActions';
import { VatActivity, Taxpayer, TaxpayerStatus } from '../taxpayersApi';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { Skeleton } from '@material-ui/lab';
import { useFormatTaxpayerAddress } from '../util/useFormatTaxpayerAddress';

/**
 * Page with taxpayer information.
 */
export function TaxpayerPage() {
  const [t] = useTranslation('taxpayers');
  const { id: taxpayerId } = useParams<{ id: string }>();
  const { taxpayer, isFetching, notFound, setTaxpayer, refetch } = useTaxpayer(
    taxpayerId
  );
  useSetDocumentTitle(
    t('taxpayers:taxpayerPage.documentTitle', {
      id: taxpayerId,
    })
  );
  const {
    catalog: sectorsCatalog,
    isFetching: isFetchingSectorsCatalog,
  } = useCatalog('sectors');
  const {
    catalog: taxOfficesCatalog,
    isFetching: isFetchingTaxOfficesCatalog,
  } = useCatalog('tax-offices');
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();
  const actions = useTaxpayerActions(taxpayer, refetch);

  const {
    formattedAddress,
    isFetching: isFetchingSections,
  } = useFormatTaxpayerAddress(taxpayer);

  const setVatActivities = useCallback(
    (vatActivities: SetStateAction<VatActivity[]>) => {
      setTaxpayer(
        (taxpayer: Taxpayer | null) =>
          taxpayer && {
            ...taxpayer,
            vatActivities:
              typeof vatActivities === 'function'
                ? vatActivities(taxpayer.vatActivities)
                : vatActivities,
          }
      );
    },
    [setTaxpayer]
  );

  // Fiscal information fields
  const fiscalInformation: DescriptionListItem[] = [
    {
      key: 'taxpayer-id',
      label: t('taxpayers:taxpayerFields.taxpayerId'),
      content: taxpayerId,
    },
    {
      key: 'name',
      label: t('taxpayers:taxpayerFields.name'),
      content: taxpayer?.name,
      fetching: isFetching,
    },
    {
      key: 'old-taxpayer-id',
      label: t('taxpayers:taxpayerFields.oldTaxpayerId'),
      content: taxpayer?.oldTaxpayerId || (
        <NotSpecified>{t('taxpayers:oldTaxpayerIdNotSpecified')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'representative',
      label: t('taxpayers:taxpayerFields.representative'),
      content: taxpayer?.representative,
      fetching: isFetching,
    },
    {
      key: 'existence',
      label: t('taxpayers:taxpayerFields.existence'),
      content: taxpayer && (
        <>
          {t('taxpayers:taxpayerPage.fromDate', {
            startDate: formatDate(taxpayer.startDate),
          })}
          {taxpayer.endDate &&
            ` ${t('taxpayers:taxpayerPage.toDate', {
              endDate: formatDate(taxpayer.endDate),
            })}`}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'address',
      label: t('taxpayers:taxpayerFields.address'),
      content: formattedAddress,
      preserveWhiteSpaces: true,
      fetching: isFetching || isFetchingSections,
      renderFetching: (
        <>
          <Skeleton width={'70%'} />
          <Skeleton />
        </>
      ),
    },
    {
      key: 'address-other',
      label: t('taxpayers:taxpayerFields.addressOther'),
      content: taxpayer?.addressOther || (
        <NotSpecified>{t('taxpayers:addressOtherNotSpecified')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'sector',
      label: t('taxpayers:taxpayerFields.sector'),
      content:
        taxpayer &&
        (sectorsCatalog?.find((sector) => sector.id === taxpayer.sectorId)
          ?.name ?? (
          <NotSpecified>{t('taxpayers:sectorNotSpecified')}</NotSpecified>
        )),
      fetching: isFetching || isFetchingSectorsCatalog,
    },
    {
      key: 'tax-office',
      label: t('taxpayers:taxpayerFields.taxOffice'),
      content:
        taxpayer &&
        (taxOfficesCatalog?.find(
          (taxOffice) => taxOffice.id === taxpayer.taxOfficeId
        )?.name ?? (
          <NotSpecified>{t('taxpayers:taxOfficeNotSpecified')}</NotSpecified>
        )),
      fetching: isFetching || isFetchingTaxOfficesCatalog,
    },
  ];

  // Type information fields
  const typeInformation: DescriptionListItem[] = [
    {
      key: 'type-and-dimension',
      label: t('taxpayers:taxpayerFields.typeAndDimension'),
      content: taxpayer && (
        <>
          {t(`taxpayers:taxpayerType.${taxpayer.type}`)}
          <ExtraInfo>
            {t(`taxpayers:taxpayerDimension.${taxpayer.dimension}`)}
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'share-capital',
      label: t('taxpayers:taxpayerFields.shareCapital'),
      content: taxpayer && (
        <>
          {formatCurrency(taxpayer.shareCapital)}
          <ExtraInfo>
            {t(`taxpayers:legalRegime.${taxpayer.legalRegime}`)}
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
  ];

  // Additional information fields
  const additionalInformation: DescriptionListItem[] = [
    {
      key: 'phone',
      label: t('taxpayers:taxpayerFields.phone'),
      content: taxpayer?.phone || (
        <NotSpecified>{t('taxpayers:phoneNotSpecified')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'email',
      label: t('taxpayers:taxpayerFields.email'),
      content: taxpayer?.email || (
        <NotSpecified>{t('taxpayers:emailNotSpecified')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'notes',
      label: t('taxpayers:taxpayerFields.notes'),
      content: taxpayer?.notes || (
        <NotSpecified>{t('taxpayers:notesNotSpecified')}</NotSpecified>
      ),
      preserveWhiteSpaces: true,
      fetching: isFetching,
    },
  ];

  // System information fields
  const systemInformation: DescriptionListItem[] = [
    {
      key: 'created-date',
      label: t('taxpayers:taxpayerFields.createdDate'),
      content: formatDateTime(taxpayer?.createdDate),
      fetching: isFetching,
    },
    {
      key: 'changed-date',
      label: t('taxpayers:taxpayerFields.changedDate'),
      content: formatDateTime(taxpayer?.changedDate),
      fetching: isFetching,
    },
  ];

  return (
    <MainContent errorMessage={notFound && t('taxpayers:taxpayerNotFound')}>
      <ActionableHeader
        title={t('taxpayers:taxpayerPage.title')}
        titleId={taxpayerId}
        actions={actions}
        status={taxpayer && t(`taxpayers:taxpayerStatus.${taxpayer.status}`)}
        statusVariant={
          taxpayer?.status === TaxpayerStatus.Active ? 'success' : 'error'
        }
      />

      <PaperDescriptionList
        title={t('taxpayers:fiscalInformation')}
        items={fiscalInformation}
      />
      <PaperDescriptionList
        title={t('taxpayers:typeInformation')}
        items={typeInformation}
      />
      <PaperDescriptionList
        title={t('taxpayers:additionalInformation')}
        items={additionalInformation}
      />
      <PaperDescriptionList
        title={t('taxpayers:systemInformation')}
        items={systemInformation}
      />

      <VatActivitiesTable
        taxpayer={taxpayer}
        refetchTaxpayer={refetch}
        setVatActivities={setVatActivities}
        fetching={isFetching}
      />
    </MainContent>
  );
}
