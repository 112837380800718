import { DataTable, DataTableColumns } from '../../../components/DataTable';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VatIndicatorColumnDefaultSortDirection,
  VatIndicatorColumnType,
  VatIndicatorMetadata,
} from '../../../providers/CatalogsProvider/catalogsApi';
import { VatIndicator } from '../vatIndicatorsApi';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../../util/formatters';
import { Skeleton } from '@material-ui/lab';

/**
 * Properties of the VAT indicator table.
 */
export interface VatIndicatorTableProps {
  fetching: boolean;
  vatIndicatorMetadata: VatIndicatorMetadata | null;
  vatIndicator: VatIndicator | null;
  downloadButton: ReactNode;
}

/**
 * Number of dummy columns to show while the indicators catalog is being
 * fetched.
 */
const N_SKELETON_COLUMNS = 5;

/**
 * Table showing the VAT indicator.
 */
export function VatIndicatorTable({
  fetching,
  vatIndicatorMetadata,
  vatIndicator,
  downloadButton,
}: VatIndicatorTableProps) {
  const [t] = useTranslation('vatIndicators');
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();

  /**
   * Build the columns from the indicator metadata or provide "skeleton" columns
   * whilst fetching said metadata.
   */
  const columns = vatIndicatorMetadata
    ? Object.keys(vatIndicatorMetadata.table.columns).reduce(
        (columns, columnId) => {
          const column = vatIndicatorMetadata.table.columns[columnId];
          columns[columnId] = {
            label: t(
              `vatIndicators:vatIndicators.${vatIndicatorMetadata.vatIndicatorId}.columns.${columnId}`
            ),
            value: ({ [columnId]: value }) => value,
            format: (value) =>
              column.type === VatIndicatorColumnType.Currency
                ? formatCurrency(value as number)
                : column.type === VatIndicatorColumnType.Date
                ? formatDate(new Date(value as string))
                : column.type === VatIndicatorColumnType.DateTime
                ? formatDateTime(new Date(value as string))
                : String(value),
            defaultSortDirection:
              column.defaultSortDirection ===
              VatIndicatorColumnDefaultSortDirection.Desc
                ? 'desc'
                : 'asc',
          };
          return columns;
        },
        {} as DataTableColumns<any>
      )
    : Array(N_SKELETON_COLUMNS)
        .fill(0)
        .reduce((columns, column, i) => {
          columns[`skeleton${i}`] = {
            // Vary the widths a bit
            label: <Skeleton width={100 + i * 10 * (i % 2 ? 1 : -1)} />,
            value: () => null,
            sortable: false,
          };
          return columns;
        }, {} as DataTableColumns<any>);

  return (
    <DataTable
      title={
        vatIndicatorMetadata ? (
          t(
            `vatIndicators:vatIndicators.${vatIndicatorMetadata.vatIndicatorId}.title`
          )
        ) : (
          <Skeleton width={500} />
        )
      }
      rows={vatIndicator?.indicator ?? []}
      rowId={
        vatIndicatorMetadata
          ? ({ [vatIndicatorMetadata.table.rowId]: rowId }) => rowId
          : () => 'skeleton1'
      }
      columns={columns}
      emptyMessage={t(`vatIndicators:vatIndicatorPage.dataTable.emptyMessage`)}
      toolbarActionsLeft={vatIndicatorMetadata && downloadButton}
      fetching={fetching}
      defaultSortBy={
        vatIndicatorMetadata
          ? vatIndicatorMetadata.table.defaultSortBy
          : 'skeleton1'
      }
      minWidth={Object.keys(columns!).length * 150}
      allowFilter={!!vatIndicatorMetadata}
    />
  );
}
