import { useCallback } from 'react';
import { TaxablePeriod, TaxId } from '../../common';
import { toISODateString } from '../../util/dateUtils';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Type of a credit settlement.
 */
export interface CreditSettlement {
  creditSettlementId: number;
  assessmentNumber: string;
  assessmentNumberInserted: string;
  taxpayerId: string;
  taxpayerName: string;
  taxId: TaxId;
  taxableYear: number;
  taxablePeriod: TaxablePeriod | null;
  creditSettlementDate: Date;
  amount: number;
  description: string;
  status: CreditSettlementStatus;
  createdDate: Date;
  createdBy: string;
  changedDate: Date;
  voidDescription: string | null;
  changedBy: string | null;
}

/**
 * Status of the credit settlement.
 */
export enum CreditSettlementStatus {
  Submitted = 'SUBMITTED',
  Voided = 'VOIDED',
}

/**
 * Options used to get credit settlements.
 */
export interface GetCreditSettlementsFilterOptions {
  taxpayerId?: string;
  taxId?: TaxId;
  taxableYear?: number;
  taxablePeriod?: TaxablePeriod;
}

/**
 * Form fields used to void a credit settlement.
 */
export interface VoidCreditSettlementForm {
  voidDescription: string;
}

/**
 * Form fields when creating a credit settlement.
 */
export interface CreditSettlementForm {
  taxpayerId: string;
  assessmentNumber: string;
  taxId: TaxId | null;
  taxableYear: number | null;
  taxablePeriod: TaxablePeriod | null;
  creditSettlementDate: Date | null;
  amount: number | null;
  description: string;
}

/**
 * URL to access credit settlements.
 */
export const CREDIT_SETTLEMENTS_URL = '/api/credit-settlements';

/**
 * Transforms a credit settlement JSON as given by the API into our internal
 * credit settlement representation.
 */
function jsonToCreditSettlement(creditSettlementJson: any): CreditSettlement {
  return {
    ...creditSettlementJson,
    creditSettlementDate: new Date(creditSettlementJson.creditSettlementDate),
    createdDate: new Date(creditSettlementJson.createdDate),
    changedDate: new Date(creditSettlementJson.changedDate),
  };
}

/**
 * Hook exposing the credit settlements API.
 */
export function useCreditSettlementsApi() {
  const { getJson, postJson, putJson } = useFetchFromApi();

  /**
   * Gets a list of credit settlements matching the provided filter options.
   */
  const getCreditSettlements = useCallback(
    async function (
      filterOptions: GetCreditSettlementsFilterOptions = {}
    ): Promise<CreditSettlement[]> {
      return (
        await getJson(CREDIT_SETTLEMENTS_URL, { params: filterOptions })
      ).map(jsonToCreditSettlement);
    },
    [getJson]
  );

  /**
   * Gets the credit settlement with the provided id.
   */
  const getCreditSettlement = useCallback(
    async function (creditSettlementId: number): Promise<CreditSettlement> {
      return jsonToCreditSettlement(
        await getJson(
          `${CREDIT_SETTLEMENTS_URL}/${encodeURIComponent(creditSettlementId)}`
        )
      );
    },
    [getJson]
  );

  /**
   * Voids a credit settlement.
   */
  const voidCreditSettlement = useCallback(
    async function (
      creditSettlementId: number,
      form: VoidCreditSettlementForm
    ): Promise<CreditSettlement> {
      return jsonToCreditSettlement(
        await putJson(
          `${CREDIT_SETTLEMENTS_URL}/${encodeURIComponent(
            creditSettlementId
          )}/status`,
          {
            newStatus: CreditSettlementStatus.Voided,
            ...form,
          }
        )
      );
    },
    [putJson]
  );

  /**
   * Creates a new credit settlement from the provided credit settlement fields and
   * returns it.
   */
  const createCreditSettlement = useCallback(
    async function (form: CreditSettlementForm): Promise<CreditSettlement> {
      const toCreate = {
        ...form,
        creditSettlementDate:
          form.creditSettlementDate &&
          toISODateString(form.creditSettlementDate),
      };
      return jsonToCreditSettlement(
        await postJson(CREDIT_SETTLEMENTS_URL, toCreate)
      );
    },
    [postJson]
  );

  return {
    getCreditSettlements,
    getCreditSettlement,
    voidCreditSettlement,
    createCreditSettlement,
  };
}
