import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormTextField, ReadOnlyField } from '../../../components/Form';
import { Taxpayer } from '../../Taxpayers';
import { FormMode } from './VatReturnFormPage';
import { useDivisionSections } from '../../../providers/CatalogsProvider';
import { ivageCommon } from '../../../ivageCommon';

/**
 * Package in IVAGE common containing the VAT return form schema.
 */
const vatReturnFormSchemaPkg = ivageCommon.feature.vatreturn.schema;

/**
 * Properties of the "representative" section.
 */
export interface RepresentativeProps {
  formMode: FormMode;
  fetching: boolean;
  taxpayer: Taxpayer | null;
  isFetchingTaxpayer: boolean;
}

/**
 * "Representative" section of the VAT return form.
 */
export function Representative({
  formMode,
  fetching,
  taxpayer,
  isFetchingTaxpayer,
}: RepresentativeProps) {
  const [t] = useTranslation('vatReturns');
  const { isFetching: isFetchingSections, sections } = useDivisionSections(
    taxpayer?.divisionId
  );

  return (
    <Grid container spacing={2}>
      {/* Name */}
      <Grid item xs={12}>
        <FormTextField
          name="interestedName"
          label={t('vatReturns:vatReturnFormPage.fields.interestedName')}
          fetching={fetching}
          disabled={formMode === FormMode.View}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {t('vatReturns:vatReturnFormPage.interestedNameTitle')}
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: vatReturnFormSchemaPkg.INTERESTED_NAME_MAX_LENGTH,
          }}
        />
      </Grid>

      {/* VUE */}
      <Grid item xs={6} md={4}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.oldTaxpayerId')}
          fetching={isFetchingTaxpayer}
          value={taxpayer?.oldTaxpayerId ?? ''}
        />
      </Grid>

      {/* Company */}
      <Grid item xs={6} md={8}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.company')}
          fetching={isFetchingTaxpayer}
          value={taxpayer?.name ?? ''}
        />
      </Grid>

      {/* Tax address */}
      <Grid item xs={12} md={12}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.address')}
          fetching={isFetchingTaxpayer}
          value={taxpayer?.address ?? ''}
        />
      </Grid>

      {/* Municipality */}
      <Grid item xs={6} md={4}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.municipality')}
          fetching={isFetchingTaxpayer || isFetchingSections}
          value={sections?.municipality?.name ?? ''}
        />
      </Grid>

      {/* District */}
      <Grid item xs={6} md={4}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.district')}
          fetching={isFetchingTaxpayer || isFetchingSections}
          value={sections?.district?.name ?? ''}
        />
      </Grid>

      {/* Province */}
      <Grid item xs={12} md={4}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.province')}
          fetching={isFetchingTaxpayer || isFetchingSections}
          value={sections?.province?.name ?? ''}
        />
      </Grid>

      {/* Phone */}
      <Grid item xs={6}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.phone')}
          fetching={isFetchingTaxpayer}
          value={taxpayer?.phone ?? ''}
        />
      </Grid>

      {/* Email */}
      <Grid item xs={6}>
        <ReadOnlyField
          label={t('vatReturns:vatReturnFormPage.fields.email')}
          fetching={isFetchingTaxpayer}
          value={taxpayer?.email ?? ''}
        />
      </Grid>
    </Grid>
  );
}
