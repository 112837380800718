import { VatOmission } from '../vatOmissionsApi';

/**
 * Identifier of a VAT omission.
 */
export function vatOmissionId({
  taxpayerId,
  taxableYear,
  taxablePeriod,
}: VatOmission) {
  return [taxpayerId, taxableYear, taxablePeriod].join('-');
}
