import React from 'react';
import { PaymentRefund } from '../paymentRefundsApi';
import { ActionButtons } from '../../../components/Actions';
import { usePaymentRefundActions } from '../usePaymentRefundActions';

/**
 * Properties for the payment refund row actions.
 */
interface PaymentRefundActionsProps {
  paymentRefund: PaymentRefund;
  onVoid: () => void;
}

/**
 * Actions in a payment refund row.
 */
export function PaymentRefundRowActions({
  paymentRefund,
  onVoid,
}: PaymentRefundActionsProps) {
  return (
    <ActionButtons
      actions={usePaymentRefundActions(paymentRefund, onVoid)!}
      variant="outlined"
      size="small"
    />
  );
}
