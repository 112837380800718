import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { TaxRefund, useTaxRefundsApi } from './taxRefundsApi';
import { useTranslation } from 'react-i18next';

/**
 * Result of the `useTaxRefund` hook.
 */
export interface TaxRefundResource {
  isFetching: boolean;
  taxRefund: TaxRefund | null;
  notFound: boolean;
  setTaxRefund: Dispatch<SetStateAction<TaxRefund | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a tax refund from the API, if no `taxRefundId` is passed
 * this hook does nothing.
 */
export function useTaxRefund(taxRefundId?: number): TaxRefundResource {
  const [t] = useTranslation('taxRefunds');
  const { getTaxRefund } = useTaxRefundsApi();
  const getTaxRefundCb = useCallback(
    () => (taxRefundId != null ? getTaxRefund(taxRefundId) : null),
    [getTaxRefund, taxRefundId]
  );
  const {
    isFetching,
    resource: taxRefund,
    notFound,
    setResource: setTaxRefund,
    refetch,
  } = useResource({
    fetchResource: getTaxRefundCb,
    errorFetchingResourceMessage: t('taxRefunds:errorFetchingTaxRefund', {
      id: taxRefundId ?? '',
    }),
  });

  return { isFetching, taxRefund, notFound, setTaxRefund, refetch };
}
