import React from 'react';
import { CheckingAccount } from '../checkingAccountsApi';
import { ActionButtons } from '../../../components/Actions';
import { useCheckingAccountActions } from '../useCheckingAccountActions';

/**
 * Properties for the checking account row actions.
 */
interface CheckingAccountActionsProps {
  checkingAccount: CheckingAccount;
}

/**
 * Actions in a checking account row.
 */
export function CheckingAccountRowActions({ checkingAccount }: CheckingAccountActionsProps) {
  return (
    <ActionButtons
      actions={useCheckingAccountActions(checkingAccount)!}
      variant="outlined"
      size="small"
    />
  );
}
