import { createContext, Dispatch, SetStateAction } from 'react';
import { ActiveThemeVariant, ThemeVariant } from './ThemeProvider';

/**
 * Representation of the theme context.
 */
export interface ThemeVariantState {
  themeVariant: ThemeVariant;
  setThemeVariant: Dispatch<SetStateAction<ThemeVariant>>;
  activeThemeVariant: ActiveThemeVariant;
}

/**
 * Theme context.
 */
export const ThemeContext = createContext<ThemeVariantState>(undefined as any);
