import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../providers/ProfileProvider';
import {
  CHECKING_ACCOUNTS_PATH,
  CREDIT_SETTLEMENTS_PATH,
  NEW_CREDIT_SETTLEMENT_PATH,
  NEW_PAYMENT_PATH,
  NEW_PAYMENT_REFUND_PATH,
  NEW_TAX_REFUND_PATH,
  NEW_TAXPAYER_PATH,
  NEW_USER_PATH,
  NEW_VAT_RETURN_PATH,
  PAYMENT_REFUNDS_PATH,
  PAYMENTS_PATH,
  replacePathParams,
  STATE_CHECKING_ACCOUNT_PATH,
  TAX_REFUNDS_PATH,
  TAXPAYERS_PATH,
  USERS_PATH,
  VAT_ASSESSMENTS_PATH,
  VAT_INDICATOR_PATH,
  VAT_OMISSIONS_PATH,
  VAT_RETURNS_PATH,
} from './paths';
import { useCatalog } from '../providers/CatalogsProvider';

/**
 * Properties of a navigation tree item's lazy children.
 */
export interface NavigationTreeLazyItemProps {
  renderChildren: (
    items: NavigationTreeItem[]
  ) => ReactElement<any, any> | null;
  renderFetching: () => ReactElement<any, any> | null;
}

/**
 * Object representation of a list item of the drawer.
 */
export interface NavigationTreeItem {
  label: ReactNode;
  key: string;
  path?: string;
  childrenPathRegExp?: RegExp;
  role?: UserRole;
  children?:
    | NavigationTreeItem[]
    | JSXElementConstructor<NavigationTreeLazyItemProps>;
  fetchingChildren?: boolean;
}

/**
 * Returns a structure that can be iterated to produce the application's
 * navigation.
 */
export function useNavigationTree(): NavigationTreeItem[] {
  const [t] = useTranslation('common');

  return [
    {
      label: t('navigation.taxpayers.label'),
      key: 'taxpayers',
      children: [
        {
          label: t('navigation.taxpayers.consult'),
          key: 'consult-taxpayers',
          path: TAXPAYERS_PATH,
        },
        {
          label: t('navigation.taxpayers.new'),
          key: 'new-taxpayer',
          path: NEW_TAXPAYER_PATH,
        },
      ],
    },
    {
      label: t('navigation.vatReturns.label'),
      key: 'vat-returns',
      children: [
        {
          label: t('navigation.vatReturns.consult'),
          key: 'consult-vat-returns',
          path: VAT_RETURNS_PATH,
        },
        {
          label: t('navigation.vatReturns.new'),
          key: 'new-vat-return',
          path: NEW_VAT_RETURN_PATH,
        },
      ],
    },
    {
      label: t('navigation.vatOmissions.label'),
      key: 'vat-omissions',
      path: VAT_OMISSIONS_PATH,
    },
    {
      label: t('navigation.vatAssessments.label'),
      key: 'vat-assessments',
      path: VAT_ASSESSMENTS_PATH,
    },
    {
      label: t('navigation.checkingAccounts.label'),
      key: 'checking-accounts',
      children: [
        {
          label: t('navigation.checkingAccounts.consult'),
          key: 'consult-checking-accounts',
          path: CHECKING_ACCOUNTS_PATH,
        },
        {
          label: t('navigation.checkingAccounts.stateCheckingAccount'),
          key: 'state-checking-account',
          path: STATE_CHECKING_ACCOUNT_PATH,
        },
      ],
    },
    {
      label: t('navigation.vatIndicators.label'),
      key: 'vat-indicators',
      childrenPathRegExp: /^\/vat-indicators\/.*$/,
      children: VatIndicatorsChildren,
    },
    {
      label: t('navigation.taxRefunds.label'),
      key: 'tax-refunds',
      children: [
        {
          label: t('navigation.taxRefunds.consult'),
          key: 'consult-tax-refunds',
          path: TAX_REFUNDS_PATH,
        },
        {
          label: t('navigation.taxRefunds.new'),
          key: 'new-tax-refund',
          path: NEW_TAX_REFUND_PATH,
        },
      ],
    },
    {
      label: t('navigation.payments.label'),
      key: 'payments',
      children: [
        {
          label: t('navigation.payments.consult'),
          key: 'consult-payments',
          path: PAYMENTS_PATH,
        },
        {
          label: t('navigation.payments.new'),
          key: 'new-payment',
          path: NEW_PAYMENT_PATH,
        },
      ],
    },
    {
      label: t('navigation.paymentRefunds.label'),
      key: 'payment-refunds',
      children: [
        {
          label: t('navigation.paymentRefunds.consult'),
          key: 'consult-payment-refunds',
          path: PAYMENT_REFUNDS_PATH,
        },
        {
          label: t('navigation.paymentRefunds.new'),
          key: 'new-payment-refund',
          path: NEW_PAYMENT_REFUND_PATH,
        },
      ],
    },
    {
      label: t('navigation.creditSettlements.label'),
      key: 'credit-settlements',
      children: [
        {
          label: t('navigation.creditSettlements.consult'),
          key: 'consult-credit-settlements',
          path: CREDIT_SETTLEMENTS_PATH,
        },
        {
          label: t('navigation.creditSettlements.new'),
          key: 'new-credit-settlement',
          path: NEW_CREDIT_SETTLEMENT_PATH,
        },
      ],
    },
    {
      label: t('navigation.users.label'),
      key: 'users',
      role: UserRole.Manager,
      children: [
        {
          label: t('navigation.users.consult'),
          key: 'consult-users',
          path: USERS_PATH,
        },
        {
          label: t('navigation.users.new'),
          key: 'new-user',
          path: NEW_USER_PATH,
        },
      ],
    },
  ];
}

/**
 * Children of the VAT indicators tree item.
 */
function VatIndicatorsChildren({
  renderChildren,
  renderFetching,
}: NavigationTreeLazyItemProps) {
  const [t] = useTranslation('vatIndicators');
  const {
    isFetching: isFetchingVatIndicatorsCatalog,
    catalog: vatIndicatorsCatalog,
  } = useCatalog('vat-indicators');

  return isFetchingVatIndicatorsCatalog
    ? renderFetching()
    : renderChildren(
        vatIndicatorsCatalog!.map(({ vatIndicatorId }) => ({
          label: t(`vatIndicators:vatIndicators.${vatIndicatorId}.label`),
          key: `vat-indicator-${vatIndicatorId}`,
          path: replacePathParams(VAT_INDICATOR_PATH, { id: vatIndicatorId }),
        }))
      );
}
