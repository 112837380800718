import { createContext, ReactNode } from 'react';

/**
 * API to interact with the confirmation dialog.
 */
export interface ConfirmationDialogApi {
  confirm(message: ReactNode): Promise<boolean>;
}

/**
 * Confirmation context.
 */
export const ConfirmationDialogContext = createContext<ConfirmationDialogApi>(
  undefined as any
);
