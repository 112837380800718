import { Action } from './Action';
import { ActionButton, ActionButtonProps } from './ActionButton';

/**
 * Dialog action buttons properties.
 */
export interface DialogActionButtonsProps
  extends Omit<ActionButtonProps, 'action'> {
  actions?: Action[];
}

/**
 * Action buttons to use as dialog actions.
 */
export function DialogActionButtons({
  actions = [],
  ...props
}: DialogActionButtonsProps) {
  return (
    <>
      {/* Actions are reversed since they appear in the right side of the
          dialog */}
      {[...actions].reverse().map((action) => (
        <ActionButton key={action.id} action={action} {...props} />
      ))}
    </>
  );
}
