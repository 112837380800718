import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  GetUsersFilterOptions,
  USERS_URL,
  UserStatus,
  useUsersApi,
} from '../usersApi';
import { UsersTable } from './UsersTable';
import { SearchForm } from '../../../components/SearchForm';
import { useResource } from '../../../util/useResource';
import { useQueryParams } from '../../../util/useQueryParams';
import { FormSelect, FormTextField } from '../../../components/Form';
import { UserRole } from '../../../providers/ProfileProvider';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { ivageCommon } from '../../../ivageCommon';
import { useLfForm } from '../../../util/lfIntegration';

/**
 * Package in IVAGE common containing the user form schema.
 */
const userFormSchemaPkg = ivageCommon.feature.usermanagement.schema;

// Search parameters
const searchParams = [
  'username',
  'name',
  'email',
  'organizationalUnitId',
  'role',
  'status',
];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    username: queryParams.username || '',
    name: queryParams.name || '',
    email: queryParams.email || '',
    organizationalUnitId: isNaN(+queryParams.organizationalUnitId)
      ? null
      : +queryParams.organizationalUnitId,
    role: Object.values(UserRole).includes(queryParams.role as any)
      ? (queryParams.role as any)
      : null,
    status: Object.values(UserStatus).includes(queryParams.status as any)
      ? (queryParams.status as any)
      : null,
  };
}

/**
 * Page dedicated to consult the existing users:
 */
export function UsersPage() {
  const [t, i18n] = useTranslation('users');
  useSetDocumentTitle(t('users:usersPage.documentTitle'));
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const { getUsers } = useUsersApi();
  const formMethods = useLfForm({
    defaultValues: {
      username: '',
      name: '',
      email: '',
      organizationalUnitId: null as number | null,
      role: null as UserRole | null,
      status: null as UserStatus | null,
    },
    formValidatorName: 'getUsersFormValidator',
    i18nErrorMessagesPrefixes: 'users:usersPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const fetchUsers = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getUsers(queryParams)
        : null,
    [getUsers, isValid, queryParams]
  );
  const {
    isFetching,
    resource: users,
    setResource: setUsers,
    refetch: refetchUsers,
  } = useResource({
    fetchResource: fetchUsers,
    errorFetchingResourceMessage: t('users:usersPage.errorFetchingUsers'),
  });
  const {
    catalog: organizationalUnitsCatalog,
    isFetching: isFetchingOrganizationalUnitsCatalog,
  } = useCatalog('organizational-units');

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const {
      username,
      name,
      email,
      organizationalUnitId,
      role,
      status,
    } = getValues();
    const getUsersOptions: GetUsersFilterOptions = {
      username: username || undefined,
      name: name || undefined,
      email: email || undefined,
      organizationalUnitId: organizationalUnitId ?? undefined,
      role: role || undefined,
      status: (status || undefined) as UserStatus | undefined,
    };
    // Pressing "search" when nothing changed should refetch the users
    if (areSameQueryParams(getUsersOptions, queryParams, searchParams)) {
      refetchUsers();
    } else {
      setQueryParams(getUsersOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader title={t('users:usersPage.title')} />

      <SearchForm
        title={t('users:usersPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        <Grid item xs={12} md={4}>
          <FormTextField
            name="username"
            label={t('users:userFields.username')}
            size="small"
            inputProps={{
              maxLength: userFormSchemaPkg.USERNAME_MAX_LENGTH,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormTextField
            name="name"
            label={t('users:userFields.name')}
            size="small"
            inputProps={{
              maxLength: userFormSchemaPkg.DISPLAY_NAME_MAX_LENGTH,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormSelect
            name="organizationalUnitId"
            label={t('users:userFields.organizationalUnit')}
            size="small"
            items={[
              {
                value: null,
                label: (
                  <em>
                    {t('users:usersPage.searchForm.anyOrganizationalUnit')}
                  </em>
                ),
              },
              ...(organizationalUnitsCatalog
                ?.sort((u1, u2) =>
                  u1.name.localeCompare(u2.name, i18n.language)
                )
                .map((organizationalUnit) => ({
                  value: organizationalUnit.id,
                  label: organizationalUnit.name,
                })) ?? []),
            ]}
            fetching={isFetchingOrganizationalUnitsCatalog}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormTextField
            name="email"
            label={t('users:userFields.email')}
            size="small"
            inputProps={{
              maxLength: userFormSchemaPkg.EMAIL_MAX_LENGTH,
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormSelect
            name="role"
            label={t('users:userFields.role')}
            size="small"
            items={[
              {
                value: null,
                label: <em>{t('users:userRole.any')}</em>,
              },
              ...Object.values(UserRole).map((role) => ({
                value: role,
                label: t(`users:userRole.${role}`),
              })),
            ]}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormSelect
            name="status"
            label={t('users:userFields.status')}
            size="small"
            items={[
              {
                value: null,
                label: <em>{t('users:userStatus.any')}</em>,
              },
              ...Object.values(UserStatus).map((status) => ({
                value: status,
                label: t(`users:userStatus.${status}`),
              })),
            ]}
          />
        </Grid>
      </SearchForm>

      <UsersTable
        fetching={isFetching}
        users={users || []}
        setUsers={setUsers}
        refetchUsers={refetchUsers}
        downloadButton={
          <DownloadTableButton
            fileName={t('users:usersPage.dataTable.downloadFileName')}
            downloadUrl={`${USERS_URL}?${objectToQueryParamsString({
              ...queryParams,
              format: 'csv',
            })}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
