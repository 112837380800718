import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core';
import { indigo, teal } from '@material-ui/core/colors';
import { ActiveThemeVariant, ThemeVariant } from '../providers/ThemeProvider';

/**
 * Returns the theme given the type and language.
 */
export function theme(type: ActiveThemeVariant): Theme {
  return createMuiTheme(
    type === ThemeVariant.Light ? lightThemeOptions : darkThemeOptions
  );
}

// Light theme options
const lightThemeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: teal[500],
    },
    secondary: {
      main: indigo[500],
    },
  },
};

// Dark theme options
const darkThemeOptions: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: teal[400],
    },
    secondary: {
      main: indigo[400],
    },
    background: {
      paper: '#393939',
    },
  },
};
