import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { CheckingAccount } from '../checkingAccountsApi';
import { CheckingAccountRowActions } from './CheckingAccountRowActions';
import { Link } from '../../../components/Link';
import { replacePathParams, TAXPAYER_PATH } from '../../../navigation/paths';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { useCurrencyFormatter } from '../../../util/formatters';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

/**
 * Properties of the checking accounts table.
 */
export interface CheckingAccountsTableProps {
  fetching: boolean;
  checkingAccounts: CheckingAccount[];
  downloadButton: ReactNode;
}

/**
 * Table listing checking accounts.
 */
export function CheckingAccountsTable({
  fetching,
  checkingAccounts,
  downloadButton,
}: CheckingAccountsTableProps) {
  const [t] = useTranslation(['common', 'checkingAccounts']);
  const formatCurrency = useCurrencyFormatter();

  const columns: DataTableColumns<CheckingAccount> = {
    taxpayerId: {
      label: t('checkingAccounts:checkingAccountFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    name: {
      label: t('checkingAccounts:checkingAccountFields.taxpayerName'),
      value: ({ taxpayerName }) => taxpayerName,
    },
    debits: {
      align: 'right',
      label: t('checkingAccounts:checkingAccountPeriodFields.debits'),
      value: ({ vatFinal, interestFinal }) => vatFinal + interestFinal,
      format: (value, { vatCreditAvailable, paymentRefundableFinal }) =>
        value === 0 &&
        (vatCreditAvailable !== 0 || paymentRefundableFinal !== 0)
          ? ''
          : formatCurrency(value as number),
      sortable: false,
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t('checkingAccounts:checkingAccountPeriodFields.vatFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.interestFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('checkingAccounts:checkingAccountPeriodFields.credits'),
      value: ({ vatCreditAvailable, paymentRefundableFinal }) =>
        vatCreditAvailable + paymentRefundableFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
      sortable: false,
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatCreditAvailable !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatCreditAvailable)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.vatCreditAvailable'
                )}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.paymentRefundableFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <CheckingAccountRowActions checkingAccount={row} />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <DataTable
      title={t('checkingAccounts:checkingAccountsPage.dataTable.title')}
      rows={checkingAccounts}
      rowId={({ taxpayerId }) => taxpayerId}
      columns={columns}
      emptyMessage={t(
        'checkingAccounts:checkingAccountsPage.dataTable.emptyMessage'
      )}
      toolbarActionsLeft={downloadButton}
      fetching={fetching}
      defaultSortBy="taxpayerId"
      minWidth={800}
    />
  );
}
