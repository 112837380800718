import React from 'react';
import { useParams } from 'react-router-dom';
import { MainContent } from '../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../components/PaperDescriptionList';
import { useDateTimeFormatter } from '../../util/formatters';
import { useUser } from './useUser';
import { ActionableHeader } from '../../components/ActionableHeader';
import { useUserActions } from './useUserActions';
import { NotSpecified } from '../../components/NotSpecified';
import { useCatalog } from '../../providers/CatalogsProvider';
import { ExtraInfo } from '../../components/ExtraInfo';
import { UserStatus } from './usersApi';

/**
 * Page with user information.
 */
export function UserPage() {
  const [t] = useTranslation('users');
  const { username } = useParams<{ username: string }>();
  const { user, isFetching, notFound, setUser, refetch } = useUser(username);
  const formatDateTime = useDateTimeFormatter();
  useSetDocumentTitle(t('users:userPage.documentTitle', { username }));
  const actions = useUserActions(user, setUser, refetch);
  const {
    catalog: organizationalUnitsCatalog,
    isFetching: isFetchingOrganizationalUnitsCatalog,
  } = useCatalog('organizational-units');

  const name = user
    ? [user.firstName, user.surname].filter((str) => str).join(' ')
    : null;
  const displayName = user ? user.displayName ?? '' : null;

  // Login information fields
  const loginInformation: DescriptionListItem[] = [
    {
      key: 'username',
      label: t('users:userFields.username'),
      content: username,
    },
  ];

  // User information fields
  const userInformation: DescriptionListItem[] = [
    {
      key: 'name',
      label: t('users:userFields.name'),
      content: (
        <>
          {name || <NotSpecified>{t('users:nameNotSpecified')}</NotSpecified>}
          {name !== displayName && displayName && (
            <ExtraInfo>{user?.displayName}</ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'civil-id',
      label: t('users:userFields.civilId'),
      content: user?.civilId || (
        <NotSpecified>{t('users:civilIdNotSpecified')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'organizational-unit',
      label: t('users:userFields.organizationalUnit'),
      content:
        user &&
        (organizationalUnitsCatalog?.find(
          (organizationalUnit) =>
            organizationalUnit.id === user.organizationalUnitId
        )?.name ?? (
          <NotSpecified>
            {t('users:organizationalUnitNotSpecified')}
          </NotSpecified>
        )),
      fetching: isFetching || isFetchingOrganizationalUnitsCatalog,
    },
    {
      key: 'email',
      label: t('users:userFields.email'),
      content: user?.email || (
        <NotSpecified>{t('users:emailNotSpecified')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'created-date',
      label: t('users:userFields.createdDate'),
      content: formatDateTime(user?.createdDate),
      fetching: isFetching,
    },
    {
      key: 'changed-date',
      label: t('users:userFields.changedDate'),
      content: formatDateTime(user?.changedDate),
      fetching: isFetching,
    },
  ];

  // Role information fields
  const roleInformation: DescriptionListItem[] = [
    {
      key: 'role',
      label: t('users:userFields.role'),
      content:
        user &&
        (user.role ? (
          t(`users:userRole.${user.role}`)
        ) : (
          <NotSpecified>{t('users:roleNotSpecified')}</NotSpecified>
        )),
      fetching: isFetching,
    },
  ];

  return (
    <MainContent errorMessage={notFound && t('users:userNotFound')}>
      <ActionableHeader
        title={t('users:userPage.title')}
        titleId={username}
        actions={actions}
        status={user && t(`users:userStatus.${user.status}`)}
        statusVariant={user?.status === UserStatus.Active ? 'success' : 'error'}
      />

      <PaperDescriptionList
        title={t('users:loginInformation')}
        items={loginInformation}
      />
      <PaperDescriptionList
        title={t('users:userInformation')}
        items={userInformation}
      />
      <PaperDescriptionList
        title={t('users:roleInformation')}
        items={roleInformation}
      />
    </MainContent>
  );
}
