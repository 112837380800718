import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { PaymentRefund, usePaymentRefundsApi } from './paymentRefundsApi';
import { useLfForm } from '../../util/lfIntegration';
import { Form, FormTextField } from '../../components/Form';
import { ivageCommon } from '../../ivageCommon';
import { TitleId } from '../../components/TitleId';
import {Action, DialogActionButtons} from "../../components/Actions";

/**
 * Package in IVAGE common containing the void payment refund form schema.
 */
const voidPaymentRefundFormSchemaPkg = ivageCommon.feature.paymentrefund.schema;

/**
 * Properties of the void payment refund form.
 */
export interface VoidPaymentRefundFormProps {
  paymentRefund: PaymentRefund | null;
  open: boolean;

  setOpen(open: boolean): void;

  onSuccess(paymentRefund: PaymentRefund): void;

  onFailureDueToOutdatedUI(): void;
}

/**
 * Modal form used to void a payment refund.
 */
export function VoidPaymentRefundForm({
  paymentRefund,
  open,
  setOpen,
  onSuccess,
  onFailureDueToOutdatedUI,
}: VoidPaymentRefundFormProps) {
  const [t] = useTranslation(['common', 'paymentRefunds']);
  const { voidPaymentRefund } = usePaymentRefundsApi();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: { voidDescription: '' },
    formValidatorName: 'voidPaymentRefundFormValidator',
    i18nErrorMessagesPrefixes:
      'paymentRefunds:voidPaymentRefundForm.fieldErrors',
  });
  const { reset, getValues, formState } = formMethods;

  // Reset form whenever the payment refund changes or modal changes open state
  useEffect(() => {
    reset();
  }, [reset, paymentRefund, open]);

  // Close form modal
  function close() {
    setOpen(false);
  }

  async function onSubmit() {
    const voidPaymentRefundForm = getValues();
    try {
      const newPaymentRefund = await voidPaymentRefund(
        paymentRefund!.paymentRefundId,
        voidPaymentRefundForm
      );
      close();
      onSuccess(newPaymentRefund);
      enqueueSnackbar(
        t('paymentRefunds:paymentRefundVoidedSuccessfully', {
          id: paymentRefund!.paymentRefundId,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        close();
        onFailureDueToOutdatedUI();
        enqueueSnackbar(
          t('paymentRefunds:errorVoidingPaymentRefundDueToOutdatedUI', {
            id: paymentRefund!.paymentRefundId,
          }),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          t('paymentRefunds:errorVoidingPaymentRefund', {
            id: paymentRefund!.paymentRefundId,
          }),
          { variant: 'error' }
        );
      }
    }
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: `void-payment-refund-form-${paymentRefund?.paymentRefundId}-submit`,
      type: 'submit',
      label: t('paymentRefunds:voidPaymentRefundForm.void'),
      style: 'error',
      loading: formState.isSubmitting,
      disabled: !paymentRefund,
    },
    {
      id: `void-payment-refund-form-${paymentRefund?.paymentRefundId}-cancel`,
      label: t('formActions.cancel'),
      run: () => close(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={close}
      disableBackdropClick={formState.isDirty || formState.isSubmitting}
      disableEscapeKeyDown={formState.isDirty || formState.isSubmitting}
      aria-labelledby="void-payment-refund-form"
    >
      <Form onSubmit={onSubmit} {...formMethods}>
        <DialogTitle id="void-payment-refund-form">
          {t('paymentRefunds:voidPaymentRefundForm.title') + ' '}
          <TitleId titleId={paymentRefund?.paymentRefundId} />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Void description */}
            <Grid item xs={12}>
              <FormTextField
                name="voidDescription"
                label={t('paymentRefunds:paymentRefundFields.voidDescription')}
                multiline
                rows={5}
                inputProps={{
                  maxLength:
                    voidPaymentRefundFormSchemaPkg.VOID_DESCRIPTION_MAX_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
