import { useCallback, useContext } from 'react';
import { CatalogsContext } from './CatalogsContext';
import { useResource } from '../../util/useResource';
import { CatalogName, Catalogs, useCatalogsApi } from './catalogsApi';
import { useTranslation } from 'react-i18next';

/**
 * Catalog exposed as a resource.
 */
export interface CatalogResource<N extends CatalogName> {
  isFetching: boolean;
  catalog: Catalogs[N] | null;
}

/**
 * Hook to access the catalog with the given name. Each catalog is only fetched
 * once on a per-demand basis.
 */
export function useCatalog<N extends CatalogName>(
  catalogName: N
): CatalogResource<N> {
  const [t] = useTranslation('common');
  const { catalogPromises } = useContext(CatalogsContext);
  const { getCatalog } = useCatalogsApi();

  const fetchCatalog = useCallback(() => {
    if (!catalogPromises.current[catalogName]) {
      catalogPromises.current[catalogName] = new Promise((res, rej) =>
        getCatalog(catalogName)
          .catch((err) => {
            delete catalogPromises.current[catalogName];
            throw err;
          })
          .then(res, rej)
      ) as Promise<any>;
    }
    return catalogPromises.current[catalogName] as Promise<Catalogs[N]>;
  }, [catalogName, catalogPromises, getCatalog]);

  const { isFetching, resource: catalog } = useResource({
    fetchResource: fetchCatalog,
    errorFetchingResourceMessage: t(`catalogs.${catalogName}.errorFetching`),
  });

  return { isFetching, catalog };
}
