/**
 * Basic conversion of an LF path to a "name" path. This will fail for paths
 * containing dots or escaped characters, which we'll make sure not to use
 * anywhere.
 */
export function lfPathToNamePath(path: string): string {
  return path.slice(1).replace(/\//g, '.');
}

/**
 * Basic conversion of a "name" path to an LF path. This will fail for paths
 * containing dots or escaped characters, which we'll make sure not to use
 * anywhere.
 */
export function namePathToLfPath(name: string): string {
  return `/${name.replace(/\./g, '/')}`;
}
