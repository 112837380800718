import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useState,
} from 'react';
import {
  createStyles,
  fade,
  IconButton,
  makeStyles,
  Theme,
  Grow,
  ButtonBase,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => {
  const CURLY_BRACE_WIDTH = theme.spacing(3);

  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    brace: {
      alignSelf: 'stretch',
      '& .top, & .bottom': {
        width: CURLY_BRACE_WIDTH,
        position: 'relative',
        height: '50%',
        '&::before, &::after': {
          boxSizing: 'border-box',
          content: '""',
          position: 'absolute',
          display: 'block',
          height: '50%',
          width: CURLY_BRACE_WIDTH / 2,
        },
      },
      '& .top::before': {
        bottom: 0,
        left: 1,
        borderRight: `2px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderBottomRightRadius: 10,
      },
      '& .top::after': {
        top: 0,
        right: 1,
        borderLeft: `2px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: 10,
      },
      '& .bottom::before': {
        top: 0,
        left: 1,
        borderRight: `2px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderTopRightRadius: 10,
      },
      '& .bottom::after': {
        bottom: 0,
        right: 1,
        borderLeft: `2px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderBottomLeftRadius: 10,
      },
    },
    braceContent: {
      display: 'flex',
      '@media print': {
        breakInside: 'avoid',
      },
    },
    expandButton: {
      '@media print': {
        display: 'none',
      },
    },
    expandIcon: {
      fontSize: '1rem',
      color: theme.palette.grey[500],
    },
    braceButton: {
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(-0.5),
      padding: theme.spacing(0.5),
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        backgroundColor: fade(
          theme.palette.action.active,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    leftSideCollapsed: {
      marginRight: theme.spacing(0.5),
      '@media print': {
        marginRight: theme.spacing(2),
      }
    },
    leftSideExpanded: {
      marginRight: theme.spacing(2),
    },
    rightSide: {
      margin: theme.spacing(1, 0, 1, 1),
      textAlign: 'left',
    },
    hideInPrint: {
      '@media print': {
        display: 'none',
      },
    },
    showInPrint: {
      display: 'none',
      '@media print': {
        display: 'flex',
      },
    },
  });
});

interface CustomCurlyBraceProps {
  leftSide?: ReactNode;
  rightSide?: ReactNode;
  startExpanded?: boolean;
  expandButtonClassName?: string;
}

/**
 * Properties of the curly brace component.
 */
export type CurlyBraceProps = CustomCurlyBraceProps &
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * Component drawing a left-facing curly brace using borders.
 */
export function CurlyBrace({
  leftSide,
  rightSide,
  startExpanded = false,
  expandButtonClassName,
  className,
  ...props
}: CurlyBraceProps) {
  const classes = useStyles();
  const [showButton, setShowButton] = useState(!startExpanded);
  const [showBrace, setShowBrace] = useState(startExpanded);

  const braceContent = (
    <>
      <ButtonBase
        className={classes.braceButton}
        onClick={() => setShowBrace(false)}
        focusRipple
      >
        <div className={`${classes.brace}`}>
          <div className="top" />
          <div className="bottom" />
        </div>
      </ButtonBase>
      {rightSide && <div className={classes.rightSide}>{rightSide}</div>}
    </>
  );

  return (
    <div className={`${classes.root} ${className || ''}`} {...props}>
      {leftSide && (
        <div
          className={
            showButton ? classes.leftSideCollapsed : classes.leftSideExpanded
          }
        >
          {leftSide}
        </div>
      )}
      {showButton && (
        <IconButton
          className={`${expandButtonClassName || ''} ${classes.expandButton}`}
          size="small"
          onClick={() => setShowBrace(true)}
        >
          <Add className={classes.expandIcon} />
        </IconButton>
      )}
      <Grow
        in={showBrace}
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
        unmountOnExit
      >
        <div className={`${classes.braceContent} ${classes.hideInPrint}`}>
          {braceContent}
        </div>
      </Grow>
      <div className={`${classes.braceContent} ${classes.showInPrint}`}>
        {braceContent}
      </div>
    </div>
  );
}
