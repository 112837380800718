import React from 'react';
import { FormNumberField, FormNumberFieldProps } from './FormNumberField';
import { ivageCommonUtil } from '../../ivageCommon';

/**
 * Properties of the assessment number field.
 */
export type FormAssessmentNumberFieldProps = FormNumberFieldProps;

/**
 * Assessment number field to be used within an RHF form context.
 */
export function FormAssessmentNumberField({
  isNumericString = true,
  allowLeadingZeroes = true,
  inputProps = {},
  ...otherProps
}: FormAssessmentNumberFieldProps) {
  return (
    <FormNumberField
      isNumericString={isNumericString}
      allowLeadingZeros={allowLeadingZeroes}
      min={0}
      decimalSeparator={false}
      inputProps={{
        ...inputProps,
        maxLength: ivageCommonUtil.ASSESSMENT_NUMBER_LENGTH,
      }}
      {...otherProps}
    />
  );
}
