import { max, min } from 'date-fns';
import { VatActivity } from '../taxpayersApi';

/**
 * Returns the maximum allowed date for the `startDate` of a taxpayer given its
 * list of VAT activities.
 */
export function taxpayerMaxStartDate(activities: VatActivity[]): Date | null {
  return activities.length > 0
    ? min(activities.map(({ startDate }) => startDate))
    : null;
}

/**
 * Returns the minimum allowed date for the `endDate` of a taxpayer given its
 * `startDate` and list of VAT activities.
 */
export function taxpayerMinEndDate(
  taxpayerStartDate: Date,
  activities: VatActivity[]
): Date {
  return max([
    taxpayerStartDate,
    ...activities.map(({ startDate, endDate }) =>
      endDate ? endDate : startDate
    ),
  ]);
}
