import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useProfile } from '../../providers/ProfileProvider';
import { User, UserStatus } from './usersApi';

/**
 * Hook used to check whether the logged in user can edit a given user.
 */
export function useCanEditUser(user: User | null): boolean {
  const { hasRole } = useProfile();
  return (
    !!user &&
    user.status !== UserStatus.Suspended &&
    (!user.role || hasRole(user.role))
  );
}

/**
 * Hook used to check whethen the logged in user can change a given user's
 * status.
 */
export function useCanChangeUserStatus(user: User | null): boolean {
  const { hasRole } = useProfile();
  const { user: loggedInUser } = useAuthentication();

  return (
    !!user &&
    // Cannot suspend logged in user
    user.username !== loggedInUser?.username &&
    (!user.role || hasRole(user.role))
  );
}
