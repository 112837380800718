import { useContext } from 'react';
import {
  ReauthenticationApi,
  ReauthenticationContext,
} from './ReauthenticationContext';

/**
 * Hook exposing the reauthentication API.
 */
export function useReauthentication(): ReauthenticationApi {
  return useContext(ReauthenticationContext);
}
