import React from 'react';
import { VatReturn } from '../vatReturnsApi';
import { ActionButtons } from '../../../components/Actions';
import { useVatReturnActions } from '../useVatReturnActions';

/**
 * Properties for the VAT return row actions.
 */
interface VatReturnRowActionsProps {
  vatReturn: VatReturn;
  onVoid: () => void;
}

/**
 * Actions in a VAT return row.
 */
export function VatReturnRowActions({
  vatReturn,
  onVoid,
}: VatReturnRowActionsProps) {
  return (
    <ActionButtons
      actions={useVatReturnActions(vatReturn, onVoid)!}
      variant="outlined"
      size="small"
    />
  );
}
