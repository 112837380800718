import { useCallback } from 'react';
import { useFetchFromApi } from '../FetchFromApiProvider';
import { TaxablePeriod } from '../../common';

/**
 * Available catalogs.
 */
export interface Catalogs {
  divisions: DivisionsCatalog;
  sectors: SectorsCatalog;
  'tax-offices': TaxOfficesCatalog;
  'organizational-units': OrganizationalUnitsCatalog;
  banks: BanksCatalog;
  'tax-periods': TaxPeriodsCatalog;
  'vat-indicators': VatIndicatorsCatalog;
  configs: ConfigsCatalog;
}

/**
 * Divisions catalog.
 */
export interface DivisionsCatalog {
  provinces: Division[];
  districts: Division[];
  municipalities: Division[];
}

/**
 * Type of a division.
 */
export interface Division {
  id: number;
  name: string;
  parentID: number;
}

/**
 * Sectors catalog.
 */
export type SectorsCatalog = Sector[];

/**
 * Type of a sector.
 */
export interface Sector {
  id: number;
  name: string;
}

/**
 * Tax offices catalog.
 */
export type TaxOfficesCatalog = TaxOffice[];

/**
 * Type of a tax office.
 */
export interface TaxOffice {
  id: number;
  name: string;
}

/**
 * Organizational units catalog.
 */
export type OrganizationalUnitsCatalog = OrganizationalUnit[];

/**
 * Type of an organizational unit.
 */
export interface OrganizationalUnit {
  id: number;
  name: string;
}

/**
 * Banks catalog.
 */
export type BanksCatalog = Bank[];

/**
 * Type of a bank.
 */
export interface Bank {
  id: number;
  name: string;
}

/**
 * Tax periods catalog.
 */
export type TaxPeriodsCatalog = TaxPeriod[];

/**
 * Tax period.
 */
export interface TaxPeriod {
  id: number;
  taxableYear: number;
  taxablePeriod: TaxablePeriod;
  startDate: Date;
  endDate: Date;
  firstDay: Date;
  lastDay: Date;
  generalFactor: number;
}

/**
 * VAT indicators catalog.
 */
export type VatIndicatorsCatalog = VatIndicatorMetadata[];

/**
 * VAT indicator.
 */
export interface VatIndicatorMetadata {
  vatIndicatorId: string;
  table: {
    columns: Record<string, VatIndicatorColumn>,
    defaultSortBy: string;
    rowId: string;
  }
}

/**
 * VAT indicator column.
 */
export interface VatIndicatorColumn {
  type: VatIndicatorColumnType;
  defaultSortDirection: VatIndicatorColumnDefaultSortDirection;
}

/**
 * Type of a VAT indicator column.
 */
export enum VatIndicatorColumnType {
  String = 'STRING',
  Integer = 'INTEGER',
  Currency = 'CURRENCY',
  Date = 'DATE',
  DateTime = 'DATETIME',
}

/**
 * Default sort direction of a VAT indicator column.
 */
export enum VatIndicatorColumnDefaultSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/**
 * Configs catalog.
 */
export interface ConfigsCatalog {
  vatReturnInitialTaxableYearPeriod: Date;
  beginningDate: Date;
}

/**
 * Available catalog names.
 */
export type CatalogName = keyof Catalogs;

/**
 * URL to access the catalogs.
 */
export const CATALOGS_URL = '/api/catalogs';

/**
 * Transforms a tax period JSON as given by the API into our internal tax period
 * representation.
 */
export function jsonToTaxPeriod(taxPeriodJson: any): TaxPeriod {
  return {
    ...taxPeriodJson,
    startDate: new Date(taxPeriodJson.startDate),
    endDate: new Date(taxPeriodJson.endDate),
    firstDay: new Date(taxPeriodJson.firstDay),
    lastDay: new Date(taxPeriodJson.lastDay),
  };
}

/**
 * Hook exposing the catalogs API.
 */
export function useCatalogsApi() {
  const { getJson } = useFetchFromApi();

  /**
   * Gets a catalog with name `catalogName`.
   */
  const getCatalog = useCallback(
    async <N extends CatalogName>(catalogName: N): Promise<Catalogs[N]> => {
      // TODO: Use actual catalog
      if (catalogName === 'configs') {
        return Promise.resolve({
          vatReturnInitialTaxableYearPeriod: new Date(2020, 0, 1),
          beginningDate: new Date(1968, 11, 10),
        } as Catalogs[N]);
      }
      if (catalogName === 'tax-periods') {
        return (await getJson(`/api/${catalogName}`)).map(jsonToTaxPeriod);
      }
      return getJson(`${CATALOGS_URL}/${catalogName}`);
    },
    [getJson]
  );

  return { getCatalog };
}
