/**
 * Function that checks whether a given `object` represents the same query
 * params as `queryParams`.
 */
export function areSameQueryParams(
  object: Record<string, any>,
  queryParams: Record<string, string>,
  allowedParams?: string[]
): boolean {
  for (const param of Object.keys(object)) {
    if (!allowedParams || allowedParams.indexOf(param) !== -1) {
      if (
        (object[param] == null && queryParams[param] != null) ||
        (object[param] != null && String(object[param]) !== queryParams[param])
      ) {
        return false;
      }
    }
  }
  return true;
}
