import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { Taxpayer, useTaxpayersApi } from './taxpayersApi';
import { useTranslation } from 'react-i18next';

/**
 * Taxpayer as a resource.
 */
export interface TaxpayerResource {
  isFetching: boolean;
  taxpayer: Taxpayer | null;
  notFound: boolean;
  setTaxpayer: Dispatch<SetStateAction<Taxpayer | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a taxpayer from the API, if no `taxpayerId` is passed this
 * hook does nothing.
 */
export function useTaxpayer(taxpayerId?: string): TaxpayerResource {
  const [t] = useTranslation('taxpayers');
  const { getTaxpayer } = useTaxpayersApi();
  const getTaxpayerCb = useCallback(
    () => (taxpayerId ? getTaxpayer(taxpayerId) : null),
    [getTaxpayer, taxpayerId]
  );
  const {
    isFetching,
    resource: taxpayer,
    notFound,
    setResource: setTaxpayer,
    refetch,
  } = useResource({
    fetchResource: getTaxpayerCb,
    errorFetchingResourceMessage: t('taxpayers:errorFetchingTaxpayer', {
      id: taxpayerId ?? '',
    }),
  });

  return { isFetching, taxpayer, notFound, setTaxpayer, refetch };
}
