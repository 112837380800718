import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { DataTable, DataTableProps } from './DataTable';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: '0 !important',
      marginBottom: theme.spacing(2),
      borderStyle: 'dashed !important',
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        borderRadius: theme.shape.borderRadius,
      },
      '@media print': {
        breakInside: 'initial !important',
      }
    },
  })
);

/**
 * Properties of the inner data table.
 */
export type InnerDataTableProps<R extends object> = DataTableProps<R>;

/**
 * Data table meant to be used within the "expanded row" of another data table.
 */
export function InnerDataTable<R extends object>({
  allowFilter = false,
  allowPagination = false,
  dense = true,
  rowsPerPage = Number.MAX_VALUE,
  paperProps,
  ...otherProps
}: InnerDataTableProps<R>) {
  const classes = useStyles();

  return (
    <DataTable
      allowFilter={allowFilter}
      allowPagination={allowPagination}
      dense={dense}
      rowsPerPage={rowsPerPage}
      {...otherProps}
      paperProps={{
        className: `${classes.paper} ${paperProps?.className ?? ''}`,
        variant: paperProps?.variant ?? 'outlined',
      }}
    />
  );
}
