import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainContent } from '../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../util/formatters';
import { usePayment } from './usePayment';
import { ActionableHeader } from '../../components/ActionableHeader';
import { usePaymentActions } from './usePaymentActions';
import { NotSpecified } from '../../components/NotSpecified';
import {
  replacePathParams,
  TAXPAYER_PATH,
  USER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../navigation/paths';
import { Link } from '../../components/Link';
import { useCatalog } from '../../providers/CatalogsProvider';
import { ExtraInfo } from '../../components/ExtraInfo';
import { PaymentStatus } from './paymentsApi';
import { UserRole } from '../../providers/ProfileProvider';
import { VoidPaymentForm } from './VoidPaymentForm';

/**
 * Page with payment information.
 */
export function PaymentPage() {
  const [t, i18n] = useTranslation(['common', 'payments']);
  const { id } = useParams<{ id: string }>();
  useSetDocumentTitle(t('payments:paymentPage.documentTitle', { id }));
  const { payment, isFetching, notFound, setPayment, refetch } = usePayment(
    +id
  );
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const actions = usePaymentActions(payment, () => setIsVoidFormOpen(true));
  const {
    catalog: banksCatalog,
    isFetching: isFetchingBanksCatalog,
  } = useCatalog('banks');

  // Assessment information fields
  const assessmentInformation: DescriptionListItem[] = [
    {
      key: 'assessment-number',
      label: t('payments:paymentFields.assessmentNumber'),
      content: payment?.assessmentNumber ? (
        <>
          <Link
            to={replacePathParams(VAT_ASSESSMENT_PATH, {
              id: payment.assessmentNumber,
            })}
          >
            {payment.assessmentNumber}
          </Link>
          {payment?.assessmentNumberInserted &&
            payment.assessmentNumber !== payment.assessmentNumberInserted && (
              <ExtraInfo>
                {`${t('payments:paymentFields.assessmentNumberInserted')}: `}
                <Link
                  to={replacePathParams(VAT_ASSESSMENT_PATH, {
                    id: payment.assessmentNumberInserted,
                  })}
                >
                  {payment.assessmentNumberInserted}
                </Link>
              </ExtraInfo>
            )}
        </>
      ) : (
        <NotSpecified>{t('payments:noAssessmentNumber')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxpayer-id',
      label: t('payments:paymentFields.taxpayerId'),
      content: payment && (
        <>
          <Link
            to={replacePathParams(TAXPAYER_PATH, {
              id: payment.taxpayerId,
            })}
          >
            {payment.taxpayerId}
          </Link>
          {payment.taxpayerName && (
            <ExtraInfo>{payment.taxpayerName}</ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxable-year-period',
      label: `${t('payments:paymentFields.taxableYear')}/${t(
        'payments:paymentFields.taxablePeriod'
      ).toLocaleLowerCase(i18n.language)}`,
      content: `${payment?.taxableYear}/${payment?.taxablePeriod}`,
      fetching: isFetching,
    },
  ];

  // Payment information fields
  const paymentInformation: DescriptionListItem[] = [
    {
      key: 'payment-date',
      label: t('payments:paymentFields.paymentDate'),
      content: formatDate(payment?.paymentDate),
      fetching: isFetching,
    },
    {
      key: 'amount',
      label: t('payments:paymentFields.amount'),
      content: payment && (
        <>
          {formatCurrency(payment.amount)}
          {payment.transactionMethod && (
            <ExtraInfo>
              {t(
                `transactionMethod.${payment.transactionMethod}`
              ).toLocaleLowerCase(i18n.language)}
            </ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'bank',
      label: t('payments:paymentFields.bank'),
      content:
        payment &&
        (banksCatalog?.find((bank) => bank.id === payment.bankId)?.name ?? (
          <NotSpecified>{t('payments:bankNotSpecified')}</NotSpecified>
        )),
      fetching: isFetching || isFetchingBanksCatalog,
    },
    {
      key: 'created-date',
      label: t('payments:paymentFields.createdDate'),
      content: payment && (
        <>
          {formatDateTime(payment.createdDate)}
          <ExtraInfo>
            {t('payments:paymentFields.createdBy').toLocaleLowerCase(
              i18n.language
            )}{' '}
            {
              <Link
                to={replacePathParams(USER_PATH, {
                  username: payment.createdBy,
                })}
                role={UserRole.Manager}
              >
                {payment.createdBy}
              </Link>
            }
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'changed-date',
      label: t('payments:paymentFields.changedDate'),
      content: payment && (
        <>
          {formatDateTime(payment.changedDate)}
          {payment.status === PaymentStatus.Voided && payment.changedBy && (
            <ExtraInfo>
              {t('payments:paymentFields.changedBy').toLocaleLowerCase(
                i18n.language
              )}{' '}
              {
                <Link
                  to={replacePathParams(USER_PATH, {
                    username: payment.changedBy,
                  })}
                  role={UserRole.Manager}
                >
                  {payment.changedBy}
                </Link>
              }
            </ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'void-description',
      label: t('payments:paymentFields.voidDescription'),
      content: payment?.voidDescription || (
        <NotSpecified>{t('payments:voidDescriptionNotSpecified')}</NotSpecified>
      ),
      preserveWhiteSpaces: true,
      hidden: isFetching || payment!.status !== PaymentStatus.Voided,
    },
  ];

  return (
    <MainContent errorMessage={notFound && t('payments:paymentNotFound')}>
      <ActionableHeader
        title={t('payments:paymentPage.title')}
        titleId={id}
        status={payment && t(`payments:paymentStatus.${payment.status}`)}
        statusVariant={
          payment?.status === PaymentStatus.Voided ? 'error' : 'success'
        }
        actions={actions}
      />

      <VoidPaymentForm
        payment={payment}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newPayment) => setPayment(newPayment)}
        onFailureDueToOutdatedUI={refetch}
      />

      <PaperDescriptionList
        title={t('payments:assessmentInformation')}
        items={assessmentInformation}
      />
      <PaperDescriptionList
        title={t('payments:paymentInformation')}
        items={paymentInformation}
      />
    </MainContent>
  );
}
