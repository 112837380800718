import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import {
  createStyles,
  Fade,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  generatePassword,
  PasswordGenerationOptions,
} from '../../util/generatePassword';
import {
  Autorenew,
  FileCopy,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { FormTextField, FormTextFieldProps } from './FormTextField';
import { useInputRef } from './util/useInputRef';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adornment: {
      '@media print': {
        display: 'none',
      },
    },
  })
);

/**
 * Our common's password field properties.
 */
interface CustomFormPasswordFieldProps {
  includeVisibilityToggle?: boolean;
  includeCopy?: boolean;
  includeRandomGenerator?: boolean;
  generationOptions?: PasswordGenerationOptions;
  onGeneratePassword?: (generatedPassword: string) => void;
  actionsRef?: MutableRefObject<FormPasswordFieldActions | undefined>;
  extraEndAdornment?: ReactNode;
}

/**
 * Actions that a parent common can call on our custom passwordField.
 */
export interface FormPasswordFieldActions {
  focus: () => void;
  setVisibility: (visible: boolean) => void;
  generate: () => void;
  copy: () => Promise<boolean>;
}

/**
 * Properties of the password field.
 */
export type FormPasswordFieldProps = FormTextFieldProps &
  CustomFormPasswordFieldProps;

// Detect support for used clipboard API
const supportsWriteToClipboard = !!navigator?.clipboard?.writeText;

/**
 * Password field to be used within an RHF form context with a button to toggle
 * password visibility and other features.
 */
export function FormPasswordField({
  includeVisibilityToggle = true,
  includeCopy,
  includeRandomGenerator,
  generationOptions,
  onGeneratePassword,
  extraEndAdornment,
  actionsRef,
  inputRef,
  ...otherProps
}: FormPasswordFieldProps) {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const passwordFieldRef = useInputRef(inputRef);

  if (!supportsWriteToClipboard) {
    includeCopy = false;
  }

  function toggleShowPassword() {
    setShowPassword((show) => !show);
  }

  const focusImpl = useCallback(() => {
    passwordFieldRef.current!.focus();
  }, [passwordFieldRef]);

  const copyImpl = useCallback(
    async function () {
      const password = passwordFieldRef.current!.value;
      try {
        await navigator.clipboard.writeText(password);
        enqueueSnackbar(t('passwordField.copiedSuccessfully'), {
          variant: 'info',
        });
        return true;
      } catch (err) {
        enqueueSnackbar(t('passwordField.errorCopying'), { variant: 'error' });
        return false;
      }
    },
    [enqueueSnackbar, t, passwordFieldRef]
  );

  const generateImpl = useCallback(
    function () {
      setShowPassword(true);
      onGeneratePassword &&
        onGeneratePassword(generatePassword(generationOptions));
    },
    [onGeneratePassword, generationOptions]
  );

  useImperativeHandle(
    actionsRef,
    () => ({
      focus: focusImpl,
      setVisibility: (visible: boolean) => setShowPassword(visible),
      generate: generateImpl,
      copy: copyImpl,
    }),
    [focusImpl, generateImpl, copyImpl]
  );

  return (
    <FormTextField
      {...otherProps}
      inputRef={passwordFieldRef}
      type={showPassword ? 'text' : 'password'}
      extraEndAdornment={
        (includeRandomGenerator ||
          includeCopy ||
          includeVisibilityToggle ||
          extraEndAdornment) && (
          <InputAdornment className={classes.adornment} position="end">
            <Fade in={includeRandomGenerator} mountOnEnter={true}>
              <Tooltip title={t('passwordField.actions.generate')!} arrow>
                <IconButton
                  aria-label={t('passwordField.actions.generate')}
                  onClick={generateImpl}
                  edge="end"
                  data-cy="password-field-action-generate"
                >
                  <Autorenew />
                </IconButton>
              </Tooltip>
            </Fade>
            <Fade in={includeCopy} mountOnEnter={true}>
              <Tooltip title={t('passwordField.actions.copy')!} arrow>
                <IconButton
                  aria-label={t('passwordField.actions.copy')}
                  onClick={copyImpl}
                  edge="end"
                  data-cy="password-field-action-copy"
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>
            </Fade>
            <Fade in={includeVisibilityToggle} mountOnEnter={true}>
              <Tooltip
                title={
                  showPassword
                    ? t('passwordField.actions.hide')!
                    : t('passwordField.actions.show')!
                }
                arrow
              >
                <IconButton
                  aria-label={
                    showPassword
                      ? t('passwordField.actions.hide')
                      : t('passwordField.actions.show')
                  }
                  onClick={toggleShowPassword}
                  edge="end"
                  data-cy="password-field-action-toggle-show"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
            </Fade>
            {extraEndAdornment}
          </InputAdornment>
        )
      }
    />
  );
}
