import React, { Suspense } from 'react';
import {
  Route as RouterRoute,
  RouteProps as RouterRouteProps,
} from 'react-router-dom';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { FallbackErrorPage } from '../features/FallbackError';
import { PageLoading } from '../components/PageLoading';

/**
 * Route props.
 */
export type RouteProps = Omit<
  RouterRouteProps,
  'component' | 'render' | 'path'
> & { path?: string };

/**
 * Route which wraps its content in a "page loading" suspense itself wrapped in
 * a "fallback error page" error boundary.
 */
export function Route({ path, children, ...props }: RouteProps) {
  return (
    <RouterRoute path={path} {...props}>
      {/* Providing a `key` per path makes sure that error boundaries aren't
          reused across routes with different paths, meaning that if one route
          crashes we can still navigate to others */}
      <ErrorBoundary fallback={<FallbackErrorPage />} key={path}>
        <Suspense fallback={<PageLoading />}>{children}</Suspense>
      </ErrorBoundary>
    </RouterRoute>
  );
}
