/**
 * Converts the date to UTC while representing the same date (i.e. when calling
 * `toISOString()` on the date, we should get the date that was picked).
 */
export function localDateToSameDateInUTC(date: Date): Date {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}

/**
 * Returns the date as an ISO date string (no time information).
 */
export function toISODateString(date: Date): string {
  return date.toISOString().substring(0, 10);
}
