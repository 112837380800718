import React, { ReactNode, useRef } from 'react';
import {
  CircularProgress,
  createStyles,
  Fade,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { resolveObject } from '../../util/resolveObject';
import { useDisabledFieldStyles } from './ReadOnlyField';
import { triggerNames } from '../../util/triggerNames';
import { scrollAndFocus } from './util/scrollAndFocus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // The icon needs to be left of the select arrow
    fetchingIcon: {
      marginRight: theme.spacing(3),
    },
    selectIcon: {
      '@media print': {
        display: 'none',
      }
    },
  })
);

/**
 * Properties of the form select component.
 */
export interface FormSelectProps extends FormControlProps {
  name: string;
  label?: ReactNode;
  items: FormSelectItem[];
  fetching?: boolean;
  rules?: RegisterOptions;
  onChangeTriggers?: string[];
  helperText?: ReactNode;
}

/**
 * Item of the form select component.
 */
export interface FormSelectItem {
  /**
   * Id to use as key, `value` is used as key when an `id` is not provided.
   */
  id?: string | number;
  value: any;
  label?: ReactNode;
  disabled?: boolean;
}

// Counter used to generate unique ids for each select.
let formSelectIdCounter = 0;

/**
 * Select to be used within an RHF form context.
 */
export function FormSelect({
  name,
  label,
  items,
  fetching,
  rules,
  disabled,
  variant = disabled ? 'filled' : 'outlined',
  fullWidth = true,
  helperText,
  onChange,
  onChangeTriggers,
  onBlur,
  ...otherProps
}: FormSelectProps) {
  const classes = { ...useStyles(), ...useDisabledFieldStyles() };
  const { control, errors, formState, trigger } = useFormContext();
  const selectRef = useRef<{ focus(): void; node: HTMLSelectElement }>(null);

  const shouldDisable = disabled || fetching || formState.isSubmitting;
  const error = resolveObject(errors, name);

  const id = useRef(formSelectIdCounter++);
  const inputLabelId = `form-select-${name}-${id.current}`;
  const helperTextId = `form-select-${name}-text-${id.current}`;

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      disabled={shouldDisable}
      {...otherProps}
      error={!!error}
    >
      <InputLabel id={inputLabelId} aria-describedby={helperTextId}>
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        onFocus={() => scrollAndFocus(selectRef)}
        render={({
          onChange: controllerOnChange,
          onBlur: controllerOnBlur,
          value,
          name,
          ref,
        }) => (
          <Select
            label={label}
            labelId={inputLabelId}
            name={name}
            value={value == null || fetching ? '' : value}
            inputRef={selectRef}
            ref={ref}
            onChange={(evt) => {
              controllerOnChange(evt);
              onChange?.(evt as any);
              onChangeTriggers &&
                triggerNames(formState, trigger, ...onChangeTriggers);
            }}
            onBlur={(evt) => {
              controllerOnBlur();
              onBlur?.(evt as any);
            }}
            classes={{
              disabled: classes.disabledField,
              icon: classes.selectIcon,
            }}
            endAdornment={
              fetching && (
                <InputAdornment position="end" className={classes.fetchingIcon}>
                  <Fade in={fetching}>
                    <CircularProgress size={20} />
                  </Fade>
                </InputAdornment>
              )
            }
          >
            {items.map((item) => (
              <MenuItem
                key={item.id ?? item.value}
                value={item.value}
                disabled={item.disabled}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {(error || helperText) && (
        <FormHelperText id={helperTextId}>
          {error?.message ?? helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
