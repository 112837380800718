import { TaxablePeriod } from '../../common';
import { toISODateString } from '../../util/dateUtils';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';
import { useCallback } from 'react';

/**
 * Type of a VAT return.
 */
export interface VatReturn {
  taxReturnId: number;
  taxpayerId: string;
  taxpayerName: string;
  taxableYear: number;
  taxablePeriod: TaxablePeriod;
  deliveryDate: Date;
  origin: VatReturnOrigin;
  vatTotal: number;
  vatCredit: number;
  status: VatReturnStatus;
  assessmentNumber: string | null;
  createdDate: Date;
  createdBy: string;
  voidDescription: string;
}

/**
 * Status of a VAT return.
 */
export enum VatReturnStatus {
  Effective = 'EFFECTIVE',
  Replaced = 'REPLACED',
  Voided = 'VOIDED',
}

/**
 * Origin of a VAT return.
 */
export enum VatReturnOrigin {
  Normal = 'NORMAL',
  Rectification = 'RECTIFICATION',
  Automatic = 'AUTOMATIC',
}

/**
 * Options used to get VAT returns.
 */
export interface GetVatReturnsFilterOptions {
  taxpayerId?: string;
  taxableYear?: number;
  taxablePeriod?: TaxablePeriod;
  status?: VatReturnStatus;
  latestPerTaxableYearPeriod?: boolean;
}

/**
 * Form fields used to void a VAT return.
 */
export interface VoidVatReturnForm {
  voidDescription: string;
}

/**
 * Representation of the VAT return form.
 */
export interface VatReturnForm {
  taxpayerId: string;
  origin: VatReturnOrigin;
  taxableYear: number | null;
  taxablePeriod: TaxablePeriod | null;
  deliveryDate: Date | null;
  // Representative ------------------------------------------------------------
  interestedName: string;
  // Debt determination/accrued ------------------------------------------------
  generalFactor: number | null;
  generalBase1: number | null;
  generalRate1: number | null;
  generalQuota1: number | null;
  generalBase2: number | null;
  generalRate2: number | null;
  generalQuota2: number | null;
  generalBase3: number | null;
  generalRate3: number | null;
  generalQuota3: number | null;
  interestOnLatePaymentBase: number | null;
  interestOnLatePaymentType: number | null;
  interestOnLatePaymentQuota: number | null;
  surchargeABase: number | null;
  surchargeAType: number | null;
  surchargeAQuota: number | null;
  surchargeBBase: number | null;
  surchargeBType: number | null;
  surchargeBQuota: number | null;
  intracommunityAcquisitionsBase: number | null;
  intracommunityAcquisitionsQuota: number | null;
  totalAccrued: number;
  // Debt determination/deductible ---------------------------------------------
  internalOperationsQuotas: number | null;
  importQuotas: number | null;
  intercommunityAcquisitions: number | null;
  investmentGoodsAcquisitions: number | null;
  investmentsCompensation: number | null;
  previousPeriodsCompensation: number | null;
  totalDeductible: number;
  // Debt determination/totals -------------------------------------------------
  vatTotal: number;
  vatCredit: number;
}

/**
 * Constants used by a VAT return form.
 */
export interface VatReturnConstants {
  generalFactor: number;
  generalRate1: number;
  generalRate2: number;
  generalRate3: number;
  interestOnLatePaymentType: number;
  surchargeAType: number;
  surchargeBType: number;
}

/**
 * URL to access VAT returns.
 */
export const VAT_RETURNS_URL = '/api/vat-returns';

/**
 * URL to access VAT return constants.
 */
export const VAT_RETURN_CONSTANTS_URL = '/api/vat-return-constants';

/**
 * Transforms a VAT return JSON as given by the API into our internal VAT return
 * representation.
 */
function jsonToVatReturn(vatReturnJson: any): VatReturn {
  return {
    ...vatReturnJson,
    deliveryDate: new Date(vatReturnJson.deliveryDate),
    createdDate: new Date(vatReturnJson.createdDate),
  };
}

/**
 * Hook exposing the VAT returns API.
 */
export function useVatReturnsApi() {
  const { getJson, postJson, putJson } = useFetchFromApi();

  /**
   * Returns a list of all VAT returns filtered by the provided options.
   */
  const getVatReturns = useCallback(
    async (
      filterOptions: GetVatReturnsFilterOptions = {}
    ): Promise<VatReturn[]> =>
      (await getJson(VAT_RETURNS_URL, { params: filterOptions })).map(
        jsonToVatReturn
      ),
    [getJson]
  );

  /**
   * Gets the VAT return with the provided id.
   */
  const getVatReturn = useCallback(
    async (taxReturnId: number): Promise<VatReturn> =>
      jsonToVatReturn(
        await getJson(`${VAT_RETURNS_URL}/${encodeURIComponent(taxReturnId)}`)
      ),
    [getJson]
  );

  /**
   * Gets the form of the VAT return with the provided id.
   */
  const getVatReturnForm = useCallback(
    async (taxReturnId: number): Promise<VatReturnForm> => {
      const vatReturnForm = await getJson(
        `${VAT_RETURNS_URL}/${encodeURIComponent(taxReturnId)}/form`
      );
      return {
        ...vatReturnForm,
        deliveryDate:
          vatReturnForm.deliveryDate && new Date(vatReturnForm.deliveryDate),
      };
    },
    [getJson]
  );

  /**
   * Submits a new VAT return.
   */
  const createVatReturn = useCallback(
    async (form: VatReturnForm): Promise<VatReturn> => {
      const toCreate = {
        ...form,
        deliveryDate: form.deliveryDate && toISODateString(form.deliveryDate),
      };
      return jsonToVatReturn(await postJson(VAT_RETURNS_URL, toCreate));
    },
    [postJson]
  );

  /**
   * Voids a VAT return.
   */
  const voidVatReturn = useCallback(
    async (taxReturnId: number, form: VoidVatReturnForm): Promise<VatReturn> =>
      jsonToVatReturn(
        await putJson(
          `${VAT_RETURNS_URL}/${encodeURIComponent(taxReturnId)}/status`,
          { newStatus: VatReturnStatus.Voided, ...form }
        )
      ),
    [putJson]
  );

  /**
   * Returns the VAT return constants in effect for a given date.
   */
  const getVatReturnConstants = useCallback(
    async (
      taxableYear: number,
      taxablePeriod: TaxablePeriod
    ): Promise<VatReturnConstants> => {
      return {
        generalFactor: 0,
        generalRate1: 15,
        generalRate2: 6,
        generalRate3: 0,
        interestOnLatePaymentType: 10,
        surchargeAType: 50,
        surchargeBType: 100,
      };

      // const date = new Date(taxableYear, +taxablePeriod - 1);
      // return (
      //   await getJson(
      //     `${VAT_RETURN_CONSTANTS_URL}/${encodeURIComponent(toISODateString(date))}`,
      //   )
      // );
    },
    []
  );

  return {
    getVatReturns,
    getVatReturn,
    getVatReturnForm,
    createVatReturn,
    voidVatReturn,
    getVatReturnConstants,
  };
}
