import React from 'react';
import { TaxRefund } from '../taxRefundsApi';
import { ActionButtons } from '../../../components/Actions';
import { useTaxRefundActions } from '../useTaxRefundActions';

/**
 * Properties for the tax refund row actions.
 */
interface TaxRefundActionsProps {
  taxRefund: TaxRefund;
  onVoid: () => void;
}

/**
 * Actions in a tax refund row.
 */
export function TaxRefundRowActions({
  taxRefund,
  onVoid,
}: TaxRefundActionsProps) {
  return (
    <ActionButtons
      actions={useTaxRefundActions(taxRefund, onVoid)!}
      variant="outlined"
      size="small"
    />
  );
}
