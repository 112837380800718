import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { useVatReturnsApi, VatReturn } from './vatReturnsApi';
import { useTranslation } from 'react-i18next';

/**
 * VAT return resource.
 */
export interface VatReturnResource {
  isFetching: boolean;
  vatReturn: VatReturn | null;
  notFound: boolean;
  setVatReturn: Dispatch<SetStateAction<VatReturn | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a VAT return from the API, if no id is passed this hook
 * does nothing.
 */
export function useVatReturn(taxReturnId?: number): VatReturnResource {
  const [t] = useTranslation('vatReturns');
  const { getVatReturn } = useVatReturnsApi();
  const getVatReturnCb = useCallback(
    () => (taxReturnId == null ? null : getVatReturn(taxReturnId)),
    [getVatReturn, taxReturnId]
  );
  const {
    isFetching,
    resource: vatReturn,
    notFound,
    setResource: setVatReturn,
    refetch,
  } = useResource({
    fetchResource: getVatReturnCb,
    errorFetchingResourceMessage: t('vatReturns:errorFetchingVatReturn', {
      id: taxReturnId ?? '',
    }),
  });

  return {
    isFetching,
    vatReturn,
    notFound,
    setVatReturn,
    refetch,
  };
}
