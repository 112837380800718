import { useCallback } from 'react';
import { TaxablePeriod, TaxId, TransactionMethod } from '../../common';
import { toISODateString } from '../../util/dateUtils';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Type of a tax refund.
 */
export interface TaxRefund {
  taxRefundId: number;
  assessmentNumber: string;
  assessmentNumberInserted: string;
  taxpayerId: string;
  taxpayerName: string;
  taxId: TaxId;
  taxableYear: number;
  taxablePeriod: TaxablePeriod | null;
  taxRefundDate: Date;
  amount: number;
  bankId: number;
  transactionMethod: TransactionMethod;
  description: string;
  status: TaxRefundStatus;
  createdDate: Date;
  createdBy: string;
  changedDate: Date;
  voidDescription: string | null;
  changedBy: string | null;
}

/**
 * Status of the tax refund.
 */
export enum TaxRefundStatus {
  Submitted = 'SUBMITTED',
  Voided = 'VOIDED',
}

/**
 * Options used to get tax refunds.
 */
export interface GetTaxRefundsFilterOptions {
  taxpayerId?: string;
  taxId?: TaxId;
  taxableYear?: number;
  taxablePeriod?: TaxablePeriod;
}

/**
 * Form fields used to void a tax refund.
 */
export interface VoidTaxRefundForm {
  voidDescription: string;
}

/**
 * Form fields when creating a tax refund.
 */
export interface TaxRefundForm {
  taxpayerId: string;
  assessmentNumber: string;
  taxId: TaxId | null;
  taxableYear: number | null;
  taxablePeriod: TaxablePeriod | null;
  taxRefundDate: Date | null;
  amount: number | null;
  bankId: number | null;
  transactionMethod: string | null;
  description: string;
}

/**
 * URL to access tax refunds.
 */
export const TAX_REFUNDS_URL = '/api/tax-refunds';

/**
 * Transforms a tax refund JSON as given by the API into our internal tax refund
 * representation.
 */
function jsonToTaxRefund(taxRefundJson: any): TaxRefund {
  return {
    ...taxRefundJson,
    taxRefundDate: new Date(taxRefundJson.taxRefundDate),
    createdDate: new Date(taxRefundJson.createdDate),
    changedDate: new Date(taxRefundJson.changedDate),
  };
}

/**
 * Hook exposing the tax refunds API.
 */
export function useTaxRefundsApi() {
  const { getJson, postJson, putJson } = useFetchFromApi();

  /**
   * Gets a list of tax refunds matching the provided filter options.
   */
  const getTaxRefunds = useCallback(
    async function (
      filterOptions: GetTaxRefundsFilterOptions = {}
    ): Promise<TaxRefund[]> {
      return (await getJson(TAX_REFUNDS_URL, { params: filterOptions })).map(
        jsonToTaxRefund
      );
    },
    [getJson]
  );

  /**
   * Gets the tax refund with the provided id.
   */
  const getTaxRefund = useCallback(
    async function (taxRefundId: number): Promise<TaxRefund> {
      return jsonToTaxRefund(
        await getJson(`${TAX_REFUNDS_URL}/${encodeURIComponent(taxRefundId)}`)
      );
    },
    [getJson]
  );

  /**
   * Voids a tax refund.
   */
  const voidTaxRefund = useCallback(
    async function (
      taxRefundId: number,
      form: VoidTaxRefundForm
    ): Promise<TaxRefund> {
      return jsonToTaxRefund(
        await putJson(
          `${TAX_REFUNDS_URL}/${encodeURIComponent(taxRefundId)}/status`,
          { newStatus: TaxRefundStatus.Voided, ...form }
        )
      );
    },
    [putJson]
  );

  /**
   * Creates a new tax refund from the provided tax refund fields and
   * returns it.
   */
  const createTaxRefund = useCallback(
    async function (form: TaxRefundForm): Promise<TaxRefund> {
      const toCreate = {
        ...form,
        taxRefundDate:
          form.taxRefundDate && toISODateString(form.taxRefundDate),
      };
      return jsonToTaxRefund(await postJson(TAX_REFUNDS_URL, toCreate));
    },
    [postJson]
  );

  return { getTaxRefunds, getTaxRefund, voidTaxRefund, createTaxRefund };
}
