import { useCallback, useEffect, useState } from 'react';
import {
  FieldName,
  FieldValue,
  UnpackNestedValue,
  UseFormMethods,
} from 'react-hook-form';

/**
 * Hook that auto updates a RHF field with name `name` and that depends on
 * fields with names `dependencyNames`. The field is updated with the result of
 * calling `autoUpdateFn` unless its value differs from the result of it.
 */
export function useAutoUpdateField<
  TFieldValues extends Record<string, any>,
  TFieldName extends FieldName<TFieldValues> = FieldName<TFieldValues>,
  TFieldValue extends FieldValue<TFieldValues> = FieldValue<TFieldValues>,
  TFieldDeps extends keyof TFieldValues = keyof TFieldValues
>(
  formMethods: UseFormMethods<TFieldValues>,
  name: TFieldName,
  dependencyNames: TFieldDeps[],
  autoUpdateFn: (
    dependencies: UnpackNestedValue<Pick<TFieldValues, TFieldDeps>>
  ) => TFieldValue,
  disableAutoUpdate: boolean = false
): () => void {
  const { getValues, setValue, watch, formState } = formMethods;

  // Auto-update the field whenever it matches its "automatic" value or when it
  // is empty
  const [shouldAutoUpdateField, setShouldAutoUpdateField] = useState(true);
  const field = watch(name);
  const dependencies = watch(dependencyNames);
  useEffect(() => {
    setShouldAutoUpdateField(
      field == null || field === '' || field === autoUpdateFn(dependencies)
    );
  }, [field, dependencies, autoUpdateFn]);

  // Update the field whenever appropriate when a dependency changes
  return useCallback(() => {
    if (shouldAutoUpdateField && !disableAutoUpdate) {
      const dependencies = getValues(dependencyNames);
      setValue(name, autoUpdateFn(dependencies), {
        shouldValidate: formState.isSubmitted || !!formState.touched[name],
        shouldDirty: true,
      });
    }
  }, [
    shouldAutoUpdateField,
    getValues,
    dependencyNames,
    setValue,
    name,
    autoUpdateFn,
    formState.isSubmitted,
    formState.touched,
    disableAutoUpdate,
  ]);
}
