import React from 'react';
import { VatOmission } from '../vatOmissionsApi';
import { ActionButtons } from '../../../components/Actions';
import { useVatOmissionActions } from '../useVatOmissionActions';

/**
 * Properties for the VAT omission row actions.
 */
interface VatOmissionRowActionsProps {
  vatOmission: VatOmission;
}

/**
 * Actions in a VAT omission row.
 */
export function VatOmissionRowActions({
  vatOmission,
}: VatOmissionRowActionsProps) {
  return (
    <ActionButtons
      actions={useVatOmissionActions(vatOmission)!}
      variant="outlined"
      size="small"
    />
  );
}
