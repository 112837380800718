import { useContext } from 'react';
import {
  ConfirmationDialogApi,
  ConfirmationDialogContext,
} from './ConfirmationDialogContext';

/**
 * Hook exposing the confirmation dialog API.
 */
export function useConfirmationDialog(): ConfirmationDialogApi {
  return useContext(ConfirmationDialogContext);
}
