import { useCallback } from 'react';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Hook exposing the profile API.
 */
export function useProfileApi() {
  const { patchJson, putJson } = useFetchFromApi();

  /**
   * API to change the logged in user's display name.
   */
  const changeDisplayName = useCallback(
    async (displayName: string): Promise<void> => {
      await patchJson('/api/profile', { displayName });
    },
    [patchJson]
  );

  /**
   * API to change the logged in user's password.
   */
  const changePassword = useCallback(
    async (currentPassword: string, newPassword: string): Promise<void> => {
      await putJson('/api/profile/password', { currentPassword, newPassword });
    },
    [putJson]
  );

  return { changeDisplayName, changePassword };
}
