import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  Button,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { AccountCircle, ExitToApp, Menu, Person } from '@material-ui/icons';
import { PROFILE_PATH, ROOT_PATH } from '../navigation/paths';
import { useAuthentication } from '../providers/AuthenticationProvider';
import { ThemeToggler } from '../components/ThemeToggler';
import { useProfile } from '../providers/ProfileProvider';
import { Skeleton } from '@material-ui/lab';
import { Action, ActionsButton } from '../components/Actions';
import { ThemeVariant } from '../providers/ThemeProvider';
import { useActionIsRunning } from '../components/Actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.primary.main
          : theme.palette.background.paper,
      color:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.primary.contrastText
          : theme.palette.text.primary,
      '@media print': {
        position: 'initial',
        marginBottom: theme.spacing(4),
        backgroundColor: 'initial',
        color: theme.palette.text.primary,
        boxShadow: 'none',
      },
    },
    toolbar: {
      justifyContent: 'space-between',
      padding: theme.spacing(0, 2),
      '@media print': {
        padding: 0,
      },
    },
    toolbarContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    actions: {
      '@media print': {
        display: 'none',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [`${theme.breakpoints.up('sm')}, print`]: {
        display: 'none',
      },
    },
    titleLink: {
      display: 'inline-flex',
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'none',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      '@media screen': {
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      '@media print': {
        padding: 0,
      },
    },
    logo: {
      height: 56,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        height: 48,
      },
      '@media print': {
        height: 96,
        marginRight: theme.spacing(4),
        filter: 'grayscale(1)',
      },
    },
    title: {
      '@media print': {
        fontSize: '2.5rem',
        fontWeight: 'normal',
      },
    },
    itemRight: {
      marginRight: theme.spacing(1),
    },
    userButton: {
      textTransform: 'none',
    },
    displayName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 400,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
      },
    },
    loadingIcon: {
      marginRight: theme.spacing(0.25),
      color: 'inherit',
    },
  })
);

/**
 * Properties of the app bar.
 */
export interface AppBarProps {
  onDrawerButtonClick(): void;
}

/**
 * Content of the app bar.
 */
export function AppBar({ onDrawerButtonClick }: AppBarProps) {
  const [t] = useTranslation('common');
  const classes = useStyles();

  return (
    <MuiAppBar className={classes.root} data-cy="app-bar">
      <Toolbar className={classes.toolbar}>
        {/* Left side */}
        <div className={classes.toolbarContainer}>
          {/* Button to open drawer (in small screens) */}
          <IconButton
            color="inherit"
            aria-label={t('openDrawer')}
            edge="start"
            onClick={onDrawerButtonClick}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>

          {/* Logo + title */}
          <Button component={Link} className={classes.titleLink} to={ROOT_PATH}>
            <img
              className={classes.logo}
              src="/logo.svg"
              alt={t('logoAltText')}
            />
            <Typography
              className={classes.title}
              variant="h6"
              component="span"
              noWrap
            >
              {t('title')}
            </Typography>
          </Button>
        </div>

        {/* Right side */}
        <div className={`${classes.toolbarContainer} ${classes.actions}`}>
          {/* Toggle theme */}
          <ThemeToggler color="inherit" className={classes.itemRight} />

          {/* User section */}
          <UserSection />
        </div>
      </Toolbar>
    </MuiAppBar>
  );
}

/**
 * User section of the app bar.
 */
function UserSection() {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const {
    user,
    api: { logout },
  } = useAuthentication();
  const {
    isFetching: isFetchingProfile,
    mustChangePassword,
    profile,
  } = useProfile();
  const [isLoggingOut] = useActionIsRunning('user-logout');

  /**
   * Actions in the user menu.
   */
  const userActions: Action[] = [
    {
      id: 'user-profile',
      label: t('user.profile'),
      component: Link,
      to: PROFILE_PATH,
      icon: <Person />,
      hidden: mustChangePassword,
    },
    {
      id: 'user-logout',
      label: t('user.logout'),
      run: logout,
      icon: <ExitToApp />,
      style: 'error',
    },
  ];

  return (
    <ActionsButton actions={userActions}>
      {(buttonProps) => (
        <Button
          {...buttonProps}
          className={classes.userButton}
          color="inherit"
          aria-label={t('userActions')}
          endIcon={
            isLoggingOut ? (
              <CircularProgress className={classes.loadingIcon} size={18} />
            ) : (
              <AccountCircle />
            )
          }
        >
          {isFetchingProfile ? (
            <Skeleton width={100} />
          ) : (
            <span className={classes.displayName}>
              {profile?.displayName || user?.username}
            </span>
          )}
        </Button>
      )}
    </ActionsButton>
  );
}
