import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { MainContent } from '../../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Cached, Send } from '@material-ui/icons';
import {
  replacePathParams,
  VAT_OMISSIONS_PATH,
  VAT_RETURN_PATH,
  VAT_RETURNS_PATH,
} from '../../../navigation/paths';
import {
  useVatReturnsApi,
  VatReturnOrigin,
  VatReturnStatus,
} from '../vatReturnsApi';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { Representative } from './Representative';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { DebtDetermination } from './DebtDetermination';
import { useVatReturnForm } from './useVatReturnForm';
import {
  Form,
  FormDateField,
  FormSelect,
  FormTaxpayerIdField,
  TitledPaper,
} from '../../../components/Form';
import { useTaxpayer } from '../../Taxpayers';
import { ivageCommonUtil } from '../../../ivageCommon';
import { isBefore } from 'date-fns';
import { parseOmissionString } from '../util/parseOmissionString';
import { useQueryParams } from '../../../util/useQueryParams';
import { useConfirmationDialog } from '../../../providers/ConfirmationDialogProvider';
import { Action } from '../../../components/Actions';
import { useLfForm } from '../../../util/lfIntegration';
import { defaultVatReturnForm } from './defaultVatReturnForm';
import { activeMonthDates, activeYears } from '../util/activeDates';
import { useVatReturnConstants } from './useVatReturnConstants';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useIsMounted } from '../../../util/useIsMounted';

/**
 * Mode in which the VAT return form page is open.
 */
export enum FormMode {
  New,
  View,
  Replacement,
  Automatic,
}

/**
 * Allowed query parameters.
 */
const queryParams = ['replaces', 'fromOmission'];

/**
 * Page with a VAT return form to create or replace a VAT return.
 */
export function VatReturnFormPage() {
  const [t] = useTranslation(['common', 'vatReturns']);
  const { id: viewId } = useParams<{ id?: string }>();
  const [{ replaces: replacementId, fromOmission }] = useQueryParams(
    queryParams
  );
  const {
    isFetching: isFetchingConfigsCatalog,
    catalog: configsCatalog,
  } = useCatalog('configs');
  const vatReturnFromOmission = useMemo(
    () =>
      parseOmissionString(
        fromOmission,
        configsCatalog?.vatReturnInitialTaxableYearPeriod
      ),
    [configsCatalog?.vatReturnInitialTaxableYearPeriod, fromOmission]
  );
  const formMode: FormMode =
    viewId != null
      ? FormMode.View
      : replacementId != null
      ? FormMode.Replacement
      : fromOmission != null
      ? FormMode.Automatic
      : FormMode.New;
  const id =
    formMode === FormMode.View
      ? +viewId!
      : formMode === FormMode.Replacement
      ? +replacementId!
      : null;
  const { isFetching, vatReturnForm, notFound } = useVatReturnForm(id);
  useSetDocumentTitle(
    t(
      `vatReturns:vatReturnFormPage.${
        formMode === FormMode.Replacement
          ? 'replaceVatReturnDocumentTitle'
          : formMode === FormMode.View
          ? 'readVatReturnDocumentTitle'
          : 'newVatReturnDocumentTitle'
      }`,
      formMode === FormMode.Replacement || FormMode.View ? { id } : {}
    )
  );
  const isMounted = useIsMounted();
  const { createVatReturn, getVatReturns } = useVatReturnsApi();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirmationDialog();
  const history = useHistory();
  const location = useLocation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formMethods = useLfForm({
    defaultValues: defaultVatReturnForm(vatReturnForm ?? vatReturnFromOmission),
    formValidatorName: 'vatReturnFormValidator',
    i18nErrorMessagesPrefixes: [
      'vatReturns:vatReturnFormPage.fieldErrors',
      'dateField.fieldErrors',
    ],
  });
  const {
    register,
    reset,
    getValues,
    setLfValue,
    formState,
    watch,
    setLfIssues,
    setManualLfIssue,
    removeManualLfIssue,
  } = formMethods;

  // Reset form with the VAT return being replaced/automated
  useEffect(() => {
    reset(defaultVatReturnForm(vatReturnForm ?? vatReturnFromOmission));
  }, [vatReturnForm, vatReturnFromOmission, reset]);

  // Even though there is no field to change the general factor, it is required
  // for validations and should be sent together with the form data, so we
  // register it
  register('generalFactor');

  // Fetch taxpayer when id is valid
  const taxpayerId = watch('taxpayerId');
  const {
    isFetching: isFetchingTaxpayer,
    taxpayer,
    notFound: taxpayerNotFound,
  } = useTaxpayer(
    ivageCommonUtil.taxpayerIdIsValid(taxpayerId) ? taxpayerId : undefined
  );

  // Validate that taxpayer exists
  useEffect(() => {
    removeManualLfIssue({ path: '/taxpayerId', code: 'doesNotExist' });
    if (taxpayerNotFound) {
      setManualLfIssue({ path: '/taxpayerId', code: 'doesNotExist' });
    }
  }, [taxpayerNotFound, setManualLfIssue, removeManualLfIssue]);

  // Selected taxable year, used to fetch VAT return constants and to limit the
  // periods that can be selected
  const { taxableYear, taxablePeriod } = watch([
    'taxableYear',
    'taxablePeriod',
  ]);

  // Validate that the taxpayer is active
  useEffect(() => {
    removeManualLfIssue({ path: '/taxableYear', code: 'notActive' });
    removeManualLfIssue({ path: '/taxablePeriod', code: 'notActive' });
    if (
      taxpayer &&
      configsCatalog?.vatReturnInitialTaxableYearPeriod &&
      formMode !== FormMode.View
    ) {
      if (taxableYear != null) {
        if (
          !activeYears(
            activeMonthDates(
              taxpayer.vatActivities ?? [],
              configsCatalog.vatReturnInitialTaxableYearPeriod
            )
          ).has(taxableYear)
        ) {
          setManualLfIssue({ path: '/taxableYear', code: 'notActive' });
        } else if (taxablePeriod != null) {
          const taxablePeriodDate = new Date(taxableYear, +taxablePeriod - 1);
          if (
            activeMonthDates(
              taxpayer.vatActivities ?? [],
              configsCatalog.vatReturnInitialTaxableYearPeriod
            ).find((date) => +date === +taxablePeriodDate) == null
          ) {
            setManualLfIssue({ path: '/taxablePeriod', code: 'notActive' });
          }
        }
      }
    }
  }, [
    formMode,
    configsCatalog?.vatReturnInitialTaxableYearPeriod,
    removeManualLfIssue,
    setManualLfIssue,
    taxablePeriod,
    taxableYear,
    taxpayer,
  ]);

  // Fetch VAT return constants
  const {
    isFetching: isFetchingVatReturnConstants,
    vatReturnConstants,
  } = useVatReturnConstants(taxableYear, taxablePeriod);

  // Set constants when not in `VIEW` mode
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('generalFactor', vatReturnConstants?.generalFactor ?? null);
      setLfValue('generalRate1', vatReturnConstants?.generalRate1 ?? null);
      setLfValue('generalRate2', vatReturnConstants?.generalRate2 ?? null);
      setLfValue('generalRate3', vatReturnConstants?.generalRate3 ?? null);
      setLfValue(
        'interestOnLatePaymentType',
        vatReturnConstants?.interestOnLatePaymentType ?? null
      );
      setLfValue('surchargeAType', vatReturnConstants?.surchargeAType ?? null);
      setLfValue('surchargeBType', vatReturnConstants?.surchargeBType ?? null);
    }
  }, [formMode, setLfValue, vatReturnConstants]);

  // Create the new VAT return when submitting
  async function onSubmit() {
    const vatReturnForm = getValues();

    try {
      // Fetch an existing effective VAT return for the same taxpayer and period
      // and, in certain scenarios, confirm that the submission should proceed
      const [effectiveVatReturn] = await getVatReturns({
        taxpayerId: vatReturnForm.taxpayerId,
        taxableYear: vatReturnForm.taxableYear!,
        taxablePeriod: vatReturnForm.taxablePeriod!,
        status: VatReturnStatus.Effective,
      });
      if (effectiveVatReturn) {
        const effectiveId = effectiveVatReturn.taxReturnId;
        const i18nKey = isBefore(
          vatReturnForm.deliveryDate!,
          effectiveVatReturn.deliveryDate
        )
          ? // When the VAT return won't be the effective one
            'vatReturns:confirmNotEffectiveSubmission'
          : formMode !== FormMode.Replacement
          ? // When replacing a VAT return when not in replace mode
            'vatReturns:confirmReplacementWhenNotReplacing'
          : effectiveId !== id
          ? // When the VAT return that we'll end up replacing is not the one
            // being replaced
            'vatReturns:confirmReplacementOfDifferent'
          : null;
        if (
          i18nKey &&
          !(await confirm(t(i18nKey, { id: effectiveId, replaceId: id })))
        ) {
          return;
        }
      }

      const newVatReturn = await createVatReturn(vatReturnForm);
      enqueueSnackbar(
        formMode === FormMode.Replacement
          ? t('vatReturns:vatReturnFormPage.successReplacingVatReturn', { id })
          : t('vatReturns:vatReturnFormPage.successSubmittingVatReturn'),
        { variant: 'success' }
      );

      if (
        isMounted.current &&
        location.pathname === history.location.pathname
      ) {
        setFormSubmitted(true);
        history.push(
          replacePathParams(VAT_RETURN_PATH, { id: newVatReturn.taxReturnId })
        );
      }
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        setLfIssues([
          {
            path: '/',
            code:
              vatReturnForm.origin === VatReturnOrigin.Normal
                ? 'cannotReplaceNonNormalReturn'
                : vatReturnForm.origin === VatReturnOrigin.Rectification
                ? 'noPreviousReturnExists'
                : 'effectiveReturnExists',
          },
        ]);
      } else {
        enqueueSnackbar(
          formMode === FormMode.Replacement
            ? t('vatReturns:vatReturnFormPage.errorReplacingVatReturn', { id })
            : t('vatReturns:vatReturnFormPage.errorSubmittingVatReturn'),
          { variant: 'error' }
        );
      }
    }
  }

  // Don't check the constants when checking if the form is dirty since they're
  // always auto-filled
  const constantKeys = useMemo(
    () => vatReturnConstants && Object.keys(vatReturnConstants),
    [vatReturnConstants]
  );
  const isDirty = useMemo(
    () =>
      constantKeys != null &&
      Object.keys(formState.dirtyFields).some(
        (key) => !constantKeys.includes(key)
      ),
    [constantKeys, formState]
  );

  // Actions of the form
  const actions: Action[] = [
    {
      id: 'vat-return-form-submit',
      type: 'submit',
      label:
        formMode === FormMode.Replacement
          ? t('vatReturns:vatReturnFormPage.replaceVatReturn')
          : t('vatReturns:vatReturnFormPage.submitVatReturn'),
      color: 'primary',
      icon: formMode === FormMode.Replacement ? <Cached /> : <Send />,
      loading: formState.isSubmitting,
      disabled:
        isFetching ||
        isFetchingConfigsCatalog ||
        isFetchingTaxpayer ||
        isFetchingVatReturnConstants,
      hidden: formMode === FormMode.View,
    },
    {
      id: 'vat-return-form-reset',
      type: 'reset',
      label: t('formActions.reset'),
      // When resetting, don't reset the `taxpayer` or `isFetchingTaxpayer`
      run: () => reset(),
      disabled: !isDirty || formState.isSubmitting,
      hidden: formMode === FormMode.View,
    },
    {
      id: 'vat-return-form-cancel',
      label:
        formMode === FormMode.View
          ? t('formActions.return')
          : t('formActions.cancel'),
      run: () =>
        history.push(
          formMode === FormMode.New
            ? VAT_RETURNS_PATH
            : formMode === FormMode.Automatic
            ? VAT_OMISSIONS_PATH
            : replacePathParams(VAT_RETURN_PATH, { id })
        ),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <MainContent
      errorMessage={
        (formMode === FormMode.Replacement || formMode === FormMode.View) &&
        notFound
          ? t('vatReturns:vatReturnNotFound')
          : formMode === FormMode.Automatic && !vatReturnFromOmission
          ? t('vatReturns:invalidOmission')
          : undefined
      }
    >
      <Form
        onSubmit={onSubmit}
        shouldPromptOnLeave={
          formMode !== FormMode.View && isDirty && !formSubmitted
        }
        {...formMethods}
      >
        <ActionableHeader
          title={
            formMode === FormMode.Replacement
              ? t('vatReturns:vatReturnFormPage.replaceVatReturnTitle')
              : formMode === FormMode.View
              ? t('vatReturns:vatReturnFormPage.readVatReturnTitle')
              : t('vatReturns:vatReturnFormPage.newVatReturnTitle')
          }
          titleId={id}
          actions={actions}
        />

        {/* VAT return information section */}
        <TitledPaper title={t('vatReturns:vatReturnInformation')}>
          <Grid container spacing={2}>
            {/* Taxpayer ID */}
            <Grid item xs={6}>
              <FormTaxpayerIdField
                name="taxpayerId"
                label={t('vatReturns:vatReturnFormPage.fields.taxpayerId')}
                disabled={formMode !== FormMode.New}
                onChangeTriggers={['taxableYear', 'taxablePeriod']}
                fetching={
                  (formMode === FormMode.Replacement ||
                    formMode === FormMode.View) &&
                  isFetching
                }
              />
            </Grid>

            {/* Origin */}
            <Grid item xs={6}>
              <FormSelect
                name="origin"
                label={t('vatReturns:vatReturnFormPage.fields.origin')}
                items={Object.values(VatReturnOrigin).map((origin) => ({
                  value: origin,
                  label: t(`vatReturns:vatReturnOrigin.${origin}`),
                }))}
                disabled={
                  formMode === FormMode.Replacement ||
                  formMode === FormMode.View
                }
                fetching={isFetching}
              />
            </Grid>

            {/* Taxable year/period */}
            <FormTaxableYearPeriod
              taxableYearProps={{
                name: 'taxableYear',
                label: t('vatReturns:vatReturnFormPage.fields.taxableYear'),
                onChangeTriggers: ['taxablePeriod', 'deliveryDate'],
                disabled: formMode !== FormMode.New,
                fetching: isFetching,
              }}
              taxablePeriodProps={{
                name: 'taxablePeriod',
                label: t('vatReturns:vatReturnFormPage.fields.taxablePeriod'),
                onChangeTriggers: ['deliveryDate'],
                disabled: formMode !== FormMode.New,
                fetching: isFetching,
              }}
            >
              {(taxableYear, taxablePeriod) => (
                <>
                  <Grid item xs={6} md={4}>
                    {taxableYear}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    {taxablePeriod}
                  </Grid>
                </>
              )}
            </FormTaxableYearPeriod>

            {/* Delivery date */}
            <Grid item xs={12} md={4}>
              <FormDateField
                name="deliveryDate"
                label={t('vatReturns:vatReturnFormPage.fields.deliveryDate')}
                minDate={
                  taxableYear && taxablePeriod
                    ? new Date(taxableYear, +taxablePeriod - 1)
                    : configsCatalog?.vatReturnInitialTaxableYearPeriod
                }
                disableFuture={true}
                disabled={formMode === FormMode.View}
                fetching={isFetching || isFetchingConfigsCatalog}
              />
            </Grid>
          </Grid>
        </TitledPaper>

        {/* Representative section */}
        <TitledPaper title={t('vatReturns:vatReturnFormPage.representative')}>
          <Representative
            formMode={formMode}
            fetching={isFetching}
            taxpayer={taxpayer}
            isFetchingTaxpayer={isFetchingTaxpayer}
          />
        </TitledPaper>

        {/* Debt determination section */}
        <TitledPaper
          title={t('vatReturns:vatReturnFormPage.debtDetermination')}
        >
          <DebtDetermination
            formMode={formMode}
            fetching={isFetching}
            isFetchingVatReturnConstants={isFetchingVatReturnConstants}
          />
        </TitledPaper>
      </Form>
    </MainContent>
  );
}
