import { createContext } from 'react';

/**
 * API to reset password.
 */
export interface ResetPasswordApi {
  resetPassword(): Promise<boolean>;
}

/**
 * Reset password context.
 */
export const ResetPasswordContext = createContext<ResetPasswordApi>(
  undefined as any
);
