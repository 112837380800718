import React, { useMemo } from 'react';
import { OpenInNew, RemoveCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PAYMENT_PATH, replacePathParams } from '../../navigation/paths';
import { Action } from '../../components/Actions';
import { Payment, PaymentStatus } from './paymentsApi';

/**
 * Hook that returns actions that can be performed on a payment.
 */
export function usePaymentActions(
  payment: Payment | null,
  onVoid: () => void
): Action[] {
  const [t] = useTranslation('payments');
  const { pathname } = useLocation();

  const paymentPath = replacePathParams(PAYMENT_PATH, {
    id: payment?.paymentId,
  });

  return useMemo(
    () => [
      {
        id: `payment-${payment?.paymentId}-open`,
        label: t('payments:paymentActions.open'),
        component: Link,
        to: paymentPath,
        icon: <OpenInNew />,
        hidden: !payment || pathname === paymentPath,
      },
      {
        id: `payment-${payment?.paymentId}-void`,
        label: t('payments:paymentActions.void'),
        run: onVoid,
        icon: <RemoveCircleOutline />,
        style: 'error',
        hidden: !payment || payment.status === PaymentStatus.Voided,
      },
    ],
    [t, paymentPath, payment, pathname, onVoid]
  );
}
