import { useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

/**
 * Formats a given value as currency.
 */
export function useCurrencyFormatter(
  options?: Omit<NumberFormatProps, 'value' | 'displayType' | 'renderText'>
) {
  const [t] = useTranslation('common');

  return useCallback(
    (value: number | null | undefined) =>
      // XXX: https://github.com/s-yadav/react-number-format/issues/458
      renderToStaticMarkup(
        <NumberFormat
          value={value ?? undefined}
          prefix={process.env.REACT_APP_CURRENCY_PREFIX}
          suffix={process.env.REACT_APP_CURRENCY_SUFFIX}
          thousandSeparator={t('currencyField.thousandSeparator') as string}
          decimalSeparator={t('currencyField.decimalSeparator')!}
          {...options}
          decimalScale={
            options?.decimalScale ??
            (process.env.REACT_APP_CURRENCY_DECIMAL_SCALE
              ? +process.env.REACT_APP_CURRENCY_DECIMAL_SCALE
              : undefined)
          }
          fixedDecimalScale={options?.fixedDecimalScale ?? true}
          displayType="text"
          renderText={(value) => value.toString()}
        />
      ),
    [options, t]
  );
}
