import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { useCatalog } from '../../../providers/CatalogsProvider';
import React, { useCallback } from 'react';
import { useResource } from '../../../util/useResource';
import { MainContent } from '../../../components/MainContent';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { useVatIndicatorsApi, VAT_INDICATORS_URL } from '../vatIndicatorsApi';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { VatIndicatorTable } from './VatIndicatorTable';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../../components/PaperDescriptionList';
import { useDateTimeFormatter } from '../../../util/formatters';
import { useParams } from 'react-router-dom';

/**
 * Page used to consult a VAT indicator.
 */
export function VatIndicatorPage() {
  const [t] = useTranslation(['common', 'vatIndicators']);
  const { id } = useParams<{ id: string }>();
  const {
    isFetching: isFetchingVatIndicatorsCatalog,
    catalog: vatIndicatorsCatalog,
  } = useCatalog('vat-indicators');
  const notFound =
    !vatIndicatorsCatalog ||
    vatIndicatorsCatalog.every((indicator) => indicator.vatIndicatorId !== id);
  useSetDocumentTitle(
    notFound ? undefined : t(`vatIndicators:vatIndicators.${id}.title`)
  );
  const formatDateTime = useDateTimeFormatter();
  const { getVatIndicator } = useVatIndicatorsApi();
  // Only fetch when the schema is valid
  const fetchVatIndicator = useCallback(
    async () => (notFound ? null : getVatIndicator(id)),
    [getVatIndicator, id, notFound]
  );
  const { isFetching, resource: vatIndicator } = useResource({
    fetchResource: fetchVatIndicator,
    errorFetchingResourceMessage: t(
      'vatIndicators:vatIndicatorPage.errorFetchingVatIndicator'
    ),
  });

  // Report fields
  const reportInformation: DescriptionListItem[] = [
    {
      key: 'access-date',
      label: t('vatIndicators:vatIndicatorFields.accessDate'),
      content: formatDateTime(vatIndicator?.accessDate),
      fetching: isFetchingVatIndicatorsCatalog || isFetching,
    },
  ];

  return (
    <MainContent
      errorMessage={
        vatIndicatorsCatalog &&
        notFound &&
        t('vatIndicators:vatIndicatorPage.vatIndicatorNotFound')
      }
    >
      <ActionableHeader
        title={t(`vatIndicators:vatIndicators.${id}.title`)}
        fetching={isFetchingVatIndicatorsCatalog}
      />

      <PaperDescriptionList
        title={t('vatIndicators:vatIndicatorPage.consultationInformation')}
        items={reportInformation}
      />

      <VatIndicatorTable
        fetching={vatIndicator == null}
        vatIndicatorMetadata={
          vatIndicatorsCatalog?.find(
            (indicator) => indicator.vatIndicatorId === id
          ) ?? null
        }
        vatIndicator={vatIndicator}
        downloadButton={
          <DownloadTableButton
            fileName={t(`vatIndicators:vatIndicators.${id}.fileName`)}
            downloadUrl={`${VAT_INDICATORS_URL}/${encodeURIComponent(
              id
            )}?${objectToQueryParamsString({ format: 'csv' })}`}
            disabled={vatIndicator == null}
          />
        }
      />
    </MainContent>
  );
}
