/**
 * Resolves an object given a path. E.g. given the object `{a: {b: 'x'}}` and
 * the path `'a.b'`, returns `'x'`. The method currently doesn't support
 * "bracket" syntax in paths; it can be easily implemented if the need arrives.
 */
export function resolveObject(obj: any, path: string): any {
  if (!path) {
    return obj;
  }
  const splitPath = path.split('.');
  let val = obj;
  for (const part of splitPath) {
    val = val?.[part];
  }
  return val;
}
