import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { Route } from './Route';
import { MainApp } from '../app/MainApp';
import { LoginPage } from '../features/Login';
import { useAuthentication } from '../providers/AuthenticationProvider';
import { LOGIN_PATH, REDIRECT_PARAM, ROOT_PATH } from './paths';
import { PageLoading } from '../components/PageLoading';
import { objectToQueryParamsString } from '../util/objectToQueryParamsString';

/**
 * Login router configuration.
 */
export function LoginRouterConfig() {
  const { isFetching, user } = useAuthentication();
  const location = useLocation();

  return isFetching ? (
    <PageLoading />
  ) : (
    <Switch>
      {/* Login */}
      <Route exact path={LOGIN_PATH}>
        <LoginPage />
      </Route>

      {/* Any other route: render the `MainApp` when logged in or redirect to
          the login otherwise. */}
      <Route>
        {user ? (
          <MainApp />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_PATH,
              search:
                location.pathname === ROOT_PATH
                  ? undefined
                  : objectToQueryParamsString({
                      [REDIRECT_PARAM]: location.pathname,
                    }),
            }}
          />
        )}
      </Route>
    </Switch>
  );
}
