import { Route, RouteProps } from './Route';
import { useProfile, UserRole } from '../providers/ProfileProvider';
import { NotFoundPage } from '../features/NotFound';

/**
 * Properties of th restricted route.
 */
interface RestrictedRouteProps extends RouteProps {
  role: UserRole;
}

/**
 * Route restricted by the role of the logged in user.
 */
export function RestrictedRoute({
  role,
  children,
  ...props
}: RestrictedRouteProps) {
  const { hasRole } = useProfile();
  return (
    <Route {...props}>{hasRole(role) ? children : <NotFoundPage />}</Route>
  );
}
