/**
 * Information related to the authenticated user.
 */
export interface AuthenticatedUser {
  username: string;
  expires: Date;
}

/**
 * URL to access the authentication API.
 */
const AUTHENTICATION_API_URL = '/api/access';

/**
 * Transforms an authenticated user JSON as given by the API into our internal
 * authenticated user representation.
 */
function jsonToAuthenticatedUser(authenticatedUser: any): AuthenticatedUser {
  return {
    ...authenticatedUser,
    expires: new Date(authenticatedUser.expires),
  };
}

/**
 * Logs a user in given its username and password and returns the authenticated
 * user.
 */
export async function login(
  username: string,
  password: string
): Promise<AuthenticatedUser> {
  const res = await fetch(`${AUTHENTICATION_API_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
  });
  if (res.ok) {
    return jsonToAuthenticatedUser(await res.json());
  }
  throw res;
}

/**
 * Refreshes the provided JWT. If the authenticated user is being persisted, the
 * information stored in local storage will be updated.
 */
export async function refresh(): Promise<AuthenticatedUser> {
  const res = await fetch(`${AUTHENTICATION_API_URL}/refresh`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    return jsonToAuthenticatedUser(await res.json());
  }
  throw res;
}

/**
 * Logs out the current user. The information in local storage will be deleted
 * if it was being persisted.
 */
export async function logout(): Promise<void> {
  const res = await fetch(`${AUTHENTICATION_API_URL}/logout`, {
    method: 'POST',
  });
  if (!res.ok) {
    throw res;
  }
}
