import React, { useCallback, useMemo, useRef } from 'react';
import {Email, ListAlt, OpenInNew, Refresh, SaveAlt} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { replacePathParams, VAT_ASSESSMENT_PATH } from '../../navigation/paths';
import {Action, ActionGroup} from '../../components/Actions';
import {
  useVatAssessmentsApi,
  VAT_ASSESSMENTS_URL,
  VatAssessment,
  VatAssessmentStatus,
} from './vatAssessmentsApi';
import { useSnackbar } from 'notistack';
import { isAfter, isBefore, startOfToday } from 'date-fns';
import { localDateToSameDateInUTC } from '../../util/dateUtils';
import { useConfirmationDialog } from '../../providers/ConfirmationDialogProvider';
import { useIsMounted } from '../../util/useIsMounted';
import { useCatalog } from '../../providers/CatalogsProvider';

/**
 * Hook that returns actions that can be performed on a VAT assessment.
 */
export function useVatAssessmentActions(
  vatAssessment: VatAssessment | null,
  onRecalculate: (newVatAssessment: VatAssessment) => void,
  onFailureDueToOutdatedUI: () => void
): Action[] {
  const [t] = useTranslation('vatAssessments');
  const {
    getVatAssessment,
    recalculateVatAssessment,
    emailPaymentDocument,
  } = useVatAssessmentsApi();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirmationDialog();
  const { catalog: taxPeriodsCatalog } = useCatalog('tax-periods');
  const isMounted = useIsMounted();

  /**
   * Whether the interest can be recalculated for the given assessment.
   */
  const canRecalculateInterest = useCallback(
    (vatAssessment?: VatAssessment | null) => {
      const relevantPeriod =
        vatAssessment &&
        taxPeriodsCatalog &&
        taxPeriodsCatalog.find(
          (period) =>
            period.taxableYear === vatAssessment.taxableYear &&
            period.taxablePeriod === vatAssessment.taxablePeriod
        );
      const today = localDateToSameDateInUTC(startOfToday());
      return (
        relevantPeriod &&
        vatAssessment!.status === VatAssessmentStatus.Effective &&
        (vatAssessment!.vatFinal > 0 || vatAssessment!.interestFinal > 0) &&
        isBefore(vatAssessment!.createdDate, today) &&
        isAfter(today, relevantPeriod.lastDay)
      );
    },
    [taxPeriodsCatalog]
  );

  const skipDownloadChecks = useRef(false);
  /**
   * Function that runs when the user clicks the "get payment document" link. In
   * certain cases we delay the default behaviour of simply downloading the
   * document by first running some checks.
   */
  const getPaymentDocumentImpl = useCallback(
    async (evt) => {
      // When getting the payment document for a non-effective assessment, we
      // don't need to check anything and always allow the download (an
      // assessment cannot change once it has been replaced or voided)
      if (vatAssessment!.status !== VatAssessmentStatus.Effective) {
        return;
      }

      // Otherwise we need to check that the assessment is up to date (that the
      // view isn't outdated)
      try {
        if (!skipDownloadChecks.current) {
          evt.preventDefault();
          const newVatAssessment = await getVatAssessment(
            vatAssessment!.assessmentNumber
          );
          if (newVatAssessment.status !== VatAssessmentStatus.Effective) {
            enqueueSnackbar(
              t('vatAssessments:errorGettingPaymentDocumentDueToOutdatedUI', {
                id: vatAssessment!.assessmentNumber,
              }),
              { variant: 'error' }
            );
            if (isMounted.current) {
              onFailureDueToOutdatedUI();
            }
          } else {
            // Warn when getting a payment document with outdated interest (when
            // it is possible to recalculate the interest)
            if (
              !canRecalculateInterest(vatAssessment) ||
              (await confirm(
                t(
                  'vatAssessments:confirmGetPaymentDocumentWithRecalculationAvailable',
                  { id: vatAssessment!.assessmentNumber }
                )
              ))
            ) {
              skipDownloadChecks.current = true;
              // Rerun this function while skipping checks
              await getPaymentDocumentImpl(evt);
            }
          }
        } else {
          evt.target.click();
          skipDownloadChecks.current = false;
        }
      } catch (err) {
        enqueueSnackbar(
          t('vatAssessments:errorGettingPaymentDocument', {
            id: vatAssessment!.assessmentNumber,
          }),
          { variant: 'error' }
        );
      }
    },
    [
      canRecalculateInterest,
      confirm,
      enqueueSnackbar,
      getVatAssessment,
      isMounted,
      onFailureDueToOutdatedUI,
      t,
      vatAssessment,
    ]
  );

  /**
   * Function that runs when the user clicks the "email payment document"
   * button.
   */
  const emailPaymentDocumentImpl = useCallback(async () => {
    // When emailing an effective payment document, we first check that the
    // assessment is up to date (that the view isn't outdated) to prevent
    // accidentally emailing the wrong document
    if (vatAssessment!.status === VatAssessmentStatus.Effective) {
      try {
        const newVatAssessment = await getVatAssessment(
          vatAssessment!.assessmentNumber
        );
        if (newVatAssessment.status !== VatAssessmentStatus.Effective) {
          enqueueSnackbar(
            t(
              'vatAssessments:errorEmailingPaymentDocumentDueToAssessmentOutdatedUI',
              {
                id: vatAssessment!.assessmentNumber,
              }
            ),
            { variant: 'error' }
          );
          if (isMounted.current) {
            onFailureDueToOutdatedUI();
          }
        } else {
          // Warn when getting a payment document with outdated interest (when
          // it is possible to recalculate the interest)
          if (
            canRecalculateInterest(vatAssessment) &&
            !(await confirm(
              t(
                'vatAssessments:confirmEmailPaymentDocumentWithRecalculationAvailable',
                { id: vatAssessment!.assessmentNumber }
              )
            ))
          ) {
            return;
          }
        }
      } catch (err) {
        enqueueSnackbar(
          t('vatAssessments:errorEmailingPaymentDocument', {
            id: vatAssessment!.assessmentNumber,
          }),
          { variant: 'error' }
        );
      }
    }

    try {
      await emailPaymentDocument(vatAssessment!.assessmentNumber);
      enqueueSnackbar(
        t('vatAssessments:paymentDocumentEmailSentSuccessfully', {
          id: vatAssessment!.assessmentNumber,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        // The only way this should happen is if the taxpayer no longer has an
        // email, otherwise they all shouldn't fail with a `400`
        enqueueSnackbar(
          t(
            'vatAssessments:errorEmailingPaymentDocumentDueToTaxpayerOutdatedUI',
            {
              id: vatAssessment!.assessmentNumber,
            }
          ),
          { variant: 'error' }
        );
        if (isMounted.current) {
          onFailureDueToOutdatedUI();
        }
      } else {
        enqueueSnackbar(
          t('vatAssessments:errorEmailingPaymentDocument', {
            id: vatAssessment!.assessmentNumber,
          }),
          { variant: 'error' }
        );
      }
    }
  }, [
    canRecalculateInterest,
    confirm,
    emailPaymentDocument,
    enqueueSnackbar,
    getVatAssessment,
    isMounted,
    onFailureDueToOutdatedUI,
    t,
    vatAssessment,
  ]);

  /**
   * Recalculate the VAT assessment's interest.
   */
  const recalculateImpl = useCallback(async () => {
    try {
      const newVatAssessment = await recalculateVatAssessment(
        vatAssessment!.assessmentNumber
      );
      enqueueSnackbar(
        t('vatAssessments:vatAssessmentRecalculatedSuccessfully', {
          id: vatAssessment!.assessmentNumber,
        }),
        { variant: 'success' }
      );
      if (isMounted.current) {
        onRecalculate(newVatAssessment);
      }
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        enqueueSnackbar(
          t('vatAssessments:errorRecalculatingVatAssessmentDueToOutdatedUI', {
            id: vatAssessment!.assessmentNumber,
          }),
          { variant: 'error' }
        );
        if (isMounted.current) {
          onFailureDueToOutdatedUI();
        }
      } else {
        enqueueSnackbar(
          t('vatAssessments:errorRecalculatingVatAssessment', {
            id: vatAssessment!.assessmentNumber,
          }),
          { variant: 'error' }
        );
      }
    }
  }, [
    recalculateVatAssessment,
    vatAssessment,
    enqueueSnackbar,
    t,
    isMounted,
    onRecalculate,
    onFailureDueToOutdatedUI,
  ]);

  const vatAssessmentPath = replacePathParams(VAT_ASSESSMENT_PATH, {
    id: vatAssessment?.assessmentNumber,
  });

  return useMemo(
    () => [
      {
        id: `vat-assessment-${vatAssessment?.assessmentId}-open`,
        label: t('vatAssessments:vatAssessmentActions.open'),
        component: Link,
        to: vatAssessmentPath,
        icon: <OpenInNew />,
        hidden: !vatAssessment || pathname === vatAssessmentPath,
      },
      {
        id: `vat-assessment-${vatAssessment?.assessmentId}-payment-document`,
        label: t('vatAssessments:vatAssessmentActions.paymentDocument'),
        icon: <ListAlt />,
        hidden:
          !vatAssessment ||
          (vatAssessment.vatFinal === 0 && vatAssessment.interestFinal === 0),
        children: [
          {
            id: `vat-assessment-${vatAssessment?.assessmentId}-get-payment-document`,
            label: t('vatAssessments:vatAssessmentActions.getPaymentDocument'),
            component: 'a',
            run: getPaymentDocumentImpl,
            href: `${VAT_ASSESSMENTS_URL}/${encodeURIComponent(
              vatAssessment?.assessmentNumber ?? ''
            )}/payment-document`,
            download: `${t('vatAssessments:paymentDocumentFileName')}.pdf`,
            icon: <SaveAlt />,
            loading: !taxPeriodsCatalog,
          },
          {
            id: `vat-assessment-${vatAssessment?.assessmentId}-email-payment-document`,
            label: t(
              'vatAssessments:vatAssessmentActions.emailPaymentDocument'
            ),
            run: emailPaymentDocumentImpl,
            icon: <Email />,
            loading: !taxPeriodsCatalog,
            disabled: !vatAssessment?.taxpayerEmail,
          },
        ],
      } as ActionGroup,
      {
        id: `vat-assessment-${vatAssessment?.assessmentId}-recalculate`,
        label: t('vatAssessments:vatAssessmentActions.recalculate'),
        run: recalculateImpl,
        icon: <Refresh />,
        disabled: !canRecalculateInterest(vatAssessment),
        loading: !taxPeriodsCatalog,
        hidden:
          !vatAssessment ||
          vatAssessment.status !== VatAssessmentStatus.Effective ||
          (vatAssessment.vatFinal === 0 && vatAssessment.interestFinal === 0),
      },
    ],
    [
      canRecalculateInterest,
      emailPaymentDocumentImpl,
      getPaymentDocumentImpl,
      pathname,
      recalculateImpl,
      t,
      taxPeriodsCatalog,
      vatAssessment,
      vatAssessmentPath,
    ]
  );
}
