import React from 'react';
import { VatReturn } from '../vatReturnsApi';
import { ActionButtons } from '../../../components/Actions';
import { useVatReturnActions } from '../useVatReturnActions';
import { NotSpecified } from '../../../components/NotSpecified';
import { useTranslation } from 'react-i18next';

/**
 * Properties for the VAT return history row actions.
 */
interface VatReturnHistoryRowActionsProps {
  // Whether this return is the one currently in view
  currentlyOpen: boolean;
  vatReturn: VatReturn;
  onVoid: () => void;
}

/**
 * Actions in a VAT return history row.
 */
export function VatReturnHistoryRowActions({
  currentlyOpen,
  vatReturn,
  onVoid,
}: VatReturnHistoryRowActionsProps) {
  const [t] = useTranslation('vatReturns');
  const actions = useVatReturnActions(vatReturn, onVoid)!;

  return currentlyOpen ? (
    <NotSpecified>
      {t('vatReturns:vatReturnPage.vatReturnHistoryDataTable.currentlyOpen')}
    </NotSpecified>
  ) : (
    <ActionButtons actions={actions} variant="outlined" size="small" />
  );
}
