import React from 'react';
import { ivageCommonUtil } from '../../ivageCommon';
import { FormNumberField, FormNumberFieldProps } from './FormNumberField';

/**
 * Properties of the taxpayer ID field.
 */
export type FormTaxpayerIdFieldProps = FormNumberFieldProps;

/**
 * Taxpayer id field to be used within an RHF form context.
 */
export function FormTaxpayerIdField({
  isNumericString = true,
  allowLeadingZeroes = true,
  inputProps = {},
  ...otherProps
}: FormTaxpayerIdFieldProps) {
  return (
    <FormNumberField
      isNumericString={isNumericString}
      allowLeadingZeros={allowLeadingZeroes}
      min={0}
      decimalSeparator={false}
      inputProps={{
        ...inputProps,
        maxLength: ivageCommonUtil.TAXPAYER_ID_LENGTH,
      }}
      {...otherProps}
    />
  );
}
