import React from 'react';
import {
  createStyles,
  Link as MatLink,
  LinkProps as MatLinkProps,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { useProfile, UserRole } from '../providers/ProfileProvider';

/**
 * Style for disabled inputs (also used by other fields).
 */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    icon: {
      marginLeft: theme.spacing(0.5),
      opacity: 0.55,
      '@media print': {
        display: 'none',
      }
    },
  })
);

interface CustomLinkProps {
  showIcon?: boolean;
  /**
   * When specified, the component will only provide a link if the logged in
   * user has the role.
   */
  role?: UserRole;
}

/**
 * Properties of the link component.
 */
export type LinkProps = CustomLinkProps &
  Omit<MatLinkProps<RouterLink>, 'common'> &
  RouterLinkProps;

/**
 * Link component using the Material UI `Link` component together with the React
 * Router `Link` component.
 */
export function Link({
  showIcon = true,
  role,
  className,
  color = 'inherit',
  children,
  ...otherProps
}: LinkProps) {
  const classes = useStyles();
  const { hasRole } = useProfile();

  return role && !hasRole(role) ? (
    <>{children}</>
  ) : (
    <MatLink
      className={`${classes.root} ${className ?? ''}`}
      component={RouterLink}
      color={color}
      {...otherProps}
    >
      {children}
      {showIcon && <LinkIcon className={classes.icon} />}
    </MatLink>
  );
}
