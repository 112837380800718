import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { Taxpayer, TaxpayerStatus } from '../taxpayersApi';
import { TaxpayerRowActions } from './TaxpayerRowActions';
import { NotSpecified } from '../../../components/NotSpecified';
import { StatusChip } from '../../../components/StatusChip';
import { Link } from '../../../components/Link';
import { replacePathParams, TAXPAYER_PATH } from '../../../navigation/paths';

/**
 * Properties of the taxpayers table.
 */
export interface TaxpayersTableProps {
  fetching: boolean;
  taxpayers: Taxpayer[];
  refetchTaxpayers: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing taxpayers.
 */
export function TaxpayersTable({
  fetching,
  taxpayers,
  refetchTaxpayers,
  downloadButton,
}: TaxpayersTableProps) {
  const [t] = useTranslation(['common', 'taxpayers']);
  const {
    catalog: divisionsCatalog,
    isFetching: isFetchingDivisionsCatalog,
  } = useCatalog('divisions');
  const {
    catalog: sectorsCatalog,
    isFetching: isFetchingSectorsCatalog,
  } = useCatalog('sectors');

  const columns: DataTableColumns<Taxpayer> = {
    taxpayerId: {
      label: t('taxpayers:taxpayerFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    oldTaxpayerId: {
      label: t('taxpayers:taxpayerFields.oldTaxpayerId'),
      value: ({ oldTaxpayerId }) => oldTaxpayerId || null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>
            {t('taxpayers:oldTaxpayerIdNotSpecified')}
          </NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    name: {
      label: t('taxpayers:taxpayerFields.name'),
      value: ({ name }) => name,
    },
    representative: {
      label: t('taxpayers:taxpayerFields.representative'),
      value: ({ representative }) => representative,
    },
    divisionId: {
      label: t('taxpayers:taxpayerFields.municipality'),
      value: ({ divisionId }) =>
        divisionId == null
          ? null
          : divisionsCatalog?.municipalities.find(
              (municipality) => municipality.id === divisionId
            )?.name ?? null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>{t('taxpayers:municipalityNotSpecified')}</NotSpecified>
        ),
      fetching: isFetchingDivisionsCatalog,
    },
    sector: {
      label: t('taxpayers:taxpayerFields.sector'),
      value: ({ sectorId }) =>
        sectorId == null
          ? null
          : sectorsCatalog?.find((sector) => sector.id === sectorId)?.name ??
            null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>{t('taxpayers:sectorNotSpecified')}</NotSpecified>
        ),
      fetching: isFetchingSectorsCatalog,
    },
    status: {
      label: t('taxpayers:taxpayerFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`taxpayers:taxpayerStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={status === TaxpayerStatus.Active ? 'success' : 'error'}
          label={content}
        />
      ),
      paddinglessY: true,
    },
    changedDate: {
      value: ({ changedDate }) => changedDate,
      defaultSortDirection: 'desc',
      hidden: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <TaxpayerRowActions
          taxpayer={row}
          onFailureDueToOutdatedUI={refetchTaxpayers}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <DataTable
      title={t('taxpayers:taxpayersPage.dataTable.title')}
      rows={taxpayers}
      rowId={({ taxpayerId }) => taxpayerId}
      columns={columns}
      emptyMessage={t('taxpayers:taxpayersPage.dataTable.emptyMessage')}
      toolbarActionsLeft={downloadButton}
      fetching={fetching}
      defaultSortBy="changedDate"
      minWidth={1100}
    />
  );
}
