import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { PaymentRefund, PaymentRefundStatus } from '../paymentRefundsApi';
import { PaymentRefundRowActions } from './PaymentRefundRowActions';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import { StatusChip } from '../../../components/StatusChip';
import {
  PAYMENT_REFUND_PATH,
  replacePathParams,
  TAXPAYER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { Link } from '../../../components/Link';
import { NotSpecified } from '../../../components/NotSpecified';
import { VoidPaymentRefundForm } from '../VoidPaymentRefundForm';

/**
 * Properties of the payments table.
 */
export interface PaymentRefundsTableProps {
  fetching: boolean;
  paymentRefunds: PaymentRefund[];
  setPaymentRefunds: Dispatch<SetStateAction<PaymentRefund[] | null>>;
  refetchPaymentRefunds: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing payments.
 */
export function PaymentRefundsTable({
  fetching,
  paymentRefunds,
  setPaymentRefunds,
  refetchPaymentRefunds,
  downloadButton,
}: PaymentRefundsTableProps) {
  const [t] = useTranslation(['common', 'paymentRefunds']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const [paymentRefundVoiding, setPaymentRefundVoiding] =
    useState<PaymentRefund | null>(null);

  const setPaymentRefund = useCallback(
    (paymentRefund: PaymentRefund) => {
      setPaymentRefunds(
        (paymentRefunds) =>
          paymentRefunds &&
          paymentRefunds.map((ref) =>
            ref.paymentRefundId === paymentRefund.paymentRefundId
              ? paymentRefund
              : ref
          )
      );
    },
    [setPaymentRefunds]
  );

  const columns: DataTableColumns<PaymentRefund> = {
    paymentRefundId: {
      label: '#',
      value: ({ paymentRefundId }) => paymentRefundId,
      render: (content, value) => (
        <Link to={replacePathParams(PAYMENT_REFUND_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    assessmentNumber: {
      label: t('paymentRefunds:paymentRefundFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber || null,
      render: (content, value) =>
        value ? (
          <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
            {content}
          </Link>
        ) : (
          <NotSpecified>{t('paymentRefunds:noAssessmentNumber')}</NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    taxpayerId: {
      label: t('paymentRefunds:paymentRefundFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxableYearPeriod: {
      label: t('paymentRefunds:paymentRefundFields.taxablePeriod'),
      value: ({ taxableYear, taxablePeriod }) =>
        `${taxableYear}/${taxablePeriod}`,
      defaultSortDirection: 'desc',
    },
    paymentRefundDate: {
      label: t('paymentRefunds:paymentRefundFields.date'),
      value: ({ paymentRefundDate }) => paymentRefundDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    amount: {
      label: t('paymentRefunds:paymentRefundFields.amount'),
      value: ({ amount }) => amount,
      format: (value) => formatCurrency(value as number),
      align: 'right',
    },
    status: {
      label: t('paymentRefunds:paymentRefundFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`paymentRefunds:paymentRefundStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={status === PaymentRefundStatus.Voided ? 'error' : 'success'}
          label={content}
        />
      ),
      paddinglessY: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <PaymentRefundRowActions
          paymentRefund={row}
          onVoid={() => {
            setPaymentRefundVoiding(row);
            setIsVoidFormOpen(true);
          }}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <>
      <VoidPaymentRefundForm
        paymentRefund={paymentRefundVoiding}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newPaymentRefund) => setPaymentRefund(newPaymentRefund)}
        onFailureDueToOutdatedUI={refetchPaymentRefunds}
      />

      <DataTable
        title={t('paymentRefunds:paymentRefundsPage.dataTable.title')}
        rows={paymentRefunds}
        rowId={({ paymentRefundId }) => paymentRefundId}
        columns={columns}
        emptyMessage={t(
          'paymentRefunds:paymentRefundsPage.dataTable.emptyMessage'
        )}
        toolbarActionsLeft={downloadButton}
        fetching={fetching}
        defaultSortBy="taxableYearPeriod"
        minWidth={1100}
      />
    </>
  );
}
