/**
 * API to reset the logged in user's password.
 */
export async function resetPassword(
  newPassword: string,
): Promise<void> {
  const res = await fetch('/api/profile/reset-password', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ newPassword }),
  });
  if (res.ok) {
    return;
  }
  throw res;
}
