import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { useDateFormatter } from '../../../util/formatters';
import { VatActivity, Taxpayer } from '../taxpayersApi';
import { NotSpecified } from '../../../components/NotSpecified';
import { ActionButtons } from '../../../components/Actions';
import { Add } from '@material-ui/icons';
import { VatActivityForm } from './VatActivityForm';
import { mergeVatActivityIntervals, vatActivitiesToIntervals } from './util';
import { isEqual, maxTime } from 'date-fns';
import { VatActivityRowActions } from './VatActivityRowActions';

/**
 * Properties of the VAT activities table.
 */
export interface VatActivitiesTableProps {
  taxpayer: Taxpayer | null;
  refetchTaxpayer: () => void;
  setVatActivities: Dispatch<SetStateAction<VatActivity[]>>;
  fetching: boolean;
}

/**
 * Table listing VAT activities.
 */
export function VatActivitiesTable({
  taxpayer,
  refetchTaxpayer,
  setVatActivities,
  fetching,
}: VatActivitiesTableProps) {
  const [t] = useTranslation(['common', 'taxpayers']);
  const formatDate = useDateFormatter();

  // VAT activity being edited (`null` when creating a new one)
  const [
    editingVatActivity,
    setEditingVatActivity,
  ] = useState<VatActivity | null>(null);
  const [activityFormIsOpen, setActivityFormIsOpen] = useState(false);

  // Decide whether we can create a new VAT activity. No activities can be
  // created when there isn't any possible "time slot" where an activity could
  // fit: this happens when the result of merging all activity intervals is
  // equal to the start date/end date interval of the taxpayer
  const mergedActivities = useMemo(
    () =>
      taxpayer &&
      mergeVatActivityIntervals(vatActivitiesToIntervals(taxpayer, null)),
    [taxpayer]
  );
  const canCreateVatActivity =
    mergedActivities != null &&
    (mergedActivities.length !== 1 ||
      !isEqual(mergedActivities[0].start, taxpayer!.startDate) ||
      !isEqual(
        mergedActivities[0].end,
        taxpayer!.endDate ?? new Date(maxTime)
      ));

  // Runs after a successful create/edit VAT activity
  const onVatActivityFormSuccess = useCallback(
    (newVatActivity: VatActivity) => {
      if (editingVatActivity == null) {
        // Created new activity
        setVatActivities((vatActivities) => [newVatActivity, ...vatActivities]);
      } else {
        // Edited an activity
        setVatActivities((vatActivities) =>
          vatActivities.map((activity) =>
            activity === editingVatActivity ? newVatActivity : activity
          )
        );
      }
    },
    [setVatActivities, editingVatActivity]
  );

  // Runs when clicking on the "edit" button of a VAT activity
  const onEditVatActivityActionClick = useCallback(
    (vatActivity: VatActivity) => {
      setEditingVatActivity(vatActivity);
      setActivityFormIsOpen(true);
    },
    []
  );

  // Runs after a successful removal of a VAT activity
  const onRemoveVatActivity = useCallback(
    (removedVatActivity: VatActivity) =>
      setVatActivities((vatActivities) =>
        vatActivities.filter(
          (activity) =>
            activity.vatActivityId !== removedVatActivity.vatActivityId
        )
      ),
    [setVatActivities]
  );

  const columns: DataTableColumns<VatActivity> = {
    vatActivityId: {
      label: '#',
      value: ({ vatActivityId }) => vatActivityId,
      defaultSortDirection: 'desc',
    },
    startDate: {
      label: t('taxpayers:vatActivityFields.startDate'),
      value: ({ startDate }) => startDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    endDate: {
      label: t('taxpayers:vatActivityFields.endDate'),
      value: ({ endDate }) => endDate,
      format: (value) => formatDate(value as Date | null),
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>
            {t('taxpayers:activityEndDateNotSpecified')}
          </NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <VatActivityRowActions
          taxpayer={taxpayer}
          vatActivity={row}
          onEditActionClick={onEditVatActivityActionClick}
          onRemove={onRemoveVatActivity}
          onRemoveFailureDueToOutdatedUI={refetchTaxpayer}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  const tableActions = (
    <ActionButtons
      actions={[
        {
          id: 'new',
          label: t('taxpayers:taxpayerPage.vatActivitiesDataTable.actions.new'),
          run: () => {
            setEditingVatActivity(null);
            setActivityFormIsOpen(true);
          },
          color: 'primary',
          disabled: !canCreateVatActivity,
          icon: <Add />,
        },
      ]}
      variant="contained"
    />
  );

  return (
    <>
      {taxpayer && (
        <VatActivityForm
          taxpayer={taxpayer}
          vatActivity={editingVatActivity}
          open={activityFormIsOpen}
          close={() => setActivityFormIsOpen(false)}
          onSuccess={onVatActivityFormSuccess}
        />
      )}

      <DataTable
        title={t('taxpayers:taxpayerPage.vatActivitiesDataTable.title')}
        rows={taxpayer?.vatActivities ?? []}
        rowId={({ vatActivityId }) => vatActivityId}
        columns={columns}
        emptyMessage={t(
          'taxpayers:taxpayerPage.vatActivitiesDataTable.emptyMessage'
        )}
        toolbarActionsRight={tableActions}
        fetching={fetching}
        defaultSortBy="startDate"
        allowFilter={false}
        allowPagination={false}
        rowsPerPage={Number.MAX_VALUE}
        minWidth={400}
        sortByQueryParam="vatActivitiesSortBy"
        sortDirectionQueryParam="vatActivitiesSortDirection"
      />
    </>
  );
}
