import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { useCurrencyFormatter } from '../../../util/formatters';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import {
  CHECKING_ACCOUNTS_URL,
  CheckingAccount,
  CheckingAccountPeriod,
  useCheckingAccountsApi,
} from '../checkingAccountsApi';
import { CheckingAccountsPerPeriodTable } from './CheckingAccountsPerPeriodTable';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { useResource } from '../../../util/useResource';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

type CheckingAccountYear = Omit<
  CheckingAccountPeriod,
  'taxablePeriod' | 'assessmentNumber'
>;

/**
 * Properties of the checking account per year table.
 */
export interface CheckingAccountsPerYearTableProps {
  checkingAccount: CheckingAccount | null;
}

/**
 * Table listing the checking accounts per year.
 */
export function CheckingAccountsPerYearTable({
  checkingAccount,
}: CheckingAccountsPerYearTableProps) {
  const [t] = useTranslation(['common', 'checkingAccounts']);
  const formatCurrency = useCurrencyFormatter();

  const { getCheckingAccountPeriods } = useCheckingAccountsApi();
  const fetchCheckingAccountPeriods = useCallback(
    async () =>
      checkingAccount && getCheckingAccountPeriods(checkingAccount.taxpayerId),
    [checkingAccount, getCheckingAccountPeriods]
  );
  const { isFetching, resource: checkingAccountPeriods } = useResource({
    fetchResource: fetchCheckingAccountPeriods,
    errorFetchingResourceMessage: t(
      'checkingAccounts:checkingAccountPage.errorFetchingCheckingAccountPeriods'
    ),
  });

  const checkingAccountsPerYear = useMemo(
    () =>
      checkingAccountPeriods?.reduce((obj, periodAccount) => {
        (
          obj[periodAccount.taxableYear] ||
          (obj[periodAccount.taxableYear] = [])
        ).push(periodAccount);
        return obj;
      }, {} as Record<string, CheckingAccountPeriod[]>),
    [checkingAccountPeriods]
  );

  const checkingAccountYears = useMemo(
    () =>
      checkingAccountsPerYear &&
      Object.keys(checkingAccountsPerYear).map((taxableYear) =>
        checkingAccountsPerYear[taxableYear].reduce(
          (yearAccount, periodAccount) => ({
            ...yearAccount,
            vatFinal: yearAccount.vatFinal + periodAccount.vatFinal,
            interestFinal:
              yearAccount.interestFinal + periodAccount.interestFinal,
            vatCreditAvailable:
              yearAccount.vatCreditAvailable + periodAccount.vatCreditAvailable,
            paymentRefundableFinal:
              yearAccount.paymentRefundableFinal +
              periodAccount.paymentRefundableFinal,
          }),
          {
            taxableYear: +taxableYear,
            vatFinal: 0,
            interestFinal: 0,
            vatCreditAvailable: 0,
            paymentRefundableFinal: 0,
          } as CheckingAccountYear
        )
      ),
    [checkingAccountsPerYear]
  );

  const columns: DataTableColumns<CheckingAccountYear> = {
    taxableYear: {
      label: t('checkingAccounts:checkingAccountPeriodFields.taxableYear'),
      value: ({ taxableYear }) => taxableYear,
      defaultSortDirection: 'desc',
      sortable: false,
    },
    debits: {
      align: 'right',
      label: t('checkingAccounts:checkingAccountPeriodFields.debits'),
      value: ({ vatFinal, interestFinal }) => vatFinal + interestFinal,
      format: (value, { vatCreditAvailable, paymentRefundableFinal }) =>
        value === 0 &&
        (vatCreditAvailable !== 0 || paymentRefundableFinal !== 0)
          ? ''
          : formatCurrency(value as number),
      sortable: false,
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t('checkingAccounts:checkingAccountPeriodFields.vatFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.interestFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('checkingAccounts:checkingAccountPeriodFields.credits'),
      value: ({ vatCreditAvailable, paymentRefundableFinal }) =>
        vatCreditAvailable + paymentRefundableFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
      sortable: false,
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatCreditAvailable !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatCreditAvailable)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.vatCreditAvailable'
                )}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t(
                  'checkingAccounts:checkingAccountPeriodFields.paymentRefundableFinal'
                )}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
  };

  return (
    <DataTable
      title={t(
        'checkingAccounts:checkingAccountPage.checkingAccountsPerYearDataTable.title'
      )}
      rows={checkingAccountYears || []}
      rowId={({ taxableYear }) => taxableYear}
      columns={columns}
      emptyMessage={t(
        'checkingAccounts:checkingAccountPage.checkingAccountsPerYearDataTable.emptyMessage'
      )}
      toolbarActionsLeft={
        <DownloadTableButton
          fileName={t(
            'checkingAccounts:checkingAccountPage.checkingAccountsPerYearDataTable.downloadFileName'
          )}
          downloadUrl={`${CHECKING_ACCOUNTS_URL}/${encodeURIComponent(
            checkingAccount?.taxpayerId!
          )}/periods?${objectToQueryParamsString({
            format: 'csv',
          })}`}
          disabled={!checkingAccount || isFetching}
        />
      }
      fetching={!checkingAccount || isFetching}
      allowFilter={false}
      allowPagination={false}
      defaultSortBy="taxableYear"
      rowsPerPage={Number.MAX_VALUE}
      minWidth={400}
      renderExpandedRow={(row) => (
        <CheckingAccountsPerPeriodTable
          checkingAccountPeriods={checkingAccountsPerYear![row.taxableYear]}
        />
      )}
    />
  );
}
