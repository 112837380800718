import {createContext, SetStateAction} from 'react';

/**
 * Representation of the store.
 */
export interface Store<T = any> {
  get(key: string): T | undefined;
  get(key: string, defaultValue: T): T;
  set(key: string, value: SetStateAction<T>): void;
  delete(key: string): void;
}

/**
 * Store context.
 */
export const StoreContext = createContext<Store>(undefined as any);
