import React from 'react';
import { Fade, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useProfile } from '../../providers/ProfileProvider';
import { Save } from '@material-ui/icons';
import { Form, FormTextField } from '../../components/Form';
import { useLfForm } from '../../util/lfIntegration';
import { ivageCommon } from '../../ivageCommon';
import { useProfileApi } from './profileApi';
import { ActionButton } from '../../components/Actions/ActionButton';
import {useIsMounted} from "../../util/useIsMounted";

/**
 * Package in IVAGE common containing the edit display name form schema.
 */
const editDisplayNameSchemaPkg = ivageCommon.feature.usermanagement.schema;

/**
 * Component used to change the logged in user's display name.
 */
export function EditDisplayNameForm() {
  const [t] = useTranslation('profile');
  const { changeDisplayName } = useProfileApi();
  const { enqueueSnackbar } = useSnackbar();
  const { profile, setProfile } = useProfile();
  const formMethods = useLfForm({
    defaultValues: { displayName: profile!.displayName || '' },
    formValidatorName: 'editDisplayNameFormValidator',
    i18nErrorMessagesPrefixes:
      'profile:profilePage.editDisplayNameForm.fieldErrors',
  });
  const { getValues, reset, formState } = formMethods;
  const isMounted = useIsMounted();

  async function onSubmit() {
    const displayName = getValues('displayName');
    try {
      await changeDisplayName(displayName);
      setProfile({ ...profile!, displayName });
      enqueueSnackbar(
        t(
          'profile:profilePage.editDisplayNameForm.displayNameChangedSuccessfully'
        ),
        { variant: 'success' }
      );

      if (isMounted.current) {
        reset({displayName});
      }
    } catch (err) {
      enqueueSnackbar(
        t('profile:profilePage.editDisplayNameForm.errorChangingDisplayName'),
        { variant: 'error' }
      );
    }
  }

  // Edit action
  const editAction = {
    id: 'edit-display-name-form-submit',
    type: 'submit',
    label: t('profile:profilePage.editDisplayNameForm.save'),
    color: 'primary',
    icon: <Save />,
    loading: formState.isSubmitting,
    disabled: !formState.isDirty,
  };

  return (
    <Form
      shouldPromptOnLeave={formState.isDirty}
      onSubmit={onSubmit}
      {...formMethods}
    >
      <FormTextField
        name="displayName"
        size="small"
        inputProps={{
          maxLength: editDisplayNameSchemaPkg.DISPLAY_NAME_MAX_LENGTH,
        }}
        extraEndAdornment={
          <Fade in={formState.isDirty}>
            <InputAdornment position="end">
              <ActionButton
                variant="contained"
                size="small"
                action={editAction}
              />
            </InputAdornment>
          </Fade>
        }
      />
    </Form>
  );
}
