import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { PaymentRefund, usePaymentRefundsApi } from './paymentRefundsApi';
import { useTranslation } from 'react-i18next';

/**
 * Result of the `usePaymentRefund` hook.
 */
export interface PaymentRefundResource {
  isFetching: boolean;
  paymentRefund: PaymentRefund | null;
  notFound: boolean;
  setPaymentRefund: Dispatch<SetStateAction<PaymentRefund | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a payment refund from the API, if no `paymentRefundId` is
 * passed this hook does nothing.
 */
export function usePaymentRefund(
  paymentRefundId?: number
): PaymentRefundResource {
  const [t] = useTranslation('paymentRefunds');
  const { getPaymentRefund } = usePaymentRefundsApi();
  const getPaymentRefundCb = useCallback(
    () => (paymentRefundId != null ? getPaymentRefund(paymentRefundId) : null),
    [getPaymentRefund, paymentRefundId]
  );
  const {
    isFetching,
    resource: paymentRefund,
    notFound,
    setResource: setPaymentRefund,
    refetch,
  } = useResource({
    fetchResource: getPaymentRefundCb,
    errorFetchingResourceMessage: t(
      'paymentRefunds:errorFetchingPaymentRefund',
      { id: paymentRefundId ?? '' }
    ),
  });

  return { isFetching, paymentRefund, notFound, setPaymentRefund, refetch };
}
