import { CreateUserForm, UpdateUserForm, User } from '../usersApi';

/**
 * Default "create user form" value.
 */
export function defaultCreateUserForm(): CreateUserForm {
  return {
    username: '',
    password: '',
    firstName: '',
    surname: '',
    displayName: '',
    email: '',
    civilId: '',
    organizationalUnitId: null,
    role: null,
  };
}

/**
 * Default "update user form" value.
 */
export function defaultUpdateUserForm(user: User): UpdateUserForm {
  return {
    password: null,
    firstName: user.firstName ?? '',
    surname: user.surname ?? '',
    displayName: user.displayName ?? '',
    email: user.email ?? '',
    civilId: user.civilId ?? '',
    organizationalUnitId: user.organizationalUnitId ?? null,
    role: user.role ?? null,
  };
}
