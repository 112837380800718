import { createContext, MutableRefObject } from 'react';
import { CatalogName, Catalogs } from './catalogsApi';

/**
 * Object where, for each catalog, we store the promise responsible for fetching
 * it.
 */
export type CatalogPromises = {
  [catalog in CatalogName]?: Promise<Catalogs[catalog]>;
};

/**
 * Representation of the catalogs context.
 */
export interface CatalogPromisesState {
  catalogPromises: MutableRefObject<CatalogPromises>;
}

/**
 * Catalogs context.
 */
export const CatalogsContext = createContext<CatalogPromisesState>(
  undefined as any
);
