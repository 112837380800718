import React, { ReactNode, RefObject } from 'react';
import {
  CircularProgress,
  Fade,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { resolveObject } from '../../util/resolveObject';
import { useDisabledFieldStyles } from './ReadOnlyField';
import { triggerNames } from '../../util/triggerNames';
import { useInputRef } from './util/useInputRef';
import { scrollAndFocus } from './util/scrollAndFocus';

interface CustomFormTextFieldProps {
  name: string;
  fetching?: boolean;
  rules?: RegisterOptions;
  extraEndAdornment?: ReactNode;
  onChangeTriggers?: string[];
  inputRef?: RefObject<HTMLInputElement>;
}

/**
 * Properties of the text field.
 */
export type FormTextFieldProps = CustomFormTextFieldProps & TextFieldProps;

/**
 * Text field to be used within an RHF form context.
 */
export function FormTextField({
  name,
  label,
  fetching,
  disabled,
  rules,
  variant = disabled ? 'filled' : 'outlined',
  fullWidth = true,
  helperText,
  inputRef,
  onChange,
  onChangeTriggers,
  onBlur,
  extraEndAdornment,
  InputProps = {},
  ...otherProps
}: FormTextFieldProps) {
  const classes = useDisabledFieldStyles();
  const { control, errors, formState, trigger } = useFormContext();
  const textFieldRef = useInputRef(inputRef);

  const shouldDisable = disabled || fetching || formState.isSubmitting;
  const error = resolveObject(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => scrollAndFocus(textFieldRef)}
      render={({
        onChange: controllerOnChange,
        onBlur: controllerOnBlur,
        value,
        name,
        ref,
      }) => (
        <TextField
          variant={variant}
          fullWidth={fullWidth}
          label={label}
          disabled={shouldDisable}
          {...otherProps}
          value={value ?? ''}
          name={name}
          inputRef={textFieldRef}
          ref={ref}
          onChange={(evt) => {
            controllerOnChange(evt);
            onChangeTriggers &&
              triggerNames(formState, trigger, ...onChangeTriggers);
            onChange?.(evt);
          }}
          onBlur={(evt) => {
            controllerOnBlur();
            onBlur?.(evt);
          }}
          InputProps={{
            ...InputProps,
            classes: {
              ...InputProps.classes,
              disabled: `${classes.disabledField} ${
                InputProps.classes?.disabled ?? ''
              }`,
            },
            endAdornment: (fetching || extraEndAdornment) && (
              <InputAdornment position="end">
                <Fade in={fetching} mountOnEnter={true}>
                  <CircularProgress size={20} />
                </Fade>
                {extraEndAdornment}
              </InputAdornment>
            ),
          }}
          error={!!error}
          helperText={error?.message ?? helperText}
        />
      )}
    />
  );
}
