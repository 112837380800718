import { VatActivity, VatActivityForm } from '../taxpayersApi';

/**
 * Default "create vat activity form" value.
 */
export function defaultCreateVatActivityForm(): VatActivityForm {
  return {
    startDate: null,
    endDate: null,
  };
}

/**
 * Default "update vat activity form" value.
 */
export function defaultUpdateVatActivityForm(
  vatActivity: VatActivity
): VatActivityForm {
  return {
    startDate: vatActivity.startDate ?? null,
    endDate: vatActivity.endDate ?? null,
  };
}
