import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { StatusChip } from '../../../components/StatusChip';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import {
  useVatReturnsApi,
  VAT_RETURNS_URL,
  VatReturn,
  VatReturnStatus,
} from '../vatReturnsApi';
import { VatReturnHistoryRowActions } from './VatReturnHistoryRowActions';
import { Link } from '../../../components/Link';
import {
  replacePathParams,
  VAT_ASSESSMENT_PATH,
  VAT_RETURN_PATH,
} from '../../../navigation/paths';
import { NotSpecified } from '../../../components/NotSpecified';
import { useResource } from '../../../util/useResource';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { DownloadTableButton } from '../../../components/DownloadTableButton';

/**
 * Properties of the VAT return history.
 */
export interface VatReturnHistoryTableProps {
  vatReturn: VatReturn | null;
  setVatReturnVoiding: Dispatch<SetStateAction<VatReturn | null>>;
  setIsVoidFormOpen: Dispatch<SetStateAction<boolean>>;
  setOnVoidSuccess: Dispatch<
    SetStateAction<((newVatReturn: VatReturn) => void) | undefined>
  >;
}

/**
 * Table listing the history of a VAT return.
 */
export function VatReturnHistoryTable({
  vatReturn,
  setVatReturnVoiding,
  setIsVoidFormOpen,
  setOnVoidSuccess,
}: VatReturnHistoryTableProps) {
  const [t] = useTranslation(['common', 'vatReturns']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();
  const { getVatReturns } = useVatReturnsApi();

  const fetchVatReturnsHistory = useCallback(
    () =>
      vatReturn
        ? getVatReturns({
            taxpayerId: vatReturn.taxpayerId,
            taxableYear: vatReturn.taxableYear,
            taxablePeriod: vatReturn.taxablePeriod,
          })
        : Promise.resolve([]),
    [getVatReturns, vatReturn]
  );
  const {
    resource: vatReturnHistory,
    setResource: setVatReturnHistory,
    isFetching,
  } = useResource({
    fetchResource: fetchVatReturnsHistory,
    errorFetchingResourceMessage: t(
      'vatReturns:vatReturnPage.errorFetchingVatReturnHistory'
    ),
  });

  const setVatReturn = useCallback(
    (vatReturn: VatReturn) => {
      setVatReturnHistory(
        (vatReturnHistory) =>
          vatReturnHistory &&
          vatReturnHistory.map((ret) =>
            ret.taxReturnId === vatReturn.taxReturnId ? vatReturn : ret
          )
      );
    },
    [setVatReturnHistory]
  );

  const columns: DataTableColumns<VatReturn> = {
    taxReturnId: {
      label: '#',
      value: ({ taxReturnId }) => taxReturnId,
      render: (content, value, row) =>
        vatReturn?.taxReturnId === row.taxReturnId ? (
          content
        ) : (
          <Link to={replacePathParams(VAT_RETURN_PATH, { id: value })}>
            {content}
          </Link>
        ),
      defaultSortDirection: 'desc',
    },
    deliveryDate: {
      label: t('vatReturns:vatReturnFields.delivery'),
      value: ({ deliveryDate }) => deliveryDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    vatTotal: {
      align: 'right',
      label: t('vatReturns:vatReturnFields.vatTotal'),
      value: ({ vatTotal }) => vatTotal,
      format: (value, { vatCredit }) =>
        value === 0 && vatCredit !== 0 ? '' : formatCurrency(value as number),
    },
    vatCredit: {
      align: 'right',
      label: t('vatReturns:vatReturnFields.vatCredit'),
      value: ({ vatCredit }) => vatCredit,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
    },
    status: {
      label: t('vatReturns:vatReturnFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`vatReturns:vatReturnStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={
            status === VatReturnStatus.Effective
              ? 'success'
              : status === VatReturnStatus.Voided
              ? 'error'
              : 'default'
          }
          label={content}
        />
      ),
      paddinglessY: true,
    },
    assessmentNumber: {
      label: t('vatReturns:vatReturnFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber,
      render: (content, value) =>
        value ? (
          <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
            {content}
          </Link>
        ) : (
          <NotSpecified>{t('vatReturns:noAssessmentNumber')}</NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <VatReturnHistoryRowActions
          currentlyOpen={row.taxReturnId === vatReturn!!.taxReturnId}
          vatReturn={row}
          onVoid={() => {
            setVatReturnVoiding(row);
            setIsVoidFormOpen(true);
            setOnVoidSuccess(() => (newVatReturn: VatReturn) =>
              setVatReturn(newVatReturn)
            );
          }}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <DataTable
      title={t('vatReturns:vatReturnPage.vatReturnHistoryDataTable.title')}
      rows={vatReturnHistory || []}
      rowId={({ taxReturnId }) => taxReturnId}
      columns={columns}
      emptyMessage={t(
        'vatReturns:vatReturnPage.vatReturnHistoryDataTable.emptyMessage'
      )}
      toolbarActionsLeft={
        <DownloadTableButton
          fileName={t('vatReturns:vatReturnsPage.dataTable.downloadFileName')}
          downloadUrl={`${VAT_RETURNS_URL}?${objectToQueryParamsString({
            taxpayerId: vatReturn?.taxpayerId,
            taxableYear: vatReturn?.taxableYear,
            taxablePeriod: vatReturn?.taxablePeriod,
            format: 'csv',
          })}`}
          disabled={!vatReturn || isFetching}
        />
      }
      fetching={!vatReturn || isFetching}
      highlightRows={vatReturn ? [vatReturn.taxReturnId] : undefined}
      defaultSortBy="taxReturnId"
      minWidth={1000}
      filterQueryParam="historyFilter"
      pageQueryParam="historyPage"
      sortByQueryParam="historySortBy"
      sortDirectionQueryParam="historySortDirection"
    />
  );
}
