import {
  Chip,
  ChipProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { ThemeVariant } from '../providers/ThemeProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '@media print': {
        backgroundColor: 'transparent !important',
        border: `1px solid ${theme.palette.divider}`,
      }
    },
    statusSuccess: {
      backgroundColor:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.success.light
          : theme.palette.success.dark,
    },
    statusError: {
      backgroundColor:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.error.light
          : theme.palette.error.dark,
    },
  })
);

/**
 * Allowed status of the status chip.
 */
export type StatusChipStatus = 'default' | 'success' | 'error';

/**
 * Properties of the status chip.
 */
export interface StatusChipProps extends ChipProps {
  status?: StatusChipStatus;
}

/**
 * A "chip" used to display some kind of status.
 */
export function StatusChip({
  status = 'default',
  classes: cls,
  ...otherProps
}: StatusChipProps) {
  const classes = useStyles();

  return (
    <Chip
      {...otherProps}
      classes={{
        ...cls,
        root: `${cls?.root ?? ''} ${classes.root} ${
          status === 'success'
            ? classes.statusSuccess
            : status === 'error'
            ? classes.statusError
            : ''
        }`,
      }}
    />
  );
}
