import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { StatusChip } from '../../../components/StatusChip';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import {
  useVatAssessmentsApi,
  VAT_ASSESSMENTS_URL,
  VatAssessment,
  VatAssessmentStatus,
} from '../vatAssessmentsApi';
import { VatAssessmentHistoryRowActions } from './VatAssessmentHistoryRowActions';
import { useResource } from '../../../util/useResource';
import { Link } from '../../../components/Link';
import {
  replacePathParams,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

/**
 * Properties of the VAT assessment history.
 */
export interface VatAssessmentHistoryTableProps {
  vatAssessment: VatAssessment | null;
  refetchVatAssessment: () => void;
}

/**
 * Table listing the history of a VAT assessment.
 */
export function VatAssessmentHistoryTable({
  vatAssessment,
  refetchVatAssessment,
}: VatAssessmentHistoryTableProps) {
  const [t] = useTranslation(['common', 'vatAssessments']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();

  const { getVatAssessments } = useVatAssessmentsApi();
  const fetchVatAssessmentsHistory = useCallback(
    () =>
      vatAssessment
        ? getVatAssessments({
            taxpayerId: vatAssessment.taxpayerId,
            taxableYear: vatAssessment.taxableYear,
            taxablePeriod: vatAssessment.taxablePeriod,
          })
        : Promise.resolve([]),
    [getVatAssessments, vatAssessment]
  );
  const {
    resource: vatAssessmentHistory,
    setResource: setVatAssessmentHistory,
    isFetching,
  } = useResource({
    fetchResource: fetchVatAssessmentsHistory,
    errorFetchingResourceMessage: t(
      'vatAssessments:vatAssessmentPage.errorFetchingVatAssessmentHistory'
    ),
  });

  // When recalculating the interest of a VAT assessment in the history, add the
  // new assessment and change the old one's status to replaced
  const recalculateVatAssessment = useCallback(
    (oldVatAssessment: VatAssessment, newVatAssessment: VatAssessment) => {
      setVatAssessmentHistory(
        (vatAssessmentHistory) =>
          vatAssessmentHistory && [
            newVatAssessment,
            ...vatAssessmentHistory.map((assess) =>
              assess.assessmentId === oldVatAssessment.assessmentId
                ? { ...oldVatAssessment, status: VatAssessmentStatus.Replaced }
                : assess
            ),
          ]
      );
    },
    [setVatAssessmentHistory]
  );

  const columns: DataTableColumns<VatAssessment> = {
    assessmentNumber: {
      label: t('vatAssessments:vatAssessmentFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber,
      render: (content, value, row) =>
        vatAssessment?.assessmentId === row.assessmentId ? (
          content
        ) : (
          <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
            {content}
          </Link>
        ),
      defaultSortDirection: 'desc',
    },
    createdDate: {
      label: t('vatAssessments:vatAssessmentFields.created'),
      value: ({ createdDate }) => createdDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    debits: {
      align: 'right',
      label: t('vatAssessments:vatAssessmentFields.debits'),
      value: ({ vatFinal, interestFinal }) => vatFinal + interestFinal,
      format: (value, { vatRefundableFinal, paymentRefundableFinal }) =>
        value === 0 &&
        (vatRefundableFinal !== 0 || paymentRefundableFinal !== 0)
          ? ''
          : formatCurrency(value as number),
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.vatFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.interestFinal')}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('vatAssessments:vatAssessmentFields.credits'),
      value: ({ vatRefundableFinal, paymentRefundableFinal }) =>
        vatRefundableFinal + paymentRefundableFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatRefundableFinal !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell>
            <div>
              {formatCurrency(row.vatRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.vatRefundableFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatAssessmentFields.paymentRefundableFinal')}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    status: {
      label: t('vatAssessments:vatAssessmentFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`vatAssessments:vatAssessmentStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={
            status === VatAssessmentStatus.Effective
              ? 'success'
              : status === VatAssessmentStatus.Voided
              ? 'error'
              : 'default'
          }
          label={content}
        />
      ),
      paddinglessY: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <VatAssessmentHistoryRowActions
          currentlyOpen={
            row.assessmentNumber === vatAssessment!!.assessmentNumber
          }
          vatAssessment={row}
          onRecalculate={recalculateVatAssessment}
          onFailureDueToOutdatedUI={refetchVatAssessment}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <DataTable
      title={t(
        'vatAssessments:vatAssessmentPage.vatAssessmentHistoryDataTable.title'
      )}
      rows={vatAssessmentHistory || []}
      rowId={({ assessmentId }) => assessmentId}
      columns={columns}
      emptyMessage={t(
        'vatAssessments:vatAssessmentPage.vatAssessmentHistoryDataTable.emptyMessage'
      )}
      toolbarActionsLeft={
        <DownloadTableButton
          fileName={t(
            'vatAssessments:vatAssessmentsPage.dataTable.downloadFileName'
          )}
          downloadUrl={`${VAT_ASSESSMENTS_URL}?${objectToQueryParamsString({
            taxpayerId: vatAssessment?.taxpayerId,
            taxableYear: vatAssessment?.taxableYear,
            taxablePeriod: vatAssessment?.taxablePeriod,
            format: 'csv',
          })}`}
          disabled={!vatAssessment || isFetching}
        />
      }
      fetching={!vatAssessment || isFetching}
      highlightRows={vatAssessment ? [vatAssessment.assessmentId] : undefined}
      defaultSortBy="createdDate"
      minWidth={800}
      filterQueryParam="historyFilter"
      pageQueryParam="historyPage"
      sortByQueryParam="historySortBy"
      sortDirectionQueryParam="historySortDirection"
    />
  );
}
