import { useCallback } from 'react';
import { UserRole } from '../../providers/ProfileProvider';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Type of a user.
 */
export interface User {
  username: string;
  role: UserRole;
  firstName: string;
  surname: string;
  displayName: string;
  email: string;
  civilId: string;
  organizationalUnitId: number | null;
  status: UserStatus;
  createdDate: Date;
  changedDate: Date;
}

/**
 * Status of a user.
 */
export enum UserStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
}

/**
 * User form fields when creating a user.
 */
export interface CreateUserForm {
  username: string;
  password: string;
  firstName: string;
  surname: string;
  displayName: string;
  email: string;
  civilId: string;
  organizationalUnitId: number | null;
  role: UserRole | null;
}

/**
 * User form fields when updating a user.
 */
export type UpdateUserForm = Omit<CreateUserForm, 'username' | 'password'> & {
  password: string | null;
};

/**
 * Options used to get users.
 */
export interface GetUsersFilterOptions {
  username?: string;
  name?: string;
  email?: string;
  organizationalUnitId?: number;
  role?: UserRole;
  status?: UserStatus;
}

/**
 * URL to access users.
 */
export const USERS_URL = '/api/users';

/**
 * Transforms a user JSON as given by the API into our internal user
 * representation.
 */
function jsonToUser(userJson: any): User {
  return {
    ...userJson,
    createdDate: new Date(userJson.createdDate),
    changedDate: new Date(userJson.changedDate),
  };
}

/**
 * Hook exposing the users API.
 */
export function useUsersApi() {
  const { getJson, postJson, patchJson, putJson } = useFetchFromApi();

  /**
   * Gets a list of users matching the provided filter options.
   */
  const getUsers = useCallback(
    async (filterOptions: GetUsersFilterOptions = {}): Promise<User[]> =>
      (await getJson(USERS_URL, { params: filterOptions })).map(jsonToUser),
    [getJson]
  );

  /**
   * Gets the user with the provided username.
   */
  const getUser = useCallback(
    async (username: string): Promise<User> =>
      jsonToUser(await getJson(`${USERS_URL}/${encodeURIComponent(username)}`)),
    [getJson]
  );

  /**
   * Creates a new user from the provided user fields and returns it.
   */
  const createUser = useCallback(
    async (form: CreateUserForm): Promise<User> =>
      jsonToUser(await postJson(USERS_URL, form)),
    [postJson]
  );

  /**
   * Updates a user with the provided username with the given fields and returns
   * the updated user.
   */
  const updateUser = useCallback(
    async (username: string, form: UpdateUserForm): Promise<User> =>
      jsonToUser(
        await patchJson(`${USERS_URL}/${encodeURIComponent(username)}`, form)
      ),
    [patchJson]
  );

  /**
   * Updates the status of a user with the given username to the new provided
   * status.
   */
  const updateUserStatus = useCallback(
    async (username: string, newStatus: UserStatus): Promise<User> =>
      jsonToUser(
        await putJson(`${USERS_URL}/${encodeURIComponent(username)}/status`, {
          status: newStatus,
        })
      ),
    [putJson]
  );

  return { getUsers, getUser, createUser, updateUser, updateUserStatus };
}
