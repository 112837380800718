// @ts-ignore
import * as ivageCommonRoot from 'ivage-common';

/**
 * "Common" IVAGE module (top-most exports).
 */
export const ivageCommon: any = ivageCommonRoot.pt.opensoft.ivage;

/**
 * Util section of the "common" IVAGE module.
 */
export const ivageCommonUtil: any = ivageCommon.util;
