import { ReactNode, SetStateAction, useCallback, useState } from 'react';
import { StoreContext } from './StoreContext';

/**
 * Properties of the store provider.
 */
interface StoreProviderProps {
  children: ReactNode;
}

/**
 * Simple global store solution.
 */
export function StoreProvider({ children }: StoreProviderProps) {
  const [store, setStore] = useState<Record<string, any>>({});

  const get = useCallback(
    <T,>(key: string, defaultValue?: T) => {
      const val = store[key];
      return val === undefined ? defaultValue : val;
    },
    [store]
  );

  const set = useCallback((key: string, val: SetStateAction<any>) => {
    setStore((store) => ({
      ...store,
      [key]: typeof val === 'function' ? val(store[key]) : val,
    }));
  }, []);

  const del = useCallback((key: string) => {
    setStore((store) => {
      const { [key]: _, ...rest } = store;
      return rest;
    });
  }, []);

  return (
    <StoreContext.Provider value={{ get, set, delete: del }}>
      {children}
    </StoreContext.Provider>
  );
}
