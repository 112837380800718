import React, { ChangeEvent, MouseEvent, ReactNode, useMemo } from 'react';
import {
  createStyles,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  PaperProps,
  Select,
  Table,
  TableBody,
  TableCell,
  TableCellClassKey,
  TableContainer,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { SortDirection, stableSort } from '../../util/stableSort';
import { DataTableToolbar } from './DataTableToolbar';
import { DataTableHead } from './DataTableHead';
import { useTranslation } from 'react-i18next';
import { filterRows } from './filter';
import { Skeleton } from '@material-ui/lab';
import { DataTableRow } from './DataTableRow';
import { useQueryParams } from '../../util/useQueryParams';
import { isDate } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        borderRadius: 0,
      },
      '@media print': {
        breakInside: 'avoid',
        boxShadow: 'none',
        marginLeft: 0,
        marginRight: 0,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        '& .MuiTableHead-root': {
          breakInside: 'avoid',
          display: 'table-row-group',
        },
        '& tr': {
          breakInside: 'avoid',
        },
      },
    },
    table: {
      '& .MuiTableCell-sizeSmall': {
        paddingRight: theme.spacing(2),
      },
    },
    tableBody: {
      '& > tr:last-child > *': {
        borderBottomColor: 'transparent',
      },
    },
    fetching: {
      display: 'block',
      margin: '0 auto',
    },
    cellSkeleton: {
      minWidth: 20,
    },
    emptyMessage: {
      textAlign: 'center',
      color: theme.palette.text.hint,
    },
    pagination: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    paginationRows: {
      display: 'flex',
      alignItems: 'center',
    },
    paginationRowsSelect: {
      fontSize: theme.typography.fontSize,
      margin: theme.spacing(0, 0.5),
      marginTop: 1,
    },
    paginationRowsSelectRoot: {
      paddingLeft: theme.spacing(0.5),
    },
  })
);

/**
 * Identifier of a row.
 */
export type RowId = string | number;

/**
 * Identified of a column.
 */
export type ColumnId = string;

/**
 * Data table column settings.
 */
export interface DataTableColumn<
  R,
  V extends string | number | Date | null = string | number | Date | null
> {
  label?: ReactNode;
  align?: 'left' | 'right';
  filterable?: boolean;
  sortable?: boolean;
  defaultSortDirection?: SortDirection;
  fetching?: boolean;
  renderFetching?: ReactNode;
  /**
   * Hidden columns cannot be filtered, but it is possible to use them to sort
   * the table.
   */
  hidden?: boolean;
  /**
   * Actual value associated with the cell. Sorting works based on this value.
   */
  value?: (row: R) => V;
  /**
   * Formatted value of the cell. Requires `value` to be defined. Filtering
   * works based on this value.
   */
  format?: (value: V, row: R) => string;
  /**
   * Rendered cell. The passed content may be a highlighted react node.
   */
  render?: (content: ReactNode, value: V | undefined, row: R) => ReactNode;
  /**
   * Classes to apply to cells of this column.
   */
  cellClasses?: Partial<Record<TableCellClassKey, string>>;
  /**
   * Remove padding-y from cells of this column.
   */
  paddinglessY?: boolean;
  /**
   * Remove padding-x from cells of this column.
   */
  paddinglessX?: boolean;
  width?: number | string;
  hideInPrint?: boolean;
}

/**
 * Data table columns.
 */
export type DataTableColumns<R> = Record<ColumnId, DataTableColumn<R>>;

/**
 * Data table properties.
 */
export interface DataTableProps<R extends object> {
  title?: ReactNode;
  rows: R[];
  rowId: (row: R) => RowId;
  columns: DataTableColumns<R>;
  emptyMessage: string;
  defaultSortBy: ColumnId;
  fetching?: boolean;
  highlightRows?: RowId[];
  rowsPerPage?: number;
  // Row expansion props:
  renderExpandedRow?: (row: R) => ReactNode;
  disabledRowExpansion?: (row: R) => boolean;
  // UI props:
  dense?: boolean;
  minWidth?: number | string;
  fetchingRows?: number; // Number of "skeleton rows" to use when fetching
  toolbarActionsLeft?: ReactNode;
  toolbarActionsRight?: ReactNode;
  allowFilter?: boolean;
  allowPagination?: boolean;
  paperProps?: PaperProps;
  // Query param props:
  filterQueryParam?: string;
  pageQueryParam?: string;
  sortByQueryParam?: string;
  sortDirectionQueryParam?: string;
}

/**
 * Data table with filtering and sorting capabilities.
 */
let dataTableId = 0;
export function DataTable<R extends object>({
  title,
  rows,
  rowId,
  columns,
  emptyMessage,
  toolbarActionsLeft,
  toolbarActionsRight,
  fetching = false,
  fetchingRows = 2,
  dense = false,
  highlightRows = [],
  allowFilter = true,
  allowPagination = true,
  defaultSortBy,
  rowsPerPage = 10,
  minWidth = 750,
  renderExpandedRow,
  disabledRowExpansion,
  paperProps,
  filterQueryParam = 'filter',
  pageQueryParam = 'page',
  sortByQueryParam = 'sortBy',
  sortDirectionQueryParam = 'sortDirection',
}: DataTableProps<R>) {
  const [t] = useTranslation('common');
  const tableId = useMemo(() => dataTableId++, []);
  const classes = useStyles();
  const { i18n } = useTranslation();

  const allowedQueryParams = useMemo(
    () => [
      ...(allowFilter ? [filterQueryParam] : []),
      ...(allowPagination ? [pageQueryParam] : []),
      sortByQueryParam,
      sortDirectionQueryParam,
    ],
    [
      allowFilter,
      allowPagination,
      filterQueryParam,
      pageQueryParam,
      sortByQueryParam,
      sortDirectionQueryParam,
    ]
  );
  const [queryParams, setQueryParams] = useQueryParams(allowedQueryParams);

  const columnIds = new Set(Object.keys(columns));

  const filter = queryParams[filterQueryParam] || '';
  const page = +queryParams[pageQueryParam] - 1 || 0;
  const sortBy: ColumnId =
    columnIds.has(queryParams[sortByQueryParam]) &&
    (columns[queryParams[sortByQueryParam]].sortable ?? true)
      ? queryParams[sortByQueryParam]
      : defaultSortBy;
  const sortDirection =
    queryParams[sortDirectionQueryParam] === 'asc' ||
    queryParams[sortDirectionQueryParam] === 'desc'
      ? (queryParams[sortDirectionQueryParam] as SortDirection)
      : columns[sortBy].defaultSortDirection ?? 'asc';

  // Comparator used to sort rows
  const comparator = useMemo(() => {
    const column = columns[sortBy];
    return (row1: any, row2: any) => {
      let cell1 = column.value!(row1);
      let cell2 = column.value!(row2);
      // Treat invalid dates as `null`
      if (isDate(cell1) && isNaN(+(cell1 as Date))) {
        cell1 = null;
      }
      if (isDate(cell2) && isNaN(+(cell2 as Date))) {
        cell2 = null;
      }
      return cell1 == null && cell2 == null
        ? 0
        : cell1 == null && cell2 != null
        ? -1
        : cell1 != null && cell2 == null
        ? 1
        : typeof cell1 === 'string'
        ? cell1.localeCompare(cell2 as string, i18n.language)
        : +cell1! - +(cell2 as number | Date);
    };
  }, [columns, sortBy, i18n.language]);

  // Set of rows to highlight
  const highlightRowsSet = useMemo(() => new Set(highlightRows), [
    highlightRows,
  ]);

  // Rows sorted and filtered
  const sortedFilteredRows = useMemo(
    () =>
      stableSort(
        filterRows(rows, columns, filter, i18n.language),
        comparator,
        sortDirection
      ),
    [rows, columns, filter, i18n.language, sortDirection, comparator]
  );

  function handleFilterChange(event: ChangeEvent<HTMLInputElement>) {
    setQueryParams(
      { [filterQueryParam]: event.target.value || undefined },
      'replace'
    );
  }

  function handleRequestSort(_: MouseEvent<unknown>, column: ColumnId) {
    const columnDefaultSortDirection =
      columns[column].defaultSortDirection ?? 'asc';
    const newDirection =
      sortBy !== column
        ? columnDefaultSortDirection
        : sortDirection === 'asc'
        ? 'desc'
        : 'asc';
    setQueryParams(
      {
        [sortByQueryParam]: column === defaultSortBy ? undefined : column,
        [sortDirectionQueryParam]:
          newDirection === columnDefaultSortDirection
            ? undefined
            : newDirection,
      },
      'replace'
    );
  }

  function handleChangePage(_: unknown, newPage: number) {
    setQueryParams(
      { [pageQueryParam]: newPage + 1 === 1 ? undefined : newPage + 1 },
      'replace'
    );
  }

  const hasExpandRowColumn = renderExpandedRow != null;
  const isEmpty = sortedFilteredRows.length === 0;
  const visibleColumnKeys = Object.keys(columns).filter(
    (columnId) => !columns[columnId].hidden
  );
  const nPages = Math.ceil(sortedFilteredRows.length / rowsPerPage);
  const curPage = Math.min(Math.max(page, 0), Math.max(nPages - 1, 0));

  return (
    <div className={classes.root}>
      <Paper
        data-cy="data-table"
        {...paperProps}
        className={`${classes.paper} ${paperProps?.className ?? ''}`}
      >
        <DataTableToolbar
          tableId={tableId}
          title={title}
          dense={dense}
          showFilter={allowFilter}
          filter={filter}
          onFilterChange={handleFilterChange}
          actionsLeft={toolbarActionsLeft}
          actionsRight={toolbarActionsRight}
        />
        <TableContainer>
          <Table
            className={classes.table}
            style={{ minWidth }}
            aria-labelledby={`data-table-title-${tableId}`}
            size={dense ? 'small' : 'medium'}
          >
            <DataTableHead
              columns={columns}
              visibleColumnKeys={visibleColumnKeys}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onRequestSort={handleRequestSort}
              hasExpandRowColumn={!fetching && !isEmpty && hasExpandRowColumn}
            />
            <TableBody className={classes.tableBody}>
              {!fetching &&
                sortedFilteredRows
                  .slice(
                    curPage * rowsPerPage,
                    curPage * rowsPerPage + rowsPerPage
                  )
                  .map((row: R) => {
                    const id = rowId(row);

                    return (
                      <DataTableRow
                        key={`data-table-row-${id}`}
                        columns={columns}
                        visibleColumnKeys={visibleColumnKeys}
                        row={row}
                        rowId={id}
                        filter={filter}
                        highlightRowsSet={highlightRowsSet}
                        renderExpandedRow={renderExpandedRow}
                        disabledRowExpansion={disabledRowExpansion}
                      />
                    );
                  })}
              {fetching &&
                Array(fetchingRows)
                  .fill(0)
                  .map((_, i) => (
                    <TableRow
                      key={`data-table-fetching-row-${i}`}
                      data-cy={`data-table-fetching-row-${i}`}
                    >
                      {visibleColumnKeys.map((columnId) => (
                        <TableCell key={columnId}>
                          <Skeleton className={classes.cellSkeleton} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              {!fetching && isEmpty && (
                <TableRow data-cy="data-table-empty">
                  <TableCell colSpan={visibleColumnKeys.length}>
                    <Typography
                      className={classes.emptyMessage}
                      variant="h6"
                      component="div"
                    >
                      {emptyMessage}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {allowPagination && (
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[]}
            component="div"
            count={sortedFilteredRows.length}
            rowsPerPage={rowsPerPage}
            page={curPage}
            nextIconButtonText={t('dataTable.nextPage')}
            backIconButtonText={t('dataTable.previousPage')}
            labelDisplayedRows={({ count, page }) =>
              count > 0 && (
                <div className={classes.paginationRows}>
                  {t('dataTable.rows')}:
                  <Select
                    className={classes.paginationRowsSelect}
                    classes={{
                      root: classes.paginationRowsSelectRoot,
                    }}
                    input={<InputBase />}
                    value={page}
                    onChange={(evt) =>
                      handleChangePage(evt, +(evt.target.value as any))
                    }
                  >
                    {[...Array(nPages).keys()].map((page) => (
                      <MenuItem key={page} value={page}>
                        {`${page * rowsPerPage + 1}–${Math.min(
                          page * rowsPerPage + rowsPerPage,
                          count
                        )}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {`${t('dataTable.ofCount')} ${count}`}
                </div>
              )
            }
            onChangePage={handleChangePage}
            data-cy="data-table-pagination"
          />
        )}
      </Paper>
    </div>
  );
}
