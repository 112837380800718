import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Options of the document title hook.
 */
export interface UseSetDocumentTitleOptions {
  title?: string;
}

/**
 * Hook that sets the document title of a page we're visiting, appending it the
 * title of the project.
 */
export function useSetDocumentTitle(title: string = '') {
  const [t] = useTranslation('common');

  useEffect(() => {
    document.title = title
      ? `${title} | ${t('documentTitle')}`
      : t('documentTitle');
  }, [t, title]);
}
