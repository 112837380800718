import {
  useStoreState,
  UseStoreStateResult,
} from '../../providers/StoreProvider';
import { Action, isActionGroup } from './Action';
import { useStore } from '../../providers/StoreProvider/useStore';

/**
 * Key used to save the state of when an action is running.
 */
export function actionIsRunningKey(actionId: string) {
  return `action-${actionId}-is-running`;
}

/**
 * Hook with information on whether an action with a given id is currently
 * running.
 */
export function useActionIsRunning(
  actionId: string
): UseStoreStateResult<boolean> {
  return useStoreState<boolean>(actionIsRunningKey(actionId), false);
}

/**
 * Hook to (deeply) check whether any of the given actions is running.
 */
export function useActionsAreRunning(actions: Action[]): boolean {
  const store = useStore();

  function actionIsRunning(action: Action): boolean {
    return !!(
      action.loading ||
      store.get(actionIsRunningKey(action.id)) ||
      (isActionGroup(action) &&
        action.children.some((act) => actionIsRunning(act)))
    );
  }

  return actions.some((action) => actionIsRunning(action));
}
