import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  GetVatOmissionsFilterOptions,
  useVatOmissionsApi,
  VAT_OMISSIONS_URL,
} from '../vatOmissionsApi';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { VatOmissionsTable } from './VatOmissionsTable';
import { SearchForm } from '../../../components/SearchForm';
import { useQueryParams } from '../../../util/useQueryParams';
import { useResource } from '../../../util/useResource';
import { FormTaxpayerIdField } from '../../../components/Form';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { isAfter, isBefore, startOfToday } from 'date-fns';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useLfForm } from '../../../util/lfIntegration';
import { TaxablePeriod } from '../../../common';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { localDateToSameDateInUTC } from '../../../util/dateUtils';

// Search parameters
const searchParams = ['taxpayerId', 'taxableYear', 'taxablePeriod'];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    taxableYear: isNaN(+queryParams.taxableYear)
      ? null
      : +queryParams.taxableYear,
    taxablePeriod: Object.values(TaxablePeriod).includes(
      queryParams.taxablePeriod as any
    )
      ? (queryParams.taxablePeriod as any)
      : null,
  };
}

/**
 * Page dedicated to consult VAT omissions.
 */
export function VatOmissionsPage() {
  const [t] = useTranslation(['common', 'vatOmissions']);
  useSetDocumentTitle(t('vatOmissions:vatOmissionsPage.documentTitle'));
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      taxableYear: null as number | null,
      taxablePeriod: null as TaxablePeriod | null,
    },
    formValidatorName: 'getVatOmissionsFormValidator',
    i18nErrorMessagesPrefixes:
      'vatOmissions:vatOmissionsPage.searchForm.fieldErrors',
  });
  const {
    catalog: taxPeriodsCatalog,
    isFetching: isFetchingTaxPeriodsCatalog,
  } = useCatalog('tax-periods');
  const { getValues, reset, isValid } = formMethods;
  const { getVatOmissions } = useVatOmissionsApi();
  // Only fetch when the schema is valid
  const fetchVatOmissions = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getVatOmissions(queryParams)
        : null,
    [getVatOmissions, isValid, queryParams]
  );
  const {
    resource: vatOmissions,
    refetch: refetchVatOmissions,
  } = useResource({
    fetchResource: fetchVatOmissions,
    errorFetchingResourceMessage: t(
      'vatOmissions:vatOmissionsPage.errorFetchingVatOmissions'
    ),
  });

  // VAT omissions can only occur up until the "previous" tax period, i.e. there
  // can't be VAT omissions for the period that is currently active
  const today = localDateToSameDateInUTC(startOfToday());
  const previousTaxPeriod =
    taxPeriodsCatalog &&
    taxPeriodsCatalog[
      Math.max(
        0,
        taxPeriodsCatalog.findIndex(
          (taxPeriod) =>
            !isBefore(today, taxPeriod.firstDay) &&
            !isAfter(today, taxPeriod.lastDay)
        ) - 1
      )
    ];

  // If neither the year nor the taxpayer are set, set the default query params
  useEffect(() => {
    if (
      !queryParams.taxpayerId &&
      !queryParams.taxableYear &&
      previousTaxPeriod
    ) {
      setQueryParams(
        {
          taxableYear: previousTaxPeriod?.taxableYear,
          taxablePeriod:
            queryParams.taxablePeriod || previousTaxPeriod?.taxablePeriod,
        },
        'replace'
      );
    }
  }, [previousTaxPeriod, queryParams, setQueryParams]);

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const { taxpayerId, taxableYear, taxablePeriod } = getValues();
    const getVatOmissionsOptions: GetVatOmissionsFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      taxableYear: taxableYear || undefined,
      taxablePeriod: taxablePeriod || undefined,
    };
    // Pressing "search" when nothing changed should refetch the VAT omissions
    if (areSameQueryParams(getVatOmissionsOptions, queryParams, searchParams)) {
      refetchVatOmissions();
    } else {
      setQueryParams(getVatOmissionsOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader title={t('vatOmissions:vatOmissionsPage.title')} />

      <SearchForm
        title={t('vatOmissions:vatOmissionsPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        {/* TIN */}
        <Grid item xs={12} md={4}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('vatOmissions:vatOmissionFields.taxpayerId')}
            size="small"
            onChangeTriggers={['taxableYear']}
          />
        </Grid>

        {/* Taxable year/period */}
        <FormTaxableYearPeriod
          taxableYearProps={{
            name: 'taxableYear',
            label: t('vatOmissions:vatOmissionFields.taxableYear'),
            size: 'small',
            onChangeTriggers: ['taxpayerId'],
          }}
          taxablePeriodProps={{
            name: 'taxablePeriod',
            label: t('vatOmissions:vatOmissionFields.taxablePeriod'),
            size: 'small',
          }}
          maxDate={previousTaxPeriod?.endDate}
          fetching={isFetchingTaxPeriodsCatalog}
        >
          {(taxableYear, taxablePeriod) => (
            <>
              <Grid item xs={6} md={4}>
                {taxableYear}
              </Grid>
              <Grid item xs={6} md={4}>
                {taxablePeriod}
              </Grid>
            </>
          )}
        </FormTaxableYearPeriod>
      </SearchForm>

      <VatOmissionsTable
        fetching={vatOmissions == null}
        vatOmissions={vatOmissions || []}
        downloadButton={
          <DownloadTableButton
            fileName={t(
              'vatOmissions:vatOmissionsPage.dataTable.downloadFileName'
            )}
            downloadUrl={`${VAT_OMISSIONS_URL}?${objectToQueryParamsString({
              ...queryParams,
              format: 'csv',
            })}`}
            disabled={vatOmissions == null}
          />
        }
      />
    </MainContent>
  );
}
