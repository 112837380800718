import React from 'react';
import { User } from '../usersApi';
import { ActionButtons } from '../../../components/Actions';
import { useUserActions } from '../useUserActions';

/**
 * Properties for the user row actions.
 */
interface UserActionsProps {
  user: User;
  setUser: (user: User) => void;
  onUpdateStatusFailureDueToOutdatedUI: () => void;
}

/**
 * Actions in a user row.
 */
export function UserRowActions({
  user,
  setUser,
  onUpdateStatusFailureDueToOutdatedUI,
}: UserActionsProps) {
  return (
    <ActionButtons
      actions={
        useUserActions(user, setUser, onUpdateStatusFailureDueToOutdatedUI)!
      }
      variant="outlined"
      size="small"
    />
  );
}
