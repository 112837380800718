import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  CircularProgress,
  ClickAwayListener,
  Collapse,
  createStyles,
  Grow,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Theme,
  useTheme,
} from '@material-ui/core';
import { Action, ActionGroup, isActionGroup } from './Action';
import { ThemeVariant } from '../../providers/ThemeProvider';
import { useActionIsRunning, useActionsAreRunning } from './useActionIsRunning';
import { useIsMounted } from '../../util/useIsMounted';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsMenu: {
      zIndex: theme.zIndex.tooltip,
    },
    actionIcon: {
      minWidth: 0,
      marginRight: theme.spacing(2),
    },
    actionError: {
      color:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.error.dark
          : theme.palette.error.main,
    },
    actionErrorIcon: {
      color:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.error.light
          : theme.palette.error.main,
    },
    actionSuccess: {
      color:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.success.dark
          : theme.palette.success.main,
    },
    actionSuccessIcon: {
      color:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.success.light
          : theme.palette.success.main,
    },
    loadingIcon: {
      margin: theme.spacing(0, 0.5),
    },
    expandIcon: {
      marginLeft: theme.spacing(1),
    },
  })
);

/**
 * Representation of a menu action.
 */
export type MenuAction = Action & Omit<MenuItemProps, keyof Action>;

/**
 * Properties of the popper actions menu.
 */
export interface ActionsMenuPopperProps {
  anchorRef: RefObject<HTMLElement>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  actions: MenuAction[];
  menuListId?: string;
}

/**
 * A popper component containing a menu of actions.
 */
export function ActionsMenuPopper({
  anchorRef,
  open,
  setOpen,
  actions,
  menuListId,
}: ActionsMenuPopperProps) {
  const classes = useStyles();

  const nonHiddenActions = actions.filter((action) => !action.hidden);

  const handleMenuClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (!anchorRef.current?.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const handleMenuListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const isMounted = useIsMounted();
  const closeMenu = () => {
    if (isMounted.current) {
      setOpen(false);
    }
  };

  // Return focus to the button when we transition from open -> !open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [anchorRef, open]);

  return (
    <Popper
      className={classes.actionsMenu}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper elevation={8}>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <MenuList
                autoFocusItem={open}
                id={menuListId}
                onKeyDown={handleMenuListKeyDown}
              >
                {nonHiddenActions!.map((action) => (
                  <ActionMenuItem
                    key={action.id}
                    action={action}
                    closeMenu={closeMenu}
                  />
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

/**
 * Action menu item props.
 */
interface ActionMenuItemProps extends MenuItemProps {
  action: Action;
  depth?: number;

  closeMenu(): void;
}

/**
 * A single action menu item.
 */
function ActionMenuItem({
  action,
  depth = 0,
  closeMenu,
  ...itemProps
}: ActionMenuItemProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    id,
    label,
    run,
    icon,
    loading,
    disabled,
    classes: cls,
    style,
    ...actionProps
  } = action;
  const [running, setRunning, deleteRunning] = useActionIsRunning(id);

  // `run` implementation that marks the action as running
  const runImpl = async (run?: (evt?: any) => void, evt?: any) => {
    setRunning(true);
    closeMenu();
    try {
      await run?.(evt);
    } finally {
      deleteRunning();
    }
  };

  // The action may represent a group of actions
  const isGroup = isActionGroup(action);
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const groupLoading = useActionsAreRunning(isGroup ? action.children : []);
  const handleGroupToggle = () => {
    setIsGroupOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <MenuItem
        classes={{
          ...cls,
          root: `${cls?.root ?? ''} ${
            !loading && !running && !disabled
              ? style === 'error'
                ? classes.actionError
                : style === 'success'
                ? classes.actionSuccess
                : ''
              : ''
          }`,
        }}
        style={{ paddingLeft: theme.spacing(2 + 2 * depth) }}
        onClick={(evt: any) =>
          isGroup ? handleGroupToggle() : runImpl(run, evt)
        }
        tabIndex={0}
        {...itemProps}
        {...(actionProps as any)}
        disabled={loading || running || disabled}
        data-cy={`menu-action-${id}`}
      >
        <ListItemIcon
          className={classes.actionIcon}
          classes={{
            root:
              !loading && !running && !disabled
                ? style === 'error'
                  ? classes.actionErrorIcon
                  : style === 'success'
                  ? classes.actionSuccessIcon
                  : undefined
                : undefined,
          }}
        >
          {loading || groupLoading || running ? (
            <CircularProgress className={classes.loadingIcon} size={16} />
          ) : (
            icon
          )}
        </ListItemIcon>
        <ListItemText>{label}</ListItemText>

        {isGroup &&
          (isGroupOpen ? (
            <ExpandLess className={classes.expandIcon} />
          ) : (
            <ExpandMore className={classes.expandIcon} />
          ))}
      </MenuItem>

      {isGroup && (
        <Collapse in={isGroupOpen} timeout="auto" unmountOnExit>
          <MenuList disablePadding disableListWrap>
            {(action as ActionGroup).children
              .filter((act) => !act.hidden)
              .map((act) => (
                <ActionMenuItem
                  key={act.id}
                  action={act}
                  depth={depth + 1}
                  closeMenu={closeMenu}
                />
              ))}
          </MenuList>
        </Collapse>
      )}
    </>
  );
}
