import {
  CreateTaxpayerForm,
  Taxpayer,
  TaxpayerType,
  UpdateTaxpayerForm,
} from '../taxpayersApi';
import { DivisionSections } from '../../../providers/CatalogsProvider';

/**
 * Default "create taxpayer form" value.
 */
export function defaultCreateTaxpayerForm(): CreateTaxpayerForm {
  return {
    taxpayerId: '',
    oldTaxpayerId: '',
    name: '',
    representative: '',
    startDate: null,
    provinceId: null,
    districtId: null,
    divisionId: null,
    address: '',
    addressOther: '',
    sectorId: null,
    taxOfficeId: null,
    type: TaxpayerType.Legal,
    dimension: null,
    shareCapital: null,
    legalRegime: null,
    phone: '',
    email: '',
    notes: '',
  };
}

/**
 * Default "update taxpayer form" value.
 */
export function defaultUpdateTaxpayerForm(
  taxpayerId: string,
  taxpayer: Taxpayer | null,
  divisionSections: DivisionSections | null
): UpdateTaxpayerForm {
  return {
    taxpayerId,
    oldTaxpayerId: taxpayer?.oldTaxpayerId ?? '',
    name: taxpayer?.name ?? '',
    representative: taxpayer?.representative ?? '',
    startDate: taxpayer?.startDate ?? null,
    endDate: taxpayer?.endDate ?? null,
    provinceId: divisionSections?.province.id ?? null,
    districtId: divisionSections?.district.id ?? null,
    divisionId: taxpayer?.divisionId ?? null,
    address: taxpayer?.address ?? '',
    addressOther: taxpayer?.addressOther ?? '',
    sectorId: taxpayer?.sectorId ?? null,
    taxOfficeId: taxpayer?.taxOfficeId ?? null,
    type: taxpayer?.type ?? TaxpayerType.Legal,
    dimension: taxpayer?.dimension ?? null,
    shareCapital: taxpayer?.shareCapital ?? null,
    legalRegime: taxpayer?.legalRegime ?? null,
    phone: taxpayer?.phone ?? '',
    email: taxpayer?.email ?? '',
    notes: taxpayer?.notes ?? '',
  };
}
