import React, { Fragment, ReactNode } from 'react';
import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { TitledPaper } from './TitledPaper';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      margin: theme.spacing(-2),
    },
    item: {
      padding: theme.spacing(2),
      alignItems: 'center',
      '@media print': {
        breakInside: 'avoid',
      },
    },
    itemLabel: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      wordBreak: 'break-word',
    },
    itemContent: {
      wordBreak: 'break-word',
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
    divider: {
      '@media print': {
        colorAdjust: 'exact',
      },
    },
    preserveWhiteSpaces: {
      whiteSpace: 'pre',
    },
  })
);

/**
 * Description list item.
 */
export interface DescriptionListItem {
  key: string;
  label: string;
  content: ReactNode;
  fetching?: boolean;
  hidden?: boolean;
  renderFetching?: ReactNode;
  /**
   * Render the content with `white-space: pre`.
   */
  preserveWhiteSpaces?: boolean;
}

/**
 * Description list properties.
 */
export interface DescriptionListProps {
  title?: ReactNode;
  items: DescriptionListItem[];
}

/**
 * Description list.
 */
export function PaperDescriptionList({ title, items }: DescriptionListProps) {
  const classes = useStyles();

  return (
    <TitledPaper title={title}>
      <dl className={classes.list}>
        {items
          .filter((item) => !item.hidden)
          .map(
            (
              {
                key,
                label,
                content,
                fetching,
                renderFetching = <Skeleton />,
                preserveWhiteSpaces,
              },
              i
            ) => (
              <Fragment key={key}>
                {i !== 0 && <Divider className={classes.divider} />}
                <Grid
                  container
                  className={classes.item}
                  data-cy={`description-list-item-${key}`}
                >
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.itemLabel} component="dt">
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      className={`${classes.itemContent} ${
                        preserveWhiteSpaces ? classes.preserveWhiteSpaces : ''
                      }`}
                      component="dd"
                    >
                      {fetching ? renderFetching : content}
                    </Typography>
                  </Grid>
                </Grid>
              </Fragment>
            )
          )}
      </dl>
    </TitledPaper>
  );
}
