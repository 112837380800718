import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  CREDIT_SETTLEMENTS_URL,
  GetCreditSettlementsFilterOptions,
  useCreditSettlementsApi,
} from '../creditSettlementsApi';
import { SearchForm } from '../../../components/SearchForm';
import { useResource } from '../../../util/useResource';
import { useQueryParams } from '../../../util/useQueryParams';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { FormTaxpayerIdField } from '../../../components/Form';
import { CreditSettlementsTable } from './CreditSettlementsTable';
import { TaxablePeriod, TaxId } from '../../../common';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useLfForm } from '../../../util/lfIntegration';

// Search parameters
const searchParams = ['taxpayerId', 'taxId', 'taxableYear', 'taxablePeriod'];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    taxId: Object.values(TaxId).includes(queryParams.taxId as any)
      ? (queryParams.taxId as any)
      : null,
    taxableYear: isNaN(+queryParams.taxableYear)
      ? null
      : +queryParams.taxableYear,
    taxablePeriod: Object.values(TaxablePeriod).includes(
      queryParams.taxablePeriod as any
    )
      ? (queryParams.taxablePeriod as any)
      : null,
  };
}

/**
 * Page dedicated to consult the existing credit settlements.
 */
export function CreditSettlementsPage() {
  const [t] = useTranslation(['common', 'creditSettlements']);
  useSetDocumentTitle(
    t('creditSettlements:creditSettlementsPage.documentTitle')
  );
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      taxId: TaxId.VAT as TaxId | null,
      taxableYear: null as number | null,
      taxablePeriod: null as TaxablePeriod | null,
    },
    formValidatorName: 'getCreditSettlementsFormValidator',
    i18nErrorMessagesPrefixes:
      'creditSettlements:creditSettlementsPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const { getCreditSettlements } = useCreditSettlementsApi();
  const fetchCreditSettlements = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getCreditSettlements(queryParams)
        : null,
    [getCreditSettlements, isValid, queryParams]
  );
  const {
    isFetching,
    resource: creditSettlements,
    setResource: setCreditSettlements,
    refetch: refetchCreditSettlements,
  } = useResource({
    fetchResource: fetchCreditSettlements,
    errorFetchingResourceMessage: t(
      'creditSettlements:creditSettlementsPage.errorFetchingCreditSettlements'
    ),
  });

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const { taxpayerId, taxId, taxableYear, taxablePeriod } = getValues();
    const getCreditSettlementsOptions: GetCreditSettlementsFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      taxId: taxId ?? undefined,
      taxableYear: taxableYear || undefined,
      taxablePeriod: taxablePeriod || undefined,
    };
    // Pressing "search" when nothing changed should refetch the credit
    // settlements
    if (
      areSameQueryParams(getCreditSettlementsOptions, queryParams, searchParams)
    ) {
      refetchCreditSettlements();
    } else {
      setQueryParams(getCreditSettlementsOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader
        title={t('creditSettlements:creditSettlementsPage.title')}
      />

      <SearchForm
        title={t('creditSettlements:creditSettlementsPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        {/* TIN */}
        <Grid item xs={12} md={4}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('creditSettlements:creditSettlementFields.taxpayerId')}
            size="small"
          />
        </Grid>

        {/* Taxable year/period */}
        <FormTaxableYearPeriod
          taxableYearProps={{
            name: 'taxableYear',
            label: t('creditSettlements:creditSettlementFields.taxableYear'),
            size: 'small',
          }}
          taxablePeriodProps={{
            name: 'taxablePeriod',
            label: t('creditSettlements:creditSettlementFields.taxablePeriod'),
            size: 'small',
          }}
        >
          {(taxableYear, taxablePeriod) => (
            <>
              <Grid item xs={6} md={4}>
                {taxableYear}
              </Grid>
              <Grid item xs={6} md={4}>
                {taxablePeriod}
              </Grid>
            </>
          )}
        </FormTaxableYearPeriod>
      </SearchForm>

      <CreditSettlementsTable
        fetching={isFetching}
        creditSettlements={creditSettlements || []}
        setCreditSettlements={setCreditSettlements}
        refetchCreditSettlements={refetchCreditSettlements}
        downloadButton={
          <DownloadTableButton
            fileName={t(
              'creditSettlements:creditSettlementsPage.dataTable.downloadFileName'
            )}
            downloadUrl={`${CREDIT_SETTLEMENTS_URL}?${objectToQueryParamsString(
              {
                ...queryParams,
                format: 'csv',
              }
            )}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
