import React, { ReactNode, useState } from 'react';
import {
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
} from '@material-ui/core';
import { DataTableCell } from './DataTableCell';
import { DataTableColumns, RowId } from './DataTable';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ThemeVariant } from '../../providers/ThemeProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandableRow: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    selected: {
      backgroundColor: `${
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.grey[100]
          : theme.palette.grey[800]
      } !important`,
    },
    expandRow: {
      '@media print': {
        breakInside: 'initial !important',
      },
    },
    // Styles to omit the "expand row" when printing
    expandRowCell: {
      '@media print': {
        padding: 0,
      },
    },
    expandRowCellButton: {
      '@media print': {
        display: 'none',
      },
    },
    expandedRow: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    expandedRowCollapse: {
      // When printing, always expand the row
      '@media print': {
        height: 'auto',
        visibility: 'visible',
      },
    },
  })
);

/**
 * Data table row properties.
 */
export interface DataTableRowProps<C extends DataTableColumns<any>> {
  columns: C;
  visibleColumnKeys: string[];
  row: any;
  rowId: RowId;
  filter: string;
  highlightRowsSet: Set<RowId>;
  renderExpandedRow?: (row: any) => ReactNode;
  disabledRowExpansion?: (row: any) => boolean;
}

/**
 * Row of the data table.
 */
export function DataTableRow<C extends DataTableColumns<any>>({
  columns,
  visibleColumnKeys,
  row,
  rowId,
  filter,
  highlightRowsSet,
  renderExpandedRow,
  disabledRowExpansion,
}: DataTableRowProps<C>) {
  const classes = useStyles();
  const [t] = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const hasExpandRowColumn = renderExpandedRow != null;
  const disabledExpansion = !hasExpandRowColumn || disabledRowExpansion?.(row);

  return (
    <>
      <TableRow
        className={hasExpandRowColumn ? classes.expandableRow : ''}
        data-cy={`data-table-row-${rowId}`}
        selected={highlightRowsSet.has(rowId)}
        classes={{ selected: classes.selected }}
      >
        {visibleColumnKeys.map((columnId) => (
          <DataTableCell
            key={columnId}
            row={row}
            rowId={rowId}
            column={columns[columnId]}
            columnId={columnId}
            filter={filter}
          />
        ))}

        {hasExpandRowColumn && (
          <TableCell className={classes.expandRowCell}>
            <IconButton
              className={classes.expandRowCellButton}
              aria-label={t('dataTable.expandRow')}
              size="small"
              onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
              disabled={disabledExpansion}
            >
              {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {hasExpandRowColumn && (
        <TableRow
          className={classes.expandRow}
          data-cy={`data-table-row-expanded-${rowId}`}
        >
          <TableCell
            className={classes.expandedRow}
            colSpan={visibleColumnKeys.length + 1}
          >
            <Collapse
              className={!disabledExpansion ? classes.expandedRowCollapse : ''}
              in={isExpanded}
              timeout="auto"
              unmountOnExit={false}
            >
              {renderExpandedRow!(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
