import { useContext } from 'react';
import {
  ResetPasswordApi,
  ResetPasswordContext,
} from './ResetPasswordContext';

/**
 * Hook exposing the reset password API.
 */
export function useResetPassword(): ResetPasswordApi {
  return useContext(ResetPasswordContext);
}
