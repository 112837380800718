import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Properties of the "scroll to top" component.
 */
export interface ScrollToTopOnRouteChangeProps {
  children: ReactNode;
}

/**
 * Utility component that scrolls to the top of the page on route change.
 */
export function ScrollToTopOnRouteChange({
  children,
}: ScrollToTopOnRouteChangeProps) {
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  return <>{children}</>;
}
