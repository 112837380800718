import {
  useForm,
  FieldValues,
  UseFormMethods,
  UseFormOptions,
  FieldName,
  SetFieldValue,
} from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getI18nNsFromKey } from '../getI18nNsFromKey';
import { LocalizedIssue } from './lfIntegrationApi';
import { lfResolver } from './lfResolver';
import { setLfIssues } from './setLfIssues';
import { lfPathToNamePath } from './util/pathNameConvertions';

interface CustomUseLfFormOptions {
  formValidatorName: string;
  formValidatorArgs?: any[];
  i18nErrorMessagesPrefixes: string | string[];
}

/**
 * Options for the `useLfForm` hook.
 */
export type UseLfFormOptions<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
> = CustomUseLfFormOptions &
  Omit<UseFormOptions<TFieldValues, TContext>, 'resolver'>;

interface CustomUseLfFormMethods<
  TFieldValues extends FieldValues = FieldValues
> {
  isValid(form: TFieldValues): Promise<boolean>;
  setLfValue(
    name: FieldName<TFieldValues>,
    value: SetFieldValue<TFieldValues>,
    config?:
      | Partial<{ shouldValidate: boolean; shouldDirty: boolean }>
      | undefined
  ): void;
  setLfIssues(issues: LocalizedIssue[], shouldFocus?: boolean): void;
  setManualLfIssue(issue: LocalizedIssue): void;
  removeManualLfIssue(issue: LocalizedIssue): void;
}

/**
 * Return type of the `useLfForm` hook.
 */
export type UseLfFormMethods<TFieldValues extends FieldValues = FieldValues> =
  CustomUseLfFormMethods<TFieldValues> & UseFormMethods<TFieldValues>;

/**
 * Hook to use RHF (with default settings) together with LF.
 */
export function useLfForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>({
  mode = 'onTouched',
  context,
  formValidatorName,
  formValidatorArgs,
  i18nErrorMessagesPrefixes,
  ...options
}: UseLfFormOptions<TFieldValues, TContext>): UseLfFormMethods<TFieldValues> {
  const [manualLfIssues, setManualLfIssues] = useState<LocalizedIssue[]>([]);

  // Use the namespaces used by the `i18nErrorMessagesPrefixes`
  const i18nPrefixes = useMemo(
    () =>
      typeof i18nErrorMessagesPrefixes === 'string'
        ? [i18nErrorMessagesPrefixes]
        : i18nErrorMessagesPrefixes,
    [i18nErrorMessagesPrefixes]
  );
  const ns = useMemo(
    () => [
      ...new Set([
        'common',
        ...i18nPrefixes.map((prefix) => getI18nNsFromKey(prefix) ?? 'common'),
      ]),
    ],
    [i18nPrefixes]
  );
  const { i18n } = useTranslation(ns);

  const resolver = useMemo(
    () =>
      lfResolver({
        formValidatorName,
        formValidatorArgs,
        i18n,
        i18nErrorMessagesPrefixes: i18nPrefixes,
      }),
    [formValidatorArgs, formValidatorName, i18n, i18nPrefixes]
  );

  const formContext = useMemo(
    () => ({ ...context, lfIssues: manualLfIssues }),
    [context, manualLfIssues]
  );

  const formMethods = useForm({
    mode,
    context: formContext,
    resolver,
    ...options,
  }) as UseFormMethods<TFieldValues>;
  const { setError, setValue, trigger, formState } = formMethods;

  /**
   * Function that checks whether a value is valid according to the schema.
   */
  const isValidCb = useCallback(
    async (form: TFieldValues) => {
      const errors = (await resolver(form, formContext)).errors;
      return Object.keys(errors).length === 0;
    },
    [formContext, resolver]
  );

  /**
   * `setLfValue` is an implementation of `setValue` with a default
   * `shouldValidate` that validates if the field is touched or the form has
   * been submitted and with `shouldDirty` defaulting to `true`.
   */
  const setLfValueCb = useCallback(
    (
      name: FieldName<TFieldValues>,
      value: SetFieldValue<TFieldValues>,
      config?:
        | Partial<{ shouldValidate: boolean; shouldDirty: boolean }>
        | undefined
    ) => {
      setValue(name, value, {
        shouldValidate:
          config?.shouldValidate ??
          (formState.isSubmitted || !!formState.touched[name]),
        shouldDirty: config?.shouldDirty ?? true,
      });
    },
    [formState.isSubmitted, formState.touched, setValue]
  );

  /**
   * Sets a list of LF issues.
   */
  const setLfIssuesCb = useCallback(
    (issues: LocalizedIssue[], shouldFocus: boolean = false) =>
      setLfIssues(issues, setError, shouldFocus, i18n, i18nPrefixes),
    [setError, i18n, i18nPrefixes]
  );

  /**
   * Manually sets an LF issue.
   */
  const setManualLfIssueCb = useCallback(
    (issue: LocalizedIssue) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setManualLfIssue', issue);
      }
      setManualLfIssues((issues) => [...issues, issue]);
      const namePath: any = lfPathToNamePath(issue.path);
      if (formState.isSubmitted || formState.touched[namePath]) {
        Promise.resolve().then(() => trigger(namePath));
      }
    },
    [formState.isSubmitted, formState.touched, trigger]
  );

  /**
   * Manually removes an LF issue.
   */
  const removeManualLfIssueCb = useCallback(
    (issue: LocalizedIssue) => {
      setManualLfIssues((issues) => {
        if (
          issues.some((i) => i.path === issue.path && i.code === issue.code)
        ) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('removeManualLfIssue', issue);
          }
          return issues.filter(
            (i) => i.path !== issue.path || i.code !== issue.code
          );
        } else {
          return issues;
        }
      });
      const namePath: any = lfPathToNamePath(issue.path);
      if (formState.isSubmitted || formState.touched[namePath]) {
        Promise.resolve().then(() => trigger(namePath));
      }
    },
    [formState.isSubmitted, formState.touched, trigger]
  );

  return useMemo(
    () => ({
      ...formMethods,
      isValid: isValidCb,
      setLfValue: setLfValueCb,
      setLfIssues: setLfIssuesCb,
      setManualLfIssue: setManualLfIssueCb,
      removeManualLfIssue: removeManualLfIssueCb,
    }),
    [
      formMethods,
      isValidCb,
      removeManualLfIssueCb,
      setLfIssuesCb,
      setManualLfIssueCb,
      setLfValueCb,
    ]
  );
}
