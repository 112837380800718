import { Component, ErrorInfo, ReactNode } from 'react';

/**
 * Error boundary props.
 */
export interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode;
}

/**
 * Error boundary state.
 */
interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * Error boundary component.
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: Report to server
  }

  render() {
    return this.state.hasError ? this.props.fallback : this.props.children;
  }
}
