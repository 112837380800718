import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { APP_VERSION } from '../components/AppVersion';
// TODO: Load these dynamically with `import()` or better, don't use them at all
import { enUS as dateFnsEnUS, es as dateFnsEs } from 'date-fns/locale';

/**
 * Main i18next config.
 */
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: ['common'],
    defaultNS: 'common',
    lng: process.env.REACT_APP_LNG,
    fallbackLng: process.env.REACT_APP_FALLBACK_LNG || false,
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // Not needed in React
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      // Prevent the browser from caching translation files between releases
      queryStringParams: { v: APP_VERSION },
    },
  });

/**
 * Return the `date-fns` locale given the `i18next` locale.
 */
export function getDateFnsLocale(lng: string) {
  if (lng === 'es') {
    return dateFnsEs;
  }
  return dateFnsEnUS;
}
