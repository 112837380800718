import React, { ReactNode, useRef } from 'react';
import { CatalogPromises, CatalogsContext } from './CatalogsContext';

/**
 * Properties of the catalogs provider.
 */
export interface CatalogsProviderProps {
  children: ReactNode;
}

/**
 * Catalogs provider.
 */
export function CatalogsProvider({ children }: CatalogsProviderProps) {
  const catalogPromises = useRef<CatalogPromises>({});

  return (
    <CatalogsContext.Provider value={{ catalogPromises }}>
      {children}
    </CatalogsContext.Provider>
  );
}
