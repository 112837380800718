import React, { useMemo } from 'react';
import { OpenInNew, RemoveCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { TAX_REFUND_PATH, replacePathParams } from '../../navigation/paths';
import { Action } from '../../components/Actions';
import { TaxRefund, TaxRefundStatus } from './taxRefundsApi';

/**
 * Hook that returns actions that can be performed on a tax refund.
 */
export function useTaxRefundActions(
  taxRefund: TaxRefund | null,
  onVoid: () => void
): Action[] {
  const [t] = useTranslation('taxRefunds');
  const { pathname } = useLocation();

  const taxRefundPath = replacePathParams(TAX_REFUND_PATH, {
    id: taxRefund?.taxRefundId,
  });

  return useMemo(
    () => [
      {
        id: `tax-refund-${taxRefund?.taxRefundId}-open`,
        label: t('taxRefunds:taxRefundActions.open'),
        component: Link,
        to: taxRefundPath,
        icon: <OpenInNew />,
        hidden: !taxRefund || pathname === taxRefundPath,
      },
      {
        id: `tax-refund-${taxRefund?.taxRefundId}-void`,
        label: t('taxRefunds:taxRefundActions.void'),
        run: onVoid,
        icon: <RemoveCircleOutline />,
        style: 'error',
        hidden: !taxRefund || taxRefund.status === TaxRefundStatus.Voided,
      },
    ],
    [t, taxRefundPath, taxRefund, pathname, onVoid]
  );
}
