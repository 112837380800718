import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { VatReturn, VatReturnStatus } from '../vatReturnsApi';
import { VatReturnRowActions } from './VatReturnRowActions';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import { StatusChip } from '../../../components/StatusChip';
import {
  replacePathParams,
  TAXPAYER_PATH,
  VAT_ASSESSMENT_PATH,
  VAT_RETURN_PATH,
} from '../../../navigation/paths';
import { Link } from '../../../components/Link';
import { NotSpecified } from '../../../components/NotSpecified';
import { VoidVatReturnForm } from '../VoidVatReturnForm';

/**
 * Properties of the VAT returns table.
 */
export interface VatReturnsTableProps {
  fetching: boolean;
  vatReturns: VatReturn[];
  setVatReturns: Dispatch<SetStateAction<VatReturn[] | null>>;
  refetchVatReturns: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing VAT returns.
 */
export function VatReturnsTable({
  fetching,
  vatReturns,
  setVatReturns,
  refetchVatReturns,
  downloadButton,
}: VatReturnsTableProps) {
  const [t] = useTranslation(['common', 'vatReturns']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const [vatReturnVoiding, setVatReturnVoiding] = useState<VatReturn | null>(
    null
  );

  const setVatReturn = useCallback(
    (vatReturn: VatReturn) => {
      setVatReturns(
        (vatReturns) =>
          vatReturns &&
          vatReturns.map((ret) =>
            ret.taxReturnId === vatReturn.taxReturnId ? vatReturn : ret
          )
      );
    },
    [setVatReturns]
  );

  const columns: DataTableColumns<VatReturn> = {
    taxReturnId: {
      label: '#',
      value: ({ taxReturnId }) => taxReturnId,
      render: (content, value) => (
        <Link to={replacePathParams(VAT_RETURN_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxpayerId: {
      label: t('vatReturns:vatReturnFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxableYearPeriod: {
      label: t('vatReturns:vatReturnFields.taxablePeriod'),
      value: ({ taxableYear, taxablePeriod }) =>
        `${taxableYear}/${taxablePeriod}`,
      defaultSortDirection: 'desc',
    },
    deliveryDate: {
      label: t('vatReturns:vatReturnFields.delivery'),
      value: ({ deliveryDate }) => deliveryDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    vatTotal: {
      align: 'right',
      label: t('vatReturns:vatReturnFields.vatTotal'),
      value: ({ vatTotal }) => vatTotal,
      format: (value, { vatCredit }) =>
        value === 0 && vatCredit !== 0 ? '' : formatCurrency(value as number),
    },
    vatCredit: {
      align: 'right',
      label: t('vatReturns:vatReturnFields.vatCredit'),
      value: ({ vatCredit }) => vatCredit,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
    },
    status: {
      label: t('vatReturns:vatReturnFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`vatReturns:vatReturnStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={
            status === VatReturnStatus.Effective
              ? 'success'
              : status === VatReturnStatus.Voided
              ? 'error'
              : 'default'
          }
          label={content}
        />
      ),
      paddinglessY: true,
    },
    assessmentNumber: {
      label: t('vatReturns:vatReturnFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber,
      render: (content, value) =>
        value ? (
          <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
            {content}
          </Link>
        ) : (
          <NotSpecified>{t('vatReturns:noAssessmentNumber')}</NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <VatReturnRowActions
          vatReturn={row}
          onVoid={() => {
            setVatReturnVoiding(row);
            setIsVoidFormOpen(true);
          }}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <>
      <VoidVatReturnForm
        vatReturn={vatReturnVoiding}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newVatReturn) => setVatReturn(newVatReturn)}
        onFailureDueToOutdatedUI={refetchVatReturns}
      />

      <DataTable
        title={t('vatReturns:vatReturnsPage.dataTable.title')}
        rows={vatReturns}
        rowId={({ taxReturnId }) => taxReturnId}
        columns={columns}
        emptyMessage={t('vatReturns:vatReturnsPage.dataTable.emptyMessage')}
        toolbarActionsLeft={downloadButton}
        fetching={fetching}
        defaultSortBy="taxableYearPeriod"
        minWidth={1200}
      />
    </>
  );
}
