import { createContext, Dispatch, SetStateAction } from 'react';
import { UserProfile, UserRole } from './profileApi';

/**
 * Representation of the profile context.
 */
export interface Profile {
  isFetching: boolean;
  profile: UserProfile | null;
  setProfile: Dispatch<SetStateAction<UserProfile | null>>;
  mustChangePassword: boolean;
  refetch(): void;
  hasRole(role: UserRole): boolean;
}

/**
 * Profile context.
 */
export const ProfileContext = createContext<Profile>(undefined as any);
