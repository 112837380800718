import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Add, Edit, Refresh } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CreateUserForm, UpdateUserForm, useUsersApi } from '../usersApi';
import {
  Form,
  FormPasswordField,
  FormPasswordFieldActions,
  TitledPaper,
  FormSelect,
  FormTextField,
  ReadOnlyField,
} from '../../../components/Form';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { MainContent } from '../../../components/MainContent';
import {
  replacePathParams,
  USER_PATH,
  USERS_PATH,
} from '../../../navigation/paths';
import { useUser } from '../useUser';
import {
  useProfile,
  UserProfile,
  UserRole,
} from '../../../providers/ProfileProvider';
import { useAuthentication } from '../../../providers/AuthenticationProvider';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { useCanEditUser } from '../permissions';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { Action } from '../../../components/Actions';
import { ivageCommon } from '../../../ivageCommon';
import {
  defaultCreateUserForm,
  defaultUpdateUserForm,
} from './defaultUserForms';
import { useLfForm } from '../../../util/lfIntegration';
import { useAutoUpdateField } from '../../../util/useAutoUpdateField';
import { useIsMounted } from '../../../util/useIsMounted';

/**
 * Fake password to show in the password field when editing a user, representing
 * its "current" password.
 */
const FAKE_PASSWORD = '•'.repeat(ivageCommon.config.PASSWORD_MIN_LENGTH);

/**
 * Package in IVAGE common containing the user form schema.
 */
const userFormSchemaPkg = ivageCommon.feature.usermanagement.schema;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fakePassword: {
      color: theme.palette.text.secondary,
    },
    passwordEditButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

/**
 * Form used to create a new user or edit an existing one.
 */
export function UserFormPage() {
  const classes = useStyles();
  const [t, i18n] = useTranslation(['common', 'users']);
  const { username } = useParams<{ username?: string }>();
  const isEditingUser = !!username;
  const { isFetching, user, notFound } = useUser(username);
  const canEditUser = useCanEditUser(user);
  useSetDocumentTitle(
    t(
      `users:userFormPage.${
        isEditingUser ? 'editUserDocumentTitle' : 'newUserDocumentTitle'
      }`,
      isEditingUser ? { username } : {}
    )
  );
  const { createUser, updateUser } = useUsersApi();
  const { user: loggedInUser } = useAuthentication();
  const { setProfile, hasRole } = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formMethods = useLfForm({
    defaultValues: user ? defaultUpdateUserForm(user) : defaultCreateUserForm(),
    formValidatorName: isEditingUser
      ? 'updateUserFormValidator'
      : 'createUserFormValidator',
    i18nErrorMessagesPrefixes: 'users:userFormPage.fieldErrors',
  });
  const isMounted = useIsMounted();
  const {
    watch,
    reset,
    getValues,
    setLfValue,
    formState,
    setLfIssues,
  } = formMethods;
  const passwordActions = useRef<FormPasswordFieldActions>();
  const {
    catalog: organizationalUnitsCatalog,
    isFetching: isFetchingOrganizationalUnitsCatalog,
  } = useCatalog('organizational-units');

  // Reset form whenever the user changes
  useEffect(() => {
    reset(user ? defaultUpdateUserForm(user) : defaultCreateUserForm());
  }, [user, reset]);

  // Auto update the display name based on the first name and surname
  const onNameChange = useAutoUpdateField(
    formMethods,
    'displayName',
    ['firstName', 'surname'],
    useCallback(
      ({ firstName, surname }) =>
        [firstName, surname].filter((str) => str).join(' '),
      []
    )
  );

  // Toggle the editing status of the password (when editing a user)
  const password = watch('password');
  const isEditingPassword = !isFetching && password !== null;
  const onToggleEditPassword = useCallback(() => {
    const newIsEditing = !isEditingPassword;
    setLfValue('password', newIsEditing ? '' : null);

    if (newIsEditing) {
      // Focus password when pressing the edit button
      setTimeout(() => passwordActions.current!.focus());
    } else {
      // Make sure visibility is disabled when switching back to not editing
      // the password and reset the touched status
      passwordActions.current!.setVisibility(false);
      delete formState.touched.password;
    }
  }, [isEditingPassword, setLfValue, formState.touched.password]);

  // Set the generated password when clicking on the password generation button
  const onGeneratePassword = useCallback(
    (password: string) => {
      setLfValue('password', password);
    },
    [setLfValue]
  );

  // Create or update the user when submitting
  async function onSubmit() {
    const userForm = getValues();
    try {
      const user = await (isEditingUser
        ? updateUser(username!, userForm as UpdateUserForm)
        : createUser(userForm as CreateUserForm));

      // Set profile when editing the logged in user
      if (isEditingUser && username! === loggedInUser!.username) {
        const { username, password, ...profile } = userForm as any;
        setProfile(profile as UserProfile);
      }

      enqueueSnackbar(
        isEditingUser
          ? t('users:userFormPage.successUpdatingUser', { username })
          : t('users:userFormPage.successCreatingUser', {
              username: user.username,
            }),
        { variant: 'success' }
      );

      if (
        isMounted.current &&
        location.pathname === history.location.pathname
      ) {
        setFormSubmitted(true);
        history.push(replacePathParams(USER_PATH, { username: user.username }));
      }
    } catch (err) {
      if (
        err instanceof Response &&
        err.status === 400 &&
        isMounted.current &&
        location.pathname === history.location.pathname
      ) {
        setLfIssues(await err.json(), true);
      } else {
        enqueueSnackbar(
          isEditingUser
            ? t('users:userFormPage.errorUpdatingUser', { username })
            : t('users:userFormPage.errorCreatingUser', {
                username: (userForm as CreateUserForm).username,
              }),
          { variant: 'error' }
        );
      }
    }
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: 'user-form-submit',
      type: 'submit',
      label: isEditingUser
        ? t('users:userFormPage.updateUser')
        : t('users:userFormPage.createUser'),
      color: 'primary',
      icon: isEditingUser ? <Refresh /> : <Add />,
      loading: formState.isSubmitting,
      disabled:
        (isEditingUser && !formState.isDirty) ||
        isFetching ||
        isFetchingOrganizationalUnitsCatalog,
    },
    {
      id: 'user-form-reset',
      type: 'reset',
      label: t('formActions.reset'),
      run: () => reset(),
      disabled: !formState.isDirty || formState.isSubmitting,
    },
    {
      id: 'user-form-cancel',
      label: t('formActions.cancel'),
      run: () =>
        history.push(
          isEditingUser
            ? replacePathParams(USER_PATH, { username: username! })
            : USERS_PATH
        ),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <MainContent
      errorMessage={
        isEditingUser && notFound
          ? t('users:userNotFound')
          : isEditingUser && !isFetching && user && !canEditUser
          ? t('users:userFormPage.cannotEditUser')
          : undefined
      }
    >
      <Form
        onSubmit={onSubmit}
        shouldPromptOnLeave={formState.isDirty && !formSubmitted}
        formProps={{ autoComplete: 'off' }}
        {...formMethods}
      >
        <ActionableHeader
          title={t(
            isEditingUser
              ? 'users:userFormPage.editUserTitle'
              : 'users:userFormPage.newUserTitle'
          )}
          titleId={isEditingUser ? username : undefined}
          actions={actions}
        />

        {/* Login information */}
        <TitledPaper title={t('users:loginInformation')}>
          <Grid container spacing={2}>
            {/* Username */}
            <Grid item xs={12} md={6}>
              {isEditingUser ? (
                <ReadOnlyField
                  label={t('users:userFields.username')}
                  value={username}
                />
              ) : (
                <FormTextField
                  name="username"
                  label={t('users:userFields.username')}
                  disabled={isEditingUser}
                  inputProps={{
                    maxLength: userFormSchemaPkg.USERNAME_MAX_LENGTH,
                  }}
                />
              )}
            </Grid>

            {/* Password */}
            <Grid item xs={12} md={6}>
              <FormPasswordField
                name="password"
                label={t('users:userFields.password')}
                disabled={!isEditingPassword}
                autoComplete="new-password"
                includeVisibilityToggle={isEditingPassword}
                includeCopy={isEditingPassword}
                includeRandomGenerator={
                  isEditingPassword && !formState.isSubmitting
                }
                onGeneratePassword={onGeneratePassword}
                actionsRef={passwordActions}
                InputProps={{
                  startAdornment: !isEditingPassword && (
                    <InputAdornment position="start">
                      <span className={classes.fakePassword}>
                        {FAKE_PASSWORD}
                      </span>
                    </InputAdornment>
                  ),
                }}
                extraEndAdornment={
                  isEditingUser && (
                    <Button
                      className={classes.passwordEditButton}
                      size="small"
                      variant="contained"
                      color={isEditingPassword ? 'default' : 'primary'}
                      disabled={isFetching || formState.isSubmitting}
                      onClick={onToggleEditPassword}
                      startIcon={!isEditingPassword && <Edit />}
                    >
                      {isEditingPassword
                        ? t('formActions.cancel')
                        : t('users:userFormPage.editPassword')}
                    </Button>
                  )
                }
              />
            </Grid>
          </Grid>
        </TitledPaper>

        {/* User information */}
        <TitledPaper title={t('users:userInformation')}>
          <Grid container spacing={2}>
            {/* First name */}
            <Grid item xs={6} md={4}>
              <FormTextField
                name="firstName"
                label={t('users:userFields.firstName')}
                fetching={isFetching}
                onChange={onNameChange}
                inputProps={{
                  maxLength: userFormSchemaPkg.FIRST_NAME_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Surname */}
            <Grid item xs={6} md={4}>
              <FormTextField
                name="surname"
                label={t('users:userFields.surname')}
                fetching={isFetching}
                onChange={onNameChange}
                inputProps={{ maxLength: userFormSchemaPkg.SURNAME_MAX_LENGTH }}
              />
            </Grid>

            {/* Display name */}
            <Grid item xs={12} md={4}>
              <FormTextField
                name="displayName"
                label={t('users:userFields.displayName')}
                fetching={isFetching}
                inputProps={{
                  maxLength: userFormSchemaPkg.DISPLAY_NAME_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Civil ID number */}
            <Grid item xs={12} md={6}>
              <FormTextField
                name="civilId"
                label={t('users:userFields.civilId')}
                fetching={isFetching}
                inputProps={{
                  maxLength: userFormSchemaPkg.CIVIL_ID_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Organizational unit */}
            <Grid item xs={12} md={6}>
              <FormSelect
                name="organizationalUnitId"
                label={t('users:userFields.organizationalUnit')}
                items={[
                  {
                    value: null,
                    label: (
                      <em>{t('users:userFormPage.noOrganizationalUnit')}</em>
                    ),
                  },
                  ...(organizationalUnitsCatalog
                    ?.sort((u1, u2) =>
                      u1.name.localeCompare(u2.name, i18n.language)
                    )
                    .map((organizationalUnit) => ({
                      value: organizationalUnit.id,
                      label: organizationalUnit.name,
                    })) ?? []),
                ]}
                fetching={isFetching || isFetchingOrganizationalUnitsCatalog}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <FormTextField
                name="email"
                label={t('users:userFields.email')}
                fetching={isFetching}
                inputProps={{ maxLength: userFormSchemaPkg.EMAIL_MAX_LENGTH }}
              />
            </Grid>
          </Grid>
        </TitledPaper>

        {/* User role */}
        <TitledPaper title={t('users:roleInformation')}>
          <Grid container spacing={2}>
            {/* Role */}
            <Grid item xs={12}>
              <FormSelect
                name="role"
                label={t('users:userFields.role')}
                items={[
                  {
                    value: '',
                    label: <em>{t('users:userRole.none')}</em>,
                  },
                  ...Object.values(UserRole).map((role) => ({
                    value: role,
                    label: t(`users:userRole.${role}`),
                    disabled: !hasRole(role),
                  })),
                ]}
                fetching={isFetching}
              />
            </Grid>
          </Grid>
        </TitledPaper>
      </Form>
    </MainContent>
  );
}
