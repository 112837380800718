import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { TaxRefund, TaxRefundStatus } from '../taxRefundsApi';
import { TaxRefundRowActions } from './TaxRefundRowActions';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import { StatusChip } from '../../../components/StatusChip';
import {
  replacePathParams,
  TAX_REFUND_PATH,
  TAXPAYER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { Link } from '../../../components/Link';
import { NotSpecified } from '../../../components/NotSpecified';
import { VoidTaxRefundForm } from '../VoidTaxRefundForm';

/**
 * Properties of the tax refunds table.
 */
export interface TaxRefundsTableProps {
  fetching: boolean;
  taxRefunds: TaxRefund[];
  setTaxRefunds: Dispatch<SetStateAction<TaxRefund[] | null>>;
  refetchTaxRefunds: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing tax refunds.
 */
export function TaxRefundsTable({
  fetching,
  taxRefunds,
  setTaxRefunds,
  refetchTaxRefunds,
  downloadButton,
}: TaxRefundsTableProps) {
  const [t] = useTranslation(['common', 'taxRefunds']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const [taxRefundVoiding, setTaxRefundVoiding] =
    useState<TaxRefund | null>(null);

  const setTaxRefund = useCallback(
    (taxRefund: TaxRefund) => {
      setTaxRefunds(
        (taxRefunds) =>
          taxRefunds &&
          taxRefunds.map((ref) =>
            ref.taxRefundId === taxRefund.taxRefundId ? taxRefund : ref
          )
      );
    },
    [setTaxRefunds]
  );

  const columns: DataTableColumns<TaxRefund> = {
    taxRefundId: {
      label: '#',
      value: ({ taxRefundId }) => taxRefundId,
      render: (content, value) => (
        <Link to={replacePathParams(TAX_REFUND_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    assessmentNumber: {
      label: t('taxRefunds:taxRefundFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber || null,
      render: (content, value) =>
        value ? (
          <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
            {content}
          </Link>
        ) : (
          <NotSpecified>{t('taxRefunds:noAssessmentNumber')}</NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    taxpayerId: {
      label: t('taxRefunds:taxRefundFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxableYearPeriod: {
      label: t('taxRefunds:taxRefundFields.taxablePeriod'),
      value: ({ taxableYear, taxablePeriod }) =>
        `${taxableYear}/${taxablePeriod}`,
      defaultSortDirection: 'desc',
    },
    taxRefundDate: {
      label: t('taxRefunds:taxRefundFields.date'),
      value: ({ taxRefundDate }) => taxRefundDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    amount: {
      label: t('taxRefunds:taxRefundFields.amount'),
      value: ({ amount }) => amount,
      format: (value) => formatCurrency(value as number),
      align: 'right',
    },
    status: {
      label: t('taxRefunds:taxRefundFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`taxRefunds:taxRefundStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={status === TaxRefundStatus.Voided ? 'error' : 'success'}
          label={content}
        />
      ),
      paddinglessY: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <TaxRefundRowActions
          taxRefund={row}
          onVoid={() => {
            setTaxRefundVoiding(row);
            setIsVoidFormOpen(true);
          }}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <>
      <VoidTaxRefundForm
        taxRefund={taxRefundVoiding}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newTaxRefund) => setTaxRefund(newTaxRefund)}
        onFailureDueToOutdatedUI={refetchTaxRefunds}
      />

      <DataTable
        title={t('taxRefunds:taxRefundsPage.dataTable.title')}
        rows={taxRefunds}
        rowId={({ taxRefundId }) => taxRefundId}
        columns={columns}
        emptyMessage={t('taxRefunds:taxRefundsPage.dataTable.emptyMessage')}
        toolbarActionsLeft={downloadButton}
        fetching={fetching}
        defaultSortBy="taxableYearPeriod"
        minWidth={1100}
      />
    </>
  );
}
