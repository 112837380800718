/**
 * Root path.
 */
export const ROOT_PATH = '/';

/**
 * Reload path. Pushing to this path forces a reload of the previous path.
 */
export const RELOAD_PATH = '/reload';

/**
 * Login path.
 */
export const LOGIN_PATH = '/login';

/**
 * Profile path.
 */
export const PROFILE_PATH = '/profile';

/**
 * Path to consult taxpayers.
 */
export const TAXPAYERS_PATH = '/taxpayers';

/**
 * Path to consult a single taxpayer identified by `:id`.
 */
export const TAXPAYER_PATH = '/taxpayers/:id';

/**
 * Path to register a new taxpayer.
 */
export const NEW_TAXPAYER_PATH = '/taxpayer-form';

/**
 * Path to edit a taxpayer identified by `:id`.
 */
export const EDIT_TAXPAYER_PATH = '/taxpayer-form/:id';

/**
 * Path to consult VAT returns.
 */
export const VAT_RETURNS_PATH = '/vat-returns';

/**
 * Path to consult a single VAT return identified by `:id`.
 */
export const VAT_RETURN_PATH = '/vat-returns/:id';

/**
 * Path to create a new VAT return.
 */
export const NEW_VAT_RETURN_PATH = '/vat-return-form';

/**
 * Path to view a VAT return form.
 */
export const READ_VAT_RETURN_PATH = '/vat-return-form/:id';

/**
 * Path to replace a VAT return.
 */
export const REPLACE_VAT_RETURN_PATH = '/vat-return-form?replaces=:id';

/**
 * Path to create a new VAT return from an omission.
 */
export const AUTOMATIC_VAT_RETURN_PATH =
  '/vat-return-form?fromOmission=:taxpayerId-:taxableYear-:taxablePeriod';

/**
 * Path to consult VAT omissions.
 */
export const VAT_OMISSIONS_PATH = '/vat-omissions';

/**
 * Path to consult VAT assessments.
 */
export const VAT_ASSESSMENTS_PATH = '/vat-assessments';

/**
 * Path to consult a single VAT assessment identified by `:id`.
 */
export const VAT_ASSESSMENT_PATH = '/vat-assessments/:id';

/**
 * Path to consult checking accounts.
 */
export const CHECKING_ACCOUNTS_PATH = '/checking-accounts';

/**
 * Path to consult the checking account of a taxpayer.
 */
export const CHECKING_ACCOUNT_PATH = '/checking-accounts/:id';

/**
 * Path to consult the checking account of the state.
 */
export const STATE_CHECKING_ACCOUNT_PATH = '/state-checking-account';

/**
 * Path to consult a VAT indicator.
 */
export const VAT_INDICATOR_PATH = '/vat-indicators/:id';

/**
 * Path to consult tax refunds.
 */
export const TAX_REFUNDS_PATH = '/tax-refunds';

/**
 * Path to consult a single tax refund identified by `:id`.
 */
export const TAX_REFUND_PATH = '/tax-refunds/:id';

/**
 * Path to create a new tax refund.
 */
export const NEW_TAX_REFUND_PATH = '/tax-refund-form';

/**
 * Path to consult payments.
 */
export const PAYMENTS_PATH = '/payments';

/**
 * Path to consult a single payment identified by `:id`.
 */
export const PAYMENT_PATH = '/payments/:id';

/**
 * Path to create a new payment.
 */
export const NEW_PAYMENT_PATH = '/payment-form';

/**
 * Path to consult payment refunds.
 */
export const PAYMENT_REFUNDS_PATH = '/payment-refunds';

/**
 * Path to consult a single payment refund identified by `:id`.
 */
export const PAYMENT_REFUND_PATH = '/payment-refunds/:id';

/**
 * Path to create a new payment refund.
 */
export const NEW_PAYMENT_REFUND_PATH = '/payment-refund-form';

/**
 * Path to create a new payment refund from a given payment id.
 */
export const NEW_PAYMENT_REFUND_FROM_PAYMENT_PATH =
  '/payment-refund-form?refunds=:id';

/**
 * Path to consult credit settlements.
 */
export const CREDIT_SETTLEMENTS_PATH = '/credit-settlements';

/**
 * Path to consult a single credit settlement identified by `:id`.
 */
export const CREDIT_SETTLEMENT_PATH = '/credit-settlements/:id';

/**
 * Path to create a new credit settlement.
 */
export const NEW_CREDIT_SETTLEMENT_PATH = '/credit-settlement-form';

/**
 * Path to consult users.
 */
export const USERS_PATH = '/users';

/**
 * Path to consult a single user identified by `:username`.
 */
export const USER_PATH = '/users/:username';

/**
 * Path to create a new user.
 */
export const NEW_USER_PATH = '/user-form';

/**
 * Path to edit a user.
 */
export const EDIT_USER_PATH = '/user-form/:username';

/**
 * Name of the query parameter used to redirect to a page.
 */
export const REDIRECT_PARAM = 'redirectTo';

/**
 * Replaces the params in the provided path by the values of same name in the
 * params object. E.g. `replacePathParams('/users/:id', {id: 'x'})` returns
 * `'/users/x'`.
 */
export function replacePathParams(
  path: string,
  params: Record<string, any>
): string {
  return path.replace(/:\w+/g, (param) => params[param.slice(1)]);
}
