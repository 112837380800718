import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainContent } from '../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../util/formatters';
import { usePaymentRefund } from './usePaymentRefund';
import { ActionableHeader } from '../../components/ActionableHeader';
import { usePaymentRefundActions } from './usePaymentRefundActions';
import { NotSpecified } from '../../components/NotSpecified';
import {
  replacePathParams,
  TAXPAYER_PATH,
  USER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../navigation/paths';
import { Link } from '../../components/Link';
import { useCatalog } from '../../providers/CatalogsProvider';
import { ExtraInfo } from '../../components/ExtraInfo';
import { PaymentRefundStatus } from './paymentRefundsApi';
import { UserRole } from '../../providers/ProfileProvider';
import { VoidPaymentRefundForm } from './VoidPaymentRefundForm';

/**
 * Page with payment information.
 */
export function PaymentRefundPage() {
  const [t, i18n] = useTranslation(['common', 'paymentRefunds']);
  const { id } = useParams<{ id: string }>();
  useSetDocumentTitle(
    t('paymentRefunds:paymentRefundPage.documentTitle', { id })
  );
  const {
    paymentRefund,
    isFetching,
    notFound,
    setPaymentRefund,
    refetch,
  } = usePaymentRefund(+id);
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const actions = usePaymentRefundActions(paymentRefund, () =>
    setIsVoidFormOpen(true)
  );
  const {
    catalog: banksCatalog,
    isFetching: isFetchingBanksCatalog,
  } = useCatalog('banks');

  // Assessment information fields
  const assessmentInformation: DescriptionListItem[] = [
    {
      key: 'assessment-number',
      label: t('paymentRefunds:paymentRefundFields.assessmentNumber'),
      content: paymentRefund?.assessmentNumber ? (
        <>
          <Link
            to={replacePathParams(VAT_ASSESSMENT_PATH, {
              id: paymentRefund.assessmentNumber,
            })}
          >
            {paymentRefund.assessmentNumber}
          </Link>
          {paymentRefund?.assessmentNumberInserted &&
            paymentRefund.assessmentNumber !==
              paymentRefund.assessmentNumberInserted && (
              <ExtraInfo>
                {`${t(
                  'paymentRefunds:paymentRefundFields.assessmentNumberInserted'
                )}: `}
                <Link
                  to={replacePathParams(VAT_ASSESSMENT_PATH, {
                    id: paymentRefund.assessmentNumberInserted,
                  })}
                >
                  {paymentRefund.assessmentNumberInserted}
                </Link>
              </ExtraInfo>
            )}
        </>
      ) : (
        <NotSpecified>{t('paymentRefunds:noAssessmentNumber')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxpayer-id',
      label: t('paymentRefunds:paymentRefundFields.taxpayerId'),
      content: paymentRefund && (
        <>
          <Link
            to={replacePathParams(TAXPAYER_PATH, {
              id: paymentRefund.taxpayerId,
            })}
          >
            {paymentRefund.taxpayerId}
          </Link>
          {paymentRefund.taxpayerName && (
            <ExtraInfo>{paymentRefund.taxpayerName}</ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxable-year-period',
      label: `${t('paymentRefunds:paymentRefundFields.taxableYear')}/${t(
        'paymentRefunds:paymentRefundFields.taxablePeriod'
      ).toLocaleLowerCase(i18n.language)}`,
      content: `${paymentRefund?.taxableYear}/${paymentRefund?.taxablePeriod}`,
      fetching: isFetching,
    },
  ];

  // Payment refund information fields
  const paymentRefundInformation: DescriptionListItem[] = [
    {
      key: 'payment-refund-date',
      label: t('paymentRefunds:paymentRefundFields.paymentRefundDate'),
      content: formatDate(paymentRefund?.paymentRefundDate),
      fetching: isFetching,
    },
    {
      key: 'amount',
      label: t('paymentRefunds:paymentRefundFields.amount'),
      content: paymentRefund && (
        <>
          {formatCurrency(paymentRefund.amount)}
          {paymentRefund.transactionMethod && (
            <ExtraInfo>
              {t(
                `transactionMethod.${paymentRefund.transactionMethod}`
              ).toLocaleLowerCase(i18n.language)}
            </ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'bank',
      label: t('paymentRefunds:paymentRefundFields.bank'),
      content:
        paymentRefund &&
        (banksCatalog?.find((bank) => bank.id === paymentRefund.bankId)
          ?.name ?? (
          <NotSpecified>{t('paymentRefunds:bankNotSpecified')}</NotSpecified>
        )),
      fetching: isFetching || isFetchingBanksCatalog,
    },
    {
      key: 'description',
      label: t('paymentRefunds:paymentRefundFields.description'),
      content: paymentRefund?.description || (
        <NotSpecified>
          {t('paymentRefunds:descriptionNotSpecified')}
        </NotSpecified>
      ),
      preserveWhiteSpaces: true,
      fetching: isFetching,
    },
    {
      key: 'created-date',
      label: t('paymentRefunds:paymentRefundFields.createdDate'),
      content: paymentRefund && (
        <>
          {formatDateTime(paymentRefund.createdDate)}
          <ExtraInfo>
            {t(
              'paymentRefunds:paymentRefundFields.createdBy'
            ).toLocaleLowerCase(i18n.language)}{' '}
            {
              <Link
                to={replacePathParams(USER_PATH, {
                  username: paymentRefund.createdBy,
                })}
                role={UserRole.Manager}
              >
                {paymentRefund.createdBy}
              </Link>
            }
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'changed-date',
      label: t('paymentRefunds:paymentRefundFields.changedDate'),
      content: paymentRefund && (
        <>
          {formatDateTime(paymentRefund.changedDate)}
          {paymentRefund.status === PaymentRefundStatus.Voided &&
            paymentRefund.changedBy && (
              <ExtraInfo>
                {t(
                  'paymentRefunds:paymentRefundFields.changedBy'
                ).toLocaleLowerCase(i18n.language)}{' '}
                {
                  <Link
                    to={replacePathParams(USER_PATH, {
                      username: paymentRefund.changedBy,
                    })}
                    role={UserRole.Manager}
                  >
                    {paymentRefund.changedBy}
                  </Link>
                }
              </ExtraInfo>
            )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'void-description',
      label: t('paymentRefunds:paymentRefundFields.voidDescription'),
      content: paymentRefund?.voidDescription || (
        <NotSpecified>
          {t('paymentRefunds:voidDescriptionNotSpecified')}
        </NotSpecified>
      ),
      preserveWhiteSpaces: true,
      hidden:
        isFetching || paymentRefund!.status !== PaymentRefundStatus.Voided,
    },
  ];

  return (
    <MainContent
      errorMessage={notFound && t('paymentRefunds:paymentRefundNotFound')}
    >
      <ActionableHeader
        title={t('paymentRefunds:paymentRefundPage.title')}
        titleId={id}
        status={
          paymentRefund &&
          t(`paymentRefunds:paymentRefundStatus.${paymentRefund.status}`)
        }
        statusVariant={
          paymentRefund?.status === PaymentRefundStatus.Voided
            ? 'error'
            : 'success'
        }
        actions={actions}
      />

      <VoidPaymentRefundForm
        paymentRefund={paymentRefund}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newPaymentRefund) => setPaymentRefund(newPaymentRefund)}
        onFailureDueToOutdatedUI={refetch}
      />

      <PaperDescriptionList
        title={t('paymentRefunds:assessmentInformation')}
        items={assessmentInformation}
      />
      <PaperDescriptionList
        title={t('paymentRefunds:paymentRefundInformation')}
        items={paymentRefundInformation}
      />
    </MainContent>
  );
}
