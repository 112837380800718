import { useStore } from './useStore';
import { SetStateAction, useCallback } from 'react';

/**
 * Result of the `useStoreState` hook.
 */
export type UseStoreStateResult<T = any> = [
  T,
  (val: SetStateAction<T>) => void,
  () => void
];

/**
 * Hook to access and manipulate a single value of the store. Similar to
 * `useState` but the value is stored in the global store.
 */
export function useStoreState<T = any>(
  name: string,
  defaultValue?: T
): UseStoreStateResult<T> {
  const store = useStore();

  const value = store.get(name, defaultValue);
  const setValue = useCallback(
    (val: SetStateAction<T>) => store.set(name, val),
    [name, store]
  );
  const deleteValue = useCallback(() => store.delete(name), [name, store]);

  return [value, setValue, deleteValue];
}
