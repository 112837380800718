import React, { useCallback, useState } from 'react';
import {
  createStyles,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import { BrowserRouter } from 'react-router-dom';
import { ProfileProvider } from '../providers/ProfileProvider';
import { DrawerContent } from './DrawerContent';
import { AppBar } from './AppBar';
import { useConfirmationDialog } from '../providers/ConfirmationDialogProvider';
import { ScrollToTopOnRouteChange } from '../navigation/ScrollToTopOnRouteChange';
import { MainRouterConfig } from '../navigation/MainRouterConfig';

// Width of the sidebar in pixels
const DRAWER_WIDTH = 240;
// Zoom when printing
const PRINT_ZOOM = 0.65;

// XXX: Print styles using the `xs` screen size in media queries; this makes it
// so that the grid behaves as if the screen was of size `xl` when printing,
// this was based on the grid code in:
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Grid/Grid.js
const PRINT_GRID_OVERRIDE_STYLES = {
  '@media (min-width: 0)': ['sm', 'md', 'lg', 'xl'].reduce((obj, size) => {
    for (const n of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
      const width = Math.round((n / 12) * 10e7) / 10e5;
      obj[`& .MuiGrid-grid-${size}-${n}`] = {
        flexBasis: `${width}% !important`,
        flexGrow: `0 !important`,
        maxWidth: `${width}% !important`,
      };
    }
    return obj;
  }, {} as any),
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '@media print': {
        display: 'block',
        backgroundColor: theme.palette.background.paper,
        // Zoom-out when printing in order to fit more content in the page
        [`@supports (zoom: ${PRINT_ZOOM})`]: {
          zoom: PRINT_ZOOM,
        },
        // XXX: "Equivalent" zoom-out for Firefox, which doesn't support `zoom`
        [`@supports not (zoom: ${PRINT_ZOOM})`]: {
          transform: `scale(${PRINT_ZOOM})`,
          transformOrigin: '0 0',
          width: `${100 / PRINT_ZOOM}%`,
          maxWidth: `${100 / PRINT_ZOOM}%`,
        },
        ...PRINT_GRID_OVERRIDE_STYLES,
      },
    },
    permanentDrawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      '@media print': {
        display: 'none',
      },
    },
    mainToolbar: {
      '@media print': {
        display: 'none',
      },
    },
    drawerToolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
      flex: 1,
    },
    content: {
      width: '100%',
      '@media screen': {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
        },
      },
    },
  })
);

/**
 * Main application (after login).
 */
export function MainApp() {
  const [t] = useTranslation('common');
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { confirm } = useConfirmationDialog();

  const toggleDrawer = useCallback(
    () => setIsDrawerOpen((isOpen) => !isOpen),
    []
  );

  // Close drawer whenever an item is clicked
  const onItemClick = useCallback(() => setIsDrawerOpen(false), []);

  // Use our dialog confirmation to confirm changing the route (used by forms to
  // make sure we're not losing filled information)
  async function getUserConfirmation(
    message: string,
    cb: (ok: boolean) => void
  ) {
    cb(await confirm(message));
  }

  return (
    <ProfileProvider>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <ScrollToTopOnRouteChange>
          <div className={classes.root}>
            {/* App bar */}
            <AppBar onDrawerButtonClick={toggleDrawer} />

            {/* Responsive drawer */}
            <Hidden smUp>
              <Drawer
                variant="temporary"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                classes={{ paper: classes.drawerPaper }}
                ModalProps={{ keepMounted: true }}
                data-cy="app-drawer"
              >
                {/* Hide drawer */}
                <Toolbar className={classes.drawerToolbar}>
                  <IconButton
                    color="inherit"
                    aria-label={t('closeDrawer')}
                    edge="end"
                    onClick={toggleDrawer}
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
                <Divider />
                <div className={classes.drawerContainer}>
                  <DrawerContent onItemClick={onItemClick} />
                </div>
              </Drawer>
            </Hidden>
            <Hidden xsDown>
              <Drawer
                className={classes.permanentDrawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper }}
                data-cy="app-drawer"
              >
                <Toolbar />
                <div className={classes.drawerContainer}>
                  <DrawerContent onItemClick={onItemClick} />
                </div>
              </Drawer>
            </Hidden>

            {/* Main content */}
            <main className={classes.content} key="app-main" data-cy="app-main">
              <Toolbar className={classes.mainToolbar} />
              <MainRouterConfig />
            </main>
          </div>
        </ScrollToTopOnRouteChange>
      </BrowserRouter>
    </ProfileProvider>
  );
}
