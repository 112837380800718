import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import {
  useVatAssessmentsApi,
  VAT_ASSESSMENTS_URL,
  VatAssessment,
  VatEvent,
  VatEventType,
} from '../vatAssessmentsApi';
import { Link } from '../../../components/Link';
import {
  CREDIT_SETTLEMENT_PATH,
  PAYMENT_PATH,
  PAYMENT_REFUND_PATH,
  replacePathParams,
  TAX_REFUND_PATH,
  VAT_RETURN_PATH,
} from '../../../navigation/paths';
import { useResource } from '../../../util/useResource';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { VatInterestsTable } from './VatInterestsTable';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { CurlyBraceCell } from '../../../components/CurlyBraceCell';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventResourceId: {
      color: theme.palette.text.secondary,
      fontSize: '0.85rem',
    },
  })
);

/**
 * Properties of the VAT events table.
 */
export interface VatEventsTableProps {
  vatAssessment: VatAssessment | null;
}

/**
 * Table listing the events of a VAT assessment.
 */
export function VatEventsTable({ vatAssessment }: VatEventsTableProps) {
  const classes = useStyles();
  const [t] = useTranslation(['common', 'vatAssessments']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();

  const { getVatEvents } = useVatAssessmentsApi();
  const fetchVatEvents = useCallback(
    () =>
      vatAssessment
        ? getVatEvents(vatAssessment.assessmentNumber)
        : Promise.resolve([]),
    [getVatEvents, vatAssessment]
  );
  const { isFetching, resource: vatEvents } = useResource({
    fetchResource: fetchVatEvents,
    errorFetchingResourceMessage: t(
      'vatAssessments:vatAssessmentPage.errorFetchingVatEvents'
    ),
  });

  const columns: DataTableColumns<VatEvent> = {
    eventId: {
      value: ({ eventId }) => eventId,
      hidden: true,
    },
    event: {
      label: t('vatAssessments:vatEventFields.event'),
      value: ({ eventType, eventResourceId }) =>
        t(`vatAssessments:vatEventTypes.${eventType}`) +
        (eventResourceId == null ? '' : ` #${eventResourceId}`),
      render: (_content, _value, row) => {
        let path = null;
        if (row.eventResourceId != null) {
          path =
            row.eventType === VatEventType.VatReturn
              ? VAT_RETURN_PATH
              : row.eventType === VatEventType.TaxRefund
              ? TAX_REFUND_PATH
              : row.eventType === VatEventType.Payment
              ? PAYMENT_PATH
              : row.eventType === VatEventType.PaymentRefund
              ? PAYMENT_REFUND_PATH
              : row.eventType === VatEventType.CreditSettlement
              ? CREDIT_SETTLEMENT_PATH
              : null;
        }
        return path ? (
          <Link
            to={replacePathParams(path, {
              id: row.eventResourceId,
            })}
          >
            {t(`vatAssessments:vatEventTypes.${row.eventType}`)}
            &nbsp;
            <span className={classes.eventResourceId}>
              #{row.eventResourceId}
            </span>
          </Link>
        ) : (
          t(`vatAssessments:vatEventTypes.${row.eventType}`)
        );
      },
      sortable: false,
    },
    valueDate: {
      label: t('vatAssessments:vatEventFields.valueDate'),
      value: ({ valueDate }) => valueDate,
      format: (value) => formatDate(value as Date),
      sortable: false,
    },
    amount: {
      align: 'right',
      label: t('vatAssessments:vatEventFields.amount'),
      value: ({ amount }) => Math.abs(amount),
      format: (value) => formatCurrency(value as number),
      render: (content, _value, row) => (
        <>
          {row.eventType === VatEventType.VatReturn && row.amount !== 0 && (
            <ExtraInfo marginSide="right">
              {row.amount < 0
                ? t('vatAssessments:vatEventAmountToEnter')
                : t('vatAssessments:vatEventAmountToCompensate')}
            </ExtraInfo>
          )}
          {content}
        </>
      ),
      sortable: false,
    },
    interestTotal: {
      align: 'right',
      label: t('vatAssessments:vatEventFields.interestTotal'),
      value: ({ interestTotal }) => interestTotal,
      format: (value) => formatCurrency(value as number),
      sortable: false,
    },
    debits: {
      align: 'right',
      label: t('vatAssessments:vatEventFields.debits'),
      value: ({ vatFinal, interestFinal }) => vatFinal + interestFinal,
      format: (value, { vatRefundableFinal, paymentRefundableFinal }) =>
        value === 0 &&
        (vatRefundableFinal !== 0 || paymentRefundableFinal !== 0)
          ? ''
          : formatCurrency(value as number),
      sortable: false,
    },
    debitDetails: {
      render: (_content, _value, row) =>
        (row.vatFinal !== 0 || row.interestFinal !== 0) && (
          <CurlyBraceCell startExpanded>
            <div>
              {formatCurrency(row.vatFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatEventFields.vatFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.interestFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatEventFields.interestFinal')}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
    credits: {
      align: 'right',
      label: t('vatAssessments:vatEventFields.credits'),
      value: ({ vatRefundableFinal, paymentRefundableFinal }) =>
        vatRefundableFinal + paymentRefundableFinal,
      format: (value) => (value === 0 ? '' : formatCurrency(value as number)),
      sortable: false,
    },
    creditDetails: {
      render: (_content, _value, row) =>
        (row.vatRefundableFinal !== 0 || row.paymentRefundableFinal !== 0) && (
          <CurlyBraceCell startExpanded>
            <div>
              {formatCurrency(row.vatRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatEventFields.vatRefundableFinal')}
              </ExtraInfo>
            </div>
            <div>
              {formatCurrency(row.paymentRefundableFinal)}
              <ExtraInfo nonBreaking>
                {t('vatAssessments:vatEventFields.paymentRefundableFinal')}
              </ExtraInfo>
            </div>
          </CurlyBraceCell>
        ),
      sortable: false,
      paddinglessY: true,
      paddinglessX: true,
      width: 1,
    },
  };

  return (
    <DataTable
      title={t('vatAssessments:vatAssessmentPage.vatEventsDataTable.title')}
      rows={vatEvents || []}
      rowId={({ eventId }) => eventId}
      columns={columns}
      emptyMessage={t(
        'vatAssessments:vatAssessmentPage.vatEventsDataTable.emptyMessage'
      )}
      toolbarActionsLeft={
        <DownloadTableButton
          fileName={t(
            'vatAssessments:vatAssessmentPage.vatEventsDataTable.downloadFileName'
          )}
          downloadUrl={`${VAT_ASSESSMENTS_URL}/${encodeURIComponent(
            vatAssessment?.assessmentNumber!
          )}/events?${objectToQueryParamsString({ format: 'csv' })}`}
          disabled={!vatAssessment || isFetching}
        />
      }
      fetching={!vatAssessment || isFetching}
      allowFilter={false}
      allowPagination={false}
      defaultSortBy="eventId"
      rowsPerPage={Number.MAX_VALUE}
      minWidth={400}
      renderExpandedRow={(row) => (
        <VatInterestsTable vatInterests={row.vatInterests} />
      )}
      disabledRowExpansion={(row) => row.vatInterests.length === 0}
    />
  );
}
