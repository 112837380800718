import { RefObject, useEffect, useRef } from 'react';

/**
 * Hook that returns a ref object with whether the component is currently
 * mounted.
 */
export function useIsMounted(): RefObject<boolean> {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
