import React, { useCallback, useEffect } from 'react';
import {
  Chip,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OutlinedGrid } from '../../../components/OutlinedGrid';
import { FormCurrencyField, FormNumberField } from '../../../components/Form';
import { FormMode } from './VatReturnFormPage';
import { useAutoUpdateField } from '../../../util/useAutoUpdateField';
import { VatReturnOrigin } from '../vatReturnsApi';
import { useLfFormContext } from '../../../util/lfIntegration';

// Size of the quota input as a percentage of the "paper" container
const quotaInputSize = 100 * (8 / 12) * (5 / 12);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outlinedGrid: {
      marginBottom: theme.spacing(2),
    },
    accruedVatTitle: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(-1),
      textAlign: 'center',
      fontSize: '.9rem',
    },
    labelContainer: {
      [`${theme.breakpoints.up('md')}, print`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'right',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(-1),
      },
      '@media print': {
        breakInside: 'avoid',
        breakAfter: 'avoid',
      },
    },
    quotaCol: {
      [`${theme.breakpoints.up('md')}, print`]: {
        flex: `0 0 ${100 - quotaInputSize}%`,
        maxWidth: `${100 - quotaInputSize}%`,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    quotaInput: {
      [`${theme.breakpoints.up('md')}, print`]: {
        flex: `0 0 ${quotaInputSize}%`,
        maxWidth: `${quotaInputSize}%`,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    hideSm: {
      '@media screen': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    mathExp: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '@media print': {
        '& .MuiChip-root': {
          backgroundColor: 'transparent !important',
          border: `1px solid ${theme.palette.divider}`,
        },
      },
    },
    op: {
      margin: theme.spacing(0, 1),
    },
  })
);

/**
 * Properties of the "debt determination" section.
 */
export interface DebtDeterminationProps {
  formMode: FormMode;
  fetching: boolean;
  isFetchingVatReturnConstants: boolean;
}

/**
 * "Debt determination" section of the VAT return form.
 */
export function DebtDetermination({
  formMode,
  fetching,
  isFetchingVatReturnConstants,
}: DebtDeterminationProps) {
  const classes = useStyles();
  const [t] = useTranslation('vatReturns');
  const formMethods = useLfFormContext();
  const { setLfValue, watch } = formMethods;

  // When origin is "normal", fees should be disabled
  const origin = watch('origin');
  const shouldDisableFees = origin === VatReturnOrigin.Normal;

  // Reset fields when changing to a "normal" origin (and not in `VIEW` mode)
  useEffect(() => {
    if (shouldDisableFees && formMode !== FormMode.View) {
      const feeFields = [
        'interestOnLatePaymentBase',
        'surchargeABase',
        'surchargeBBase',
      ];
      for (const field of feeFields) {
        setLfValue(field, null);
      }
    }
  }, [shouldDisableFees, setLfValue, formMode]);

  // Watch rates in order to disable quotas when the rate is `0`
  const { generalRate1, generalRate2, generalRate3 } = watch([
    'generalRate1',
    'generalRate2',
    'generalRate3',
  ]);

  // Auto compute general quotas (when not in `VIEW` mode)
  const update: Record<string, () => void> = {};
  for (const [baseName, rateName, quotaName] of [
    ['generalBase1', 'generalRate1', 'generalQuota1'],
    ['generalBase2', 'generalRate2', 'generalQuota2'],
    ['generalBase3', 'generalRate3', 'generalQuota3'],
  ]) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    update[quotaName] = useAutoUpdateField(
      formMethods,
      quotaName,
      [baseName, rateName],
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback(
        ({ [baseName]: base, [rateName]: rate }) =>
          base == null ? null : Math.round((base * rate) / 100),
        [baseName, rateName]
      ),
      formMode === FormMode.View
    );
  }

  // Compute fee quotas
  const {
    interestOnLatePaymentBase,
    interestOnLatePaymentType,
    surchargeABase,
    surchargeAType,
    surchargeBBase,
    surchargeBType,
  } = watch([
    'interestOnLatePaymentBase',
    'interestOnLatePaymentType',
    'surchargeABase',
    'surchargeAType',
    'surchargeBBase',
    'surchargeBType',
  ]);
  const interestOnLatePaymentQuota =
    interestOnLatePaymentBase == null
      ? null
      : Math.round(
          interestOnLatePaymentBase * (interestOnLatePaymentType / 100)
        );
  const surchargeAQuota =
    surchargeABase == null
      ? null
      : Math.round(surchargeABase * (surchargeAType / 100));
  const surchargeBQuota =
    surchargeBBase == null
      ? null
      : Math.round(surchargeBBase * (surchargeBType / 100));
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('interestOnLatePaymentQuota', interestOnLatePaymentQuota);
    }
  }, [formMode, interestOnLatePaymentQuota, setLfValue]);
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('surchargeAQuota', surchargeAQuota);
    }
  }, [surchargeAQuota, setLfValue, formMode]);
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('surchargeBQuota', surchargeBQuota);
    }
  }, [surchargeBQuota, setLfValue, formMode]);

  // Compute total of accrued VAT (when not in `VIEW` mode)
  const accruedVatQuotas = watch([
    'generalQuota1',
    'generalQuota2',
    'generalQuota3',
    'intracommunityAcquisitionsQuota',
  ]);
  const totalAccrued = fetching
    ? null
    : [
        ...Object.values(accruedVatQuotas),
        interestOnLatePaymentQuota,
        surchargeAQuota,
        surchargeBQuota,
      ].reduce((sum, quota) => sum + (quota ?? 0), 0);
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('totalAccrued', totalAccrued);
    }
  }, [totalAccrued, setLfValue, formMode]);

  // Compute total of deductible VAT (when not in `VIEW` mode)
  const deductibleVatQuotas = watch([
    'internalOperationsQuotas',
    'importQuotas',
    'intercommunityAcquisitions',
    'investmentGoodsAcquisitions',
    'investmentsCompensation',
    'previousPeriodsCompensation',
  ]);
  const totalDeductible = fetching
    ? null
    : Object.values(deductibleVatQuotas).reduce(
        (sum, quota) => sum + (quota ?? 0),
        0
      );
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('totalDeductible', totalDeductible);
    }
  }, [totalDeductible, setLfValue, formMode]);

  // Compute total VAT to enter and compensate (when not in `VIEW` mode)
  const vatTotal = fetching
    ? null
    : Math.max(totalAccrued - totalDeductible, 0);
  const vatCredit = fetching
    ? null
    : Math.max(totalDeductible - totalAccrued, 0);
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('vatTotal', vatTotal);
    }
  }, [vatTotal, setLfValue, formMode]);
  useEffect(() => {
    if (formMode !== FormMode.View) {
      setLfValue('vatCredit', vatCredit);
    }
  }, [vatCredit, setLfValue, formMode]);

  return (
    <>
      {/* Accrued VAT */}
      <OutlinedGrid
        className={classes.outlinedGrid}
        container
        spacing={2}
        title={t('vatReturns:vatReturnFormPage.accruedVat')}
        titleComponent="h3"
      >
        {/* Titles */}
        <Hidden smDown>
          <Grid item md={4} />
          <Grid item md={8}>
            <Grid container spacing={2}>
              <Grid item md={5}>
                <Typography className={classes.accruedVatTitle}>
                  {t('vatReturns:vatReturnFormPage.baseTax')}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography className={classes.accruedVatTitle}>
                  {t('vatReturns:vatReturnFormPage.type')}
                </Typography>
              </Grid>
              <Grid item md={5}>
                <Typography className={classes.accruedVatTitle}>
                  {t('vatReturns:vatReturnFormPage.quota')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        {/* General regime */}
        <Grid item className={classes.labelContainer} xs={12} md={4}>
          <Typography component="label" htmlFor="generalBase1">
            {t('vatReturns:vatReturnFormPage.generalRegime')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {/* General regime 1 */}
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                id="generalBase1"
                name="generalBase1"
                label={`1. ${t('vatReturns:vatReturnFormPage.fields.base')}`}
                onChange={update.generalQuota1}
                onChangeTriggers={['generalQuota1']}
                fetching={fetching}
                disabled={formMode === FormMode.View}
                min={0}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <FormNumberField
                name="generalRate1"
                label={`2. ${t('vatReturns:vatReturnFormPage.fields.rate')}`}
                onChange={update.generalQuota1}
                onChangeTriggers={['generalQuota1']}
                fetching={fetching || isFetchingVatReturnConstants}
                disabled
                suffix="%"
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <FormCurrencyField
                name="generalQuota1"
                label={`3. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
                onChangeTriggers={['generalBase1']}
                fetching={fetching}
                disabled={formMode === FormMode.View || generalRate1 === 0}
                min={0}
              />
            </Grid>

            {/* General regime 2 */}
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                name="generalBase2"
                label={`4. ${t('vatReturns:vatReturnFormPage.fields.base')}`}
                onChange={update.generalQuota2}
                onChangeTriggers={['generalQuota2']}
                fetching={fetching}
                disabled={formMode === FormMode.View}
                min={0}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <FormNumberField
                name="generalRate2"
                label={`5. ${t('vatReturns:vatReturnFormPage.fields.rate')}`}
                onChange={update.generalQuota2}
                onChangeTriggers={['generalQuota2']}
                fetching={fetching || isFetchingVatReturnConstants}
                disabled
                suffix="%"
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <FormCurrencyField
                name="generalQuota2"
                label={`6. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
                onChangeTriggers={['generalBase2']}
                fetching={fetching}
                disabled={formMode === FormMode.View || generalRate2 === 0}
                min={0}
              />
            </Grid>

            {/* General regime 3 */}
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                name="generalBase3"
                label={`7. ${t('vatReturns:vatReturnFormPage.fields.base')}`}
                onChange={update.generalQuota3}
                onChangeTriggers={['generalQuota3']}
                fetching={fetching}
                disabled={formMode === FormMode.View}
                min={0}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <FormNumberField
                name="generalRate3"
                label={`8. ${t('vatReturns:vatReturnFormPage.fields.rate')}`}
                onChange={update.generalQuota3}
                onChangeTriggers={['generalQuota3']}
                fetching={fetching || isFetchingVatReturnConstants}
                disabled
                suffix="%"
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <FormCurrencyField
                name="generalQuota3"
                label={`9. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
                onChangeTriggers={['generalBase3']}
                fetching={fetching}
                disabled={formMode === FormMode.View || generalRate3 === 0}
                min={0}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Late payment */}
        <Grid item className={classes.labelContainer} xs={12} md={4}>
          <Typography component="label" htmlFor="interestOnLatePaymentBase">
            {t('vatReturns:vatReturnFormPage.interestOnLatePayment')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                id="interestOnLatePaymentBase"
                name="interestOnLatePaymentBase"
                label={`10. ${t('vatReturns:vatReturnFormPage.fields.base')}`}
                fetching={fetching}
                disabled={formMode === FormMode.View || shouldDisableFees}
                min={0}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <FormNumberField
                name="interestOnLatePaymentType"
                label={`11. ${t('vatReturns:vatReturnFormPage.fields.type')}`}
                fetching={fetching || isFetchingVatReturnConstants}
                disabled
                suffix="%"
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <FormCurrencyField
                name="interestOnLatePaymentQuota"
                label={`12. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
                fetching={fetching}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Surcharge A */}
        <Grid item className={classes.labelContainer} xs={12} md={4}>
          <Typography component="label" htmlFor="surchargeABase">
            {t('vatReturns:vatReturnFormPage.surchargeA')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                id="surchargeABase"
                name="surchargeABase"
                label={`13. ${t('vatReturns:vatReturnFormPage.fields.base')}`}
                fetching={fetching}
                disabled={formMode === FormMode.View || shouldDisableFees}
                min={0}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <FormNumberField
                name="surchargeAType"
                label={`14. ${t('vatReturns:vatReturnFormPage.fields.type')}`}
                fetching={fetching || isFetchingVatReturnConstants}
                disabled
                suffix="%"
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <FormCurrencyField
                name="surchargeAQuota"
                label={`15. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
                fetching={fetching}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Surcharge B */}
        <Grid item className={classes.labelContainer} xs={12} md={4}>
          <Typography component="label" htmlFor="surchargeBBase">
            {t('vatReturns:vatReturnFormPage.surchargeB')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                id="surchargeBBase"
                name="surchargeBBase"
                label={`16. ${t('vatReturns:vatReturnFormPage.fields.base')}`}
                disabled={formMode === FormMode.View || shouldDisableFees}
                fetching={fetching}
                min={0}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <FormNumberField
                name="surchargeBType"
                label={`17. ${t('vatReturns:vatReturnFormPage.fields.type')}`}
                fetching={fetching || isFetchingVatReturnConstants}
                disabled
                suffix="%"
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <FormCurrencyField
                name="surchargeBQuota"
                label={`18. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
                fetching={fetching}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Intracommunity acquisitions */}
        <Grid item className={classes.labelContainer} xs={12} md={4}>
          <Typography component="label" htmlFor="intracommunityAcquisitions">
            {t('vatReturns:vatReturnFormPage.intracommunityAcquisitions')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                id="intracommunityAcquisitions"
                name="intracommunityAcquisitionsBase"
                label={`19. ${t('vatReturns:vatReturnFormPage.fields.base')}`}
                onChangeTriggers={['intracommunityAcquisitionsQuota']}
                fetching={fetching}
                disabled={formMode === FormMode.View}
                min={0}
              />
            </Grid>
            <Grid item md={2} className={classes.hideSm} />
            <Grid item xs={12} md={5}>
              <FormCurrencyField
                name="intracommunityAcquisitionsQuota"
                label={`20. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
                onChangeTriggers={['intracommunityAcquisitionsBase']}
                fetching={fetching}
                disabled={formMode === FormMode.View}
                min={0}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Total accrued */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="totalAccrued">
            {t('vatReturns:vatReturnFormPage.totalAccrued')}{' '}
            <span className={classes.mathExp}>
              <Chip label="3" />
              <span className={classes.op}>+</span>
              <Chip label="6" />
              <span className={classes.op}>+</span>
              <Chip label="9" />
              <span className={classes.op}>+</span>
              <Chip label="12" />
              <span className={classes.op}>+</span>
              <Chip label="15" />
              <span className={classes.op}>+</span>
              <Chip label="18" />
              <span className={classes.op}>+</span>
              <Chip label="20" />
            </span>
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="totalAccrued"
            name="totalAccrued"
            label={`21. ${t('vatReturns:vatReturnFormPage.fields.total')}`}
            fetching={fetching}
            disabled
          />
        </Grid>
      </OutlinedGrid>

      {/* Deductible VAT */}
      <OutlinedGrid
        className={classes.outlinedGrid}
        container
        spacing={2}
        margin-top={2}
        title={t('vatReturns:vatReturnFormPage.deductibleVat')}
        titleComponent="h3"
      >
        {/* Internal operations quotas */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="internalOperationsQuotas">
            {t('vatReturns:vatReturnFormPage.internalOperationsQuotas')}
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="internalOperationsQuotas"
            name="internalOperationsQuotas"
            label={`22. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
            fetching={fetching}
            disabled={formMode === FormMode.View}
            min={0}
          />
        </Grid>

        {/* Import quotas */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="importQuotas">
            {t('vatReturns:vatReturnFormPage.importQuotas')}
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="importQuotas"
            name="importQuotas"
            label={`23. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
            fetching={fetching}
            disabled={formMode === FormMode.View}
            min={0}
          />
        </Grid>

        {/* Intercommunity acquisitions */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="intercommunityAcquisitions">
            {t('vatReturns:vatReturnFormPage.intercommunityAcquisitions')}
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="intercommunityAcquisitions"
            name="intercommunityAcquisitions"
            label={`24. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
            fetching={fetching}
            disabled={formMode === FormMode.View}
            min={0}
          />
        </Grid>

        {/* Investment goods acquisitions */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="investmentGoodsAcquisitions">
            {t('vatReturns:vatReturnFormPage.investmentGoodsAcquisitions')}
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="investmentGoodsAcquisitions"
            name="investmentGoodsAcquisitions"
            label={`25. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
            fetching={fetching}
            disabled={formMode === FormMode.View}
            min={0}
          />
        </Grid>

        {/* Investments compensation */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="investmentsCompensation">
            {t('vatReturns:vatReturnFormPage.investmentsCompensation')}
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="investmentsCompensation"
            name="investmentsCompensation"
            label={`26. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
            fetching={fetching}
            disabled={formMode === FormMode.View}
            min={0}
          />
        </Grid>

        {/* Previous periods compensation */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="previousPeriodsCompensation">
            {t('vatReturns:vatReturnFormPage.previousPeriodsCompensation')}
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="previousPeriodsCompensation"
            name="previousPeriodsCompensation"
            label={`27. ${t('vatReturns:vatReturnFormPage.fields.quota')}`}
            fetching={fetching}
            disabled={formMode === FormMode.View}
            min={0}
          />
        </Grid>

        {/* Total deductible */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="totalDeductible">
            {t('vatReturns:vatReturnFormPage.totalDeductible')}
            <span className={classes.mathExp}>
              <Chip label="22" />
              <span className={classes.op}>+</span>
              <Chip label="23" />
              <span className={classes.op}>+</span>
              <Chip label="24" />
              <span className={classes.op}>+</span>
              <Chip label="25" />
              <span className={classes.op}>+</span>
              <Chip label="26" />
              <span className={classes.op}>+</span>
              <Chip label="27" />
            </span>
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="totalDeductible"
            name="totalDeductible"
            label={`28. ${t('vatReturns:vatReturnFormPage.fields.total')}`}
            fetching={fetching}
            disabled
          />
        </Grid>
      </OutlinedGrid>

      <Grid container spacing={2}>
        {/* Total VAT to enter */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="vatTotal">
            {t('vatReturns:vatReturnFormPage.vatTotal')}
            <span className={classes.mathExp}>
              <Chip label="21" />
              <span className={classes.op}>-</span>
              <Chip label="28" />
            </span>
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="vatTotal"
            name="vatTotal"
            label={`29. ${t('vatReturns:vatReturnFormPage.fields.total')}`}
            fetching={fetching}
            disabled
          />
        </Grid>

        {/* VAT to compensate */}
        <Grid item className={`${classes.labelContainer} ${classes.quotaCol}`}>
          <Typography component="label" htmlFor="vatCredit">
            {t('vatReturns:vatReturnFormPage.vatCredit')}
            <span className={classes.mathExp}>
              <Chip label="28" />
              <span className={classes.op}>-</span>
              <Chip label="21" />
            </span>
          </Typography>
        </Grid>
        <Grid item className={classes.quotaInput}>
          <FormCurrencyField
            id="vatCredit"
            name="vatCredit"
            label={`30. ${t('vatReturns:vatReturnFormPage.fields.total')}`}
            fetching={fetching}
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
}
