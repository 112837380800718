import { Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import {
  useProfileApi,
  UserProfile,
  UserRole,
  UserRoleAuthority,
} from './profileApi';
import { ProfileContext } from './ProfileContext';
import { useTranslation } from 'react-i18next';

/**
 * Properties of the profile provider.
 */
export interface ProfileProviderProps {
  children: ReactNode;
}

/**
 * Profile provider.
 */
export function ProfileProvider({ children }: ProfileProviderProps) {
  const [t] = useTranslation('common');
  const { getProfile } = useProfileApi();

  const {
    isFetching,
    resource: userProfileOrMustChangePassword,
    setResource: setProfile,
    refetch,
  } = useResource({
    fetchResource: getProfile,
    errorFetchingResourceMessage: t('errorFetchingProfile'),
  });

  // Whether the user must change their password
  const mustChangePassword =
    (userProfileOrMustChangePassword as any)?.mustChangePassword === true;

  const profile = mustChangePassword
    ? null
    : (userProfileOrMustChangePassword as UserProfile | null);

  /**
   * Function used to check if the logged in user has a certain role.
   */
  const hasRole = useCallback(
    (role: UserRole) => {
      return (
        profile != null &&
        UserRoleAuthority[profile!.role] <= UserRoleAuthority[role]
      );
    },
    [profile]
  );

  return (
    <ProfileContext.Provider
      value={{
        isFetching,
        profile,
        setProfile: setProfile as Dispatch<SetStateAction<UserProfile | null>>,
        mustChangePassword,
        hasRole,
        refetch,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
