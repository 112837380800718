import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import {
  Form,
  FormCurrencyField,
  FormDateField,
  TitledPaper,
  FormTaxpayerIdField,
  FormTextField,
} from '../../../components/Form';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { MainContent } from '../../../components/MainContent';
import {
  CREDIT_SETTLEMENT_PATH,
  CREDIT_SETTLEMENTS_PATH,
  replacePathParams,
} from '../../../navigation/paths';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { Action } from '../../../components/Actions';
import { FormAssessmentNumberField } from '../../../components/Form/FormAssessmentNumberField';
import { useLfForm } from '../../../util/lfIntegration';
import { defaultCreditSettlementForm } from './defaultCreditSettlementForm';
import { useTaxpayer } from '../../Taxpayers';
import { useVatAssessment } from '../../VatAssessments/useVatAssessment';
import { ivageCommon, ivageCommonUtil } from '../../../ivageCommon';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useCreditSettlementsApi } from '../creditSettlementsApi';
import { useIsMounted } from '../../../util/useIsMounted';

/**
 * Package in IVAGE common containing the credit settlement form schema.
 */
const creditSettlementFormSchemaPkg =
  ivageCommon.feature.creditsettlement.schema;

/**
 * Form used to create a new credit settlement.
 */
export function CreditSettlementFormPage() {
  const [t] = useTranslation(['common', 'creditSettlements']);
  useSetDocumentTitle(
    t('creditSettlements:creditSettlementFormPage.documentTitle')
  );
  const { createCreditSettlement } = useCreditSettlementsApi();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formMethods = useLfForm({
    defaultValues: defaultCreditSettlementForm(),
    formValidatorName: 'creditSettlementFormValidator',
    i18nErrorMessagesPrefixes: [
      'creditSettlements:creditSettlementFormPage.fieldErrors',
      'dateField.fieldErrors',
    ],
  });
  const {
    reset,
    register,
    getValues,
    formState,
    setLfValue,
    watch,
    setLfIssues,
    setManualLfIssue,
    removeManualLfIssue,
  } = formMethods;
  const {
    isFetching: isFetchingConfigsCatalog,
    catalog: configsCatalog,
  } = useCatalog('configs');
  const isMounted = useIsMounted();

  // Register tax id while hiding the select for now
  register('taxId');

  // When the assessment number is filled, we auto-fill the tax and taxable
  // year/period and validate it with whether it exists and belongs to taxpayer
  const assessmentNumber = watch('assessmentNumber');
  const {
    isFetching: isFetchingAssessment,
    vatAssessment,
    notFound: assessmentNotFound,
  } = useVatAssessment(
    ivageCommonUtil.assessmentNumberIsValid(assessmentNumber)
      ? assessmentNumber
      : undefined
  );
  useEffect(() => {
    if (assessmentNumber) {
      setLfValue('taxpayerId', vatAssessment?.taxpayerId ?? '');
      // setLfValue('taxId', vatAssessment ? TaxId.VAT : null);
      setLfValue('taxableYear', vatAssessment?.taxableYear ?? null);
      setLfValue('taxablePeriod', vatAssessment?.taxablePeriod ?? null);
    }
    removeManualLfIssue({ path: '/assessmentNumber', code: 'doesNotExist' });
    if (assessmentNotFound) {
      setManualLfIssue({ path: '/assessmentNumber', code: 'doesNotExist' });
    }
  }, [
    assessmentNumber,
    vatAssessment,
    assessmentNotFound,
    setLfValue,
    removeManualLfIssue,
    setManualLfIssue,
    formState.touched.taxId,
    formState.touched.taxableYear,
    formState.touched.taxablePeriod,
  ]);

  const taxpayerId = watch('taxpayerId');
  const {
    taxpayer,
    isFetching: isFetchingTaxpayer,
    notFound: taxpayerNotFound,
  } = useTaxpayer(
    ivageCommonUtil.taxpayerIdIsValid(taxpayerId) ? taxpayerId : undefined
  );

  // Validate that taxpayer exists
  useEffect(() => {
    removeManualLfIssue({ path: '/taxpayerId', code: 'doesNotExist' });
    if (taxpayerNotFound) {
      setManualLfIssue({ path: '/taxpayerId', code: 'doesNotExist' });
    }
  }, [taxpayerNotFound, setManualLfIssue, removeManualLfIssue]);

  // Selected taxable year, use to limit the periods that can be selected
  const { taxableYear, taxablePeriod } = watch([
    'taxableYear',
    'taxablePeriod',
  ]);

  // Create or update the tax when submitting
  async function onSubmit() {
    const creditSettlementForm = getValues();
    try {
      const creditSettlement = await createCreditSettlement(
        creditSettlementForm
      );

      enqueueSnackbar(
        t(
          'creditSettlements:creditSettlementFormPage.successCreatingCreditSettlement'
        ),
        { variant: 'success' }
      );

      if (isMounted.current) {
        setFormSubmitted(true);
        history.push(
          replacePathParams(CREDIT_SETTLEMENT_PATH, {
            id: creditSettlement.creditSettlementId,
          })
        );
      }
    } catch (err) {
      if (err instanceof Response && err.status === 422 && isMounted.current) {
        setLfIssues(await err.json(), true);
      } else {
        enqueueSnackbar(
          t(
            'creditSettlements:creditSettlementFormPage.errorCreatingCreditSettlement'
          ),
          { variant: 'error' }
        );
      }
    }
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: 'credit-settlement-form-submit',
      type: 'submit',
      label: t(
        'creditSettlements:creditSettlementFormPage.createCreditSettlement'
      ),
      color: 'primary',
      icon: <Add />,
      loading: formState.isSubmitting,
      disabled:
        isFetchingConfigsCatalog || isFetchingTaxpayer || isFetchingAssessment,
    },
    {
      id: 'credit-settlement-form-reset',
      type: 'reset',
      label: t('formActions.reset'),
      run: () => reset(),
      disabled: !formState.isDirty || formState.isSubmitting,
    },
    {
      id: 'credit-settlement-form-cancel',
      label: t('formActions.cancel'),
      run: () => history.push(CREDIT_SETTLEMENTS_PATH),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <MainContent>
      <Form
        onSubmit={onSubmit}
        shouldPromptOnLeave={formState.isDirty && !formSubmitted}
        {...formMethods}
      >
        <ActionableHeader
          title={t('creditSettlements:creditSettlementFormPage.title')}
          actions={actions}
        />

        {/* Assessment information */}
        <TitledPaper title={t('creditSettlements:assessmentInformation')}>
          <Grid container spacing={2}>
            {/* Assessment number */}
            <Grid item xs={12}>
              <FormAssessmentNumberField
                name="assessmentNumber"
                label={t(
                  'creditSettlements:creditSettlementFields.assessmentNumber'
                )}
                onChangeTriggers={[
                  'taxpayerId',
                  'taxId',
                  'taxableYear',
                  'taxablePeriod',
                ]}
                helperText={t(
                  'creditSettlements:creditSettlementFormPage.assessmentFillInstructions'
                )}
              />
            </Grid>

            {/* Taxpayer id */}
            <Grid item xs={12} md={4}>
              <FormTaxpayerIdField
                name="taxpayerId"
                label={t('creditSettlements:creditSettlementFields.taxpayerId')}
                disabled={assessmentNumber !== ''}
                onChangeTriggers={['assessmentNumber']}
                fetching={isFetchingAssessment || isFetchingTaxpayer}
                helperText={taxpayer?.name}
              />
            </Grid>

            {/* Tax */}
            {/*<Grid item xs={12} md={4}>*/}
            {/*  <FormSelect*/}
            {/*    name="taxId"*/}
            {/*    label={t('creditSettlements:creditSettlementFields.tax')}*/}
            {/*    items={[*/}
            {/*      { value: null, label: <em>{t('taxId.none')}</em> },*/}
            {/*      ...TAX_IDS.map((taxId) => ({*/}
            {/*        value: taxId,*/}
            {/*        label: t(`taxId.${taxId}`),*/}
            {/*      })),*/}
            {/*    ]}*/}
            {/*    disabled={assessmentNumber !== ''}*/}
            {/*    onChangeTriggers={['assessmentNumber']}*/}
            {/*  />*/}
            {/*</Grid>*/}

            {/* Taxable year/period */}
            <FormTaxableYearPeriod
              taxableYearProps={{
                name: 'taxableYear',
                label: t(
                  'creditSettlements:creditSettlementFields.taxableYear'
                ),
                onChangeTriggers: [
                  'assessmentNumber',
                  'taxablePeriod',
                  'paymentDate',
                ],
                disabled: assessmentNumber !== '',
                fetching: isFetchingAssessment,
              }}
              taxablePeriodProps={{
                name: 'taxablePeriod',
                label: t(
                  'creditSettlements:creditSettlementFields.taxablePeriod'
                ),
                onChangeTriggers: ['assessmentNumber', 'paymentDate'],
                disabled: assessmentNumber !== '',
                fetching: isFetchingAssessment,
              }}
            >
              {(taxableYear, taxablePeriod) => (
                <>
                  <Grid item xs={6} md={4}>
                    {taxableYear}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    {taxablePeriod}
                  </Grid>
                </>
              )}
            </FormTaxableYearPeriod>
          </Grid>
        </TitledPaper>

        {/* Credit settlement information */}
        <TitledPaper title={t('creditSettlements:creditSettlementInformation')}>
          <Grid container spacing={2}>
            {/* Credit settlement date */}
            <Grid item xs={12} sm={6}>
              <FormDateField
                name="creditSettlementDate"
                label={t(
                  'creditSettlements:creditSettlementFields.creditSettlementDate'
                )}
                minDate={
                  taxableYear && taxablePeriod
                    ? new Date(taxableYear, +taxablePeriod - 1)
                    : configsCatalog?.vatReturnInitialTaxableYearPeriod
                }
                disableFuture={true}
                fetching={isFetchingConfigsCatalog}
              />
            </Grid>

            {/* Amount */}
            <Grid item xs={12} sm={6}>
              <FormCurrencyField
                name="amount"
                label={t('creditSettlements:creditSettlementFields.amount')}
                min={0}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <FormTextField
                name="description"
                label={t(
                  'creditSettlements:creditSettlementFields.description'
                )}
                multiline
                rows={5}
                inputProps={{
                  maxLength:
                    creditSettlementFormSchemaPkg.DESCRIPTION_MAX_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </TitledPaper>
      </Form>
    </MainContent>
  );
}
