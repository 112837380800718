import React, {
  DetailedHTMLProps,
  FormHTMLAttributes,
  ReactNode,
  useEffect,
} from 'react';
import { FormProvider, FormProviderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Prompt, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface CustomFormProps {
  onSubmit(): void;
  shouldPromptOnLeave?: boolean;
  formProps?: Omit<
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    'onSubmit'
  >;
  children?: ReactNode;
}

/**
 * Properties of the form component.
 */
export type FormProps = Omit<FormProviderProps, 'children'> & CustomFormProps;

/**
 * Form component which provides a RHF `FormProvider` given the methods and adds
 * a `form` element with a provided `onSubmit`.
 */
export function Form({
  onSubmit,
  shouldPromptOnLeave,
  children,
  formProps,
  ...methods
}: FormProps) {
  const [t] = useTranslation('common');
  const location = useLocation();
  const { handleSubmit, errors } = methods;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Form-wide error
  const error = errors[''];
  useEffect(() => {
    if (error != null) {
      const snackbarKey = `formError-${error.type}-${Math.random()}`;
      enqueueSnackbar(error.message, {
        variant: 'error',
        key: snackbarKey,
      });
      return () => closeSnackbar(snackbarKey);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  return (
    <FormProvider {...methods}>
      {/* Confirm when leaving a non-submitted dirty form */}
      <Prompt
        when={!!shouldPromptOnLeave}
        message={(newLocation) =>
          // Allow navigation to the same exact location with same query params
          location.pathname === newLocation.pathname &&
          location.search === newLocation.search
            ? true
            : (t('form.confirmLeaving') as string)
        }
      />

      <form onSubmit={handleSubmit(onSubmit)} {...formProps}>
        {children}
      </form>
    </FormProvider>
  );
}
