import {
  Cached,
  ListAlt,
  OpenInNew,
  RemoveCircleOutline,
} from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  READ_VAT_RETURN_PATH,
  REPLACE_VAT_RETURN_PATH,
  replacePathParams,
  VAT_RETURN_PATH,
} from '../../navigation/paths';
import { Action } from '../../components/Actions';
import { VatReturn, VatReturnStatus } from './vatReturnsApi';

/**
 * Hook that returns actions that can be performed on a VAT return.
 */
export function useVatReturnActions(
  vatReturn: VatReturn | null,
  onVoid: () => void
): Action[] {
  const [t] = useTranslation('vatReturns');
  const { pathname } = useLocation();

  const vatReturnPath = replacePathParams(VAT_RETURN_PATH, {
    id: vatReturn?.taxReturnId,
  });

  return useMemo(
    () => [
      {
        id: `vat-return-${vatReturn?.taxReturnId}-open`,
        label: t('vatReturns:vatReturnActions.open'),
        component: Link,
        to: vatReturnPath,
        icon: <OpenInNew />,
        hidden: !vatReturn || pathname === vatReturnPath,
      },
      {
        id: `vat-return-${vatReturn?.taxReturnId}-read`,
        label: t('vatReturns:vatReturnActions.read'),
        component: Link,
        to: replacePathParams(READ_VAT_RETURN_PATH, {
          id: vatReturn?.taxReturnId,
        }),
        icon: <ListAlt />,
        hidden: !vatReturn,
      },
      {
        id: `vat-return-${vatReturn?.taxReturnId}-replace`,
        label: t('vatReturns:vatReturnActions.replace'),
        component: Link,
        to: replacePathParams(REPLACE_VAT_RETURN_PATH, {
          id: vatReturn?.taxReturnId,
        }),
        icon: <Cached />,
        hidden: !vatReturn,
      },
      {
        id: `vat-return-${vatReturn?.taxReturnId}-void`,
        label: t('vatReturns:vatReturnActions.void'),
        run: onVoid,
        icon: <RemoveCircleOutline />,
        style: 'error',
        hidden: !vatReturn || vatReturn.status !== VatReturnStatus.Effective,
      },
    ],
    [t, vatReturnPath, vatReturn, pathname, onVoid]
  );
}
