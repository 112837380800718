import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  CreditSettlement,
  useCreditSettlementsApi,
} from './creditSettlementsApi';
import { useLfForm } from '../../util/lfIntegration';
import { Form, FormTextField } from '../../components/Form';
import { ivageCommon } from '../../ivageCommon';
import { TitleId } from '../../components/TitleId';
import { Action, DialogActionButtons } from '../../components/Actions';

/**
 * Package in IVAGE common containing the void credit settlement form schema.
 */
const voidCreditSettlementFormSchemaPkg =
  ivageCommon.feature.creditsettlement.schema;

/**
 * Properties of the void credit settlement form.
 */
export interface VoidCreditSettlementFormProps {
  creditSettlement: CreditSettlement | null;
  open: boolean;

  setOpen(open: boolean): void;

  onSuccess(creditSettlement: CreditSettlement): void;

  onFailureDueToOutdatedUI(): void;
}

/**
 * Modal form used to void a credit settlement.
 */
export function VoidCreditSettlementForm({
  creditSettlement,
  open,
  setOpen,
  onSuccess,
  onFailureDueToOutdatedUI,
}: VoidCreditSettlementFormProps) {
  const [t] = useTranslation(['common', 'creditSettlements']);
  const { voidCreditSettlement } = useCreditSettlementsApi();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: { voidDescription: '' },
    formValidatorName: 'voidCreditSettlementFormValidator',
    i18nErrorMessagesPrefixes:
      'creditSettlements:voidCreditSettlementForm.fieldErrors',
  });
  const { reset, getValues, formState } = formMethods;

  // Reset form whenever the credit settlement changes or modal changes open
  // state
  useEffect(() => {
    reset();
  }, [reset, creditSettlement, open]);

  // Close form modal
  function close() {
    setOpen(false);
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: `void-credit-settlement-form-${creditSettlement?.creditSettlementId}-submit`,
      type: 'submit',
      label: t('creditSettlements:voidCreditSettlementForm.void'),
      style: 'error',
      loading: formState.isSubmitting,
      disabled: !creditSettlement,
    },
    {
      id: `void-credit-settlement-form-${creditSettlement?.creditSettlementId}-cancel`,
      label: t('formActions.cancel'),
      run: () => close(),
      disabled: formState.isSubmitting,
    },
  ];

  async function onSubmit() {
    const voidCreditSettlementForm = getValues();
    try {
      const newCreditSettlement = await voidCreditSettlement(
        creditSettlement!.creditSettlementId,
        voidCreditSettlementForm
      );
      close();
      onSuccess(newCreditSettlement);
      enqueueSnackbar(
        t('creditSettlements:creditSettlementVoidedSuccessfully', {
          id: creditSettlement!.creditSettlementId,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        close();
        onFailureDueToOutdatedUI();
        enqueueSnackbar(
          t('creditSettlements:errorVoidingCreditSettlementDueToOutdatedUI', {
            id: creditSettlement!.creditSettlementId,
          }),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(
          t('creditSettlements:errorVoidingCreditSettlement', {
            id: creditSettlement!.creditSettlementId,
          }),
          { variant: 'error' }
        );
      }
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={close}
      disableBackdropClick={formState.isDirty || formState.isSubmitting}
      disableEscapeKeyDown={formState.isDirty || formState.isSubmitting}
      aria-labelledby="void-credit-settlement-form"
    >
      <Form onSubmit={onSubmit} {...formMethods}>
        <DialogTitle id="void-credit-settlement-form">
          {t('creditSettlements:voidCreditSettlementForm.title') + ' '}
          <TitleId titleId={creditSettlement?.creditSettlementId} />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Void description */}
            <Grid item xs={12}>
              <FormTextField
                name="voidDescription"
                label={t(
                  'creditSettlements:creditSettlementFields.voidDescription'
                )}
                multiline
                rows={5}
                inputProps={{
                  maxLength:
                    voidCreditSettlementFormSchemaPkg.VOID_DESCRIPTION_MAX_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
