import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Add, Refresh } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  CreateTaxpayerForm,
  UpdateTaxpayerForm,
  LegalRegime,
  TaxpayerDimension,
  TaxpayerType,
  useTaxpayersApi,
} from '../taxpayersApi';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { MainContent } from '../../../components/MainContent';
import {
  replacePathParams,
  TAXPAYER_PATH,
  TAXPAYERS_PATH,
} from '../../../navigation/paths';
import { useTaxpayer } from '../useTaxpayer';
import { ActionableHeader } from '../../../components/ActionableHeader';
import {
  Form,
  FormCurrencyField,
  FormDateField,
  TitledPaper,
  FormSelect,
  FormTaxpayerIdField,
  FormTextField,
} from '../../../components/Form';
import {
  useCatalog,
  useDivisionSections,
} from '../../../providers/CatalogsProvider';
import { taxpayerMaxStartDate, taxpayerMinEndDate } from './util';
import { Action } from '../../../components/Actions';
import { ivageCommon } from '../../../ivageCommon';
import { useLfForm } from '../../../util/lfIntegration';
import {
  defaultCreateTaxpayerForm,
  defaultUpdateTaxpayerForm,
} from './defaultTaxpayerForms';
import { isAfter, isBefore, maxTime } from 'date-fns';
import { useIsMounted } from '../../../util/useIsMounted';

/**
 * Package in IVAGE common containing the taxpayer form schema.
 */
const taxpayerFormSchemaPkg = ivageCommon.feature.taxpayermanagement.schema;

/**
 * Form used to create a new taxpayer or edit an existing one.
 */
export function TaxpayerFormPage() {
  const [t, i18n] = useTranslation(['common', 'taxpayers']);
  const { id: taxpayerId } = useParams<{ id?: string }>();
  const isEditingTaxpayer = !!taxpayerId;
  const { isFetching, taxpayer, notFound } = useTaxpayer(taxpayerId);
  const { sections } = useDivisionSections(taxpayer?.divisionId);
  useSetDocumentTitle(
    t(
      `taxpayers:taxpayerFormPage.${
        isEditingTaxpayer
          ? 'editTaxpayerDocumentTitle'
          : 'newTaxpayerDocumentTitle'
      }`,
      isEditingTaxpayer ? { taxpayerId } : {}
    )
  );
  const { createTaxpayer, updateTaxpayer } = useTaxpayersApi();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formMethods = useLfForm({
    defaultValues: isEditingTaxpayer
      ? defaultUpdateTaxpayerForm(taxpayerId!, taxpayer, sections)
      : defaultCreateTaxpayerForm(),
    formValidatorName: isEditingTaxpayer
      ? 'updateTaxpayerFormValidator'
      : 'createTaxpayerFormValidator',
    i18nErrorMessagesPrefixes: [
      'taxpayers:taxpayerFormPage.fieldErrors',
      'dateField.fieldErrors',
    ],
  });
  const {
    watch,
    reset,
    getValues,
    setLfValue,
    formState,
    setLfIssues,
    setManualLfIssue,
    removeManualLfIssue,
  } = formMethods;
  const isMounted = useIsMounted();

  // Catalogs
  const {
    isFetching: isFetchingConfigsCatalog,
    catalog: configsCatalog,
  } = useCatalog('configs');
  const {
    isFetching: isFetchingDivisionsCatalog,
    catalog: divisionsCatalog,
  } = useCatalog('divisions');
  const {
    isFetching: isFetchingSectorsCatalog,
    catalog: sectorsCatalog,
  } = useCatalog('sectors');
  const {
    isFetching: isFetchingTaxOfficesCatalog,
    catalog: taxOfficesCatalog,
  } = useCatalog('tax-offices');

  // Minimum possible taxpayer start date
  const beginningDate = configsCatalog?.beginningDate;

  // Reset form whenever the taxpayer changes
  useEffect(() => {
    reset(
      isEditingTaxpayer
        ? defaultUpdateTaxpayerForm(taxpayerId!, taxpayer, sections)
        : defaultCreateTaxpayerForm()
    );
  }, [taxpayer, reset, taxpayerId, sections, isEditingTaxpayer]);

  const { startDate, endDate } = watch([
    'startDate',
    ...(isEditingTaxpayer ? ['endDate'] : []),
  ]) as any;
  // Manually validate start/end date until we are able to use the schema for
  // such validations
  useEffect(() => {
    removeManualLfIssue({ path: '/startDate', code: 'min' });
    removeManualLfIssue({ path: '/startDate', code: 'max' });
    removeManualLfIssue({ path: '/endDate', code: 'min' });
    if (startDate) {
      if (beginningDate && isBefore(startDate, beginningDate)) {
        setManualLfIssue({ path: '/startDate', code: 'min' });
      }
      if (
        isAfter(
          startDate,
          taxpayerMaxStartDate(taxpayer?.vatActivities ?? []) ??
            new Date(maxTime)
        )
      ) {
        setManualLfIssue({ path: '/startDate', code: 'max' });
      }
    }
    if (
      beginningDate &&
      endDate &&
      isBefore(
        endDate,
        taxpayerMinEndDate(
          startDate ?? beginningDate,
          taxpayer?.vatActivities ?? []
        )
      )
    ) {
      setManualLfIssue({ path: '/endDate', code: 'min' });
    }
  }, [
    removeManualLfIssue,
    setManualLfIssue,
    startDate,
    endDate,
    taxpayer?.vatActivities,
    beginningDate,
  ]);

  // Only allow choosing districts and municipalities of their respective
  // parents
  const { provinceId, districtId } = watch(['provinceId', 'districtId']);
  // Mark province and district as required (they are only "helper" fields and
  // aren't seen by the schema)
  useEffect(() => {
    removeManualLfIssue({ path: '/provinceId', code: 'required' });
    removeManualLfIssue({ path: '/districtId', code: 'required' });
    if (provinceId == null) {
      setManualLfIssue({ path: '/provinceId', code: 'required' });
    }
    if (districtId == null) {
      setManualLfIssue({ path: '/districtId', code: 'required' });
    }
  }, [provinceId, districtId, setManualLfIssue, removeManualLfIssue]);

  function districtsOfProvince(provinceId: number | null) {
    return divisionsCatalog == null || provinceId == null
      ? []
      : divisionsCatalog.districts.filter(
          (district) => district.parentID === provinceId
        );
  }
  function municipalitiesOfDistrict(districtId: number | null) {
    return divisionsCatalog == null || districtId == null
      ? []
      : divisionsCatalog.municipalities.filter(
          (municipality) => municipality.parentID === districtId
        );
  }
  function onProvinceChange() {
    const provinceId = getValues('provinceId');
    const districts = districtsOfProvince(provinceId);
    setLfValue('districtId', districts.length === 1 ? districts[0].id : null);
    onDistrictChange();
  }
  function onDistrictChange() {
    const districtId = getValues('districtId');
    const municipalities = municipalitiesOfDistrict(districtId);
    setLfValue(
      'divisionId',
      municipalities.length === 1 ? municipalities[0].id : null
    );
  }

  // Create or update the taxpayer when submitting
  async function onSubmit() {
    const taxpayerForm = getValues();
    try {
      const newTaxpayer = await (isEditingTaxpayer
        ? updateTaxpayer(
            taxpayerId!,
            (taxpayerForm as unknown) as UpdateTaxpayerForm
          )
        : createTaxpayer(taxpayerForm as CreateTaxpayerForm));

      enqueueSnackbar(
        isEditingTaxpayer
          ? t('taxpayers:taxpayerFormPage.successUpdatingTaxpayer', {
              id: taxpayerId,
            })
          : t('taxpayers:taxpayerFormPage.successCreatingTaxpayer'),
        { variant: 'success' }
      );

      if (
        isMounted.current &&
        location.pathname === history.location.pathname
      ) {
        setFormSubmitted(true);
        history.push(
          replacePathParams(TAXPAYER_PATH, { id: newTaxpayer.taxpayerId })
        );
      }
    } catch (err) {
      if (
        err instanceof Response &&
        err.status === 400 &&
        isMounted.current &&
        location.pathname === history.location.pathname
      ) {
        const errorData = await err.json();
        if (
          errorData.errorCode === 'invalidTaxpayerId' ||
          errorData.errorCode === 'invalidName'
        ) {
          if (errorData.errorCode === 'invalidTaxpayerId') {
            // Taxpayer id already exists
            setLfIssues([{ path: '/taxpayerId', code: 'alreadyExists' }], true);
          } else {
            // Name already exists
            setLfIssues([{ path: '/name', code: 'alreadyExists' }], true);
          }
          return;
        }
      }
      enqueueSnackbar(
        isEditingTaxpayer
          ? t('taxpayers:taxpayerFormPage.errorUpdatingTaxpayer', {
              id: taxpayerId,
            })
          : t('taxpayers:taxpayerFormPage.errorCreatingTaxpayer'),
        { variant: 'error' }
      );
    }
  }

  // Form actions
  const actions: Action[] = [
    {
      id: 'taxpayer-form-submit',
      type: 'submit',
      label: isEditingTaxpayer
        ? t('taxpayers:taxpayerFormPage.updateTaxpayer')
        : t('taxpayers:taxpayerFormPage.createTaxpayer'),
      color: 'primary',
      icon: isEditingTaxpayer ? <Refresh /> : <Add />,
      disabled:
        (isEditingTaxpayer && !formState.isDirty) ||
        isFetching ||
        isFetchingConfigsCatalog ||
        isFetchingDivisionsCatalog ||
        isFetchingSectorsCatalog ||
        isFetchingTaxOfficesCatalog ||
        formState.isSubmitting,
    },
    {
      id: 'taxpayer-form-reset',
      type: 'reset',
      label: t('formActions.reset'),
      run: () => reset(),
      disabled: !formState.isDirty || formState.isSubmitting,
    },
    {
      id: 'taxpayer-form-cancel',
      label: t('formActions.cancel'),
      run: () =>
        history.push(
          isEditingTaxpayer
            ? replacePathParams(TAXPAYER_PATH, { id: taxpayerId! })
            : TAXPAYERS_PATH
        ),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <MainContent
      errorMessage={
        isEditingTaxpayer && notFound && t('taxpayers:taxpayerNotFound')
      }
    >
      <Form
        onSubmit={onSubmit}
        shouldPromptOnLeave={formState.isDirty && !formSubmitted}
        formProps={{ autoComplete: 'off' }}
        {...formMethods}
      >
        <ActionableHeader
          title={t(
            isEditingTaxpayer
              ? 'taxpayers:taxpayerFormPage.editTaxpayerTitle'
              : 'taxpayers:taxpayerFormPage.newTaxpayerTitle'
          )}
          titleId={isEditingTaxpayer ? taxpayerId : undefined}
          actions={actions}
        />

        {/* Fiscal information */}
        <TitledPaper title={t('taxpayers:fiscalInformation')}>
          <Grid container spacing={2}>
            {/* Taxpayer ID */}
            <Grid item xs={6} md={3}>
              <FormTaxpayerIdField
                name="taxpayerId"
                label={t('taxpayers:taxpayerFields.taxpayerId')}
                disabled={isEditingTaxpayer}
                helperText={
                  !isEditingTaxpayer &&
                  t('taxpayers:taxpayerFormPage.taxpayerIdHelpMessage')
                }
              />
            </Grid>

            {/* Old taxpayer ID */}
            <Grid item xs={6} md={3}>
              <FormTextField
                name="oldTaxpayerId"
                label={t('taxpayers:taxpayerFields.oldTaxpayerId')}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.OLD_TAXPAYER_ID_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Name */}
            <Grid item xs={12} md={6}>
              <FormTextField
                name="name"
                label={t('taxpayers:taxpayerFields.name')}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.NAME_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Representative */}
            <Grid item xs={12} md={isEditingTaxpayer ? 6 : 8}>
              <FormTextField
                name="representative"
                label={t('taxpayers:taxpayerFields.representative')}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.REPRESENTATIVE_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Start date */}
            <Grid
              item
              xs={isEditingTaxpayer ? 6 : 12}
              md={isEditingTaxpayer ? 3 : 4}
            >
              <FormDateField
                name="startDate"
                label={t('taxpayers:taxpayerFields.startDate')}
                minDate={beginningDate}
                maxDate={
                  taxpayerMaxStartDate(taxpayer?.vatActivities ?? []) ??
                  undefined
                }
                onChangeTriggers={['endDate']}
                fetching={isFetching || isFetchingConfigsCatalog}
              />
            </Grid>

            {/* End date */}
            {isEditingTaxpayer && (
              <Grid item xs={6} md={3}>
                <FormDateField
                  name="endDate"
                  label={t('taxpayers:taxpayerFields.endDate')}
                  minDate={taxpayerMinEndDate(
                    startDate || beginningDate,
                    taxpayer?.vatActivities ?? []
                  )}
                  fetching={isFetching || isFetchingConfigsCatalog}
                />
              </Grid>
            )}

            {/* Province */}
            <Grid item xs={12} md={4}>
              <FormSelect
                name="provinceId"
                label={t('taxpayers:taxpayerFields.province')}
                items={[
                  {
                    value: null,
                    label: (
                      <em>{t('taxpayers:taxpayerFormPage.noProvince')}</em>
                    ),
                  },
                  ...(divisionsCatalog?.provinces
                    .sort((p1, p2) =>
                      p1.name.localeCompare(p2.name, i18n.language)
                    )
                    .map((province) => ({
                      value: province.id,
                      label: province.name,
                    })) ?? []),
                ]}
                onChange={onProvinceChange}
                onChangeTriggers={['districtId', 'divisionId']}
                fetching={isFetching || isFetchingDivisionsCatalog}
              />
            </Grid>

            {/* District */}
            <Grid item xs={6} md={4}>
              <FormSelect
                name="districtId"
                label={t('taxpayers:taxpayerFields.district')}
                items={[
                  {
                    value: null,
                    label: (
                      <em>{t('taxpayers:taxpayerFormPage.noDistrict')}</em>
                    ),
                  },
                  ...districtsOfProvince(provinceId)
                    .sort((d1, d2) =>
                      d1.name.localeCompare(d2.name, i18n.language)
                    )
                    .map((district) => ({
                      value: district.id,
                      label: district.name,
                    })),
                ]}
                onChange={onDistrictChange}
                onChangeTriggers={['divisionId']}
                disabled={provinceId == null}
                fetching={isFetching || isFetchingDivisionsCatalog}
              />
            </Grid>

            {/* Municipality */}
            <Grid item xs={6} md={4}>
              <FormSelect
                name="divisionId"
                label={t('taxpayers:taxpayerFields.municipality')}
                items={[
                  {
                    value: null,
                    label: (
                      <em>{t('taxpayers:taxpayerFormPage.noMunicipality')}</em>
                    ),
                  },
                  ...municipalitiesOfDistrict(districtId)
                    .sort((m1, m2) =>
                      m1.name.localeCompare(m2.name, i18n.language)
                    )
                    .map((municipality) => ({
                      value: municipality.id,
                      label: municipality.name,
                    })),
                ]}
                disabled={districtId == null}
                fetching={isFetching || isFetchingDivisionsCatalog}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12} md={6}>
              <FormTextField
                name="address"
                label={t('taxpayers:taxpayerFields.address')}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.ADDRESS_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Address (other) */}
            <Grid item xs={12} md={6}>
              <FormTextField
                name="addressOther"
                label={t('taxpayers:taxpayerFields.addressOther')}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.ADDRESS_OTHER_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Sector */}
            <Grid item xs={6}>
              <FormSelect
                name="sectorId"
                label={t('taxpayers:taxpayerFields.sector')}
                items={[
                  {
                    value: null,
                    label: <em>{t('taxpayers:taxpayerFormPage.noSector')}</em>,
                  },
                  ...(sectorsCatalog
                    ?.sort((s1, s2) =>
                      s1.name.localeCompare(s2.name, i18n.language)
                    )
                    .map((sector) => ({
                      value: sector.id,
                      label: sector.name,
                    })) ?? []),
                ]}
                fetching={isFetching || isFetchingSectorsCatalog}
              />
            </Grid>

            {/* Tax office */}
            <Grid item xs={6}>
              <FormSelect
                name="taxOfficeId"
                label={t('taxpayers:taxpayerFields.taxOffice')}
                items={[
                  {
                    value: null,
                    label: (
                      <em>{t('taxpayers:taxpayerFormPage.noTaxOffice')}</em>
                    ),
                  },
                  ...(taxOfficesCatalog
                    ?.sort((o1, o2) =>
                      o1.name.localeCompare(o2.name, i18n.language)
                    )
                    .map((taxOffice) => ({
                      value: taxOffice.id,
                      label: taxOffice.name,
                    })) ?? []),
                ]}
                fetching={isFetching || isFetchingTaxOfficesCatalog}
              />
            </Grid>
          </Grid>
        </TitledPaper>

        {/* Type information */}
        <TitledPaper title={t('taxpayers:typeInformation')}>
          <Grid container spacing={2}>
            {/* Type */}
            <Grid item xs={6}>
              <FormSelect
                name="type"
                label={t('taxpayers:taxpayerFields.type')}
                items={Object.values(TaxpayerType).map((type) => ({
                  value: type,
                  label: t(`taxpayers:taxpayerType.${type}`),
                }))}
                disabled
                fetching={isFetching}
              />
            </Grid>

            {/* Dimension */}
            <Grid item xs={6}>
              <FormSelect
                name="dimension"
                label={t('taxpayers:taxpayerFields.dimension')}
                items={[
                  {
                    value: null,
                    label: <em>{t('taxpayers:taxpayerDimension.none')}</em>,
                  },
                  ...Object.values(TaxpayerDimension).map((dimension) => ({
                    value: dimension,
                    label: t(`taxpayers:taxpayerDimension.${dimension}`),
                  })),
                ]}
                fetching={isFetching}
              />
            </Grid>

            {/* Share capital */}
            <Grid item xs={6}>
              <FormCurrencyField
                name="shareCapital"
                label={t('taxpayers:taxpayerFields.shareCapital')}
                fetching={isFetching}
              />
            </Grid>

            {/* Legal regime */}
            <Grid item xs={6}>
              <FormSelect
                name="legalRegime"
                label={t('taxpayers:taxpayerFields.legalRegime')}
                items={[
                  {
                    value: null,
                    label: <em>{t('taxpayers:legalRegime.none')}</em>,
                  },
                  ...Object.values(LegalRegime).map((type) => ({
                    value: type,
                    label: t(`taxpayers:legalRegime.${type}`),
                  })),
                ]}
                fetching={isFetching}
              />
            </Grid>
          </Grid>
        </TitledPaper>

        {/* Additional information */}
        <TitledPaper title={t('taxpayers:additionalInformation')}>
          <Grid container spacing={2}>
            {/* Phone */}
            <Grid item xs={12} md={6}>
              <FormTextField
                name="phone"
                label={t('taxpayers:taxpayerFields.phone')}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.PHONE_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} md={6}>
              <FormTextField
                name="email"
                label={t('taxpayers:taxpayerFields.email')}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.EMAIL_MAX_LENGTH,
                }}
              />
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <FormTextField
                name="notes"
                label={t('taxpayers:taxpayerFields.notes')}
                multiline
                rows={5}
                fetching={isFetching}
                inputProps={{
                  maxLength: taxpayerFormSchemaPkg.NOTES_MAX_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </TitledPaper>
      </Form>
    </MainContent>
  );
}
