import React from 'react';
import { DataTableColumns, InnerDataTable } from '../../../components/DataTable';
import { useTranslation } from 'react-i18next';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useRateFormatter,
} from '../../../util/formatters';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { differenceInDays } from 'date-fns';
import { VatInterest } from '../vatAssessmentsApi';

/**
 * Properties of the VAT interests table.
 */
export interface VatInterestsTableProps {
  vatInterests: VatInterest[];
}

/**
 * Table listing the VAT interests of a VAT event.
 */
export function VatInterestsTable({ vatInterests }: VatInterestsTableProps) {
  const [t] = useTranslation(['vatAssessments']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();
  const formatRate = useRateFormatter();

  const columns: DataTableColumns<VatInterest> = {
    startDate: {
      label: t('vatAssessments:vatInterestFields.startDate'),
      value: ({ startDate }) => startDate,
      format: (value) => formatDate(value as Date),
      sortable: false,
    },
    endDate: {
      label: t('vatAssessments:vatInterestFields.endDate'),
      value: ({ endDate }) => endDate,
      format: (value) => formatDate(value as Date),
      render: (content, _value, { startDate, endDate }) => (
        <>
          {content}
          <ExtraInfo>
            {t('vatAssessments:vatInterestNDays', {
              count: differenceInDays(endDate, startDate) + 1,
            })}
          </ExtraInfo>
        </>
      ),
      sortable: false,
    },
    debtAmount: {
      align: 'right',
      label: t('vatAssessments:vatInterestFields.debtAmount'),
      value: ({ debtAmount }) => debtAmount,
      format: (value) => formatCurrency(value as number),
      sortable: false,
    },
    interestRate: {
      align: 'right',
      label: t('vatAssessments:vatInterestFields.interestRate'),
      value: ({ interestRate }) => interestRate,
      format: (value) => formatRate(value as number),
      sortable: false,
    },
    interestAmount: {
      align: 'right',
      label: t('vatAssessments:vatInterestFields.interestAmount'),
      value: ({ interestAmount }) => interestAmount,
      format: (value) => formatCurrency(value as number),
      sortable: false,
    },
  };

  return (
    <InnerDataTable
      title={t('vatAssessments:vatAssessmentPage.vatInterestsDataTable.title')}
      rows={vatInterests || []}
      rowId={({ vatInterestId }) => vatInterestId}
      columns={columns}
      emptyMessage={t(
        'vatAssessments:vatAssessmentPage.vatInterestsDataTable.emptyMessage'
      )}
      defaultSortBy="startDate"
      minWidth={400}
    />
  );
}
