import React from 'react';
import { createStyles, makeStyles, TableCell, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { matchAgainstFilter } from './filter';
import { ColumnId, DataTableColumn, RowId } from './DataTable';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    match: {
      fontWeight: 'bold',
    },
    paddinglessY: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    paddinglessX: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    hideInPrintCell: {
      '@media print': {
        padding: 0,
        width: 0,
      },
    },
    hideInPrint: {
      '@media print': {
        display: 'none',
      },
    },
  })
);

/**
 * Data table cell properties.
 */
export interface DataTableCellProps {
  row: any;
  rowId: RowId;
  column: DataTableColumn<any>;
  columnId: ColumnId;
  filter: string;
}

/**
 * Cell of the data table.
 */
export function DataTableCell({
  row,
  rowId,
  column,
  columnId,
  filter,
}: DataTableCellProps) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const value = column.value && column.value(row);
  const formatted =
    value == null
      ? ''
      : column.format
      ? column.format(value, row)
      : String(value);
  const match = matchAgainstFilter(formatted, filter, i18n.language);

  const cellContent =
    match == null ? (
      <>{formatted}</>
    ) : (
      <span>
        {match[0]}
        <span className={classes.match}>{match[1]}</span>
        {match[2]}
      </span>
    );
  return (
    <TableCell
      data-cy={`data-table-row-${rowId}-cell-${columnId}`}
      align={column.align}
      classes={{
        ...column.cellClasses,
        root: `${column.cellClasses?.root ?? ''} ${
          column.paddinglessY ? classes.paddinglessY : ''
        } ${column.paddinglessX ? classes.paddinglessX : ''} ${
          column.hideInPrint ? classes.hideInPrintCell : ''
        }`,
      }}
    >
      <div className={column.hideInPrint ? classes.hideInPrint : ''}>
        {column.fetching
          ? column.renderFetching ?? <Skeleton />
          : column.render
          ? column.render(cellContent, value, row)
          : cellContent}
      </div>
    </TableCell>
  );
}
