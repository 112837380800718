import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainContent } from '../../components/MainContent';
import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateFormatter,
  useDateTimeFormatter,
} from '../../util/formatters';
import { useTaxRefund } from './useTaxRefund';
import { ActionableHeader } from '../../components/ActionableHeader';
import { useTaxRefundActions } from './useTaxRefundActions';
import { NotSpecified } from '../../components/NotSpecified';
import {
  replacePathParams,
  TAXPAYER_PATH,
  USER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../navigation/paths';
import { Link } from '../../components/Link';
import { useCatalog } from '../../providers/CatalogsProvider';
import { ExtraInfo } from '../../components/ExtraInfo';
import { TaxRefundStatus } from './taxRefundsApi';
import { UserRole } from '../../providers/ProfileProvider';
import { VoidTaxRefundForm } from './VoidTaxRefundForm';

/**
 * Page with tax information.
 */
export function TaxRefundPage() {
  const [t, i18n] = useTranslation(['common', 'taxRefunds']);
  const { id } = useParams<{ id: string }>();
  useSetDocumentTitle(t('taxRefunds:taxRefundPage.documentTitle', { id }));
  const {
    taxRefund,
    isFetching,
    notFound,
    setTaxRefund,
    refetch,
  } = useTaxRefund(+id);
  const formatDate = useDateFormatter();
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const actions = useTaxRefundActions(taxRefund, () => setIsVoidFormOpen(true));
  const {
    catalog: banksCatalog,
    isFetching: isFetchingBanksCatalog,
  } = useCatalog('banks');

  // Assessment information fields
  const assessmentInformation: DescriptionListItem[] = [
    {
      key: 'assessment-number',
      label: t('taxRefunds:taxRefundFields.assessmentNumber'),
      content: taxRefund?.assessmentNumber ? (
        <>
          <Link
            to={replacePathParams(VAT_ASSESSMENT_PATH, {
              id: taxRefund.assessmentNumber,
            })}
          >
            {taxRefund.assessmentNumber}
          </Link>
          {taxRefund?.assessmentNumberInserted &&
            taxRefund.assessmentNumber !==
              taxRefund.assessmentNumberInserted && (
              <ExtraInfo>
                {`${t(
                  'taxRefunds:taxRefundFields.assessmentNumberInserted'
                )}: `}
                <Link
                  to={replacePathParams(VAT_ASSESSMENT_PATH, {
                    id: taxRefund.assessmentNumberInserted,
                  })}
                >
                  {taxRefund.assessmentNumberInserted}
                </Link>
              </ExtraInfo>
            )}
        </>
      ) : (
        <NotSpecified>{t('taxRefunds:noAssessmentNumber')}</NotSpecified>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxpayer-id',
      label: t('taxRefunds:taxRefundFields.taxpayerId'),
      content: taxRefund && (
        <>
          <Link
            to={replacePathParams(TAXPAYER_PATH, {
              id: taxRefund.taxpayerId,
            })}
          >
            {taxRefund.taxpayerId}
          </Link>
          {taxRefund.taxpayerName && (
            <ExtraInfo>{taxRefund.taxpayerName}</ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'taxable-year-period',
      label: `${t('taxRefunds:taxRefundFields.taxableYear')}/${t(
        'taxRefunds:taxRefundFields.taxablePeriod'
      ).toLocaleLowerCase(i18n.language)}`,
      content: `${taxRefund?.taxableYear}/${taxRefund?.taxablePeriod}`,
      fetching: isFetching,
    },
  ];

  // Tax refund information fields
  const taxRefundInformation: DescriptionListItem[] = [
    {
      key: 'tax-refund-date',
      label: t('taxRefunds:taxRefundFields.taxRefundDate'),
      content: formatDate(taxRefund?.taxRefundDate),
      fetching: isFetching,
    },
    {
      key: 'amount',
      label: t('taxRefunds:taxRefundFields.amount'),
      content: taxRefund && (
        <>
          {formatCurrency(taxRefund.amount)}
          {taxRefund.transactionMethod && (
            <ExtraInfo>
              {t(
                `transactionMethod.${taxRefund.transactionMethod}`
              ).toLocaleLowerCase(i18n.language)}
            </ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'bank',
      label: t('taxRefunds:taxRefundFields.bank'),
      content:
        taxRefund &&
        (banksCatalog?.find((bank) => bank.id === taxRefund.bankId)?.name ?? (
          <NotSpecified>{t('taxRefunds:bankNotSpecified')}</NotSpecified>
        )),
      fetching: isFetching || isFetchingBanksCatalog,
    },
    {
      key: 'description',
      label: t('taxRefunds:taxRefundFields.description'),
      content: taxRefund?.description || (
        <NotSpecified>{t('taxRefunds:descriptionNotSpecified')}</NotSpecified>
      ),
      preserveWhiteSpaces: true,
      fetching: isFetching,
    },
    {
      key: 'created-date',
      label: t('taxRefunds:taxRefundFields.createdDate'),
      content: taxRefund && (
        <>
          {formatDateTime(taxRefund.createdDate)}
          <ExtraInfo>
            {t('taxRefunds:taxRefundFields.createdBy').toLocaleLowerCase(
              i18n.language
            )}{' '}
            {
              <Link
                to={replacePathParams(USER_PATH, {
                  username: taxRefund.createdBy,
                })}
                role={UserRole.Manager}
              >
                {taxRefund.createdBy}
              </Link>
            }
          </ExtraInfo>
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'changed-date',
      label: t('taxRefunds:taxRefundFields.changedDate'),
      content: taxRefund && (
        <>
          {formatDateTime(taxRefund.changedDate)}
          {taxRefund.status === TaxRefundStatus.Voided && taxRefund.changedBy && (
            <ExtraInfo>
              {t('taxRefunds:taxRefundFields.changedBy').toLocaleLowerCase(
                i18n.language
              )}{' '}
              {
                <Link
                  to={replacePathParams(USER_PATH, {
                    username: taxRefund.changedBy,
                  })}
                  role={UserRole.Manager}
                >
                  {taxRefund.changedBy}
                </Link>
              }
            </ExtraInfo>
          )}
        </>
      ),
      fetching: isFetching,
    },
    {
      key: 'void-description',
      label: t('taxRefunds:taxRefundFields.voidDescription'),
      content: taxRefund?.voidDescription || (
        <NotSpecified>
          {t('taxRefunds:voidDescriptionNotSpecified')}
        </NotSpecified>
      ),
      preserveWhiteSpaces: true,
      hidden: isFetching || taxRefund!.status !== TaxRefundStatus.Voided,
    },
  ];

  return (
    <MainContent errorMessage={notFound && t('taxRefunds:taxRefundNotFound')}>
      <ActionableHeader
        title={t('taxRefunds:taxRefundPage.title')}
        titleId={id}
        status={
          taxRefund && t(`taxRefunds:taxRefundStatus.${taxRefund.status}`)
        }
        statusVariant={
          taxRefund?.status === TaxRefundStatus.Voided ? 'error' : 'success'
        }
        actions={actions}
      />

      <VoidTaxRefundForm
        taxRefund={taxRefund}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newTaxRefund) => setTaxRefund(newTaxRefund)}
        onFailureDueToOutdatedUI={refetch}
      />

      <PaperDescriptionList
        title={t('taxRefunds:assessmentInformation')}
        items={assessmentInformation}
      />
      <PaperDescriptionList
        title={t('taxRefunds:taxRefundInformation')}
        items={taxRefundInformation}
      />
    </MainContent>
  );
}
