import { i18n as I18n } from 'i18next';

/**
 * Returns the i18n message associated with an LF issue.
 */
export function getIssueMessage(
  namePath: string,
  code: string,
  data: any,
  i18n: I18n,
  i18nErrorMessagesPrefixes: string | string[]
): string {
  let message: string | null = null;
  // Attempt to fetch a message using each of the prefixes
  outerLoop: for (const prefix of typeof i18nErrorMessagesPrefixes === 'string'
    ? [i18nErrorMessagesPrefixes]
    : i18nErrorMessagesPrefixes) {
    const keys = [`${prefix}.${namePath}.${code}`, `${prefix}.*.${code}`];
    for (const key of keys) {
      if (i18n.exists(key)) {
        message = i18n.t(key, data);
        break outerLoop;
      }
    }
  }
  // If no message was found, warn and use a generic message
  if (message == null) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`Missing i18n message for: ${namePath}.${code}`);
    }
    message =
      namePath === ''
        ? i18n.t('invalidForm', { code })
        : i18n.t('invalidField', { code });
  }
  return message!;
}
