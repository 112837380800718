/**
 * Comparator used for sorting.
 */
export type Comparator<T> = (el1: T, el2: T) => number;

/**
 * Sorting order (ascending/descending).
 */
export type SortDirection = 'asc' | 'desc';

/**
 * Stable sort implementation.
 */
export function stableSort<T>(
  array: T[],
  comparator: Comparator<T>,
  sortOrder: SortDirection = 'asc'
) {
  const orderSign = sortOrder === 'asc' ? 1 : -1;
  const arrayWithIndex = array.map((el, idx) => [el, idx] as [T, number]);
  arrayWithIndex.sort(
    (a, b) => (comparator(a[0], b[0]) || a[1] - b[1]) * orderSign
  );
  return arrayWithIndex.map((el) => el[0]);
}
