import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  GetVatAssessmentsFilterOptions,
  useVatAssessmentsApi,
  VAT_ASSESSMENTS_URL,
} from '../vatAssessmentsApi';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { VatAssessmentsTable } from './VatAssessmentsTable';
import { SearchForm } from '../../../components/SearchForm';
import { useQueryParams } from '../../../util/useQueryParams';
import { useResource } from '../../../util/useResource';
import { FormTaxpayerIdField } from '../../../components/Form';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { TaxablePeriod } from '../../../common';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useLfForm } from '../../../util/lfIntegration';

// Search parameters
const searchParams = [
  'taxpayerId',
  'assessmentNumber',
  'taxableYear',
  'taxablePeriod',
];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    taxableYear: isNaN(+queryParams.taxableYear)
      ? null
      : +queryParams.taxableYear,
    taxablePeriod: Object.values(TaxablePeriod).includes(
      queryParams.taxablePeriod as any
    )
      ? (queryParams.taxablePeriod as any)
      : null,
  };
}

/**
 * Page dedicated to consult VAT returns.
 */
export function VatAssessmentsPage() {
  const [t] = useTranslation(['common', 'vatAssessments']);
  useSetDocumentTitle(t('vatAssessments:vatAssessmentsPage.documentTitle'));
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      taxableYear: null as number | null,
      taxablePeriod: null as TaxablePeriod | null,
    },
    formValidatorName: 'getVatAssessmentsFormValidator',
    i18nErrorMessagesPrefixes:
      'vatAssessments:vatAssessmentsPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const { getVatAssessments } = useVatAssessmentsApi();
  const fetchVatAssessments = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getVatAssessments({
            ...queryParams,
            latestPerTaxableYearPeriod: true,
          })
        : null,
    [getVatAssessments, isValid, queryParams]
  );
  const {
    isFetching,
    resource: vatAssessments,
    setResource: setVatAssessments,
    refetch: refetchVatAssessments,
  } = useResource({
    fetchResource: fetchVatAssessments,
    errorFetchingResourceMessage: t(
      'vatAssessments:vatAssessmentsPage.errorFetchingVatAssessments'
    ),
  });

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const { taxpayerId, taxableYear, taxablePeriod } = getValues();
    const getVatAssessmentsOptions: GetVatAssessmentsFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      taxableYear: taxableYear || undefined,
      taxablePeriod: taxablePeriod || undefined,
    };
    // Pressing "search" when nothing changed should refetch the VAT returns
    if (
      areSameQueryParams(getVatAssessmentsOptions, queryParams, searchParams)
    ) {
      refetchVatAssessments();
    } else {
      setQueryParams(getVatAssessmentsOptions);
    }
  }

  // @ts-ignore
  return (
    <MainContent>
      <ActionableHeader title={t('vatAssessments:vatAssessmentsPage.title')} />

      <SearchForm
        title={t('vatAssessments:vatAssessmentsPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        {/* TIN */}
        <Grid item xs={12} md={4}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('vatAssessments:vatAssessmentFields.taxpayerId')}
            size="small"
          />
        </Grid>

        {/* Taxable year/period */}
        <FormTaxableYearPeriod
          taxableYearProps={{
            name: 'taxableYear',
            label: t('vatAssessments:vatAssessmentFields.taxableYear'),
            size: 'small',
            onChangeTriggers: ['taxpayerId'],
          }}
          taxablePeriodProps={{
            name: 'taxablePeriod',
            label: t('vatAssessments:vatAssessmentFields.taxablePeriod'),
            size: 'small',
          }}
        >
          {(taxableYear, taxablePeriod) => (
            <>
              <Grid item xs={6} md={4}>
                {taxableYear}
              </Grid>
              <Grid item xs={6} md={4}>
                {taxablePeriod}
              </Grid>
            </>
          )}
        </FormTaxableYearPeriod>
      </SearchForm>

      <VatAssessmentsTable
        fetching={isFetching}
        vatAssessments={vatAssessments || []}
        setVatAssessments={setVatAssessments}
        refetchVatAssessments={refetchVatAssessments}
        downloadButton={
          <DownloadTableButton
            fileName={t(
              'vatAssessments:vatAssessmentsPage.dataTable.downloadFileName'
            )}
            downloadUrl={`${VAT_ASSESSMENTS_URL}?${objectToQueryParamsString({
              ...queryParams,
              latestPerTaxableYearPeriod: true,
              format: 'csv',
            })}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
