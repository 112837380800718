import React, { useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Form, FormDateField } from '../../../components/Form';
import { VatActivity, Taxpayer, useTaxpayersApi } from '../taxpayersApi';
import { vatActivitiesToIntervals } from './util';
import {
  areIntervalsOverlapping,
  isAfter,
  isBefore,
  isWithinInterval,
  maxTime,
} from 'date-fns';
import { useLfForm } from '../../../util/lfIntegration';
import {
  defaultCreateVatActivityForm,
  defaultUpdateVatActivityForm,
} from './defaultVatActivityForms';
import { localDateToSameDateInUTC } from '../../../util/dateUtils';
import { Action, DialogActionButtons } from '../../../components/Actions';
import { TitleId } from '../../../components/TitleId';

/**
 * Properties of the VAT activity form.
 */
export interface VatActivityFormProps {
  taxpayer: Taxpayer;
  vatActivity: VatActivity | null;
  open: boolean;

  close(): void;

  onSuccess(vatActivity: VatActivity): void;
}

/**
 * VAT activity form as a modal.
 */
export function VatActivityForm({
  taxpayer,
  vatActivity,
  open,
  close,
  onSuccess,
}: VatActivityFormProps) {
  const [t] = useTranslation(['common', 'taxpayers']);
  const { createVatActivity, updateVatActivity } = useTaxpayersApi();
  const { enqueueSnackbar } = useSnackbar();
  const isEditingVatActivity = !!vatActivity;
  const formMethods = useLfForm({
    defaultValues: isEditingVatActivity
      ? defaultUpdateVatActivityForm(vatActivity!)
      : defaultCreateVatActivityForm(),
    formValidatorName: isEditingVatActivity
      ? 'updateVatActivityFormValidator'
      : 'createVatActivityFormValidator',
    i18nErrorMessagesPrefixes: [
      'taxpayers:taxpayerPage.vatActivityForm.fieldErrors',
      'dateField.fieldErrors',
    ],
  });
  const {
    watch,
    reset,
    getValues,
    formState,
    setLfIssues,
    setManualLfIssue,
    removeManualLfIssue,
  } = formMethods;

  // Reset form whenever the VAT activity changes or modal changes open state
  useEffect(() => {
    reset(
      isEditingVatActivity
        ? defaultUpdateVatActivityForm(vatActivity!)
        : defaultCreateVatActivityForm()
    );
  }, [open, isEditingVatActivity, reset, vatActivity, taxpayer]);

  // Validate date ranges
  const { startDate, endDate } = watch(['startDate', 'endDate']);
  const intervals = useMemo(
    () => vatActivitiesToIntervals(taxpayer, vatActivity),
    [taxpayer, vatActivity]
  );
  useEffect(() => {
    removeManualLfIssue({ path: '/startDate', code: 'outOfBounds' });
    removeManualLfIssue({ path: '/startDate', code: 'withinActivity' });
    removeManualLfIssue({ path: '/endDate', code: 'outOfBounds' });
    removeManualLfIssue({ path: '/endDate', code: 'withinActivity' });
    removeManualLfIssue({ path: '/endDate', code: 'dateRangeWithinActivity' });

    const validStartDate = startDate != null && !isNaN(+startDate);
    if (validStartDate) {
      if (
        isBefore(startDate!, taxpayer.startDate) ||
        (taxpayer.endDate && isAfter(startDate!, taxpayer.endDate))
      ) {
        setManualLfIssue({ path: '/startDate', code: 'outOfBounds' });
      }
      if (
        intervals.some((interval) => isWithinInterval(startDate!, interval))
      ) {
        setManualLfIssue({ path: '/startDate', code: 'withinActivity' });
      }
    }

    if (endDate == null || !isNaN(+endDate)) {
      const limitedEndDate = endDate == null ? new Date(maxTime) : endDate;
      if (
        (validStartDate && isBefore(limitedEndDate, startDate!)) ||
        (taxpayer.endDate && isAfter(limitedEndDate, taxpayer.endDate))
      ) {
        setManualLfIssue({ path: '/endDate', code: 'outOfBounds' });
      }
      if (
        intervals.some((interval) => isWithinInterval(limitedEndDate, interval))
      ) {
        setManualLfIssue({ path: '/endDate', code: 'withinActivity' });
      }
      if (
        validStartDate &&
        intervals.some((interval) =>
          areIntervalsOverlapping(
            { start: startDate!, end: limitedEndDate },
            interval
          )
        )
      ) {
        setManualLfIssue({ path: '/endDate', code: 'dateRangeWithinActivity' });
      }
    }
  }, [
    endDate,
    intervals,
    removeManualLfIssue,
    setManualLfIssue,
    startDate,
    taxpayer.endDate,
    taxpayer.startDate,
  ]);

  /**
   * Dates that should be disabled in the datepicker popup.
   */
  function shouldDisableDate(date: Date | null): boolean {
    return (
      date == null ||
      intervals.some((interval) =>
        isWithinInterval(localDateToSameDateInUTC(date), interval)
      )
    );
  }

  async function onSubmit() {
    const vatActivityForm = getValues();
    try {
      const newVatActivity = await (isEditingVatActivity
        ? updateVatActivity(
            taxpayer.taxpayerId,
            vatActivity!.vatActivityId,
            vatActivityForm
          )
        : createVatActivity(taxpayer.taxpayerId, vatActivityForm));
      close();
      enqueueSnackbar(
        isEditingVatActivity
          ? t(
              'taxpayers:taxpayerPage.vatActivityForm.successUpdatingVatActivity',
              { id: vatActivity!.vatActivityId }
            )
          : t(
              'taxpayers:taxpayerPage.vatActivityForm.successCreatingVatActivity'
            ),
        { variant: 'success' }
      );
      onSuccess(newVatActivity);
    } catch (err) {
      if (
        err instanceof Response &&
        err.status === 400 &&
        (await err.text()) === 'existingVatReturn'
      ) {
        setLfIssues([{ path: '/', code: 'taxReturnExists' }]);
      } else {
        enqueueSnackbar(
          isEditingVatActivity
            ? t(
                'taxpayers:taxpayerPage.vatActivityForm.errorUpdatingVatActivity',
                { id: vatActivity!.vatActivityId }
              )
            : t(
                'taxpayers:taxpayerPage.batActivityForm.errorCreatingVatActivity'
              ),
          { variant: 'error' }
        );
      }
    }
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: `vat-activity-form-${vatActivity?.vatActivityId}-submit`,
      type: 'submit',
      label: isEditingVatActivity
        ? t('taxpayers:taxpayerPage.vatActivityForm.updateVatActivity')
        : t('taxpayers:taxpayerPage.vatActivityForm.createVatActivity'),
      color: 'primary',
      loading: formState.isSubmitting,
      disabled: isEditingVatActivity && !formState.isDirty,
    },
    {
      id: `vat-activity-form-${vatActivity?.vatActivityId}-cancel`,
      label: t('formActions.cancel'),
      run: () => close(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={close}
      disableBackdropClick={formState.isDirty || formState.isSubmitting}
      disableEscapeKeyDown={formState.isDirty || formState.isSubmitting}
      aria-labelledby="vat-activity-form"
    >
      <Form onSubmit={onSubmit} {...formMethods}>
        <DialogTitle id="vat-activity-form">
          {isEditingVatActivity
            ? t('taxpayers:taxpayerPage.vatActivityForm.editVatActivityTitle')
            : t('taxpayers:taxpayerPage.vatActivityForm.newVatActivityTitle')}
          {isEditingVatActivity && (
            <>
              {' '}
              <TitleId titleId={vatActivity!.vatActivityId} />
            </>
          )}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Start date */}
            <Grid item xs={12} sm={6}>
              <FormDateField
                name="startDate"
                label={t('taxpayers:vatActivityFields.startDate')}
                onChangeTriggers={['endDate']}
                minDate={taxpayer.startDate}
                maxDate={taxpayer.endDate ?? undefined}
                shouldDisableDate={shouldDisableDate}
              />
            </Grid>

            {/* End date */}
            <Grid item xs={12} sm={6}>
              <FormDateField
                name="endDate"
                label={t('taxpayers:vatActivityFields.endDate')}
                minDate={startDate ?? taxpayer.startDate}
                maxDate={taxpayer.endDate ?? undefined}
                shouldDisableDate={shouldDisableDate}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
