import { RefObject } from 'react';

/**
 * Scroll the element into the middle of the view and focus it on the next event
 * loop (after it is no longer disabled due to the form being submitted).
 */
export function scrollAndFocus(
  inputRef: RefObject<HTMLElement | { focus(): void; node: HTMLElement }>
) {
  const el = inputRef.current;
  // The ref provided by the `Select` element is an object containing the
  // `focus` method and a `node` property with the actual `select`
  const elToScroll = el && (el instanceof HTMLElement ? el : el.node);
  elToScroll?.scrollIntoView?.({ block: 'center' });
  setTimeout(() => el?.focus());
}
