import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MainContent } from '../../components/MainContent';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcomeMessage: {
      margin: theme.spacing(6, 0),
      color: theme.palette.text.secondary,
      fontWeight: 700,
      textAlign: 'center',
    },
  })
);

/**
 * Home page.
 */
export function HomePage() {
  const [t] = useTranslation('common');
  useSetDocumentTitle(t('homePage.documentTitle'));
  const classes = useStyles();

  return (
    <MainContent>
      <Typography
        className={classes.welcomeMessage}
        component="h1"
        variant="h2"
      >
        {t('homePage.welcomeMessage')}
      </Typography>
    </MainContent>
  );
}
