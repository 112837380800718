import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStateCheckingAccount } from '../useStateCheckingAccount';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { MainContent } from '../../../components/MainContent';
import { ActionableHeader } from '../../../components/ActionableHeader';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../../components/PaperDescriptionList';
import {
  useCurrencyFormatter,
  useDateTimeFormatter,
} from '../../../util/formatters';
import { StateCheckingAccountsPerYearTable } from './StateCheckingAccountsPerYearTable';
import { CurlyBrace } from '../../../components/CurlyBrace';
import { ExtraInfo } from '../../../components/ExtraInfo';

/**
 * Page with the state checking account information.
 */
export function StateCheckingAccountPage() {
  const [t] = useTranslation('stateCheckingAccount');
  const { stateCheckingAccount, isFetching } = useStateCheckingAccount();
  useSetDocumentTitle(
    t('stateCheckingAccount:stateCheckingAccountPage.documentTitle')
  );
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();

  // Report fields
  const reportInformation: DescriptionListItem[] = [
    {
      key: 'access-date',
      label: t('stateCheckingAccount:stateCheckingAccountFields.accessDate'),
      content: formatDateTime(stateCheckingAccount?.accessDate),
      fetching: isFetching,
    },
  ];

  const debits =
    stateCheckingAccount &&
    stateCheckingAccount.vatCreditAvailable +
      stateCheckingAccount.paymentRefundableFinal;
  const credits =
    stateCheckingAccount &&
    stateCheckingAccount.vatFinal + stateCheckingAccount.interestFinal;

  // State checking account fields
  const stateCheckingAccountInformation: DescriptionListItem[] = [
    {
      key: 'debits',
      label: t('stateCheckingAccount:stateCheckingAccountFields.debits'),
      content:
        debits && debits > 0 ? (
          <CurlyBrace
            leftSide={formatCurrency(debits)}
            rightSide={
              <>
                <div>
                  {formatCurrency(stateCheckingAccount!.vatCreditAvailable)}
                  <ExtraInfo nonBreaking>
                    {t(
                      'stateCheckingAccount:stateCheckingAccountFields.vatCreditAvailable'
                    )}
                  </ExtraInfo>
                </div>
                <div>
                  {formatCurrency(stateCheckingAccount!.paymentRefundableFinal)}
                  <ExtraInfo nonBreaking>
                    {t(
                      'stateCheckingAccount:stateCheckingAccountFields.paymentRefundableFinal'
                    )}
                  </ExtraInfo>
                </div>
              </>
            }
          />
        ) : (
          formatCurrency(debits)
        ),
      fetching: isFetching,
      hidden: debits === 0 && credits !== 0,
    },
    {
      key: 'credits',
      label: t('stateCheckingAccount:stateCheckingAccountFields.credits'),
      content:
        credits && credits > 0 ? (
          <CurlyBrace
            leftSide={formatCurrency(credits)}
            rightSide={
              <>
                <div>
                  {formatCurrency(stateCheckingAccount!.vatFinal)}
                  <ExtraInfo nonBreaking>
                    {t(
                      'stateCheckingAccount:stateCheckingAccountFields.vatFinal'
                    )}
                  </ExtraInfo>
                </div>
                <div>
                  {formatCurrency(stateCheckingAccount!.interestFinal)}
                  <ExtraInfo nonBreaking>
                    {t(
                      'stateCheckingAccount:stateCheckingAccountFields.interestFinal'
                    )}
                  </ExtraInfo>
                </div>
              </>
            }
          />
        ) : (
          formatCurrency(credits)
        ),
      fetching: isFetching,
      hidden: credits === 0,
    },
  ];

  return (
    <MainContent>
      <ActionableHeader
        title={t('stateCheckingAccount:stateCheckingAccountPage.title')}
      />

      <PaperDescriptionList
        title={t('stateCheckingAccount:reportInformation')}
        items={reportInformation}
      />
      <PaperDescriptionList
        title={t('stateCheckingAccount:stateCheckingAccountInformation')}
        items={stateCheckingAccountInformation}
      />

      <StateCheckingAccountsPerYearTable />
    </MainContent>
  );
}
