import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCheckingAccount } from '../useCheckingAccount';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import { MainContent } from '../../../components/MainContent';
import { ActionableHeader } from '../../../components/ActionableHeader';
import {
  DescriptionListItem,
  PaperDescriptionList,
} from '../../../components/PaperDescriptionList';
import { Link } from '../../../components/Link';
import { replacePathParams, TAXPAYER_PATH } from '../../../navigation/paths';
import {
  useCurrencyFormatter,
  useDateTimeFormatter,
} from '../../../util/formatters';
import { CheckingAccountsPerYearTable } from './CheckingAccountsPerYearTable';
import { CurlyBrace } from '../../../components/CurlyBrace';
import { ExtraInfo } from '../../../components/ExtraInfo';
import { useCheckingAccountActions } from '../useCheckingAccountActions';

/**
 * Page with checking account information.
 */
export function CheckingAccountPage() {
  const [t] = useTranslation('checkingAccounts');
  const { id: taxpayerId } = useParams<{ id: string }>();
  const { checkingAccount, isFetching, notFound } = useCheckingAccount(
    taxpayerId
  );
  useSetDocumentTitle(
    t('checkingAccounts:checkingAccountPage.documentTitle', {
      id: taxpayerId,
    })
  );
  const actions = useCheckingAccountActions(checkingAccount);
  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useCurrencyFormatter();

  // Report fields
  const reportInformation: DescriptionListItem[] = [
    {
      key: 'taxpayer-id',
      label: t('checkingAccounts:checkingAccountFields.taxpayerId'),
      content: checkingAccount && (
        <>
          <Link
            to={replacePathParams(TAXPAYER_PATH, {
              id: checkingAccount.taxpayerId,
            })}
          >
            {checkingAccount.taxpayerId}
          </Link>
          {checkingAccount.taxpayerName && (
            <ExtraInfo>{checkingAccount.taxpayerName}</ExtraInfo>
          )}
        </>
      ),
    },
    {
      key: 'access-date',
      label: t('checkingAccounts:checkingAccountFields.accessDate'),
      content: formatDateTime(checkingAccount?.accessDate),
      fetching: isFetching,
    },
  ];

  const debits =
    checkingAccount && checkingAccount.vatFinal + checkingAccount.interestFinal;
  const credits =
    checkingAccount &&
    checkingAccount.vatCreditAvailable + checkingAccount.paymentRefundableFinal;

  // Checking account fields
  const checkingAccountInformation: DescriptionListItem[] = [
    {
      key: 'debits',
      label: t('checkingAccounts:checkingAccountFields.debits'),
      content:
        debits && debits > 0 ? (
          <CurlyBrace
            leftSide={formatCurrency(debits)}
            rightSide={
              <>
                <div>
                  {formatCurrency(checkingAccount!.vatFinal)}
                  <ExtraInfo nonBreaking>
                    {t('checkingAccounts:checkingAccountFields.vatFinal')}
                  </ExtraInfo>
                </div>
                <div>
                  {formatCurrency(checkingAccount!.interestFinal)}
                  <ExtraInfo nonBreaking>
                    {t('checkingAccounts:checkingAccountFields.interestFinal')}
                  </ExtraInfo>
                </div>
              </>
            }
          />
        ) : (
          formatCurrency(debits)
        ),
      fetching: isFetching,
      hidden: debits === 0 && credits !== 0,
    },
    {
      key: 'credits',
      label: t('checkingAccounts:checkingAccountFields.credits'),
      content:
        credits && credits > 0 ? (
          <CurlyBrace
            leftSide={formatCurrency(credits)}
            rightSide={
              <>
                <div>
                  {formatCurrency(checkingAccount!.vatCreditAvailable)}
                  <ExtraInfo nonBreaking>
                    {t(
                      'checkingAccounts:checkingAccountFields.vatCreditAvailable'
                    )}
                  </ExtraInfo>
                </div>
                <div>
                  {formatCurrency(checkingAccount!.paymentRefundableFinal)}
                  <ExtraInfo nonBreaking>
                    {t(
                      'checkingAccounts:checkingAccountFields.paymentRefundableFinal'
                    )}
                  </ExtraInfo>
                </div>
              </>
            }
          />
        ) : (
          formatCurrency(credits)
        ),
      fetching: isFetching,
      hidden: credits === 0,
    },
  ];

  return (
    <MainContent
      errorMessage={notFound && t('checkingAccounts:checkingAccountNotFound')}
    >
      <ActionableHeader
        title={t('checkingAccounts:checkingAccountPage.title')}
        titleId={taxpayerId}
        actions={actions}
      />

      <PaperDescriptionList
        title={t('checkingAccounts:reportInformation')}
        items={reportInformation}
      />
      <PaperDescriptionList
        title={t('checkingAccounts:checkingAccountInformation')}
        items={checkingAccountInformation}
      />

      <CheckingAccountsPerYearTable checkingAccount={checkingAccount} />
    </MainContent>
  );
}
