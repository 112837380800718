import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../util/useResource';
import { User, useUsersApi } from './usersApi';
import { useTranslation } from 'react-i18next';

/**
 * Result of the `useUser` hook.
 */
export interface UserResource {
  isFetching: boolean;
  user: User | null;
  notFound: boolean;
  setUser: Dispatch<SetStateAction<User | null>>;
  refetch: () => void;
}

/**
 * Hook used to fetch a user from the API, if no `username` is passed this hook
 * does nothing.
 */
export function useUser(username?: string): UserResource {
  const [t] = useTranslation('users');
  const { getUser } = useUsersApi();
  const getUserCb = useCallback(() => (username ? getUser(username) : null), [
    getUser,
    username,
  ]);
  const {
    isFetching,
    resource: user,
    notFound,
    setResource: setUser,
    refetch,
  } = useResource({
    fetchResource: getUserCb,
    errorFetchingResourceMessage: t('users:errorFetchingUser', {
      username: username ?? '',
    }),
  });

  return { isFetching, user, notFound, setUser, refetch };
}
