import React, { useMemo } from 'react';
import { OpenInNew, RemoveCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { PAYMENT_REFUND_PATH, replacePathParams } from '../../navigation/paths';
import { Action } from '../../components/Actions';
import { PaymentRefund, PaymentRefundStatus } from './paymentRefundsApi';

/**
 * Hook that returns actions that can be performed on a payment refund.
 */
export function usePaymentRefundActions(
  paymentRefund: PaymentRefund | null,
  onVoid: () => void
): Action[] {
  const [t] = useTranslation('paymentRefunds');
  const { pathname } = useLocation();

  const paymentRefundPath = replacePathParams(PAYMENT_REFUND_PATH, {
    id: paymentRefund?.paymentRefundId,
  });

  return useMemo(
    () => [
      {
        id: `payment-refund-${paymentRefund?.paymentRefundId}-open`,
        label: t('paymentRefunds:paymentRefundActions.open'),
        component: Link,
        to: paymentRefundPath,
        icon: <OpenInNew />,
        hidden: !paymentRefund || pathname === paymentRefundPath,
      },
      {
        id: `payment-refund-${paymentRefund?.paymentRefundId}-void`,
        label: t('paymentRefunds:paymentRefundActions.void'),
        run: onVoid,
        icon: <RemoveCircleOutline />,
        style: 'error',
        hidden:
          !paymentRefund || paymentRefund.status === PaymentRefundStatus.Voided,
      },
    ],
    [t, paymentRefundPath, paymentRefund, pathname, onVoid]
  );
}
