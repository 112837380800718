import { createStyles, Fade, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      fontSize: '70%',
    },
  })
);

/**
 * Properties of the title identifier.
 */
export interface TitleIdProps {
  titleId?: string | number | null;
  className?: string;
}

/**
 * Displays an identifier in a title.
 */
export function TitleId({ titleId, className }: TitleIdProps) {
  const classes = useStyles();
  const isNumeric =
    typeof titleId === 'number' ||
    (typeof titleId === 'string' && /^\d+$/.test(titleId));

  return (
    <Fade in={titleId != null} mountOnEnter={true}>
      <span className={`${classes.root} ${className ?? ''}`}>
        {isNumeric && '#'}
        {titleId}
      </span>
    </Fade>
  );
}
