import {
  Edit,
  Email,
  ListAlt,
  OpenInNew,
  SaveAlt,
  VerifiedUserOutlined,
} from '@material-ui/icons';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  CHECKING_ACCOUNT_PATH,
  EDIT_TAXPAYER_PATH,
  replacePathParams,
  TAXPAYER_PATH,
} from '../../navigation/paths';
import { Action, ActionGroup } from '../../components/Actions';
import { Taxpayer, TAXPAYERS_URL, useTaxpayersApi } from './taxpayersApi';
import { useSnackbar } from 'notistack';
import { useIsMounted } from '../../util/useIsMounted';

/**
 * Hook that returns actions that can be performed on a taxpayer.
 */
export function useTaxpayerActions(
  taxpayer: Taxpayer | null,
  onFailureDueToOutdatedUI: () => void
): Action[] {
  const [t] = useTranslation('taxpayers');
  const { pathname } = useLocation();
  const { emailTaxpayerDocument } = useTaxpayersApi();
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();

  const taxpayerPath = replacePathParams(TAXPAYER_PATH, {
    id: taxpayer?.taxpayerId,
  });

  /**
   * Function that runs when the user clicks the "email taxpayer document"
   * button.
   */
  const emailTaxpayerDocumentImpl = useCallback(async () => {
    try {
      await emailTaxpayerDocument(taxpayer!.taxpayerId);
      enqueueSnackbar(
        t('taxpayers:taxpayerDocumentEmailSentSuccessfully', {
          id: taxpayer!.taxpayerId,
        }),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        // The only way this should happen is if the taxpayer no longer has an
        // email, otherwise they all shouldn't fail with a `400`
        enqueueSnackbar(
          t('taxpayers:errorEmailingTaxpayerDocumentDueToOutdatedUI', {
            id: taxpayer!.taxpayerId,
          }),
          { variant: 'error' }
        );
        if (isMounted.current) {
          onFailureDueToOutdatedUI();
        }
      } else {
        enqueueSnackbar(
          t('taxpayers:errorEmailingTaxpayerDocument', {
            id: taxpayer!.taxpayerId,
          }),
          { variant: 'error' }
        );
      }
    }
  }, [
    emailTaxpayerDocument,
    enqueueSnackbar,
    isMounted,
    onFailureDueToOutdatedUI,
    t,
    taxpayer,
  ]);

  return useMemo(
    () => [
      {
        id: `taxpayer-${taxpayer?.taxpayerId}-open`,
        label: t('taxpayers:taxpayerActions.open'),
        component: Link,
        to: taxpayerPath,
        icon: <OpenInNew />,
        hidden: !taxpayer || pathname === taxpayerPath,
      },
      {
        id: `taxpayer-${taxpayer?.taxpayerId}-edit`,
        label: t('taxpayers:taxpayerActions.edit'),
        component: Link,
        to: replacePathParams(EDIT_TAXPAYER_PATH, {
          id: taxpayer?.taxpayerId,
        }),
        color: 'primary',
        icon: <Edit />,
        hidden: !taxpayer,
      },
      {
        id: `taxpayer-${taxpayer?.taxpayerId}-checking-account`,
        label: t('taxpayers:taxpayerActions.checkingAccount'),
        component: Link,
        to: replacePathParams(CHECKING_ACCOUNT_PATH, {
          id: taxpayer?.taxpayerId,
        }),
        icon: <ListAlt />,
        hidden: !taxpayer,
      },
      {
        id: `taxpayer-${taxpayer?.taxpayerId}-taxpayer-document`,
        label: t('taxpayers:taxpayerActions.taxpayerDocument'),
        icon: <VerifiedUserOutlined />,
        hidden: !taxpayer,
        children: [
          {
            id: `taxpayer-${taxpayer?.taxpayerId}-get-taxpayer-document`,
            label: t('taxpayers:taxpayerActions.getTaxpayerDocument'),
            component: 'a',
            href: `${TAXPAYERS_URL}/${encodeURIComponent(
              taxpayer?.taxpayerId ?? ''
            )}/taxpayer-document`,
            download: `${t('taxpayers:taxpayerDocumentFileName')}.pdf`,
            icon: <SaveAlt />,
          },
          {
            id: `taxpayer-${taxpayer?.taxpayerId}-email-taxpayer-document`,
            label: t('taxpayers:taxpayerActions.emailTaxpayerDocument'),
            run: emailTaxpayerDocumentImpl,
            icon: <Email />,
            disabled: !taxpayer?.email,
          },
        ],
      } as ActionGroup,
    ],
    [emailTaxpayerDocumentImpl, pathname, t, taxpayer, taxpayerPath]
  );
}
