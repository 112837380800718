import { useCallback } from 'react';
import { useFetchFromApi } from '../FetchFromApiProvider';

/**
 * User profile information.
 */
export interface UserProfile {
  firstName: string;
  surname: string;
  displayName: string;
  civilId: string;
  organizationalUnitId: number | null;
  email: string;
  role: UserRole;
}

/**
 * User role.
 */
export enum UserRole {
  Administrator = 'Administrator',
  Manager = 'Manager',
  Official = 'Official',
}

/**
 * Authority of each user role (a smaller number represents more authority).
 */
export enum UserRoleAuthority {
  Administrator = 0,
  Manager = 1,
  Official = 2,
}

/**
 * URL to access the profile.
 */
export const PROFILE_URL = '/api/profile';

/**
 * Hook exposing the profile API.
 */
export function useProfileApi() {
  const { getJson } = useFetchFromApi();

  /**
   * Returns the profile information of the logged in user.
   */
  const getProfile = useCallback(
    async (): Promise<UserProfile> => getJson<UserProfile>(PROFILE_URL),
    [getJson]
  );

  return { getProfile };
}
