import { useCallback } from 'react';
import { toISODateString } from '../../util/dateUtils';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Type of a taxpayer.
 */
export interface Taxpayer {
  taxpayerId: string;
  oldTaxpayerId: string;
  name: string;
  status: TaxpayerStatus;
  representative: string;
  phone: string;
  email: string;
  notes: string;
  startDate: Date;
  endDate: Date | null;
  type: TaxpayerType;
  divisionId: number | null;
  address: string;
  addressOther: string;
  shareCapital: number;
  legalRegime: LegalRegime;
  dimension: TaxpayerDimension;
  sectorId: number | null;
  taxOfficeId: number | null;
  vatActivities: VatActivity[];
  createdDate: Date;
  changedDate: Date;
}

/**
 * Status of the taxpayer.
 */
export enum TaxpayerStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/**
 * Type of taxpayer.
 */
export enum TaxpayerType {
  Singular = 'SINGULAR',
  Legal = 'LEGAL',
}

/**
 * Taxpayer legal regime.
 */
export enum LegalRegime {
  Public = 'PUBLIC',
  PrivateNational = 'PRIVATE_NATIONAL',
  PrivateForeign = 'PRIVATE_FOREIGN',
}

/**
 * Taxpayer dimension.
 */
export enum TaxpayerDimension {
  Big = 'BIG',
  MediumNormal = 'MEDIUM_NORMAL',
  MediumAutonomous = 'MEDIUM_AUTONOMOUS',
  MediumSole = 'MEDIUM_SOLE',
  SmallNormal = 'SMALL_NORMAL',
  SmallAutonomous = 'SMALL_AUTONOMOUS',
  SmallSole = 'SMALL_SOLE',
  Micro = 'MICRO',
}

/**
 * VAT activity of a taxpayer.
 */
export interface VatActivity {
  vatActivityId: number;
  startDate: Date;
  endDate: Date | null;
  createdBy: string;
  createdDate: Date;
  changedBy: string;
  changedDate: Date;
}

/**
 * Options used to get taxpayers.
 */
export interface GetTaxpayersFilterOptions {
  taxpayerId?: string;
  oldTaxpayerId?: string;
  name?: string;
  exactNameMatch?: boolean;
  representative?: string;
  divisionId?: number;
  sectorId?: number;
  status?: TaxpayerStatus;
}

/**
 * Taxpayer form fields when creating a taxpayer.
 */
export interface CreateTaxpayerForm {
  taxpayerId: string;
  oldTaxpayerId: string;
  name: string;
  representative: string;
  startDate: Date | null;
  provinceId: number | null;
  districtId: number | null;
  divisionId: number | null;
  address: string;
  addressOther: string;
  sectorId: number | null;
  taxOfficeId: number | null;
  type: TaxpayerType;
  dimension: TaxpayerDimension | null;
  shareCapital: number | null;
  legalRegime: LegalRegime | null;
  phone: string;
  email: string;
  notes: string;
}

/**
 * Taxpayer form fields when updating a taxpayer.
 */
export interface UpdateTaxpayerForm extends CreateTaxpayerForm {
  endDate: Date | null;
}

/**
 * VAT activity form when creating/updating a VAT activity.
 */
export interface VatActivityForm {
  startDate: Date | null;
  endDate: Date | null;
}

/**
 * URL to access taxpayers.
 */
export const TAXPAYERS_URL = '/api/taxpayers';

/**
 * Transforms a taxpayer JSON as given by the API into our internal taxpayer
 * representation.
 */
function jsonToTaxpayer(taxpayerJson: any): Taxpayer {
  return {
    ...taxpayerJson,
    startDate: new Date(taxpayerJson.startDate),
    endDate: taxpayerJson.endDate && new Date(taxpayerJson.endDate),
    createdDate: new Date(taxpayerJson.createdDate),
    changedDate: new Date(taxpayerJson.changedDate),
    vatActivities: taxpayerJson.vatActivities?.map(jsonToVatActivity) ?? null,
  };
}

/**
 * Transforms a VAT activity JSON as given by the API into our internal tax
 * activity representation.
 */
function jsonToVatActivity(vatActivityJson: any): VatActivity {
  return {
    ...vatActivityJson,
    startDate: new Date(vatActivityJson.startDate),
    endDate: vatActivityJson.endDate && new Date(vatActivityJson.endDate),
    createdDate: new Date(vatActivityJson.createdDate),
    changedDate: new Date(vatActivityJson.changedDate),
  };
}

/**
 * Hook exposing the taxpayers API.
 */
export function useTaxpayersApi() {
  const { getJson, deleteJson, postJson, putJson } = useFetchFromApi();

  /**
   * Gets a list of taxpayers matching the provided filter options.
   */
  const getTaxpayers = useCallback(
    async (
      filterOptions: GetTaxpayersFilterOptions = {}
    ): Promise<Taxpayer[]> =>
      (await getJson(TAXPAYERS_URL, { params: filterOptions })).map(
        jsonToTaxpayer
      ),
    [getJson]
  );

  /**
   * Gets the taxpayer with the provided id.
   */
  const getTaxpayer = useCallback(
    async (taxpayerId: string): Promise<Taxpayer> =>
      jsonToTaxpayer(
        await getJson(`${TAXPAYERS_URL}/${encodeURIComponent(taxpayerId)}`)
      ),
    [getJson]
  );

  /**
   * Creates a new taxpayer from the provided taxpayer fields and returns it.
   */
  const createTaxpayer = useCallback(
    async (form: CreateTaxpayerForm): Promise<Taxpayer> => {
      const { districtId, provinceId, ...formWithoutUnusedFields } = form;
      const toCreate = {
        ...formWithoutUnusedFields,
        // TODO: This should accept an empty string instead
        taxpayerId: form.taxpayerId || null,
        startDate: form.startDate && toISODateString(form.startDate),
      };
      return jsonToTaxpayer(await postJson(TAXPAYERS_URL, toCreate));
    },
    [postJson]
  );

  /**
   * Updates a taxpayer with the provided id with the given fields and returns
   * the updated taxpayer.
   */
  const updateTaxpayer = useCallback(
    async (taxpayerId: string, form: UpdateTaxpayerForm): Promise<Taxpayer> => {
      const {
        taxpayerId: _id,
        districtId,
        provinceId,
        ...formWithoutUnusedFields
      } = form;
      const toUpdate = {
        ...formWithoutUnusedFields,
        startDate: form.startDate && toISODateString(form.startDate),
        endDate: form.endDate && toISODateString(form.endDate),
      };
      return jsonToTaxpayer(
        await putJson(
          `${TAXPAYERS_URL}/${encodeURIComponent(taxpayerId)}`,
          toUpdate
        )
      );
    },
    [putJson]
  );

  /**
   * Creates a new VAT activity for the taxpayer with the provided id.
   */
  const createVatActivity = useCallback(
    async (taxpayerId: string, form: VatActivityForm): Promise<VatActivity> => {
      const toCreate = {
        startDate: form.startDate && toISODateString(form.startDate),
        endDate: form.endDate && toISODateString(form.endDate),
      };
      return jsonToVatActivity(
        await postJson(
          `${TAXPAYERS_URL}/${encodeURIComponent(taxpayerId)}/vat-activities`,
          toCreate
        )
      );
    },
    [postJson]
  );

  /**
   * Updates the VAT activity with the provided start date of the taxpayer with
   * the provided id.
   */
  const updateVatActivity = useCallback(
    async (
      taxpayerId: string,
      vatActivityId: number,
      form: VatActivityForm
    ): Promise<VatActivity> => {
      const toUpdate = {
        startDate: form.startDate && toISODateString(form.startDate),
        endDate: form.endDate && toISODateString(form.endDate),
      };
      return jsonToVatActivity(
        await putJson(
          `${TAXPAYERS_URL}/${encodeURIComponent(
            taxpayerId
          )}/vat-activities/${encodeURIComponent(vatActivityId)}`,
          toUpdate
        )
      );
    },
    [putJson]
  );

  /**
   * Removes the VAT activity with the provided start date of the taxpayer with
   * the provided id.
   */
  const removeVatActivity = useCallback(
    async (taxpayerId: string, vatActivityId: number): Promise<VatActivity> =>
      jsonToVatActivity(
        await deleteJson(
          `${TAXPAYERS_URL}/${encodeURIComponent(
            taxpayerId
          )}/vat-activities/${encodeURIComponent(vatActivityId)}`
        )
      ),
    [deleteJson]
  );

  /**
   * Emails the taxpayer document to the taxpayer.
   */
  const emailTaxpayerDocument = useCallback(
    function (taxpayerId: string): Promise<void> {
      return postJson(
        `${TAXPAYERS_URL}/${encodeURIComponent(
          taxpayerId
        )}/taxpayer-document/email`
      );
    },
    [postJson]
  );

  return {
    getTaxpayers,
    getTaxpayer,
    createTaxpayer,
    updateTaxpayer,
    createVatActivity,
    updateVatActivity,
    removeVatActivity,
    emailTaxpayerDocument,
  };
}
