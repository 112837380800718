import { getMonth, getYear, startOfToday, subMonths } from 'date-fns';
import { CreditSettlementForm } from '../creditSettlementsApi';
import { localDateToSameDateInUTC } from '../../../util/dateUtils';
import { TaxablePeriod, TaxId } from '../../../common';

/**
 * Default "credit settlement form" value.
 */
export function defaultCreditSettlementForm(): CreditSettlementForm {
  return {
    taxpayerId: '',
    assessmentNumber: '',
    taxId: TaxId.VAT,
    taxableYear: getYear(subMonths(new Date(), 1)),
    taxablePeriod: (getMonth(subMonths(new Date(), 1)) + 1)
      .toString()
      .padStart(2, '0') as TaxablePeriod,
    creditSettlementDate: localDateToSameDateInUTC(startOfToday()),
    amount: null,
    description: '',
  };
}
