import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { ThemeVariant } from '../providers/ThemeProvider';

/**
 * Application version.
 */
export const APP_VERSION = process.env.REACT_APP_VERSION || '0.0.0-dev';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color:
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.text.secondary
          : theme.palette.text.disabled,
    },
  })
);

/**
 * Properties of the application version.
 */
export type AppVersionProps = Omit<
  TypographyProps<'span'>,
  'variant' | 'component' | 'children'
>;

/**
 * Component showing the application version.
 */
export function AppVersion({ className = '', ...props }: AppVersionProps) {
  const classes = useStyle();

  return (
    <Typography
      variant="caption"
      component="span"
      className={`${classes.root} ${className}`}
      {...props}
    >
      v{APP_VERSION}
    </Typography>
  );
}
