import React, { useState } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MainContent } from '../../components/MainContent';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import {
  PaperDescriptionList,
  DescriptionListItem,
} from '../../components/PaperDescriptionList';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { EditDisplayNameForm } from './EditDisplayNameForm';
import { useProfile } from '../../providers/ProfileProvider';
import { Refresh } from '@material-ui/icons';
import { NotSpecified } from '../../components/NotSpecified';
import { useCatalog } from '../../providers/CatalogsProvider';
import { ActionableHeader } from '../../components/ActionableHeader';
import { ChangePasswordForm } from './ChangePasswordForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContent: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
  })
);

/**
 * User profile page.
 */
export function ProfilePage() {
  const [t] = useTranslation(['profile', 'users']);
  useSetDocumentTitle(t('profile:profilePage.documentTitle'));
  const classes = useStyles();
  const userAuth = useAuthentication();
  const { profile } = useProfile();
  const {
    catalog: organizationalUnitsCatalog,
    isFetching: isFetchingOrganizationalUnitsCatalog,
  } = useCatalog('organizational-units');
  const [passwordChangeIsOpen, setPasswordChangeIsOpen] = useState(false);

  // Login information fields
  const loginInformation: DescriptionListItem[] = [
    {
      key: 'username',
      label: t('users:userFields.username'),
      content: userAuth.user!.username,
    },
    {
      key: 'password',
      label: t('users:userFields.password'),
      content: (
        <div className={classes.formContent}>
          <ChangePasswordForm
            open={passwordChangeIsOpen}
            setOpen={setPasswordChangeIsOpen}
          />

          <Button
            variant="contained"
            size="small"
            onClick={() => setPasswordChangeIsOpen(true)}
            startIcon={<Refresh />}
          >
            {t('profile:profilePage.changePassword')}
          </Button>
        </div>
      ),
    },
  ];

  // User information fields
  const userInformation: DescriptionListItem[] = [
    {
      key: 'name',
      label: t('users:userFields.name'),
      content: [profile!.firstName, profile!.surname]
        .filter((str) => str)
        .join(' ') || (
        <NotSpecified>{t('users:nameNotSpecified')}</NotSpecified>
      ),
    },
    {
      key: 'display-name',
      label: t('users:userFields.displayName'),
      content: (
        <div className={classes.formContent}>
          <EditDisplayNameForm />
        </div>
      ),
    },
    {
      key: 'civil-id',
      label: t('users:userFields.civilId'),
      content: profile!.civilId || (
        <NotSpecified>{t('users:civilIdNotSpecified')}</NotSpecified>
      ),
    },
    {
      key: 'organizational-unit',
      label: t('users:userFields.organizationalUnit'),
      content: organizationalUnitsCatalog?.find(
        (organizationalUnit) =>
          organizationalUnit.id === profile!.organizationalUnitId
      )?.name ?? (
        <NotSpecified>{t('users:organizationalUnitNotSpecified')}</NotSpecified>
      ),
      fetching: isFetchingOrganizationalUnitsCatalog,
    },
    {
      key: 'email',
      label: t('users:userFields.email'),
      content: profile!.email || (
        <NotSpecified>{t('users:emailNotSpecified')}</NotSpecified>
      ),
    },
  ];

  return (
    <MainContent>
      <ActionableHeader title={t('profile:profilePage.title')} />

      <PaperDescriptionList
        title={t('profile:profilePage.loginInformation')}
        items={loginInformation}
      />
      <PaperDescriptionList
        title={t('profile:profilePage.userInformation')}
        items={userInformation}
      />
    </MainContent>
  );
}
