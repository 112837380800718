import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { VatOmission } from '../vatOmissionsApi';
import { replacePathParams, TAXPAYER_PATH } from '../../../navigation/paths';
import { Link } from '../../../components/Link';
import { NotSpecified } from '../../../components/NotSpecified';
import { VatOmissionRowActions } from './VatOmissionRowActions';
import { vatOmissionId } from '../util/vatOmissionId';

/**
 * Properties of the VAT omissions table.
 */
export interface VatOmissionsTableProps {
  fetching: boolean;
  vatOmissions: VatOmission[];
  downloadButton: ReactNode;
}

/**
 * Table listing VAT omissions.
 */
export function VatOmissionsTable({
  fetching,
  vatOmissions,
  downloadButton,
}: VatOmissionsTableProps) {
  const [t] = useTranslation(['common', 'vatOmissions']);

  const columns: DataTableColumns<VatOmission> = {
    taxpayerId: {
      label: t('vatOmissions:vatOmissionFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxableYearPeriod: {
      label: t('vatOmissions:vatOmissionFields.taxablePeriod'),
      value: ({ taxableYear, taxablePeriod }) =>
        `${taxableYear}/${taxablePeriod}`,
      defaultSortDirection: 'desc',
    },
    name: {
      label: t('vatOmissions:vatOmissionFields.name'),
      value: ({ name }) => name,
    },
    representative: {
      label: t('vatOmissions:vatOmissionFields.representative'),
      value: ({ representative }) => representative,
    },
    phone: {
      label: t('vatOmissions:vatOmissionFields.phone'),
      value: ({ phone }) => phone || null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>{t('vatOmissions:phoneNotSpecified')}</NotSpecified>
        ),
    },
    email: {
      label: t('vatOmissions:vatOmissionFields.email'),
      value: ({ email }) => email || null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>{t('vatOmissions:emailNotSpecified')}</NotSpecified>
        ),
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, vatOmission) => (
        <VatOmissionRowActions vatOmission={vatOmission} />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <DataTable
      title={t('vatOmissions:vatOmissionsPage.dataTable.title')}
      rows={vatOmissions}
      rowId={(vatOmission) => vatOmissionId(vatOmission)}
      columns={columns}
      emptyMessage={t('vatOmissions:vatOmissionsPage.dataTable.emptyMessage')}
      toolbarActionsLeft={downloadButton}
      fetching={fetching}
      defaultSortBy="taxableYearPeriod"
      minWidth={1100}
    />
  );
}
