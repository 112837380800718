import { Dispatch, SetStateAction, useCallback } from 'react';
import { useResource } from '../../../util/useResource';
import { useVatReturnsApi, VatReturnForm } from '../vatReturnsApi';
import { useTranslation } from 'react-i18next';

/**
 * VAT return form resource.
 */
export interface VatReturnFormResource {
  isFetching: boolean;
  vatReturnForm: VatReturnForm | null;
  notFound: boolean;
  setVatReturnForm: Dispatch<SetStateAction<VatReturnForm | null>>;
}

/**
 * Hook used to fetch a VAT return form from the API, if no id is passed this
 * hook does nothing.
 */
export function useVatReturnForm(
  taxReturnId?: number | null
): VatReturnFormResource {
  const [t] = useTranslation('vatReturns');
  const { getVatReturnForm } = useVatReturnsApi();
  const getVatReturnFormCb = useCallback(
    () => (taxReturnId != null ? getVatReturnForm(taxReturnId) : null),
    [getVatReturnForm, taxReturnId]
  );
  const {
    isFetching,
    resource: vatReturnForm,
    notFound,
    setResource: setVatReturnForm,
  } = useResource({
    fetchResource: getVatReturnFormCb,
    errorFetchingResourceMessage: t('vatReturns:errorFetchingVatReturn', {
      id: taxReturnId,
    }),
  });

  return {
    isFetching,
    vatReturnForm,
    notFound,
    setVatReturnForm,
  };
}
