/**
 * Returns a query params string from an object mapping query params to their
 * values. E.g. `{x: "a", y: 2}` becomes: `"x=a&y=2"`.
 */
export function objectToQueryParamsString(obj: Record<string, any>): string {
  return Object.keys(obj)
    .reduce((queries: string[], param) => {
      if (obj[param] != null) {
        queries.push(
          `${encodeURIComponent(param)}=${encodeURIComponent(
            String(obj[param])
          )}`
        );
      }
      return queries;
    }, [])
    .join('&');
}
