import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useLfForm } from '../../util/lfIntegration';
import { Form, FormPasswordField } from '../../components/Form';
import { useProfileApi } from './profileApi';
import {Action, DialogActionButtons} from "../../components/Actions";

/**
 * Properties of the form to change password.
 */
export interface ChangePasswordFormProps {
  open: boolean;
  setOpen(open: boolean): void;
}

/**
 * Form to change password as a modal.
 */
export function ChangePasswordForm({ open, setOpen }: ChangePasswordFormProps) {
  const [t] = useTranslation(['common', 'profile']);
  const { changePassword } = useProfileApi();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useLfForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    formValidatorName: 'changePasswordFormValidator',
    i18nErrorMessagesPrefixes:
      'profile:profilePage.changePasswordForm.fieldErrors',
  });
  const { getValues, formState, setLfIssues } = formMethods;

  async function onSubmit() {
    const { currentPassword, newPassword } = getValues();
    try {
      await changePassword(currentPassword, newPassword);
      close();
      enqueueSnackbar(
        t('profile:profilePage.changePasswordForm.passwordChangedSuccessfully'),
        { variant: 'success' }
      );
    } catch (err) {
      if (err instanceof Response && err.status === 400) {
        setLfIssues([{ path: '/currentPassword', code: 'invalid' }], true);
      } else {
        enqueueSnackbar(
          t('profile:profilePage.changePasswordForm.errorChangingPassword'),
          { variant: 'error' }
        );
      }
    }
  }

  function close() {
    setOpen(false);
  }

  // Actions of the form
  const actions: Action[] = [
    {
      id: 'change-password-form-submit',
      type: 'submit',
      label: t('profile:profilePage.changePasswordForm.submit'),
      color: 'primary',
      icon: <Refresh />,
      loading: formState.isSubmitting,
    },
    {
      id: 'change-password-form-cancel',
      label: t('formActions.cancel'),
      run: () => close(),
      disabled: formState.isSubmitting,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={close}
      disableBackdropClick={formState.isDirty || formState.isSubmitting}
      disableEscapeKeyDown={formState.isDirty || formState.isSubmitting}
      aria-labelledby="change-password-form"
    >
      <Form onSubmit={onSubmit} {...formMethods}>
        <DialogTitle id="change-password-form">
          {t('profile:profilePage.changePasswordForm.title')}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {/* Current password */}
            <Grid item xs={12}>
              <FormPasswordField
                name="currentPassword"
                label={t(
                  'profile:profilePage.changePasswordForm.fields.currentPassword'
                )}
                autoComplete="current-password"
                onChangeTriggers={['newPassword']}
              />
            </Grid>

            {/* New password */}
            <Grid item xs={12}>
              <FormPasswordField
                name="newPassword"
                label={t(
                  'profile:profilePage.changePasswordForm.fields.newPassword'
                )}
                autoComplete="new-password"
                onChangeTriggers={['repeatNewPassword']}
              />
            </Grid>

            {/* Repeat new password */}
            <Grid item xs={12}>
              <FormPasswordField
                name="repeatNewPassword"
                label={t(
                  'profile:profilePage.changePasswordForm.fields.repeatNewPassword'
                )}
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DialogActionButtons actions={actions} />
        </DialogActions>
      </Form>
    </Dialog>
  );
}
