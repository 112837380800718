import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  GetPaymentsFilterOptions,
  PAYMENTS_URL,
  usePaymentsApi,
} from '../paymentsApi';
import { SearchForm } from '../../../components/SearchForm';
import { useResource } from '../../../util/useResource';
import { useQueryParams } from '../../../util/useQueryParams';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { FormTaxpayerIdField } from '../../../components/Form';
import { PaymentsTable } from './PaymentsTable';
import { TaxablePeriod, TaxId } from '../../../common';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useLfForm } from '../../../util/lfIntegration';

// Search parameters
const searchParams = ['taxpayerId', 'taxId', 'taxableYear', 'taxablePeriod'];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    taxId: Object.values(TaxId).includes(queryParams.taxId as any)
      ? (queryParams.taxId as any)
      : null,
    taxableYear: isNaN(+queryParams.taxableYear)
      ? null
      : +queryParams.taxableYear,
    taxablePeriod: Object.values(TaxablePeriod).includes(
      queryParams.taxablePeriod as any
    )
      ? (queryParams.taxablePeriod as any)
      : null,
  };
}

/**
 * Page dedicated to consult the existing payments.
 */
export function PaymentsPage() {
  const [t] = useTranslation(['common', 'payments']);
  useSetDocumentTitle(t('payments:paymentsPage.documentTitle'));
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      taxId: TaxId.VAT as TaxId | null,
      taxableYear: null as number | null,
      taxablePeriod: null as TaxablePeriod | null,
    },
    formValidatorName: 'getPaymentsFormValidator',
    i18nErrorMessagesPrefixes: 'payments:paymentsPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const { getPayments } = usePaymentsApi();
  const fetchPayments = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getPayments(queryParams)
        : null,
    [getPayments, isValid, queryParams]
  );
  const {
    isFetching,
    resource: payments,
    setResource: setPayments,
    refetch: refetchPayments,
  } = useResource({
    fetchResource: fetchPayments,
    errorFetchingResourceMessage: t(
      'payments:paymentsPage.errorFetchingPayments'
    ),
  });

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const { taxpayerId, taxId, taxableYear, taxablePeriod } = getValues();
    const getPaymentsOptions: GetPaymentsFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      taxId: taxId ?? undefined,
      taxableYear: taxableYear || undefined,
      taxablePeriod: taxablePeriod || undefined,
    };
    // Pressing "search" when nothing changed should refetch the payments
    if (areSameQueryParams(getPaymentsOptions, queryParams, searchParams)) {
      refetchPayments();
    } else {
      setQueryParams(getPaymentsOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader title={t('payments:paymentsPage.title')} />

      <SearchForm
        title={t('payments:paymentsPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        {/* TIN */}
        <Grid item xs={12} md={4}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('payments:paymentFields.taxpayerId')}
            size="small"
          />
        </Grid>

        {/* Taxable year/period */}
        <FormTaxableYearPeriod
          taxableYearProps={{
            name: 'taxableYear',
            label: t('payments:paymentFields.taxableYear'),
            size: 'small',
          }}
          taxablePeriodProps={{
            name: 'taxablePeriod',
            label: t('payments:paymentFields.taxablePeriod'),
            size: 'small',
          }}
        >
          {(taxableYear, taxablePeriod) => (
            <>
              <Grid item xs={6} md={4}>
                {taxableYear}
              </Grid>
              <Grid item xs={6} md={4}>
                {taxablePeriod}
              </Grid>
            </>
          )}
        </FormTaxableYearPeriod>
      </SearchForm>

      <PaymentsTable
        fetching={isFetching}
        payments={payments || []}
        setPayments={setPayments}
        refetchPayments={refetchPayments}
        downloadButton={
          <DownloadTableButton
            fileName={t('payments:paymentsPage.dataTable.downloadFileName')}
            downloadUrl={`${PAYMENTS_URL}?${objectToQueryParamsString({
              ...queryParams,
              format: 'csv',
            })}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
