import { useTranslation } from 'react-i18next';
import { useSetDocumentTitle } from '../../util/useSetDocumentTitle';
import {MainContent} from "../../components/MainContent";

/**
 * Not found page.
 */
export function NotFoundPage() {
  const [t] = useTranslation('common');
  useSetDocumentTitle(t('notFoundPage.documentTitle'));

  return <MainContent errorMessage={t('notFoundPage.message')} />;
}
