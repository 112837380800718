import React, { ElementType } from 'react';
import {
  createStyles,
  Grid,
  GridProps,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ThemeVariant } from '../providers/ThemeProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(1.5),
      position: 'relative',
      border: `1px solid ${
        theme.palette.type === ThemeVariant.Light
          ? theme.palette.grey[600]
          : theme.palette.grey[400]
      }`,
      borderRadius: theme.shape.borderRadius,
      '@media print': {
        breakInside: 'avoid',
      },
    },
    title: {
      position: 'absolute',
      top: theme.spacing(-1.5),
      left: theme.spacing(1),
      padding: theme.spacing(0, 1),
      fontSize: '1rem',
      backgroundColor: theme.palette.background.paper,
    },
  })
);

/**
 * Properties of the outlined grid.
 */
export interface OutlinedGridProps extends GridProps {
  title?: string;
  titleComponent?: ElementType<any>;
}

/**
 * Grid with an outline.
 */
export function OutlinedGrid({
  title,
  titleComponent,
  children,
  className,
  ...props
}: OutlinedGridProps) {
  const classes = useStyles();

  return (
    <Grid className={`${classes.root} ${className || ''}`} {...props}>
      {title && (
        <Typography
          className={classes.title}
          component={titleComponent || 'span'}
          variant="h6"
        >
          {title}
        </Typography>
      )}

      {children}
    </Grid>
  );
}
