import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { MainContent } from '../../../components/MainContent';
import { useSetDocumentTitle } from '../../../util/useSetDocumentTitle';
import {
  GetPaymentRefundsFilterOptions,
  PAYMENT_REFUNDS_URL,
  usePaymentRefundsApi,
} from '../paymentRefundsApi';
import { SearchForm } from '../../../components/SearchForm';
import { useResource } from '../../../util/useResource';
import { useQueryParams } from '../../../util/useQueryParams';
import { areSameQueryParams } from '../../../util/areSameQueryParams';
import { DownloadTableButton } from '../../../components/DownloadTableButton';
import { objectToQueryParamsString } from '../../../util/objectToQueryParamsString';
import { FormTaxpayerIdField } from '../../../components/Form';
import { PaymentRefundsTable } from './PaymentRefundsTable';
import { TaxablePeriod, TaxId } from '../../../common';
import { ActionableHeader } from '../../../components/ActionableHeader';
import { FormTaxableYearPeriod } from '../../../components/Form/FormTaxableYearPeriod';
import { useLfForm } from '../../../util/lfIntegration';

// Search parameters
const searchParams = ['taxpayerId', 'taxId', 'taxableYear', 'taxablePeriod'];

/**
 * Converts the search query params into a search form value.
 */
function queryParamsToSearchForm(queryParams: Record<string, string>) {
  return {
    taxpayerId: queryParams.taxpayerId || '',
    taxId: Object.values(TaxId).includes(queryParams.taxId as any)
      ? (queryParams.taxId as any)
      : null,
    taxableYear: isNaN(+queryParams.taxableYear)
      ? null
      : +queryParams.taxableYear,
    taxablePeriod: Object.values(TaxablePeriod).includes(
      queryParams.taxablePeriod as any
    )
      ? (queryParams.taxablePeriod as any)
      : null,
  };
}

/**
 * Page dedicated to consult the existing payments.
 */
export function PaymentRefundsPage() {
  const [t] = useTranslation(['common', 'paymentRefunds']);
  useSetDocumentTitle(t('paymentRefunds:paymentRefundsPage.documentTitle'));
  const [queryParams, setQueryParams] = useQueryParams(searchParams);
  const formMethods = useLfForm({
    defaultValues: {
      taxpayerId: '',
      taxId: TaxId.VAT as TaxId | null,
      taxableYear: null as number | null,
      taxablePeriod: null as TaxablePeriod | null,
    },
    formValidatorName: 'getPaymentRefundsFormValidator',
    i18nErrorMessagesPrefixes:
      'paymentRefunds:paymentRefundsPage.searchForm.fieldErrors',
  });
  const { getValues, reset, isValid } = formMethods;
  const { getPaymentRefunds } = usePaymentRefundsApi();
  const fetchPaymentRefunds = useCallback(
    async () =>
      (await isValid(queryParamsToSearchForm(queryParams)))
        ? getPaymentRefunds(queryParams)
        : null,
    [getPaymentRefunds, isValid, queryParams]
  );
  const {
    isFetching,
    resource: payments,
    setResource: setPaymentRefunds,
    refetch: refetchPaymentRefunds,
  } = useResource({
    fetchResource: fetchPaymentRefunds,
    errorFetchingResourceMessage: t(
      'paymentRefunds:paymentRefundsPage.errorFetchingPaymentRefunds'
    ),
  });

  // Reset form value from query params
  useEffect(() => {
    reset(queryParamsToSearchForm(queryParams));
  }, [queryParams, reset]);

  function onSubmit() {
    const { taxpayerId, taxId, taxableYear, taxablePeriod } = getValues();
    const getPaymentRefundsOptions: GetPaymentRefundsFilterOptions = {
      taxpayerId: taxpayerId || undefined,
      taxId: taxId ?? undefined,
      taxableYear: taxableYear || undefined,
      taxablePeriod: taxablePeriod || undefined,
    };
    // Pressing "search" when nothing changed should refetch the payment refunds
    if (
      areSameQueryParams(getPaymentRefundsOptions, queryParams, searchParams)
    ) {
      refetchPaymentRefunds();
    } else {
      setQueryParams(getPaymentRefundsOptions);
    }
  }

  return (
    <MainContent>
      <ActionableHeader title={t('paymentRefunds:paymentRefundsPage.title')} />

      <SearchForm
        title={t('paymentRefunds:paymentRefundsPage.searchForm.title')}
        onSubmit={onSubmit}
        {...formMethods}
      >
        {/* TIN */}
        <Grid item xs={12} md={4}>
          <FormTaxpayerIdField
            name="taxpayerId"
            label={t('paymentRefunds:paymentRefundFields.taxpayerId')}
            size="small"
          />
        </Grid>

        {/* Taxable year/period */}
        <FormTaxableYearPeriod
          taxableYearProps={{
            name: 'taxableYear',
            label: t('paymentRefunds:paymentRefundFields.taxableYear'),
            size: 'small',
          }}
          taxablePeriodProps={{
            name: 'taxablePeriod',
            label: t('paymentRefunds:paymentRefundFields.taxablePeriod'),
            size: 'small',
          }}
        >
          {(taxableYear, taxablePeriod) => (
            <>
              <Grid item xs={6} md={4}>
                {taxableYear}
              </Grid>
              <Grid item xs={6} md={4}>
                {taxablePeriod}
              </Grid>
            </>
          )}
        </FormTaxableYearPeriod>
      </SearchForm>

      <PaymentRefundsTable
        fetching={isFetching}
        paymentRefunds={payments || []}
        setPaymentRefunds={setPaymentRefunds}
        refetchPaymentRefunds={refetchPaymentRefunds}
        downloadButton={
          <DownloadTableButton
            fileName={t(
              'paymentRefunds:paymentRefundsPage.dataTable.downloadFileName'
            )}
            downloadUrl={`${PAYMENT_REFUNDS_URL}?${objectToQueryParamsString({
              ...queryParams,
              format: 'csv',
            })}`}
            disabled={isFetching}
          />
        }
      />
    </MainContent>
  );
}
