import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from './Route';
import {
  CHECKING_ACCOUNT_PATH,
  CHECKING_ACCOUNTS_PATH,
  CREDIT_SETTLEMENT_PATH,
  CREDIT_SETTLEMENTS_PATH,
  EDIT_TAXPAYER_PATH,
  EDIT_USER_PATH,
  NEW_CREDIT_SETTLEMENT_PATH,
  NEW_PAYMENT_PATH,
  NEW_PAYMENT_REFUND_PATH,
  NEW_TAX_REFUND_PATH,
  NEW_TAXPAYER_PATH,
  NEW_USER_PATH,
  NEW_VAT_RETURN_PATH,
  PAYMENT_PATH,
  PAYMENT_REFUND_PATH,
  PAYMENT_REFUNDS_PATH,
  PAYMENTS_PATH,
  PROFILE_PATH,
  READ_VAT_RETURN_PATH,
  RELOAD_PATH,
  ROOT_PATH,
  STATE_CHECKING_ACCOUNT_PATH,
  TAX_REFUND_PATH,
  TAX_REFUNDS_PATH,
  TAXPAYER_PATH,
  TAXPAYERS_PATH,
  USER_PATH,
  USERS_PATH,
  VAT_ASSESSMENT_PATH,
  VAT_ASSESSMENTS_PATH,
  VAT_INDICATOR_PATH,
  VAT_OMISSIONS_PATH,
  VAT_RETURN_PATH,
  VAT_RETURNS_PATH,
} from './paths';
import { HomePage } from '../features/Home';
import { ProfilePage } from '../features/Profile';
import {
  VatReturnFormPage,
  VatReturnPage,
  VatReturnsPage,
} from '../features/VatReturns';
import { VatOmissionsPage } from '../features/VatOmissions';
import {
  VatAssessmentPage,
  VatAssessmentsPage,
} from '../features/VatAssessments';
import {
  TaxpayerPage,
  TaxpayersPage,
  TaxpayerFormPage,
} from '../features/Taxpayers';
import { NotFoundPage } from '../features/NotFound';
import { RestrictedRoute } from './RestrictedRoute';
import {
  TaxRefundFormPage,
  TaxRefundPage,
  TaxRefundsPage,
} from '../features/TaxRefunds';
import {
  PaymentFormPage,
  PaymentPage,
  PaymentsPage,
} from '../features/Payments';
import {
  PaymentRefundFormPage,
  PaymentRefundPage,
  PaymentRefundsPage,
} from '../features/PaymentRefunds';
import { UserFormPage, UserPage, UsersPage } from '../features/Users';
import {
  CreditSettlementFormPage,
  CreditSettlementPage,
  CreditSettlementsPage,
} from '../features/CreditSettlements';
import { useProfile, UserRole } from '../providers/ProfileProvider';
import {
  CheckingAccountPage,
  CheckingAccountsPage,
} from '../features/CheckingAccounts';
import { StateCheckingAccountPage } from '../features/StateCheckingAccount';
import { VatIndicatorPage } from '../features/VatIndicators';
import { Reloader } from './Reloader';
import { PageLoading } from '../components/PageLoading';

/**
 * Main router configuration.
 */
export function MainRouterConfig() {
  const { isFetching: isFetchingProfile } = useProfile();

  return isFetchingProfile ? (
    <PageLoading />
  ) : (
    <Switch>
      {/* "Helper" route used to force a component reload */}
      <Route exact path={RELOAD_PATH}>
        <Reloader />
      </Route>

      {/* Home */}
      <Route exact path={ROOT_PATH}>
        <HomePage />
      </Route>

      {/* Profile */}
      <Route exact path={PROFILE_PATH}>
        <ProfilePage />
      </Route>

      {/* Taxpayers */}
      <Route exact path={TAXPAYERS_PATH}>
        <TaxpayersPage />
      </Route>
      <Route exact path={TAXPAYER_PATH}>
        <TaxpayerPage />
      </Route>
      <Route exact path={NEW_TAXPAYER_PATH}>
        <TaxpayerFormPage />
      </Route>
      <Route exact path={EDIT_TAXPAYER_PATH}>
        <TaxpayerFormPage />
      </Route>

      {/* VAT returns */}
      <Route exact path={VAT_RETURNS_PATH}>
        <VatReturnsPage />
      </Route>
      <Route exact path={VAT_RETURN_PATH}>
        <VatReturnPage />
      </Route>
      <Route exact path={NEW_VAT_RETURN_PATH}>
        <VatReturnFormPage />
      </Route>
      <Route exact path={READ_VAT_RETURN_PATH}>
        <VatReturnFormPage />
      </Route>

      {/* VAT omissions */}
      <Route exact path={VAT_OMISSIONS_PATH}>
        <VatOmissionsPage />
      </Route>

      {/* VAT assessments */}
      <Route exact path={VAT_ASSESSMENTS_PATH}>
        <VatAssessmentsPage />
      </Route>
      <Route exact path={VAT_ASSESSMENT_PATH}>
        <VatAssessmentPage />
      </Route>

      {/* Checking accounts */}
      <Route exact path={CHECKING_ACCOUNTS_PATH}>
        <CheckingAccountsPage />
      </Route>
      <Route exact path={CHECKING_ACCOUNT_PATH}>
        <CheckingAccountPage />
      </Route>
      <Route exact path={STATE_CHECKING_ACCOUNT_PATH}>
        <StateCheckingAccountPage />
      </Route>

      {/* VAT indicators */}
      <Route exact path={VAT_INDICATOR_PATH}>
        <VatIndicatorPage />
      </Route>

      {/* Tax refunds */}
      <Route exact path={TAX_REFUNDS_PATH}>
        <TaxRefundsPage />
      </Route>
      <Route exact path={TAX_REFUND_PATH}>
        <TaxRefundPage />
      </Route>
      <Route exact path={NEW_TAX_REFUND_PATH}>
        <TaxRefundFormPage />
      </Route>

      {/* Payments */}
      <Route exact path={PAYMENTS_PATH}>
        <PaymentsPage />
      </Route>
      <Route exact path={PAYMENT_PATH}>
        <PaymentPage />
      </Route>
      <Route exact path={NEW_PAYMENT_PATH}>
        <PaymentFormPage />
      </Route>

      {/* Payment refunds */}
      <Route exact path={PAYMENT_REFUNDS_PATH}>
        <PaymentRefundsPage />
      </Route>
      <Route exact path={PAYMENT_REFUND_PATH}>
        <PaymentRefundPage />
      </Route>
      <Route exact path={NEW_PAYMENT_REFUND_PATH}>
        <PaymentRefundFormPage />
      </Route>

      {/* Credit settlements */}
      <Route exact path={CREDIT_SETTLEMENTS_PATH}>
        <CreditSettlementsPage />
      </Route>
      <Route exact path={CREDIT_SETTLEMENT_PATH}>
        <CreditSettlementPage />
      </Route>
      <Route exact path={NEW_CREDIT_SETTLEMENT_PATH}>
        <CreditSettlementFormPage />
      </Route>

      {/* Users */}
      <RestrictedRoute exact role={UserRole.Manager} path={USERS_PATH}>
        <UsersPage />
      </RestrictedRoute>
      <RestrictedRoute exact role={UserRole.Manager} path={USER_PATH}>
        <UserPage />
      </RestrictedRoute>
      <RestrictedRoute exact role={UserRole.Manager} path={NEW_USER_PATH}>
        <UserFormPage />
      </RestrictedRoute>
      <RestrictedRoute exact role={UserRole.Manager} path={EDIT_USER_PATH}>
        <UserFormPage />
      </RestrictedRoute>

      {/* Not found */}
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
