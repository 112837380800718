import { createContext } from 'react';
import { AuthenticatedUser } from './authenticationApi';

/**
 * Representation of authentication context.
 */
export interface Authentication {
  isFetching: boolean;
  user: AuthenticatedUser | null;
  api: AuthenticationApi;
}

/**
 * Authentication API.
 */
export interface AuthenticationApi {
  login(username: string, password: string): Promise<AuthenticatedUser>;
  refresh(): Promise<AuthenticatedUser>;
  logout(): Promise<void>;
}

/**
 * Authentication context.
 */
export const AuthenticationContext = createContext<Authentication>(
  undefined as any
);
