import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { Payment, PaymentStatus } from '../paymentsApi';
import { PaymentRowActions } from './PaymentRowActions';
import {
  useCurrencyFormatter,
  useDateFormatter,
} from '../../../util/formatters';
import { StatusChip } from '../../../components/StatusChip';
import {
  PAYMENT_PATH,
  replacePathParams,
  TAXPAYER_PATH,
  VAT_ASSESSMENT_PATH,
} from '../../../navigation/paths';
import { Link } from '../../../components/Link';
import { NotSpecified } from '../../../components/NotSpecified';
import { VoidPaymentForm } from '../VoidPaymentForm';

/**
 * Properties of the payments table.
 */
export interface PaymentsTableProps {
  fetching: boolean;
  payments: Payment[];
  setPayments: Dispatch<SetStateAction<Payment[] | null>>;
  refetchPayments: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing payments.
 */
export function PaymentsTable({
  fetching,
  payments,
  setPayments,
  refetchPayments,
  downloadButton,
}: PaymentsTableProps) {
  const [t] = useTranslation(['common', 'payments']);
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();
  const [isVoidFormOpen, setIsVoidFormOpen] = useState(false);
  const [paymentVoiding, setPaymentVoiding] = useState<Payment | null>(null);

  const setPayment = useCallback(
    (payment: Payment) => {
      setPayments(
        (payments) =>
          payments &&
          payments.map((paym) =>
            paym.paymentId === payment.paymentId ? payment : paym
          )
      );
    },
    [setPayments]
  );

  const columns: DataTableColumns<Payment> = {
    paymentId: {
      label: '#',
      value: ({ paymentId }) => paymentId,
      render: (content, value) => (
        <Link to={replacePathParams(PAYMENT_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    assessmentNumber: {
      label: t('payments:paymentFields.assessmentNumber'),
      value: ({ assessmentNumber }) => assessmentNumber || null,
      render: (content, value) =>
        value ? (
          <Link to={replacePathParams(VAT_ASSESSMENT_PATH, { id: value })}>
            {content}
          </Link>
        ) : (
          <NotSpecified>{t('payments:noAssessmentNumber')}</NotSpecified>
        ),
      defaultSortDirection: 'desc',
    },
    taxpayerId: {
      label: t('payments:paymentFields.taxpayerId'),
      value: ({ taxpayerId }) => taxpayerId,
      render: (content, value) => (
        <Link to={replacePathParams(TAXPAYER_PATH, { id: value })}>
          {content}
        </Link>
      ),
      defaultSortDirection: 'desc',
    },
    taxableYearPeriod: {
      label: t('payments:paymentFields.taxablePeriod'),
      value: ({ taxableYear, taxablePeriod }) =>
        `${taxableYear}/${taxablePeriod}`,
      defaultSortDirection: 'desc',
    },
    paymentDate: {
      label: t('payments:paymentFields.date'),
      value: ({ paymentDate }) => paymentDate,
      format: (value) => formatDate(value as Date),
      defaultSortDirection: 'desc',
    },
    amount: {
      label: t('payments:paymentFields.amount'),
      value: ({ amount }) => amount,
      format: (value) => formatCurrency(value as number),
      align: 'right',
    },
    status: {
      label: t('payments:paymentFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`payments:paymentStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={status === PaymentStatus.Voided ? 'error' : 'success'}
          label={content}
        />
      ),
      paddinglessY: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <PaymentRowActions
          payment={row}
          onVoid={() => {
            setPaymentVoiding(row);
            setIsVoidFormOpen(true);
          }}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <>
      <VoidPaymentForm
        payment={paymentVoiding}
        open={isVoidFormOpen}
        setOpen={setIsVoidFormOpen}
        onSuccess={(newPayment) => setPayment(newPayment)}
        onFailureDueToOutdatedUI={refetchPayments}
      />

      <DataTable
        title={t('payments:paymentsPage.dataTable.title')}
        rows={payments}
        rowId={({ paymentId }) => paymentId}
        columns={columns}
        emptyMessage={t('payments:paymentsPage.dataTable.emptyMessage')}
        toolbarActionsLeft={downloadButton}
        fetching={fetching}
        defaultSortBy="taxableYearPeriod"
        minWidth={1100}
      />
    </>
  );
}
