import { useCallback } from 'react';
import { TaxablePeriod, TaxId, TransactionMethod } from '../../common';
import { toISODateString } from '../../util/dateUtils';
import { useFetchFromApi } from '../../providers/FetchFromApiProvider';

/**
 * Type of a payment refund.
 */
export interface PaymentRefund {
  paymentRefundId: number;
  assessmentNumber: string;
  assessmentNumberInserted: string;
  taxpayerId: string;
  taxpayerName: string;
  taxId: TaxId;
  taxableYear: number;
  taxablePeriod: TaxablePeriod | null;
  paymentRefundDate: Date;
  amount: number;
  bankId: number;
  transactionMethod: TransactionMethod;
  description: string;
  status: PaymentRefundStatus;
  createdDate: Date;
  createdBy: string;
  changedDate: Date;
  voidDescription: string | null;
  changedBy: string | null;
}

/**
 * Status of the payment refund.
 */
export enum PaymentRefundStatus {
  Submitted = 'SUBMITTED',
  Voided = 'VOIDED',
}

/**
 * Options used to get payments.
 */
export interface GetPaymentRefundsFilterOptions {
  taxpayerId?: string;
  taxId?: TaxId;
  taxableYear?: number;
  taxablePeriod?: TaxablePeriod;
}

/**
 * Form fields used to void a payment refund.
 */
export interface VoidPaymentRefundForm {
  voidDescription: string;
}

/**
 * Form fields when creating a payment refund.
 */
export interface PaymentRefundForm {
  taxpayerId: string;
  assessmentNumber: string;
  taxId: TaxId | null;
  taxableYear: number | null;
  taxablePeriod: TaxablePeriod | null;
  paymentRefundDate: Date | null;
  amount: number | null;
  bankId: number | null;
  transactionMethod: string | null;
  description: string;
}

/**
 * URL to access payment refunds.
 */
export const PAYMENT_REFUNDS_URL = '/api/payment-refunds';

/**
 * Transforms a payment refund JSON as given by the API into our internal
 * payment refund representation.
 */
function jsonToPaymentRefund(paymentRefundJson: any): PaymentRefund {
  return {
    ...paymentRefundJson,
    paymentRefundDate: new Date(paymentRefundJson.paymentRefundDate),
    createdDate: new Date(paymentRefundJson.createdDate),
    changedDate: new Date(paymentRefundJson.changedDate),
  };
}

/**
 * Hook exposing the payment refunds API.
 */
export function usePaymentRefundsApi() {
  const { getJson, postJson, putJson } = useFetchFromApi();

  /**
   * Gets a list of payment refunds matching the provided filter options.
   */
  const getPaymentRefunds = useCallback(
    async function (
      filterOptions: GetPaymentRefundsFilterOptions = {}
    ): Promise<PaymentRefund[]> {
      return (
        await getJson(PAYMENT_REFUNDS_URL, { params: filterOptions })
      ).map(jsonToPaymentRefund);
    },
    [getJson]
  );

  /**
   * Gets the payment refund with the provided id.
   */
  const getPaymentRefund = useCallback(
    async function (paymentRefundId: number): Promise<PaymentRefund> {
      return jsonToPaymentRefund(
        await getJson(
          `${PAYMENT_REFUNDS_URL}/${encodeURIComponent(paymentRefundId)}`
        )
      );
    },
    [getJson]
  );

  /**
   * Voids a payment refund.
   */
  const voidPaymentRefund = useCallback(
    async function (
      paymentRefundId: number,
      form: VoidPaymentRefundForm
    ): Promise<PaymentRefund> {
      return jsonToPaymentRefund(
        await putJson(
          `${PAYMENT_REFUNDS_URL}/${encodeURIComponent(
            paymentRefundId
          )}/status`,
          {
            newStatus: PaymentRefundStatus.Voided,
            ...form,
          }
        )
      );
    },
    [putJson]
  );

  /**
   * Creates a new payment refund from the provided payment refund fields and
   * returns it.
   */
  const createPaymentRefund = useCallback(
    async function (form: PaymentRefundForm): Promise<PaymentRefund> {
      const toCreate = {
        ...form,
        paymentRefundDate:
          form.paymentRefundDate && toISODateString(form.paymentRefundDate),
      };
      return jsonToPaymentRefund(await postJson(PAYMENT_REFUNDS_URL, toCreate));
    },
    [postJson]
  );

  return {
    getPaymentRefunds,
    getPaymentRefund,
    voidPaymentRefund,
    createPaymentRefund,
  };
}
