import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DataTableColumns } from '../../../components/DataTable';
import { User, UserStatus } from '../usersApi';
import { UserRowActions } from './UserRowActions';
import { StatusChip } from '../../../components/StatusChip';
import { NotSpecified } from '../../../components/NotSpecified';
import { useCatalog } from '../../../providers/CatalogsProvider';
import { replacePathParams, USER_PATH } from '../../../navigation/paths';
import { Link } from '../../../components/Link';

/**
 * Properties of the users table.
 */
export interface UsersTableProps {
  fetching: boolean;
  users: User[];
  setUsers: Dispatch<SetStateAction<User[] | null>>;
  refetchUsers: () => void;
  downloadButton: ReactNode;
}

/**
 * Table listing users.
 */
export function UsersTable({
  fetching,
  users,
  setUsers,
  refetchUsers,
  downloadButton,
}: UsersTableProps) {
  const [t] = useTranslation(['common', 'users']);
  const {
    isFetching: isFetchingOrganizationalUnitsCatalog,
    catalog: organizationalUnitsCatalog,
  } = useCatalog('organizational-units');

  function setUser(user: User) {
    setUsers(
      (users) =>
        users && users.map((u) => (u.username === user.username ? user : u))
    );
  }

  const columns: DataTableColumns<User> = {
    username: {
      label: t('users:userFields.username'),
      value: ({ username }) => username,
      render: (content, value) => (
        <Link to={replacePathParams(USER_PATH, { username: value })}>
          {content}
        </Link>
      ),
    },
    name: {
      label: t('users:userFields.name'),
      value: ({ firstName, surname }) =>
        [firstName, surname].filter((str) => str).join(' ') || null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>{t('users:nameNotSpecified')}</NotSpecified>
        ),
    },
    organizationalUnit: {
      label: t('users:userFields.organizationalUnit'),
      value: ({ organizationalUnitId }) =>
        organizationalUnitsCatalog?.find(
          (organizationalUnit) => organizationalUnit.id === organizationalUnitId
        )?.name ?? null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>
            {t('users:organizationalUnitNotSpecified')}
          </NotSpecified>
        ),
      fetching: isFetchingOrganizationalUnitsCatalog,
    },
    email: {
      label: t('users:userFields.email'),
      value: ({ email }) => email || null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>{t('users:emailNotSpecified')}</NotSpecified>
        ),
    },
    role: {
      label: t('users:userFields.role'),
      value: ({ role }) =>
        role ? (t(`users:userRole.${role}`) as string) : null,
      render: (content, value) =>
        value ? (
          content
        ) : (
          <NotSpecified>{t('users:roleNotSpecified')}</NotSpecified>
        ),
    },
    status: {
      label: t('users:userFields.status'),
      value: ({ status }) => status,
      format: (value) => t(`users:userStatus.${value}`),
      render: (content, status) => (
        <StatusChip
          status={status === UserStatus.Active ? 'success' : 'error'}
          label={content}
        />
      ),
      paddinglessY: true,
    },
    changedDate: {
      label: t('users:userFields.changedDate'),
      value: ({ changedDate }) => changedDate,
      defaultSortDirection: 'desc',
      hidden: true,
    },
    actions: {
      label: t('actions'),
      render: (_content, _value, row) => (
        <UserRowActions
          user={row}
          setUser={setUser}
          onUpdateStatusFailureDueToOutdatedUI={refetchUsers}
        />
      ),
      paddinglessY: true,
      width: 1,
      hideInPrint: true,
    },
  };

  return (
    <DataTable
      title={t('users:usersPage.dataTable.title')}
      rows={users}
      rowId={({ username }) => username}
      columns={columns}
      emptyMessage={t('users:usersPage.dataTable.emptyMessage')}
      toolbarActionsLeft={downloadButton}
      fetching={fetching}
      defaultSortBy="changedDate"
      minWidth={1100}
    />
  );
}
